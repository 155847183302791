import {
    CustomButtonContainer,
    PropertyContainer,
    PropertyLabel,
    Separator
} from "./styles"
import { ListItemOptions, Text } from '../../styles'

import TabMagicNFT from '../../ContentTabs/Tabs/TabMagicNFT'
import TabPresentation from "../../ContentTabs/Tabs/TabPresentation"
import TabLivestream from "../../ContentTabs/Tabs/TabLivestream"

export const ImageProperties = ({ setModalChildren, currentItemData }) => {
    return <>
        <PropertyLabel>Frame</PropertyLabel>
        <ListItemOptions>
            <Text>NFT</Text>
            <CustomButtonContainer
                onClick={() => setModalChildren(<TabMagicNFT />)}
            >
                Edit
            </CustomButtonContainer>
        </ListItemOptions>
        {currentItemData.properties['presentationMeta'] &&
            <ListItemOptions>
                <Text>Media files</Text>
                <CustomButtonContainer
                    onClick={() => setModalChildren(<TabPresentation />)}
                >
                    Edit
                </CustomButtonContainer>
            </ListItemOptions>
        }
        {currentItemData.properties['presentationMeta'] &&
            <ListItemOptions>
                <Text>Livestream</Text>
                <CustomButtonContainer
                    onClick={() => setModalChildren(<TabLivestream />)}
                >
                    Edit
                </CustomButtonContainer>
            </ListItemOptions>
        }
        <Separator />
    </>
}