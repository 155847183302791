import ObjectItem from "./ObjectItem";
import { inheritConstructor, SerAttr } from '../SerializableObject'
import { TypeEntity, UpdateState } from '../Constants'

export default class NFT3D extends ObjectItem {
    onConstructor() {
        super.onConstructor(...arguments)

        this.addProperty('imageMeta', {
            control: 'edit',
            type: 'object',
            // visible: false,
            visible: false,
            value: {
                default: null,
                get: property => {
                    return property._value
                },
                set: (property, value) => {
                    // console.log('change imageMeta property=', value, 'current=', property._value)
                    if (property._value === value) return
                    // console.log('change imageMeta property=', value, 'current=', property._value, this)
                    property._value = value
                    this.setUpdateState(UpdateState.Shape)
                }
            }
        })

        this.addProperty('visible', { control: 'edit', type: 'bool', visible: false, value: true })
    }
}

inheritConstructor(NFT3D, ObjectItem, 'NFT3D', {
    type: TypeEntity.ObjectItem,
    serializable: [
        new SerAttr('imageMeta'),
        new SerAttr('visible', { defaultValue: true })
    ]
})