import { EDITOR_CONTEXT_CONSTS } from './const';

const marketListReducer = (state, action) => {
  switch (action.type) {
    case EDITOR_CONTEXT_CONSTS.SET_SPACE: {
      return {
        ...state,
        space: action.payload,
      };
    }
    case EDITOR_CONTEXT_CONSTS.SET_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case EDITOR_CONTEXT_CONSTS.SET_ERROR: {
      return {
        ...state,
        isError: action.payload,
      };
    }
    default:
      return state;
  }
};

export default marketListReducer;
