import { EventDispatcher } from "EventDispatcher";

export default class LoadingManager {
    constructor({ app }) {
        this.app = app;
        this.progressPercents = {
            objectItem: 0,
            media: 0
        };

        this.htmlComponents = {
            startLogo: null,
            textProgress: null,
            circleProgress: null,
            loaderCircle: null,
            blocker: null
        };
        this.finishedLoading = false;
        this.events = new EventDispatcher();
    }

    onObjectItemProgress(objItemProgress) {
        if (!objItemProgress || this.finishedLoading) return;
        this.progressPercents.objectItem = objItemProgress;
        return this.onProgress();
    }

    onMediaProgress(mediaProgress) {
        if (!mediaProgress || this.finishedLoading) return;
        this.progressPercents.media = mediaProgress;
        if (this.progressPercents.media > 100) {
            console.warn(`LoadingManager: mediaProgress > 100% (${this.progressPercents.media})`);
            this.progressPercents.media = 100;
        }
        return this.onProgress();
    }

    addMediaProgress(mediaProgress) {
        return this.onMediaProgress(this.progressPercents.media + mediaProgress);
    }

    getMediaProgress() {
        return this.progressPercents.media;
    }

    getTotalProgress() {
        return this.progressPercents.objectItem;
        // const len = Object.values(this.progressPercents).length;
        // Get mean value of all progress bars
        // return Object.values(this.progressPercents).reduce((a, b) => a + b, 0) / len;
    }

    htmlComponentsLoaded() {
        return !Object.values(this.htmlComponents).includes(null);
    }

    onProgress(skip) {
        if (this.finishedLoading) return;
        const totalProgress = this.getTotalProgress();
        if (this.htmlComponents.startLogo)
            this.htmlComponents.startLogo.style.display = "none";
        if (this.htmlComponents.loaderCircle)
            this.htmlComponents.loaderCircle.style.display = "block";
        if (this.htmlComponents.textProgress)
            // this.htmlComponents.textProgress.textContent = `${totalProgress.toFixed(0)}%`;
        if (this.htmlComponents.circleProgress)
            this.htmlComponents.circleProgress.style.width = `${totalProgress}%`;
        if (totalProgress >= 100 || skip) {
            // console.log('Loading manager: loading finished')
            if (this.htmlComponents.blocker) {
                this.htmlComponents.blocker.style.display = "block";
            }
            this.finishedLoading = true;
            this.events.trigger("loadingFinished");
        }
    }
}