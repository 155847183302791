export const defaultSource = `
let totalTime = 0;
//This function is called once when object is loaded
function start() {

}

// This function is called every frame
function update(obj, dt) {
    totalTime += dt;
}

// This function is called when object is clicked
function click() {

}`;

export const templateSource = {
    "0": `let total = 0;
let rotationSpeed = 3;
// This function is called once when object is loaded
function start() {

}

// This function is called every frame
function update(obj, dt) {
    total += dt;
    
    obj.data.rotation.value.y = rotationSpeed  *  total;
    obj.update(8);
}

function click() {
    
}
    `,
    "1": `let total = 0;
let hoverSpeed = 1;
let startY = 3;
let amplitude = 1;

// This function is called once when object is loaded
function start() {

}

// This function is called every frame
function update(obj, dt) {
total += dt;

obj.data.position.value.y = startY + (amplitude * Math.sin(hoverSpeed * total) / 3.1415) ;
obj.update(8);
}

function click() {

}
    `,
    "2":`let modalOpen = false;
// This function is called once when object is loaded
function start() {
    const modalDiv = document.createElement("div");
    modalDiv.style = \`
    position: absolute; 
    height: 300px; 
    width: 400px;
    top: 50%; right: 50%;
    transform: translate(50%,-50%); 
    color: #fff;
    backdrop-filter: blur(34px);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    border-radius: 5px;
    color: black;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    \`;
    modalDiv.id = "modalDiv";

    const messageDiv = document.createElement("div");
    messageDiv.style = \`width: 80%; text-align: center; color: #fff;\`;
    messageDiv.textContent = "Hello world!";

    modalDiv.appendChild(messageDiv);
    
    const root = document.getElementById("root");
    root.appendChild(modalDiv);
}

// This function is called every frame
function update(obj, dt) {

}

function click() {
    modalOpen = !modalOpen;
    const modalDiv = document.getElementById("modalDiv");
    modalDiv.style.display = modalOpen ? "none" : "flex";
}
    `
};