
import * as service from '../../service'

export default class ServiceStore {
  //constructor() {}

  init() {
    service.init()
  }

  destroy() {
    service.destroy()
  }

  download(data, name, type, changeCb) {
    service.download(data, name, type, changeCb)
  }

  upload(changeCb, data) {
    service.upload(changeCb, data)
  }
}
