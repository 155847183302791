import { BaseAnimation } from "./BaseAnimation";
import { AnimationStates } from "./const/AnimationStates";

export default class StateIdle extends BaseAnimation {
    constructor(animationAction) {
        super(animationAction, 'STATE_IDLE');
        this.transitions = {
            STATE_WALK: 0.25,
            STATE_RUN: 0.25,
            STATE_DANCE: 0.2,
            STATE_JUMP: 0.2,
            STATE_FALL: 0.2
        };
        this.id = AnimationStates.STATE_IDLE;
    }
}