import IPFSGatewayTools from "@pinata/ipfs-gateway-tools/dist/browser";
const gatewayTools = new IPFSGatewayTools();
const desiredGateway = "https://gateway-metaplayerone.mypinata.cloud/ipfs/";
const gatewayToken = "yvSGF0ouOhbuESpyb57oScN-ZhppMjHXfMPblprc75sRqJxY4f0vcRRuxnUoJDi9"

const formatIPFSLink = (link, mediaWidth, mediaHeight) => {
  try {
    if (!link) return link;
    const cidData = gatewayTools.containsCID(link);
    if (cidData.containsCid) {
      let str = desiredGateway + cidData.cid + '?pinataGatewayToken=' + gatewayToken;
      if (mediaWidth) str += '&img-width=' + mediaWidth;
      if (mediaHeight) str += '&img-height=' + mediaHeight;
      return str;
    };
    return link;
  } catch (e) {
    console.warn(e);
    return link;
  }
};

export default formatIPFSLink;
