import React, { useContext } from 'react'
import styled from 'styled-components'
import { TypeEntity } from '../../../editor/Core/Constants'
import { Context } from '../../store/Context'
const Name = styled.h2`
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  font-size: 18px;
`

const isPicture = (item) => {
  return item.type === TypeEntity.Picture;
}

export default function PropertyName(props) {
  const { editorStore } = useContext(Context);

  let pictureId;
  if (isPicture(props?.item)) {
        const filtered = editorStore.editor?.project?.objects?.filter(el => isPicture(el));
        pictureId = filtered.findIndex(el => el?.id === props?.item?.id);
  }

  return <Name>
    {pictureId !== undefined && `#${pictureId + 1}`} {props.property.value?.toString()}
  </Name>
}
