import React, { useReducer } from 'react';
import useReducerFactory from './reducer';

import {
  MyNftsListDispatchContext,
  MyNftsListStateContext,
  defaultState,
  dispatchProvider,
  useMyNftsListContext,
  useMyNftsListDispatch,
} from './context';

const MyNftsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(useReducerFactory, defaultState);
  const caller = dispatchProvider(dispatch);

  return (
    <MyNftsListStateContext.Provider value={state}>
      <MyNftsListDispatchContext.Provider value={caller}>
        {children}
      </MyNftsListDispatchContext.Provider>
    </MyNftsListStateContext.Provider>
  );
};

const useMyNfts = () => ({
  ...useMyNftsListContext(),
  ...useMyNftsListDispatch(),
});

export { useMyNfts, MyNftsProvider };
