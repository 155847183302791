import { useEffect } from 'react';
import useAuth from '../../../../helpers/auth';
import { useLocation, useParams } from 'react-router-dom';
import { useEditor } from '.';
import { $host } from '../../../api/base';
import { AuthHelperService } from '../../../../helpers/auth/helpers';

export const useFetchEditorContext = () => {
  const { address, uid } = useParams();
  const { user } = useAuth();
  const { setLoading, setSpace, setError } = useEditor();

  const loadMetaSpace = async () => {
    try {
      setLoading(true);
      const apiKey = localStorage.getItem('apiToken');
      if (!apiKey && user?.get('sessionToken')) {
        await AuthHelperService.backendAuth(user.get('sessionToken'));
      }
      const { data } = await $host.get(`space/${address}&${uid}`);
      setSpace(data);
      setError(false);
      setLoading(false);
    } catch (e) {
      console.warn("loadMetaSpace error", e)
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadMetaSpace();
  }, [uid, user]);
};
