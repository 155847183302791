import styled from 'styled-components';
import { Column } from '../styles';

export const getStyle = (matches, width) => ({
  // position: 'absolute',
  // top: '50%',
  // left: '50%',
  overflow: 'hidden',
  minHeight: '50%',
  // transform: 'translate(-50%, -50%)',
  width: !matches ? `${width}px` : '98%',
  borderRadius: '20px',
  bgcolor: 'background.paper',
  // boxShadow: 4,
  background: '#0E0E0E',
  display: 'flex',
  flexDirection: 'column',
  padding: '40px',
  p: !matches ? 4 : 1,
});

export const ModalCotnentWrapper = styled(Column)`
  gap: 12px;
  height: 100%;
  flex: 1;
  justify-content: space-between;
`;

export const CloseContainer = styled.div`
  display: flex;
  min-width: ${(props => !props.matches ? '864px' : '98%')};
  cursor: pointer;
  color: #fff;
  font-size: 18px;
  justify-content: flex-end;
  font-weight: 700;
`;

export const Close = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  background: #1C1C1C;
  cursor: pointer;
`;