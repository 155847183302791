import { Button } from '@mui/material';
import styled from 'styled-components';

const ButtonSave = styled(Button)`
  background: linear-gradient(99.78deg, rgb(159, 11, 238) 0.69%, rgb(20, 91, 253) 98.32%);
  min-width: auto !important;
  font-family: 'Montserrat', sans-serif !important; 
  position: absolute !important;
  text-transform: capitalize !important;
  padding: 6px 4px !important;
  bottom: ${(props) => (props.isEditEnabled ? '120px' : '24px')};
  cursor: pointer;
  width: 130px;
  right: 24px;
  .css-1hc1lm0-MuiButtonBase-root-MuiButton-root:hover {
    background: #2a2a2a;
  }
`;
export const SubmitTokenBtn = ({ onClick, isEditEnabled }) => {
  return (
    <ButtonSave
      onClick={onClick}
      variant="contained"
      isEditEnabled={isEditEnabled}
    >
      Submit tokens
    </ButtonSave>
  );
};
