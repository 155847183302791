import {
  Texture,
  ImageLoader,
  TextureLoader,
  ClampToEdgeWrapping,
  MeshBasicMaterial,
  RepeatWrapping,
  FrontSide,
  Color,
  MeshStandardMaterial,
  // RGBFormat,
  RGBAFormat,
  VideoTexture,
  sRGBEncoding,
  LinearFilter,
  SRGBColorSpace,
} from 'three';
import fetchProgress from 'fetch-progress'

import ObjectItemRendererThree from './ObjectItem';

import { TypeEntity, UpdateState } from '../../../../Core/Constants';
import { createResizedImageAspected } from '../../../../../service';
import CONFIG from '../../../../../../config';

//const texturesCache = new Map()
const imagesCache = new Map();
const debugCache = false;
const resizeImages = true;
const resizeImagesSize = 1024;

export default class PictureRendererThree extends ObjectItemRendererThree {
  init() {
    super.init();

    this._imageCurrent = '';
    this._imageMetaCurrent = null;
    this.texture = null;
    this._needApply = false;
  }

  destroy() {
    super.destroy();
    if (this.texture) {
      this.texture.dispose();

      this.texture = null;
    }
    this._needApply = false;

    this._imageCurrent = '';
    this._imageMetaCurrent = null;
  }

  update(flags) {
    this.updateLoadObject(flags);
    this.updatePosition(flags);
    if (flags & UpdateState.Shape) {
      if (
        !this.item.data.imageMeta.value &&
        this._imageCurrent !== this.item.data.image.value
      ) {
        this._imageCurrent = this.item.data.image.value;
        this.applyImage(this._imageCurrent);
      }
      if (this._imageMetaCurrent !== this.item.data.imageMeta.value) {
        this._imageMetaCurrent = this.item.data.imageMeta.value;
        if (this._imageMetaCurrent) {
          this._artWork = this.app.managerNFT.roomArtworks?.find(
            (aw) =>
              aw.item.token_id === this._imageMetaCurrent.token_id &&
              aw.item.token_address === this._imageMetaCurrent.token_address
          )?.item?.metadata;
          if (this._imageMetaCurrent.isPresentation) {
            const slides = this.item.data.presentationMeta.value?.slides
            if (slides) {
              this.applyImage(slides[0].url, slides[0].isVideo, false, true)
            }
            return
          }
          if (this._artWork) {
            // console.log('this._artWork', this._artWork);
            const { url, isVideo } = this.getContentTypeFromMetadata(
              this._artWork
            );
            this.applyImage(url, isVideo);
          } else if (this._imageMetaCurrent?.isLivestream) {
            this.applyImage(this._imageMetaCurrent.url, true, false, false, true);
          } else {
            console.warn(
              'no artWork=',
              this._imageMetaCurrent,
              this.app.managerNFT.roomArtworks,
              this
            );
          }
        } else console.warn('no _imageMetaCurrent=', this);
      }
    }
  }

  getContentTypeFromMetadata(metadata) {
    return {
      isVideo: !!metadata.animation_url,
      isStream: !!metadata.stream,
      url: metadata?.animation_url ? metadata?.animation_url : metadata?.image,
    };
  }

  updateImage() {
    // console.log('details:', this.item.data.imageMeta.value, this._artWork);
    if (!this.item.data.imageMeta.value) this.applyImage(this._imageCurrent);
    else if (this._imageMetaCurrent?.isLivestream) {
      // console.log("Upply livestream", this);
      this.applyImage(this._imageMetaCurrent.url, true, false, false, true);
    } else if (this._artWork) {
      const { url, isVideo, isStream } = this.getContentTypeFromMetadata(this._artWork);
      // console.log(`Image meta: ${url} ${isVideo}`);
      this.applyImage(url, isVideo, false, false, isStream);
    } else if (this._imageMetaCurrent?.isPresentation) {
      this.applyPresentationFrame()
    } else {
      console.warn("Picture load unhandled", this)
    }
  }

  /**
   * This function is used to switch and apply a frame to a presentation frame
   * @param { bool } next - whether to switch to next or previous slide
   * @returns { int } index of a new slide
   */
  applyPresentationFrame(next = true) {
    if (this.currentSlide === undefined) {
      this.currentSlide = 0
    } else {
      this.currentSlide += next ? 1 : -1
      this.ignoreBlocking = true
    }
    const slidesLength = Object.keys(this.item.data.presentationMeta.value.slides).length
    if (this.currentSlide > slidesLength - 1 && next) this.currentSlide = 0
    if (this.currentSlide < 0 && !next) this.currentSlide = slidesLength - 1
    this.setPresentationFrame(this.currentSlide)
    return this.currentSlide
  }

  setPresentationFrame(slideId) {
    const slide = this.item.data.presentationMeta.value.slides[slideId]
    this.applyImage(slide.url, slide.isVideo, false, true)
  }

  applyImage(url, isVideo, ignoreProxy = url?.startsWith("blob:"), isPresentation = false, isStream = false) {
    if (!this._loaded) {
      this._needApply = true;
      /*console.warn('not loaded - force needApply=', this);*/ return;
    }
    this._needApply = false;

    const frameMesh = this.fameMesh;
    if (!frameMesh) {
      console.warn('no frameMesh=', this);
      return;
    }
    if (!frameMesh.material) {
      console.warn('no frameMesh.material=', this);
      return;
    }

    /*this._loadingTexture = this.engineComponent.startBlockingOperation()
        this.texture = texturesCache.get(url)
        if (!this.texture) {
            this.texture = new TextureLoader().load(url, 
                texture => {this.onLoadStopTexture ()//; console.log('texture loaded=', texture, texture.image.naturalWidth, texture.image.naturalHeight)
                },
                progress => {//console.log('texture load progress=', progress)
                },
                error => {this.onLoadStopTexture (); console.warn('texture load error=', error)}
            )
            texturesCache.set(url, this.texture)
        }*/

    const onSetTexture = (cacheDataImage) => {
      if (debugCache) console.log('onSetTexture=', cacheDataImage, url, this);
      this.texture.image = cacheDataImage.image;
      this.texture.format = cacheDataImage.format;
      this.texture.needsUpdate = true;

      this.onLoadStopTexture();
    };

    this._loadingTexture = this.engineComponent.startBlockingOperation();
    const totalMedia = this.app.project.objects.filter(obj => obj?.type === TypeEntity.Picture).length
    if (!isVideo) {
      url = ignoreProxy ? url : CONFIG.REACT_APP_SERVER_PROXY_URL + url;
      this.texture = new Texture();
    }
    let cacheData = imagesCache.get(url);
    if (cacheData && !isVideo) {
      if (cacheData.loading) {
        if (debugCache)
          console.log('image loading - add request=', cacheData, url, this);
        cacheData.requests.push(onSetTexture);
      } else if (cacheData.error) {
        if (debugCache)
          console.log(
            'image already loaded - but with error=',
            cacheData,
            url,
            this
          );
        this.onLoadStopTexture();
      } else {
        if (debugCache)
          console.log('image already loaded=', cacheData, url, this);
        onSetTexture(cacheData);
      }
    } else {
      if (isVideo) {
        const videoUid = url
        const viewMode = this.app.engineComponent.sceneComponent.controlsMode === 2
        if (imagesCache.get(videoUid)) {
          console.log(imagesCache.get(videoUid));
          const oldVideo = document.getElementById(videoUid);
          if (oldVideo && oldVideo?.pause) {
            oldVideo?.pause();
            oldVideo?.removeAttribute('src');
            oldVideo?.load();
            oldVideo?.remove();
            console.log(`Video with uid ${videoUid} removed`);
          }
        }
        const videoElement = document.createElement('video');
        const source = document.createElement('source');
        videoElement.appendChild(source)
        videoElement.setAttribute('crossorigin', 'anonymous')
        source.setAttribute('type', isStream ?
          (url.startsWith('https://www.youtube.com') ? 'video/youtube' : 'application/x-mpegURL') : 'video/mp4')
        videoElement.setAttribute('preload', 'metadata')
        videoElement.setAttribute('loop', 'loop')
        videoElement.setAttribute('id', videoUid)
        videoElement.setAttribute('webkit-playsinline', 'webkit-playsinline')
        videoElement.setAttribute('playsinline', 'playsinline')
        videoElement.setAttribute('style', 'display: none;')

        if (isPresentation || isStream) {
          videoElement.muted = true;
          videoElement.autoplay = true;
        }
        const root = document.getElementById('root');
        root.appendChild(videoElement);

        const proxiedUrl = ignoreProxy ? url : CONFIG.REACT_APP_SERVER_PROXY_URL + url;
        if (viewMode && !isStream) {
          // preload video fully in view mode
          let prevProgress, progressDiff;
          const app = this.app;
          const videoRequest = fetch(proxiedUrl)
            .then(fetchProgress({
              onProgress(progress) {
                const progressPercentage = progress.transferred / progress.total * 100;
                if (prevProgress) progressDiff = progressPercentage - prevProgress;
                else progressDiff = progressPercentage;
                prevProgress = progressPercentage;
                app.loadingManager.addMediaProgress(progressDiff / totalMedia);
              }
            }))
            .then(response => response.blob());

          videoRequest.then(blob => {
            videoElement.src = window.URL.createObjectURL(blob);
            videoElement.setAttribute('type', 'video/mp4')
            this.onLoadStopTexture();
          });
        } else {
          // use url as src for faster loading for edit/preview mode
          source.src = isStream ? url : proxiedUrl;
          if (isStream) {
            const options = {
              techOrder: ["html5", "youtube"],
              html5: {
                hls: {
                  overrideNative: !window.videojs.browser.IS_SAFARI,
                  nativeTextTracks: false
                }
              },
            }
            window.videojs(videoElement, options)
            // console.log("Playing stream", url, source)
          }
          this.onLoadStopTexture();
        }

        this.texture = new VideoTexture(videoElement)
        this.texture.flipY = false
        this.texture.colorSpace = SRGBColorSpace
        this.texture.magFilter = LinearFilter
        frameMesh.material = new MeshBasicMaterial({
          map: this.texture,
          side: FrontSide,
          toneMapped: false,
        })
        frameMesh.material.needsUpdate = true
        frameMesh.videoState = 'play'
        frameMesh.videoHtml = videoElement
        imagesCache.set(url, true)
        // console.log('applyVideo=', url, frameMesh.material, this);
      } else {
        let isError = false;
        cacheData = { loading: true, requests: [onSetTexture] };
        const loader = new ImageLoader();
        loader.crossOrigin = 'anonymous'
        loader.load(
          url,
          (image) => {
            if (debugCache)
              console.log(
                'image loaded=',
                cacheData,
                cacheData.requests.length,
                url,
                this
              );

            // JPEGs can't have an alpha channel, so memory can be saved by storing them as RGB.
            const isJPEG =
              url.search(/\.jpe?g($|\?)/i) > 0 ||
              url.search(/^data:image\/jpeg/) === 0;
            // cacheData.format = isJPEG ? RGBFormat : RGBAFormat;
            cacheData.format = RGBAFormat

            if (resizeImages) {
              const resizedImage = createResizedImageAspected(
                image,
                resizeImagesSize,
                (/*event*/) => {
                  cacheData.image = resizedImage;
                  cacheData.requests.forEach((request) => request(cacheData));
                  delete cacheData.requests;
                  delete cacheData.loading;
                }
              );
            } else {
              cacheData.image = image;
              cacheData.requests.forEach((request) => request(cacheData));
              delete cacheData.requests;
              delete cacheData.loading;
            }

            this.texture.flipY = false
            this.texture.wrapT = this.texture.wrapS = ClampToEdgeWrapping // RepeatWrapping
            this.texture.colorSpace = SRGBColorSpace
            // console.log('frameMesh.material.map old=', frameMesh.material.map)
            if (!frameMesh.material.map) frameMesh.material.needsUpdate = true
            frameMesh.material.map = this.texture
            frameMesh.material.side = FrontSide
            // console.log('applyImage=', url, this.texture, this)
          },
          (progress) => {
            // console.log('image load progress=', progress),
          },
          (error) => {
            isError = true
            console.warn('image load error=', error);
            if (debugCache)
              console.log(
                'image loaded with error=',
                cacheData,
                cacheData.requests.length,
                url,
                this
              );

            cacheData.error = error;
            cacheData.requests.forEach((request) => request(cacheData));
            delete cacheData.requests;
            delete cacheData.loading;
          }
        );
      }
    }
  }

  onLoadStopTexture() {
    this.engineComponent.finishBlockingOperation(this._loadingTexture);
    delete this._loadingTexture;
  }

  onLoad(gltf) {
    // console.log(`onLoad for:`, gltf);
    super.onLoad(...arguments);

    this.fameMesh = this.getFrameMesh();

    this.meshCollider.visible = false; // мешает смотреть картину - прозрачностью
    // this.meshCollider.position.x -= this.meshCollider.scale.x
    // this.group.children[1].position.y -= this.meshCollider.scale.y

    if (this._needApply) this.updateImage();
  }

  getFrameMesh() {
    if (!this.group) {
      console.warn('no group=', this);
      return null;
    }
    const imageObjects = this.group.children[1];
    if (!imageObjects) {
      console.warn('no imageObjects=', this);
      return null;
    }
    // console.log('imageObjects=', imageObjects)

    const logFixing = false;
    let frameMesh;
    // const frameMeshes = []
    imageObjects.traverse((object) => {
      if (object.isMesh) {
        // if (object.name?.includes('Frame_')) frameMeshes.push(object)
        if (object.name?.includes('picture_placeholder')) frameMesh = object;

        if (object.material.transparent === true) {
          object.material.transparent = false;
          logFixing && console.warn('fix material transparent=', object);
        }
        if (object.material.side !== FrontSide) {
          object.material.side = FrontSide;
          logFixing && console.warn('fix material side=', object);
        }
        if (object.material.depthWrite === false) {
          object.material.depthWrite = true;
          logFixing && console.warn('fix material depthWrite=', object);
        }

        /*const mSrc = object.material.toJSON()
                const mDst = new MeshStandardMaterial().toJSON()
                console.log('mSrc=', mSrc, 'mDst=', mDst, 'object=', object)
                console.log('*mSrc=', JSON.stringify(mSrc, null, '\t'), '*mDst=', JSON.stringify(mDst, null, '\t'))*/
        // object.material = new MeshStandardMaterial()
      }
    });
    // const frameMesh = frameMeshes[1]
    // console.log('frameMesh=', frameMesh)
    return frameMesh;
  }
}
