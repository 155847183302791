import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #000;
  z-index: 10;
`;

export const Container = styled.div`
  padding: 24px;
  backdrop-filter: blur(34px);
  background: linear-gradient(
    0deg,
    rgba(12, 12, 12, 0.3),
    rgba(12, 12, 12, 0.3)
  );
  border-radius: 16px;
`;

export const Title = styled.div`
  color: #fff;
  font-size: 16px;
  font-weight: 500;
`;

export const Subtitle = styled.div`
  color: #737373;
  font-size: 12px;
  font-weight: 400;
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ColumnsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 769px) {
    grid-template-columns: 1fr;
  }
`;

export const BigTitle = styled.div`
  font-size: 28px;
  color: #fefefe;
  text-align: center;
  padding-bottom: 24px;

  @media screen and (max-width: 769px) {
    font-size: 24px;
    padding-bottom: 16px;
  }
`;

export const Description = styled.div`
  color: #737373;
  font-size: 18px;
  font-weight: 400;
`;
