import ArtRoomsEditor from '../../editor/ArtRoomsEditor'

export default class EditorStore {
  editor = null

  constructor() {
    this.editor = new ArtRoomsEditor()
  }

  async init(container) {
    /*if (!this.editor) {
      this.editor = new ArtRoomsEditor({container})
      this.editor.engineComponent.container = container
      this.editor.init()
    } else console.warn('no editor')*/

    if (!this.editor) {console.warn('init - no editor'); return}
    if (this.editor._inited) {console.warn('init - already inited'); return}
    
    this.editor.create({container})
    //this.editor.engineComponent.container = container
    await this.editor.init()
    this.editor._inited = true
  }

  destroy() {
    if (!this.editor) {console.warn('destroy - no editor'); return}
    if (!this.editor._inited) {console.warn('destroy - already destroyed'); return}

    this.editor.destroy()
    this.editor._inited = false
    //this.editor = null
  }
}
