import React from 'react'
import { Route, Routes as Switch, BrowserRouter } from 'react-router-dom'

import EditPage from './editor/ui/pages/EditPage'

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/space/:address/:uid/:mode" element={<EditPage />} />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
