import { BaseAnimation } from "./BaseAnimation";
import { AnimationStates } from "./const/AnimationStates";

export default class StateDance extends BaseAnimation {
    constructor(animationAction) {
        super(animationAction, 'STATE_DANCE');
        this.transitions = {
            STATE_IDLE: 0.2,
            STATE_WALK: 0.25,
            STATE_RUN: 0.25,
            STATE_JUMP: 0.2
        };
        this.id = AnimationStates.STATE_DANCE;
    }
}