import styled from 'styled-components';
import { Column } from '../styles';
import { Button } from 'antd';

export const getStyle = (matches) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  overflow: 'hidden',
  minHeight: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: !matches ? '800px' : '98%',
  borderRadius: '20px',
  bgcolor: 'background.paper',
  boxShadow: 4,
  background: '#121212',
  display: 'flex',
  flexDirection: 'column',
  p: !matches ? 4 : 1,
});
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;  
  width: 100%;
  height: 100%;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
const Scrollbar = styled.div`
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: #161616;
    border-radius: 10px;
    -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
  }
  &::-webkit-scrollbar-thumb {
    background: #7E8390;
    border-radius: 10px;
  }
`;
export const NftWrapper = styled(Scrollbar)`
  gap: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  flex-wrap: wrap;
  height: 340px;
`;
export const ModalCotnentWrapper = styled(Column)`
  gap: 12px;
  height: 100%;
  flex: 1;
  justify-content: space-between;
`;
export const ButtonPublish = styled(Button)`
    border-radius: 50px;
    background: ${({disabled}) => disabled ? 'transparent' : 'linear-gradient(90deg, #4D44FE 0%, #8552F6 100%)'};
    box-shadow: inset 0px 0px 0px 2px ${({disabled}) => disabled ? '#969696' : '#4D44FE'};
    padding: 0;
    border: none;
    text-transform: uppercase;
    width: 48%;
    height: 40px;
    color: ${({disabled}) => disabled ? '#bbb' : '#fff'};
    cursor: ${({disabled}) => disabled ? 'auto' : 'pointer'};
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
`;
export const Title = styled.div`
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 5px;
`;
export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
`