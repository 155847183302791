import {Button} from '@mui/material'
import styled from 'styled-components'

const ButtonSave = styled(Button)`
  background: linear-gradient(99.78deg, rgb(159, 11, 238) 0.69%, rgb(20, 91, 253) 98.32%) !important;
  min-width: auto !important;
  position: absolute !important;
  text-transform: capitalize !important;
  padding: 5px -1px !important;
  bottom: 24px;
  width: 70px;
  right: 24px;
`
export const SaveBtn = ({onSave}) => {
  return (
    <ButtonSave onClick={onSave} variant="contained">
      Save
    </ButtonSave>
  )
}
