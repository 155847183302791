import Moralis from "../const/moralis";

export const getUrlProfile = (address) => {
    if (address) return address;
    const user = Moralis.User.current().get('ethAddress');
    switch (window.location.hostname) {
        case "meta.alpha.metaplayerone.app":
            return `https://alpha.metaplayerone.app/meta/${user}/metaspace`;
        case "meta.stage.metaplayerone.app":
            return `https://stage.metaplayerone.app/meta/${user}/metaspace`;
        case "meta.dev.metaplayerone.app":
            return `https://dev.metaplayerone.app/meta/${user}/metaspace`;
        case "localhost":
            return `http://localhost:3000/meta/${user}/metaspace`;
        default:
            return null;
    }
};
