import BaseRendererThree from './Base'

import { Color, Vector3, Group, Mesh, MeshStandardMaterial, BoxGeometry, SphereGeometry, LoadingManager, Box3, DoubleSide } from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'

import { TypeEntity, UpdateState } from '../../../../Core/Constants'
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { getBounds } from '../Algs'
import { getResourceUrl } from '../../../../Core/Algs/Basic'
import { MeshBVH, StaticGeometryGenerator, MeshBVHVisualizer } from 'three-mesh-bvh'
import { compileCustomScript } from '../../../../Core/CustomScripts';

// single geometry for all
// const geometry = new CapsuleGeometry(0.3, 1, 20, 20)
// const geometry = new SphereGeometry(1, 20, 20)
const geometry = new BoxGeometry()

export default class ObjectItemRendererThree extends BaseRendererThree {
  //constructor () {super(...arguments)}

  init() {
    this.meshCollider = null
    this.group = null
    this._needDestroy = false
    this._loaded = false
  }

  destroy() {
    if (this.meshCollider && this.group) {
      this.meshCollider.material.dispose()
      //this.meshCollider.geometry.dispose()
      this.engineComponent.sceneComponent.scene.remove(this.group)

      this.meshCollider = null
      this.group = null
      this._needDestroy = false
    } else {
      console.warn('destroy: no data for now - delayed')
      this._needDestroy = true
    }
    this._loaded = false
  }

  update(flags) {
    this.updateLoadObject(flags)

    this.updatePosition(flags)

    // Colors are set here
    // if (this.meshCollider && this.group) {
    //   if (flags & UpdateState.Style) {
    //     this.meshCollider.material.color = 
    //       this.item.data.hovered.value ? 
    //         new Color(0xffff00) : this.item.data.selected.value ? 
    //           new Color(0xff0000) : new Color(0xffffff)
    //   }
    // }
  }

  updatePosition(flags) {
    if (this.meshCollider && this.group) {
      if (flags & UpdateState.Shape) {
        this.group.scale.set(this.item.data.size.value.x, this.item.data.size.value.y, this.item.data.size.value.z)
        this.group.position.set(this.item.data.position.value.x, this.item.data.position.value.y, this.item.data.position.value.z)
        //if (this.item.__forcedQuat) this.group.quaternion.copy(this.item.__forcedQuat)
        //else 
        this.group.rotation.set(this.item.data.rotation.value.x, this.item.data.rotation.value.y, this.item.data.rotation.value.z)
      }
    }
  }

  extractDataToItem() {
    // no events to update
    this.item.data.size._value.x = this.group.scale.x
    this.item.data.size._value.y = this.group.scale.y
    this.item.data.size._value.z = this.group.scale.z

    this.item.data.position._value.x = this.group.position.x
    this.item.data.position._value.y = this.group.position.y
    this.item.data.position._value.z = this.group.position.z

    this.item.data.rotation._value.x = this.group.rotation.x
    this.item.data.rotation._value.y = this.group.rotation.y
    this.item.data.rotation._value.z = this.group.rotation.z
  }

  updateLoadObject(flags) {
    if (flags & UpdateState.Shape) {
      if (this._pathCurrent !== this.item.data.path.value) {
        // if (this.app.engineComponent.sceneComponent?.scene) {
        //   console.log("loaded item", this.item.id, "scene", this.app.engineComponent.sceneComponent.scene)
        //   this.app.engineComponent.sceneComponent.scene.children.forEach(sceneObject => {
        //     if (sceneObject?._itemMP?.id === this.item.id) {
        //       console.log("Remove object")
        //       this.app.engineComponent.sceneComponent.scene.remove(sceneObject);
        //     }
        //   });
        // }
        this._pathCurrent = this.item.data.path.value;
        const url = getResourceUrl(this._pathCurrent);
        // console.log('path=', this._pathCurrent, 'url=', url)
        const pbi = url.lastIndexOf("/");
        const progress = new LoadingManager();
        if (pbi !== -1) {
          const pathBase = url.substring(0, pbi + 1);
          const pathFile = url.substring(pbi + 1);
          // console.log('pathBase=', pathBase, 'pathFile=', pathFile)

          this._loadingGLTF = this.engineComponent.startBlockingOperation();
          this._loaded = false;

          const imageMeta = this.item.data?.imageMeta?.value
          if (imageMeta && this.item?.type === TypeEntity.ObjectItem) {
            // object is a 3D NFT
            this._artWork = this.app.managerNFT.roomArtworks?.find(
              aw => aw.item.token_id === imageMeta.token_id && aw.item.token_address === imageMeta.token_address
            )
          }

          let startLogo = document.getElementById("start_logo");
          let textProgress = document.getElementById("progress");
          let circleProgress = document.getElementById("processCircle");
          let loaderCircle = document.getElementById("loader_circle");
          let blocker = document.getElementById("blocker");

          this.engineComponent.app.loadingManager.htmlComponents = {
            textProgress, circleProgress, loaderCircle
          }

          progress.onProgress = (url, itemsLoaded, itemsTotal) => {
            if (itemsTotal > 0) {
              let prog = parseInt((itemsLoaded / itemsTotal) * 100);
              this.engineComponent.app.loadingManager.onObjectItemProgress(prog);
            }
          }

          const loader = new GLTFLoader(progress).setPath(pathBase);
          loader.load(
            pathFile,
            (gltf) => {
              this.onLoadStop3D();
              this.onLoad(gltf);
              // cameraFitView(gltf.scene)
            }, // called when the resource is loaded
            (xhr) => {
              /*console.log((xhr.loaded / xhr.total * 100) + '% loaded')*/
            }, // called while loading is progressing
            (error) => {
              this.onLoadStop3D();
              console.warn("An error happened=", error);
            } // called when loading has errors
          );
        } else console.warn("bad url=", url);
      }
    }
  }

  onLoadStop3D() {
    this.engineComponent.finishBlockingOperation(this._loadingGLTF)
    delete this._loadingGLTF
  }

  isGLTFSpace(name) {
    return name === this.engineComponent?.app?.project?.baseSpaceName;
  }

  onLoadSpace(gltfScene) {
    // const gltfScene = res.scene;
    // gltfScene.scale.setScalar(.01);

    // const box = new Box3();
    // box.setFromObject(gltfScene);
    // box.getCenter(gltfScene.position).negate();
    gltfScene.updateMatrixWorld(true);

    // visual geometry setup
    // const toMerge = {};
    // console.log(gltfScene)
    // const environment = new Group();
    // gltfScene.traverse(c => {
    //   if (c.isMesh) {
    //     // const hex = c.material.color.getHex();
    //     // toMerge[hex] = toMerge[hex] || [];
    //     // toMerge[hex].push(c);
    //     environment.add(c);
    //   }
    // });

    // for (const hex in toMerge) {
    //   const arr = toMerge[hex];
    //   const visualGeometries = [];
    //   arr.forEach(mesh => {
    //     if (mesh.material.emissive.r !== 0) {
    //       environment.attach(mesh);
    //     } else {
    //       const geom = mesh.geometry.clone();
    //       geom.applyMatrix4(mesh.matrixWorld);
    //       visualGeometries.push(geom);
    //     }
    //   });

    //   if (visualGeometries.length) {
    //     const newGeom = BufferGeometryUtils.mergeBufferGeometries(visualGeometries);
    //     const newMesh = new Mesh(newGeom, new MeshStandardMaterial({ color: parseInt(hex), shadowSide: 2 }));
    //     // newMesh.castShadow = true;
    //     // newMesh.receiveShadow = true;
    //     // newMesh.material.shadowSide = 2;

    //     environment.add(newMesh);

    //   }

    // }

    const staticGenerator = new StaticGeometryGenerator(gltfScene);
    staticGenerator.attributes = ['position'];

    const mergedGeometry = staticGenerator.generate();
    mergedGeometry.boundsTree = new MeshBVH(mergedGeometry);

    const collider = new Mesh(mergedGeometry);
    // collider.material.wireframe = true;
    collider.material.transparent = true;
    collider.visible = false;
    // collider.material.opacity = 0;

    // const visualizer = new MeshBVHVisualizer( collider, 10);
    // this.engineComponent.sceneComponent.scene.add(visualizer);
    return collider;
  }

  onLoad(gltf) {
    //this.onLoadStop()

    if (this._needDestroy) {
      this._needDestroy = false;
      return;
    }
    // console.log('onLoad=', gltf, this)

    // make bound - collider for whole gltf group
    const bounds = getBounds(gltf.scene);
    //console.log('bounds=', bounds, this)

    const material = new MeshStandardMaterial({
      opacity: 0,
      transparent: true,
    });

    const meshCollider = new Mesh(geometry, material);
    this.meshCollider = meshCollider;
    const size = bounds.box.getSize(new Vector3());
    const center = bounds.box.getCenter(new Vector3());
    meshCollider.scale.copy(size);
    meshCollider.position.copy(center);
    // console.log('size=', size, 'center=', center)
    //meshCollider.scale.set(bounds.sphere.radius, bounds.sphere.radius, bounds.sphere.radius)

    let group = new Group();
    this.group = group;
    group.add(meshCollider);
    group.add(gltf.scene);

    this.group._itemMP = this.item;
    meshCollider._itemMP = this.item;
    if (!this.engineComponent.sceneComponent?.scene) {
      console.warn("ObjectItem::onLoad: no scene object");
      return;
    }

    //this.mesh = gltf.scene
    //this.engineComponent.sceneComponent.scene.add(this.mesh)

    const isGLTFSpace = this.isGLTFSpace(this.item?.data?.name?.value);
    if (isGLTFSpace) {
      const collider = this.onLoadSpace(gltf.scene)
      const spaceLoaded = new CustomEvent("spaceLoaded", {
        detail: {
          object: this.item,
          collider
        }
      });
      group.add(collider);
      document.dispatchEvent(spaceLoaded);
    }

    this.engineComponent.sceneComponent.scene.add(group);

    gltf.scene.traverse((object) => {
      if (object.isMesh) {
        if (object.material) {
          // console.log('we have maatss', object.material)
          object.material.side = DoubleSide;
        }
        // object._isPickable = false
        object._itemMP = this.item;
        object.geometry.boundsTree = new MeshBVH(object.geometry);
        // console.log("object.isMesh",object )
        object.castShadow = true;
        if (isGLTFSpace) object.receiveShadow = true;
      }
    });

    this.item.setUpdateState(UpdateState.Shape);
    this.engineComponent._requestProjectUpdate = true;
    this.engineComponent.app.events.trigger("objectItemLoaded", {
      item: this.item,
    });

    if (this.item?.data?.script?.value) {
      // console.log(this.item?.data?.script?.value)
      const { start, update, click, error } = compileCustomScript(this.item.data.script.value, this.item);
      if (!error) {
        this.item.data._compiledUpdate = update;
        this.item.data._compiledClick = click;
        try { start(); } catch (e) { console.log(e) }
        // console.log(this.item)
      } else {
        console.log("Compile error", error)
      }
    }

    this._loaded = true;
  }
}