import { TabList, TabPanel } from "@mui/lab";
import { Tab, TextField } from "@mui/material";
import { Button } from "antd";
import styled from "styled-components";

export const ButtonPublish = styled(Button)`
  background: linear-gradient(90deg, #4E44FE 0%, #8452F6 100%);
  min-width: auto !important;
  border-radius: 50px !important;
  text-transform: uppercase;
  bottom: 5px;
  width: 50%;
  margin-left: 10px;
  font-family: Montserrat !important;
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 24px !important;
`

export const TitleFrame = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: #fff;
`;
export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 50px;
  max-height: 450px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: #161616;
    border-radius: 10px;
    -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
  }
  &::-webkit-scrollbar-thumb {
    background: #7E8390;
    border-radius: 10px;
  }
`;

export const ItemName = styled.div`
  font-size: 14px;
  color: #353535;
  font-weight: 600;
  vertical-align: middle;
  margin-bottom: 1%;
  
  span {
    color: #a4a4a4;
    font-size: 12px;
    margin-right: 15px;
  }
`;


export const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props?.small ? '20px' : '40px'};
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const BorderBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 0.4em;
`

export const CustomTabList = styled(TabList)`
  width: 100% !important;
  box-sizing: border-box !important;
  display: flex !important;
  span {
    display: none !important;
  }
  .MuiTab-root {
    font-family: Montserrat !important;
    font-size: 10px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    flex: 1 !important;
    background: #161616 !important;
    color: #7E8390 !important;
    text-transform: uppercase !important;
    border-radius: 50px !important;
  }
  & .MuiTabs-scroller {
    padding: 0 24px 0 24px !important;
  }
  .Mui-selected {
    background: linear-gradient(99.78deg, rgb(159, 11, 238) 0.69%, rgb(20, 91, 253) 98.32%) !important;
    color: #fff !important;
  }
`;
export const TabsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const CustomTab = styled(Tab)`
  font-size: 14px !important;
  color: black !important;
  text-transform: capitalize !important;
  padding: 8px !important;
  box-sizing: border-box !important;
  min-height: auto !important;
  margin: 8px !important;
  justify-content: space-between !important;
  background: #eee !important;
  border-radius: 7px !important;

  svg {
    margin: 0 !important;
  }
  @media (max-width: 400px) {
    font-size: 12px !important;
    min-width: auto !important;
  }
`;

export const CenteredButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
export const Close = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  background: #161616;
  cursor: pointer;
`;

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

export const StyledInput = styled(TextField)`
  box-shadow: none;
  border-color: transparent;

  .MuiInputLabel-root {
    color: #fff;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 0;
  }

  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
    webkit-appearance: none;
  }
  .MuiFormLabel-root {
    display: none;
  }

  .MuiOutlinedInput-root {
    padding-right: 20px;
    overflow: 'hidden';
    height: 32px;
    color: #fff;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    background: #161616;
    border-radius: 6px;
    
    

    & fieldset {
      &.MuiOutlinedInput-notchedOutline {
          border: 0;
      }
    }
  }
`
export const GradientText = styled.div`
  background: linear-gradient(90deg, #01A6FF 0%, #01F9FF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  width: fit-content;
`

export const StyledTabPanel = styled(TabPanel)``;

export const Wrapper = styled.div`
  & .MuiTabPanel-root {
    padding: 0;
  }
`;