import React from 'react';
import {
  Container,
  Wrapper,
  ColumnsWrapper,
  ItemWrapper,
  Subtitle,
  BigTitle,
  Title,
} from './style';
import { useEditor } from '../../../store/editor-context';

const amountKeys = ['access_token_balance_of', 'access_fee'];

const AccessDenied = () => {
  const { space: editorSpace } = useEditor();
  const { space, owner_of } = editorSpace || {};
  const param_whitelist = ['access_fee', 'access_token_address', 'access_token_balance_of'];

  return (
    <Wrapper>
      {space && (
        <Container>
          <BigTitle>Access denied</BigTitle>
          <ColumnsWrapper>
            {Object.keys(space).filter(e => param_whitelist.includes(e)).map((item) => (
              <ItemWrapper>
                <Title>
                  {item
                    .split('_')
                    .map((key) => key[0].toUpperCase() + key.slice(1))
                    .join(' ')}
                </Title>
                <Subtitle>
                  {amountKeys.includes(item)
                    ? space[item] / 10 ** 18
                    : space[item]}
                </Subtitle>
              </ItemWrapper>
            ))}

            {Object.keys(owner_of).map((item) => (
              <ItemWrapper>
                <Title>{item[0].toUpperCase() + item.slice(1)}</Title>
                <Subtitle>{owner_of[item]}</Subtitle>
              </ItemWrapper>
            ))}
          </ColumnsWrapper>
        </Container>
      )}
    </Wrapper>
  );
};

export default AccessDenied;
