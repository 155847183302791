/* eslint-disable no-mixed-operators */
/* eslint-disable no-use-before-define */
import {EventDispatcher as EventDispatcher9, Quaternion as Quaternion3, Vector3 as Vector35} from 'three'
import {EventDispatcher, Matrix4, Spherical, Vector3} from 'three'
import {EventDispatcher as EventDispatcher2, Vector3 as Vector33} from 'three'
import {Vector3 as Vector32} from 'three'
import {EventDispatcher as EventDispatcher8} from 'three'
import {EventDispatcher as EventDispatcher3} from 'three'
import {EventDispatcher as EventDispatcher4} from 'three'
import {EventDispatcher as EventDispatcher5, Vector3 as Vector34} from 'three'
import {EventDispatcher as EventDispatcher6} from 'three'
import {EventDispatcher as EventDispatcher7} from 'three'

/**
 * spatial-controls v5.0.8 build Thu Dec 09 2021
 * https://github.com/vanruesc/spatial-controls
 * Copyright 2021 Raoul van Rüschen
 * @license Zlib
 */
// src/core/Action.ts
var Action = /* @__PURE__ */ (Action2 => {
  Action2[(Action2['MOVE_FORWARD'] = 0)] = 'MOVE_FORWARD'
  Action2[(Action2['MOVE_LEFT'] = 1)] = 'MOVE_LEFT'
  Action2[(Action2['MOVE_BACKWARD'] = 2)] = 'MOVE_BACKWARD'
  Action2[(Action2['MOVE_RIGHT'] = 3)] = 'MOVE_RIGHT'
  Action2[(Action2['MOVE_DOWN'] = 4)] = 'MOVE_DOWN'
  Action2[(Action2['MOVE_UP'] = 5)] = 'MOVE_UP'
  Action2[(Action2['ZOOM_OUT'] = 6)] = 'ZOOM_OUT'
  Action2[(Action2['ZOOM_IN'] = 7)] = 'ZOOM_IN'
  Action2[(Action2['BOOST'] = 8)] = 'BOOST'
  Action2[(Action2['ROTATE'] = 9)] = 'ROTATE'
  return Action2
})(Action || {})

// src/core/ControlMode.ts
var ControlMode = /* @__PURE__ */ (ControlMode2 => {
  ControlMode2['FIRST_PERSON'] = 'first-person'
  ControlMode2['THIRD_PERSON'] = 'third-person'
  return ControlMode2
})(ControlMode || {})

// src/core/Direction.ts
var Direction = /* @__PURE__ */ (Direction2 => {
  Direction2[(Direction2['FORWARD'] = 0)] = 'FORWARD'
  Direction2[(Direction2['LEFT'] = 1)] = 'LEFT'
  Direction2[(Direction2['BACKWARD'] = 2)] = 'BACKWARD'
  Direction2[(Direction2['RIGHT'] = 3)] = 'RIGHT'
  Direction2[(Direction2['DOWN'] = 4)] = 'DOWN'
  Direction2[(Direction2['UP'] = 5)] = 'UP'
  return Direction2
})(Direction || {})

// src/core/SpatialControls.ts

// src/strategies/BoostStrategy.ts
var BoostStrategy = class {
  movementState
  constructor(movementState) {
    this.movementState = movementState
  }
  execute(flag) {
    this.movementState.boost = flag
  }
}

// src/strategies/MovementStrategy.ts
var MovementStrategy = class {
  movementState
  direction
  constructor(movementState, direction) {
    this.movementState = movementState
    this.direction = direction
  }
  execute(flag) {
    const state = this.movementState
    switch (this.direction) {
      case Direction.BACKWARD:
        state.backward = flag
        state.backwardBeforeForward = flag
        break
      case Direction.FORWARD:
        state.forward = flag
        state.backwardBeforeForward = !flag
        break
      case Direction.RIGHT:
        state.right = flag
        state.rightBeforeLeft = flag
        break
      case Direction.LEFT:
        state.left = flag
        state.rightBeforeLeft = !flag
        break
      case Direction.UP:
        state.up = flag
        state.upBeforeDown = flag
        break
      case Direction.DOWN:
        state.down = flag
        state.upBeforeDown = !flag
        break
      default:
        break
    }
  }
}

// src/input/KeyCode.ts
var KeyCode = /* @__PURE__ */ (KeyCode2 => {
  KeyCode2[(KeyCode2['BACKSPACE'] = 8)] = 'BACKSPACE'
  KeyCode2[(KeyCode2['TAB'] = 9)] = 'TAB'
  KeyCode2[(KeyCode2['ENTER'] = 13)] = 'ENTER'
  KeyCode2[(KeyCode2['SHIFT'] = 16)] = 'SHIFT'
  KeyCode2[(KeyCode2['CTRL'] = 17)] = 'CTRL'
  KeyCode2[(KeyCode2['ALT'] = 18)] = 'ALT'
  KeyCode2[(KeyCode2['PAUSE'] = 19)] = 'PAUSE'
  KeyCode2[(KeyCode2['CAPS_LOCK'] = 20)] = 'CAPS_LOCK'
  KeyCode2[(KeyCode2['ESCAPE'] = 27)] = 'ESCAPE'
  KeyCode2[(KeyCode2['SPACE'] = 32)] = 'SPACE'
  KeyCode2[(KeyCode2['PAGE_UP'] = 33)] = 'PAGE_UP'
  KeyCode2[(KeyCode2['PAGE_DOWN'] = 34)] = 'PAGE_DOWN'
  KeyCode2[(KeyCode2['END'] = 35)] = 'END'
  KeyCode2[(KeyCode2['HOME'] = 36)] = 'HOME'
  KeyCode2[(KeyCode2['LEFT'] = 37)] = 'LEFT'
  KeyCode2[(KeyCode2['UP'] = 38)] = 'UP'
  KeyCode2[(KeyCode2['RIGHT'] = 39)] = 'RIGHT'
  KeyCode2[(KeyCode2['DOWN'] = 40)] = 'DOWN'
  KeyCode2[(KeyCode2['INSERT'] = 45)] = 'INSERT'
  KeyCode2[(KeyCode2['DELETE'] = 46)] = 'DELETE'
  KeyCode2[(KeyCode2['DIGIT_0'] = 48)] = 'DIGIT_0'
  KeyCode2[(KeyCode2['DIGIT_1'] = 49)] = 'DIGIT_1'
  KeyCode2[(KeyCode2['DIGIT_2'] = 50)] = 'DIGIT_2'
  KeyCode2[(KeyCode2['DIGIT_3'] = 51)] = 'DIGIT_3'
  KeyCode2[(KeyCode2['DIGIT_4'] = 52)] = 'DIGIT_4'
  KeyCode2[(KeyCode2['DIGIT_5'] = 53)] = 'DIGIT_5'
  KeyCode2[(KeyCode2['DIGIT_6'] = 54)] = 'DIGIT_6'
  KeyCode2[(KeyCode2['DIGIT_7'] = 55)] = 'DIGIT_7'
  KeyCode2[(KeyCode2['DIGIT_8'] = 56)] = 'DIGIT_8'
  KeyCode2[(KeyCode2['DIGIT_9'] = 57)] = 'DIGIT_9'
  KeyCode2[(KeyCode2['A'] = 65)] = 'A'
  KeyCode2[(KeyCode2['B'] = 66)] = 'B'
  KeyCode2[(KeyCode2['C'] = 67)] = 'C'
  KeyCode2[(KeyCode2['D'] = 68)] = 'D'
  KeyCode2[(KeyCode2['E'] = 69)] = 'E'
  KeyCode2[(KeyCode2['F'] = 70)] = 'F'
  KeyCode2[(KeyCode2['G'] = 71)] = 'G'
  KeyCode2[(KeyCode2['H'] = 72)] = 'H'
  KeyCode2[(KeyCode2['I'] = 73)] = 'I'
  KeyCode2[(KeyCode2['J'] = 74)] = 'J'
  KeyCode2[(KeyCode2['K'] = 75)] = 'K'
  KeyCode2[(KeyCode2['L'] = 76)] = 'L'
  KeyCode2[(KeyCode2['M'] = 77)] = 'M'
  KeyCode2[(KeyCode2['N'] = 78)] = 'N'
  KeyCode2[(KeyCode2['O'] = 79)] = 'O'
  KeyCode2[(KeyCode2['P'] = 80)] = 'P'
  KeyCode2[(KeyCode2['Q'] = 81)] = 'Q'
  KeyCode2[(KeyCode2['R'] = 82)] = 'R'
  KeyCode2[(KeyCode2['S'] = 83)] = 'S'
  KeyCode2[(KeyCode2['T'] = 84)] = 'T'
  KeyCode2[(KeyCode2['U'] = 85)] = 'U'
  KeyCode2[(KeyCode2['V'] = 86)] = 'V'
  KeyCode2[(KeyCode2['W'] = 87)] = 'W'
  KeyCode2[(KeyCode2['X'] = 88)] = 'X'
  KeyCode2[(KeyCode2['Y'] = 89)] = 'Y'
  KeyCode2[(KeyCode2['Z'] = 90)] = 'Z'
  KeyCode2[(KeyCode2['META_LEFT'] = 91)] = 'META_LEFT'
  KeyCode2[(KeyCode2['META_RIGHT'] = 92)] = 'META_RIGHT'
  KeyCode2[(KeyCode2['SELECT'] = 93)] = 'SELECT'
  KeyCode2[(KeyCode2['NUMPAD_0'] = 96)] = 'NUMPAD_0'
  KeyCode2[(KeyCode2['NUMPAD_1'] = 97)] = 'NUMPAD_1'
  KeyCode2[(KeyCode2['NUMPAD_2'] = 98)] = 'NUMPAD_2'
  KeyCode2[(KeyCode2['NUMPAD_3'] = 99)] = 'NUMPAD_3'
  KeyCode2[(KeyCode2['NUMPAD_4'] = 100)] = 'NUMPAD_4'
  KeyCode2[(KeyCode2['NUMPAD_5'] = 101)] = 'NUMPAD_5'
  KeyCode2[(KeyCode2['NUMPAD_6'] = 102)] = 'NUMPAD_6'
  KeyCode2[(KeyCode2['NUMPAD_7'] = 103)] = 'NUMPAD_7'
  KeyCode2[(KeyCode2['NUMPAD_8'] = 104)] = 'NUMPAD_8'
  KeyCode2[(KeyCode2['NUMPAD_9'] = 105)] = 'NUMPAD_9'
  KeyCode2[(KeyCode2['MULTIPLY'] = 106)] = 'MULTIPLY'
  KeyCode2[(KeyCode2['ADD'] = 107)] = 'ADD'
  KeyCode2[(KeyCode2['SUBTRACT'] = 109)] = 'SUBTRACT'
  KeyCode2[(KeyCode2['DECIMAL_POINT'] = 110)] = 'DECIMAL_POINT'
  KeyCode2[(KeyCode2['DIVIDE'] = 111)] = 'DIVIDE'
  KeyCode2[(KeyCode2['F1'] = 112)] = 'F1'
  KeyCode2[(KeyCode2['F2'] = 113)] = 'F2'
  KeyCode2[(KeyCode2['F3'] = 114)] = 'F3'
  KeyCode2[(KeyCode2['F4'] = 115)] = 'F4'
  KeyCode2[(KeyCode2['F5'] = 116)] = 'F5'
  KeyCode2[(KeyCode2['F6'] = 117)] = 'F6'
  KeyCode2[(KeyCode2['F7'] = 118)] = 'F7'
  KeyCode2[(KeyCode2['F8'] = 119)] = 'F8'
  KeyCode2[(KeyCode2['F9'] = 120)] = 'F9'
  KeyCode2[(KeyCode2['F10'] = 121)] = 'F10'
  KeyCode2[(KeyCode2['F11'] = 122)] = 'F11'
  KeyCode2[(KeyCode2['F12'] = 123)] = 'F12'
  KeyCode2[(KeyCode2['NUM_LOCK'] = 144)] = 'NUM_LOCK'
  KeyCode2[(KeyCode2['SCROLL_LOCK'] = 145)] = 'SCROLL_LOCK'
  KeyCode2[(KeyCode2['SEMICOLON'] = 186)] = 'SEMICOLON'
  KeyCode2[(KeyCode2['EQUAL_SIGN'] = 187)] = 'EQUAL_SIGN'
  KeyCode2[(KeyCode2['COMMA'] = 188)] = 'COMMA'
  KeyCode2[(KeyCode2['DASH'] = 189)] = 'DASH'
  KeyCode2[(KeyCode2['PERIOD'] = 190)] = 'PERIOD'
  KeyCode2[(KeyCode2['FORWARD_SLASH'] = 191)] = 'FORWARD_SLASH'
  KeyCode2[(KeyCode2['GRAVE_ACCENT'] = 192)] = 'GRAVE_ACCENT'
  KeyCode2[(KeyCode2['OPEN_BRACKET'] = 219)] = 'OPEN_BRACKET'
  KeyCode2[(KeyCode2['BACK_SLASH'] = 220)] = 'BACK_SLASH'
  KeyCode2[(KeyCode2['CLOSE_BRACKET'] = 221)] = 'CLOSE_BRACKET'
  KeyCode2[(KeyCode2['SINGLE_QUOTE'] = 222)] = 'SINGLE_QUOTE'
  return KeyCode2
})(KeyCode || {})

// src/input/PointerBehaviour.ts
var PointerBehaviour = /* @__PURE__ */ (PointerBehaviour2 => {
  PointerBehaviour2['DEFAULT'] = 'default'
  PointerBehaviour2['LOCK'] = 'lock'
  PointerBehaviour2['LOCK_HOLD'] = 'lock-hold'
  return PointerBehaviour2
})(PointerBehaviour || {})

// src/input/PointerButton.ts
var PointerButton = /* @__PURE__ */ (PointerButton2 => {
  PointerButton2[(PointerButton2['MAIN'] = 0)] = 'MAIN'
  PointerButton2[(PointerButton2['AUXILIARY'] = 1)] = 'AUXILIARY'
  PointerButton2[(PointerButton2['SECONDARY'] = 2)] = 'SECONDARY'
  return PointerButton2
})(PointerButton || {})

// src/input/PointerType.ts
var PointerType = /* @__PURE__ */ (PointerType2 => {
  PointerType2['MOUSE'] = 'mouse'
  PointerType2['PEN'] = 'pen'
  PointerType2['TOUCH'] = 'touch'
  return PointerType2
})(PointerType || {})

// src/strategies/RotationStrategy.ts
var RotationStrategy = class {
  controls
  constructor(controls) {
    this.controls = controls
  }
  execute(flag, event) {
    const behaviour = this.controls.settings.pointer.getBehaviour()
    const isMouse = event.type === 'mousedown' || event.type === 'mouseup'
    if (isMouse && behaviour !== PointerBehaviour.DEFAULT) {
      this.controls.setPointerLocked()
    } else {
      this.controls.setRotationEnabled(flag)
    }
  }
}

// src/strategies/ZoomStrategy.ts
var ZoomStrategy = class {
  rotationManager
  zoomIn
  constructor(rotationManager, zoomIn) {
    this.rotationManager = rotationManager
    this.zoomIn = zoomIn
  }
  execute(flag) {
    if (flag) {
      this.rotationManager.zoom(this.zoomIn ? -1 : 1)
    }
  }
}

// src/managers/MovementState.ts
var MovementState = class {
  left
  right
  forward
  backward
  up
  down
  backwardBeforeForward
  rightBeforeLeft
  upBeforeDown
  boost
  constructor() {
    this.reset()
  }
  get active() {
    return this.forward || this.backward || this.left || this.right || this.up || this.down
  }
  reset() {
    this.left = false
    this.right = false
    this.forward = false
    this.backward = false
    this.up = false
    this.down = false
    this.backwardBeforeForward = false
    this.rightBeforeLeft = false
    this.upBeforeDown = false
    this.boost = false
    return this
  }
}

// src/managers/RotationManager.ts

// src/core/time.ts
var MILLISECONDS_TO_SECONDS = 1 / 1e3

// src/math/ScalarDamper.ts
var ScalarDamper = class {
  maxSpeed
  velocity
  constructor(maxSpeed = Number.POSITIVE_INFINITY) {
    this.maxSpeed = maxSpeed
    this.velocity = 0
  }
  resetVelocity() {
    this.velocity = 0
  }
  interpolate(a, b, lambda, omega, exp, dt) {
    const maxChange = this.maxSpeed * Math.max(lambda, 1e-4)
    const change = Math.min(Math.max(a - b, -maxChange), maxChange)
    const c = a - change
    const velocity = this.velocity
    const t = (velocity + omega * change) * dt
    this.velocity = (velocity - omega * t) * exp
    let result = c + (change + t) * exp
    if (Math.abs(change) < 1e-6) {
      result = b
      this.velocity = 0
    } else if (b - a > 0 === result > b) {
      this.velocity = (result - b) / dt
      result = b
    }
    return result
  }
  static calculateOmega(lambda) {
    return 2 / Math.max(lambda, 1e-4)
  }
  static calculateExp(omega, dt) {
    const x2 = omega * dt
    const x22 = x2 * x2
    return 1 / (1 + x2 + 0.48 * x22 + 0.235 * x2 * x22)
  }
}

// src/managers/RotationManager.ts
var TWO_PI = Math.PI * 2
var u = new Vector3()
var v = new Vector3()
var m = new Matrix4()
var RotationManager = class extends EventDispatcher {
  position
  quaternion
  target
  settings
  spherical0
  spherical1
  scalarDampers
  timestamp
  updateEvent
  constructor(position, quaternion, target, settings) {
    super()
    this.position = position
    this.quaternion = quaternion
    this.target = target
    this.settings = settings
    this.spherical0 = new Spherical()
    this.spherical1 = new Spherical()
    this.timestamp = 0
    this.updateEvent = {type: 'update'}
    this.scalarDampers = [new ScalarDamper(), new ScalarDamper(), new ScalarDamper()]
  }
  setPosition(position) {
    this.position = position
    return this
  }
  setQuaternion(quaternion) {
    this.quaternion = quaternion
    return this
  }
  setTarget(target) {
    this.target = target
    return this
  }
  resetVelocity() {
    this.spherical1.copy(this.spherical0)
    for (const scalarDamper of this.scalarDampers) {
      scalarDamper.resetVelocity()
    }
  }
  restrictAngles() {
    const s = this.spherical1
    const rotation = this.settings.rotation
    const thetaMin = rotation.getMinAzimuthalAngle()
    const thetaMax = rotation.getMaxAzimuthalAngle()
    const phiMin = rotation.getMinPolarAngle()
    const phiMax = rotation.getMaxPolarAngle()
    s.theta = Math.min(Math.max(s.theta, thetaMin), thetaMax)
    s.phi = Math.min(Math.max(s.phi, phiMin), phiMax)
    if (s.phi === 0 || s.phi === Math.PI) {
      s.makeSafe()
    }
    return this
  }
  restrictRadius() {
    const s = this.spherical1
    const zoom = this.settings.zoom
    const min = zoom.getMinDistance()
    const max = zoom.getMaxDistance()
    s.radius = Math.min(Math.max(s.radius, min), max)
    return this
  }
  getRadius() {
    return this.spherical0.radius
  }
  restrictSpherical() {
    return this.restrictRadius().restrictAngles()
  }
  updateSpherical() {
    if (this.settings.general.getMode() === ControlMode.THIRD_PERSON) {
      const pivotOffset = this.settings.rotation.getPivotOffset()
      v.subVectors(u.subVectors(this.position, pivotOffset), this.target)
      this.spherical1.setFromVector3(v)
    } else {
      this.spherical1.setFromVector3(this.target)
    }
    this.restrictSpherical()
    this.spherical0.copy(this.spherical1)
    return this
  }
  updatePosition() {
    if (this.settings.general.getMode() === ControlMode.THIRD_PERSON) {
      const pivotOffset = this.settings.rotation.getPivotOffset()
      this.position.setFromSpherical(this.spherical0).add(this.target).add(pivotOffset)
    }
    return this
  }
  updateQuaternion() {
    const settings = this.settings
    const rotation = settings.rotation
    const target = this.target
    const up = u.copy(rotation.getUpVector())
    const phi = this.spherical0.phi % TWO_PI
    if ((phi < 0 && phi > -Math.PI) || (phi > Math.PI && phi < TWO_PI)) {
      up.negate()
    }
    if (settings.general.getMode() === ControlMode.THIRD_PERSON) {
      m.lookAt(v.subVectors(this.position, target), rotation.getPivotOffset(), up)
    } else {
      m.lookAt(v.set(0, 0, 0), target.setFromSpherical(this.spherical0), up)
    }
    this.quaternion.setFromRotationMatrix(m)
    this.dispatchEvent(this.updateEvent)
    return this
  }
  adjustSpherical(theta, phi) {
    const s = this.spherical1
    const settings = this.settings
    const rotation = settings.rotation
    const invertedY = rotation.isInvertedY()
    const orbit = settings.general.getMode() === ControlMode.THIRD_PERSON
    const orbitXorInvertedY = (orbit || invertedY) && !(orbit && invertedY)
    s.theta = !rotation.isInvertedX() ? s.theta - theta : s.theta + theta
    s.phi = orbitXorInvertedY ? s.phi - phi : s.phi + phi
    return this.restrictAngles()
  }
  zoom(sign) {
    const s = this.spherical1
    const settings = this.settings
    const zoom = settings.zoom
    if (zoom.isEnabled() && settings.general.getMode() === ControlMode.THIRD_PERSON) {
      const amount = sign * zoom.getSensitivity()
      s.radius = zoom.isInverted() ? s.radius - amount : s.radius + amount
      this.restrictRadius()
    }
    return this
  }
  lookAt(point) {
    if (this.settings.general.getMode() === ControlMode.THIRD_PERSON) {
      this.target.copy(point).sub(this.settings.rotation.getPivotOffset())
    } else {
      this.target.subVectors(point, this.position).normalize()
    }
    return this
  }
  getViewDirection(view) {
    const settings = this.settings
    const orbit = settings.general.getMode() === ControlMode.THIRD_PERSON
    view.setFromSpherical(this.spherical0).normalize()
    return orbit ? view.negate() : view
  }
  update(timestamp) {
    const s0 = this.spherical0
    const s1 = this.spherical1
    const equal = s0.radius === s1.radius && s0.theta === s1.theta && s0.phi === s1.phi
    if (!equal) {
      const settings = this.settings
      const scalarDampers = this.scalarDampers
      const elapsed = (timestamp - this.timestamp) * MILLISECONDS_TO_SECONDS
      if (settings.rotation.getDamping() > 0) {
        const damping = settings.rotation.getDamping()
        const omega = ScalarDamper.calculateOmega(damping)
        const exp = ScalarDamper.calculateExp(omega, elapsed)
        s0.theta = scalarDampers[0].interpolate(s0.theta, s1.theta, damping, omega, exp, elapsed)
        s0.phi = scalarDampers[1].interpolate(s0.phi, s1.phi, damping, omega, exp, elapsed)
      } else {
        s0.theta = s1.theta
        s0.phi = s1.phi
      }
      if (settings.zoom.getDamping() > 0) {
        const damping = settings.zoom.getDamping()
        const omega = ScalarDamper.calculateOmega(damping)
        const exp = ScalarDamper.calculateExp(omega, elapsed)
        s0.radius = scalarDampers[2].interpolate(s0.radius, s1.radius, damping, omega, exp, elapsed)
      } else {
        s0.radius = s1.radius
      }
      this.updatePosition().updateQuaternion()
    } else {
      if (Math.abs(s0.theta) >= TWO_PI) {
        s0.theta %= TWO_PI
        s1.theta %= TWO_PI
      }
      if (Math.abs(s0.phi) >= TWO_PI) {
        s0.phi %= TWO_PI
        s1.phi %= TWO_PI
      }
    }
    this.timestamp = timestamp
  }
}

// src/managers/TranslationManager.ts

// src/core/axes.ts
var x = new Vector32(1, 0, 0)
var y = new Vector32(0, 1, 0)
var z = new Vector32(0, 0, 1)

// src/managers/TranslationManager.ts
var v2 = new Vector33()
var TranslationManager = class extends EventDispatcher2 {
  position
  quaternion
  target
  settings
  movementState
  velocity0
  velocity1
  scalarDampers
  timestamp
  updateEvent
  constructor(position, quaternion, target, settings) {
    super()
    this.position = position
    this.quaternion = quaternion
    this.target = target
    this.settings = settings
    this.movementState = new MovementState()
    this.velocity0 = new Vector33()
    this.velocity1 = new Vector33()
    this.timestamp = 0
    this.updateEvent = {type: 'update'}
    this.scalarDampers = [new ScalarDamper(), new ScalarDamper(), new ScalarDamper()]
    this.moveSpeedMultiplier = 1
  }
  getMovementState() {
    return this.movementState
  }
  setPosition(position) {
    this.position = position
    return this
  }
  setQuaternion(quaternion) {
    this.quaternion = quaternion
    return this
  }
  setTarget(target) {
    this.target = target
    return this
  }
  resetVelocity() {
    this.velocity0.set(0, 0, 0)
    this.velocity1.set(0, 0, 0)
    for (const scalarDamper of this.scalarDampers) {
      scalarDamper.resetVelocity()
    }
  }
  translateOnAxis(axis, distance) {
    v2.copy(axis).applyQuaternion(this.quaternion).multiplyScalar(distance)
    this.position.add(v2)
    if (this.settings.general.getMode() === ControlMode.THIRD_PERSON) {
      this.target.add(v2)
    }
  }
  translate(elapsed) {
    const v4 = this.velocity0
    if (v4.x !== 0) {
      this.translateOnAxis(x, v4.x * elapsed)
    }
    if (v4.y !== 0) {
      this.translateOnAxis(y, v4.y * elapsed)
    }
    if (v4.z !== 0) {
      this.translateOnAxis(z, v4.z * elapsed)
    }
    this.dispatchEvent(this.updateEvent)
  }
  update(timestamp) {
    const settings = this.settings
    if (settings.translation.isEnabled()) {
      const state = this.movementState
      const translation = this.settings.translation
      const boost = state.boost ? translation.getBoostMultiplier() : 1
      const sensitivity = translation.getSensitivity()
      const scalarDampers = this.scalarDampers
      const v0 = this.velocity0
      const v1 = this.velocity1
      const elapsed = (timestamp - this.timestamp) * MILLISECONDS_TO_SECONDS
      const speed = sensitivity * boost * this.moveSpeedMultiplier
      v1.setScalar(0)
      if (state.active) {
        if (state.backward && state.forward) {
          v1.z = state.backwardBeforeForward ? speed : -speed
        } else if (state.backward) {
          v1.z = speed
        } else if (state.forward) {
          v1.z = -speed
        }
        if (state.right && state.left) {
          v1.x = state.rightBeforeLeft ? speed : -speed
        } else if (state.right) {
          v1.x = speed
        } else if (state.left) {
          v1.x = -speed
        }
        if (state.up && state.down) {
          v1.y = state.upBeforeDown ? speed : -speed
        } else if (state.up) {
          v1.y = speed
        } else if (state.down) {
          v1.y = -speed
        }
      }
      if (!v0.equals(v1)) {
        if (translation.getDamping() > 0) {
          const damping = translation.getDamping()
          const omega = ScalarDamper.calculateOmega(damping)
          const exp = ScalarDamper.calculateExp(omega, elapsed)
          v0.x = scalarDampers[0].interpolate(v0.x, v1.x, damping, omega, exp, elapsed)
          v0.y = scalarDampers[1].interpolate(v0.y, v1.y, damping, omega, exp, elapsed)
          v0.z = scalarDampers[2].interpolate(v0.z, v1.z, damping, omega, exp, elapsed)
        } else {
          v0.copy(v1)
        }
      }
      if (v0.x !== 0 || v0.y !== 0 || v0.z !== 0) {
        this.translate(elapsed)
      }
    }
    this.timestamp = timestamp
  }
}

// src/settings/Settings.ts

// src/settings/GeneralSettings.ts
var GeneralSettings = class extends EventDispatcher3 {
  mode
  previousMode
  constructor() {
    super()
    this.mode = ControlMode.FIRST_PERSON
    this.previousMode = this.mode
  }
  getPreviousMode() {
    return this.previousMode
  }
  getMode() {
    return this.mode
  }
  setMode(value) {
    if (this.mode !== value) {
      this.mode = value
      this.dispatchEvent({type: 'change'})
      this.previousMode = value
    }
  }
  copy(settings) {
    this.mode = settings.getMode()
    return this
  }
  clone() {
    const clone = new GeneralSettings()
    return clone.copy(this)
  }
  fromJSON(json) {
    this.mode = json.mode
    return this
  }
  toJSON() {
    return {
      mode: this.mode
    }
  }
}

// src/settings/Bindings.ts
var Bindings = class {
  defaultActions
  actions
  constructor() {
    this.defaultActions = /* @__PURE__ */ new Map()
    this.actions = /* @__PURE__ */ new Map()
  }
  reset() {
    this.actions = new Map(this.defaultActions)
    return this
  }
  setDefault(actions) {
    this.defaultActions = actions
    return this.reset()
  }
  clearDefault() {
    this.defaultActions.clear()
    return this
  }
  clear() {
    this.actions.clear()
    return this
  }
  copy(bindings) {
    this.defaultActions = new Map(bindings.defaultActions)
    this.actions = new Map(bindings.actions)
    return this
  }
  clone() {
    const clone = new Bindings()
    return clone.copy(this)
  }
  fromJSON(json) {
    if (json !== void 0) {
      this.defaultActions = new Map(json.defaultActions)
      this.actions = new Map(json.actions)
    }
    return this
  }
  has(key) {
    return this.actions.has(key)
  }
  get(key) {
    return this.actions.get(key)
  }
  set(key, action) {
    this.actions.set(key, action)
    return this
  }
  delete(key) {
    return this.actions.delete(key)
  }
  toJSON() {
    return {
      defaultActions: [...this.defaultActions],
      actions: [...this.actions]
    }
  }
}

// src/settings/PointerSettings.ts
var PointerSettings = class extends EventDispatcher4 {
  behaviour
  sensitivity
  constructor() {
    super()
    this.behaviour = PointerBehaviour.DEFAULT
    this.sensitivity = 1e-3
  }
  getBehaviour() {
    return this.behaviour
  }
  setBehaviour(value) {
    this.behaviour = value
    this.dispatchEvent({type: 'change'})
  }
  getSensitivity() {
    return this.sensitivity
  }
  setSensitivity(value) {
    this.sensitivity = value
    this.dispatchEvent({type: 'change'})
  }
  copy(settings) {
    this.behaviour = settings.getBehaviour()
    this.sensitivity = settings.getSensitivity()
    return this
  }
  clone() {
    const clone = new PointerSettings()
    return clone.copy(this)
  }
  fromJSON(json) {
    this.behaviour = json.behaviour
    this.sensitivity = json.sensitivity
    return this
  }
  toJSON() {
    return {
      behaviour: this.behaviour,
      sensitivity: this.sensitivity
    }
  }
}

// src/settings/RotationSettings.ts
var RotationSettings = class extends EventDispatcher5 {
  up
  pivotOffset
  minAzimuthalAngle
  maxAzimuthalAngle
  minPolarAngle
  maxPolarAngle
  invertedX
  invertedY
  sensitivityX
  sensitivityY
  damping
  constructor() {
    super()
    this.up = new Vector34()
    this.up.copy(y)
    this.pivotOffset = new Vector34()
    this.minAzimuthalAngle = Number.NEGATIVE_INFINITY
    this.maxAzimuthalAngle = Number.POSITIVE_INFINITY
    this.minPolarAngle = 0
    this.maxPolarAngle = Math.PI
    this.invertedX = false
    this.invertedY = false
    this.sensitivityX = 1
    this.sensitivityY = 1
    this.damping = 0
  }
  getUpVector() {
    return this.up
  }
  setUpVector(value) {
    this.up = value
    this.dispatchEvent({type: 'change'})
  }
  getPivotOffset() {
    return this.pivotOffset
  }
  setPivotOffset(value) {
    this.pivotOffset = value
    this.dispatchEvent({type: 'change'})
  }
  getMinAzimuthalAngle() {
    return this.minAzimuthalAngle
  }
  setMinAzimuthalAngle(value) {
    this.minAzimuthalAngle = value
    this.dispatchEvent({type: 'change'})
  }
  getMaxAzimuthalAngle() {
    return this.maxAzimuthalAngle
  }
  setMaxAzimuthalAngle(value) {
    this.maxAzimuthalAngle = value
    this.dispatchEvent({type: 'change'})
  }
  getMinPolarAngle() {
    return this.minPolarAngle
  }
  setMinPolarAngle(value) {
    this.minPolarAngle = value
    this.dispatchEvent({type: 'change'})
  }
  getMaxPolarAngle() {
    return this.maxPolarAngle
  }
  setMaxPolarAngle(value) {
    this.maxPolarAngle = value
    this.dispatchEvent({type: 'change'})
  }
  isInvertedX() {
    return this.invertedX
  }
  setInvertedX(value) {
    this.invertedX = value
    this.dispatchEvent({type: 'change'})
  }
  isInvertedY() {
    return this.invertedY
  }
  setInvertedY(value) {
    this.invertedY = value
    this.dispatchEvent({type: 'change'})
  }
  getSensitivityX() {
    return this.sensitivityX
  }
  setSensitivityX(value) {
    this.sensitivityX = value
    this.dispatchEvent({type: 'change'})
  }
  getSensitivityY() {
    return this.sensitivityY
  }
  setSensitivityY(value) {
    this.sensitivityY = value
    this.dispatchEvent({type: 'change'})
  }
  setSensitivity(value) {
    this.sensitivityX = this.sensitivityY = value
    this.dispatchEvent({type: 'change'})
  }
  getDamping() {
    return this.damping
  }
  setDamping(value) {
    this.damping = value
    this.dispatchEvent({type: 'change'})
  }
  copy(settings) {
    this.up.copy(settings.getUpVector())
    this.pivotOffset.copy(settings.getPivotOffset())
    this.minAzimuthalAngle = settings.getMinAzimuthalAngle()
    this.maxAzimuthalAngle = settings.getMaxAzimuthalAngle()
    this.minPolarAngle = settings.getMinPolarAngle()
    this.maxPolarAngle = settings.getMaxPolarAngle()
    this.invertedX = settings.isInvertedX()
    this.invertedY = settings.isInvertedY()
    this.sensitivityX = settings.getSensitivityX()
    this.sensitivityY = settings.getSensitivityY()
    this.damping = settings.getDamping()
    return this
  }
  clone() {
    const clone = new RotationSettings()
    return clone.copy(this)
  }
  fromJSON(json) {
    this.up.copy(json.up)
    this.pivotOffset.copy(json.pivotOffset)
    this.minAzimuthalAngle = json.minAzimuthalAngle !== null ? json.minAzimuthalAngle : Number.NEGATIVE_INFINITY
    this.maxAzimuthalAngle = json.maxAzimuthalAngle !== null ? json.maxAzimuthalAngle : Number.POSITIVE_INFINITY
    this.minPolarAngle = json.minPolarAngle !== null ? json.minPolarAngle : Number.NEGATIVE_INFINITY
    this.maxPolarAngle = json.maxPolarAngle !== null ? json.maxPolarAngle : Number.POSITIVE_INFINITY
    this.invertedX = json.invertedX
    this.invertedY = json.invertedY
    this.sensitivityX = json.sensitivityX
    this.sensitivityY = json.sensitivityY
    this.damping = json.damping
    return this
  }
  toJSON() {
    return {
      up: this.up,
      pivotOffset: this.pivotOffset,
      minAzimuthalAngle: this.minAzimuthalAngle,
      maxAzimuthalAngle: this.maxAzimuthalAngle,
      minPolarAngle: this.minPolarAngle,
      maxPolarAngle: this.maxPolarAngle,
      invertedX: this.invertedX,
      invertedY: this.invertedY,
      sensitivityX: this.sensitivityX,
      sensitivityY: this.sensitivityY,
      damping: this.damping
    }
  }
}

// src/settings/TranslationSettings.ts
var TranslationSettings = class extends EventDispatcher6 {
  enabled
  sensitivity
  boostMultiplier
  damping
  constructor() {
    super()
    this.enabled = true
    this.sensitivity = 1
    this.boostMultiplier = 2
    this.damping = 0
  }
  isEnabled() {
    return this.enabled
  }
  setEnabled(value) {
    this.enabled = value
    this.dispatchEvent({type: 'change'})
  }
  getSensitivity() {
    return this.sensitivity
  }
  setSensitivity(value) {
    this.sensitivity = value
    this.dispatchEvent({type: 'change'})
  }
  getBoostMultiplier() {
    return this.boostMultiplier
  }
  setBoostMultiplier(value) {
    this.boostMultiplier = Math.max(value, 1)
    this.dispatchEvent({type: 'change'})
  }
  getDamping() {
    return this.damping
  }
  setDamping(value) {
    this.damping = value
    this.dispatchEvent({type: 'change'})
  }
  copy(settings) {
    this.enabled = settings.isEnabled()
    this.sensitivity = settings.getSensitivity()
    this.boostMultiplier = settings.getBoostMultiplier()
    this.damping = settings.getDamping()
    return this
  }
  clone() {
    const clone = new TranslationSettings()
    return clone.copy(this)
  }
  fromJSON(json) {
    this.enabled = json.enabled
    this.sensitivity = json.sensitivity
    this.boostMultiplier = json.boostMultiplier
    this.damping = json.damping
    return this
  }
  toJSON() {
    return {
      enabled: this.enabled,
      sensitivity: this.sensitivity,
      boostMultiplier: this.boostMultiplier,
      damping: this.damping
    }
  }
}

// src/settings/ZoomSettings.ts
var ZoomSettings = class extends EventDispatcher7 {
  enabled
  inverted
  minDistance
  maxDistance
  sensitivity
  damping
  constructor() {
    super()
    this.enabled = true
    this.inverted = false
    this.minDistance = 1e-6
    this.maxDistance = Number.POSITIVE_INFINITY
    this.sensitivity = 1
    this.damping = 0
  }
  isEnabled() {
    return this.enabled
  }
  setEnabled(value) {
    this.enabled = value
    this.dispatchEvent({type: 'change'})
  }
  isInverted() {
    return this.inverted
  }
  setInverted(value) {
    this.inverted = value
    this.dispatchEvent({type: 'change'})
  }
  getMinDistance() {
    return this.minDistance
  }
  setMinDistance(value) {
    this.minDistance = Math.min(Math.max(value, 1e-6), Number.POSITIVE_INFINITY)
    this.dispatchEvent({type: 'change'})
  }
  getMaxDistance() {
    return this.maxDistance
  }
  setMaxDistance(value) {
    this.maxDistance = Math.min(Math.max(value, this.minDistance), Number.POSITIVE_INFINITY)
    this.dispatchEvent({type: 'change'})
  }
  setRange(min, max) {
    this.minDistance = min
    this.maxDistance = max
    this.dispatchEvent({type: 'change'})
  }
  getSensitivity() {
    return this.sensitivity
  }
  setSensitivity(value) {
    this.sensitivity = value
    this.dispatchEvent({type: 'change'})
  }
  getDamping() {
    return this.damping
  }
  setDamping(value) {
    this.damping = value
    this.dispatchEvent({type: 'change'})
  }
  copy(settings) {
    this.enabled = settings.isEnabled()
    this.inverted = settings.isInverted()
    this.minDistance = settings.getMinDistance()
    this.maxDistance = settings.getMaxDistance()
    this.sensitivity = settings.getSensitivity()
    this.damping = settings.getDamping()
    return this
  }
  clone() {
    const clone = new ZoomSettings()
    return clone.copy(this)
  }
  fromJSON(json) {
    this.enabled = json.enabled
    this.inverted = json.inverted
    this.minDistance = json.minDistance
    this.maxDistance = json.maxDistance || Number.POSITIVE_INFINITY
    this.sensitivity = json.sensitivity
    this.damping = json.damping
    return this
  }
  toJSON() {
    return {
      enabled: this.enabled,
      inverted: this.inverted,
      minDistance: this.minDistance,
      maxDistance: this.maxDistance,
      sensitivity: this.sensitivity,
      damping: this.damping
    }
  }
}

// src/settings/Settings.ts
var Settings = class extends EventDispatcher8 {
  keyBindings
  pointerBindings
  general
  pointer
  rotation
  translation
  zoom
  constructor() {
    super()
    this.keyBindings = new Bindings()
    this.keyBindings.setDefault(
      /* @__PURE__ */ new Map([
        [KeyCode.W, Action.MOVE_FORWARD],
        // [KeyCode.UP, Action.MOVE_FORWARD],
        [KeyCode.A, Action.MOVE_LEFT],
        // [KeyCode.LEFT, Action.MOVE_LEFT],
        [KeyCode.S, Action.MOVE_BACKWARD],
        // [KeyCode.DOWN, Action.MOVE_BACKWARD],
        [KeyCode.D, Action.MOVE_RIGHT],
        // [KeyCode.RIGHT, Action.MOVE_RIGHT],
        [KeyCode.X, Action.MOVE_DOWN],
        [KeyCode.SPACE, Action.MOVE_UP],
        [KeyCode.PAGE_DOWN, Action.ZOOM_OUT],
        [KeyCode.PAGE_UP, Action.ZOOM_IN],
        [KeyCode.SHIFT, Action.BOOST]
      ])
    )
    this.pointerBindings = new Bindings()
    this.pointerBindings.setDefault(/* @__PURE__ */ new Map([[PointerButton.MAIN, Action.ROTATE]]))
    this.general = new GeneralSettings()
    this.pointer = new PointerSettings()
    this.rotation = new RotationSettings()
    this.translation = new TranslationSettings()
    this.zoom = new ZoomSettings()
    this.general.addEventListener('change', e => this.dispatchEvent(e))
    this.pointer.addEventListener('change', e => this.dispatchEvent(e))
    this.rotation.addEventListener('change', e => this.dispatchEvent(e))
    this.translation.addEventListener('change', e => this.dispatchEvent(e))
    this.zoom.addEventListener('change', e => this.dispatchEvent(e))
  }
  copy(settings) {
    this.keyBindings.copy(settings.keyBindings)
    this.pointerBindings.copy(settings.pointerBindings)
    this.general.copy(settings.general)
    this.pointer.copy(settings.pointer)
    this.rotation.copy(settings.rotation)
    this.translation.copy(settings.translation)
    this.zoom.copy(settings.zoom)
    this.dispatchEvent({type: 'change'})
    return this
  }
  clone() {
    const clone = new Settings()
    return clone.copy(this)
  }
  fromJSON(json) {
    const settings = JSON.parse(json)
    this.keyBindings.fromJSON(settings.keyBindings)
    this.pointerBindings.fromJSON(settings.pointerBindings)
    this.general.fromJSON(settings.general)
    this.pointer.fromJSON(settings.pointer)
    this.rotation.fromJSON(settings.rotation)
    this.translation.fromJSON(settings.translation)
    this.zoom.fromJSON(settings.zoom)
    this.dispatchEvent({type: 'change'})
    return this
  }
  toBlob() {
    return new Blob([JSON.stringify(this)], {
      type: 'text/json'
    })
  }
  toJSON() {
    return {
      keyBindings: this.keyBindings,
      pointerBindings: this.pointerBindings,
      general: this.general,
      pointer: this.pointer,
      rotation: this.rotation,
      translation: this.translation,
      zoom: this.zoom
    }
  }
}

// src/core/SpatialControls.ts
var v3 = new Vector35()
var SpatialControls = class extends EventDispatcher9 {
  domElement
  position
  quaternion
  target
  previousPosition
  previousQuaternion
  previousTarget
  rotationManager
  translationManager
  strategies
  dragging
  enabled
  settings
  constructor(position = new Vector35(), quaternion = new Quaternion3(), domElement = null) {
    super()
    if (domElement === null && typeof document !== 'undefined') {
      this.domElement = document.body
    } else {
      this.domElement = domElement
    }
    this.position = position
    this.quaternion = quaternion
    this.target = new Vector35()
    this.previousPosition = new Vector35()
    this.previousQuaternion = new Quaternion3()
    this.previousTarget = new Vector35()
    const settings = (this.settings = new Settings())
    settings.addEventListener('change', e => this.handleEvent(e))
    this.rotationManager = new RotationManager(position, quaternion, this.target, settings)
    this.translationManager = new TranslationManager(position, quaternion, this.target, settings)
    this.rotationManager.addEventListener('update', e => this.dispatchEvent(e))
    this.translationManager.addEventListener('update', e => this.dispatchEvent(e))
    const state = this.translationManager.getMovementState()
    this.strategies = /* @__PURE__ */ new Map([
      [Action.MOVE_FORWARD, new MovementStrategy(state, Direction.FORWARD)],
      [Action.MOVE_LEFT, new MovementStrategy(state, Direction.LEFT)],
      [Action.MOVE_BACKWARD, new MovementStrategy(state, Direction.BACKWARD)],
      [Action.MOVE_RIGHT, new MovementStrategy(state, Direction.RIGHT)],
      [Action.MOVE_DOWN, new MovementStrategy(state, Direction.DOWN)],
      [Action.MOVE_UP, new MovementStrategy(state, Direction.UP)],
      [Action.ZOOM_OUT, new ZoomStrategy(this.rotationManager, false)],
      [Action.ZOOM_IN, new ZoomStrategy(this.rotationManager, true)],
      [Action.BOOST, new BoostStrategy(state)],
      [Action.ROTATE, new RotationStrategy(this)]
    ])
    this.dragging = false
    this.enabled = false
    if (position !== null && quaternion !== null) {
      this.target.set(0, 0, -1).applyQuaternion(this.quaternion)
      this.lookAt(this.target)
      if (domElement !== null) {
        this.setEnabled()
      }
      this.previousPosition.copy(this.position)
      this.previousQuaternion.copy(this.quaternion)
      this.previousTarget.copy(this.target)
    }
  }
  getDomElement() {
    return this.domElement
  }
  setDomElement(domElement) {
    const enabled = this.enabled
    if (domElement !== null) {
      if (enabled) {
        this.setEnabled(false)
      }
      this.domElement = domElement
      this.setEnabled(enabled)
    }
    return this
  }
  getPosition() {
    return this.position
  }
  setPosition(x2, y2, z2) {
    if (x2 instanceof Vector35) {
      this.position = x2
      this.rotationManager.setPosition(x2)
      this.translationManager.setPosition(x2)
    } else {
      this.position.set(x2, y2, z2)
    }
    return this
  }
  getQuaternion() {
    return this.quaternion
  }
  setQuaternion(quaternion) {
    this.quaternion = quaternion
    this.rotationManager.setQuaternion(quaternion)
    this.translationManager.setQuaternion(quaternion)
    return this
  }
  getTarget() {
    return this.target
  }
  setTarget(x2, y2, z2) {
    if (x2 instanceof Vector35) {
      this.target = x2
      this.rotationManager.setTarget(x2)
      this.translationManager.setTarget(x2)
    } else {
      this.target.set(x2, y2, z2)
    }
    return this
  }
  lookAt(x2, y2, z2) {
    if (x2 instanceof Vector35) {
      this.rotationManager.lookAt(x2)
    } else {
      this.rotationManager.lookAt(v3.set(x2, y2, z2))
    }
    return this
  }
  getViewDirection(view) {
    return this.rotationManager.getViewDirection(view)
  }
  isEnabled() {
    return this.enabled
  }
  setEnabled(enabled = true) {
    const domElement = this.domElement
    this.translationManager.getMovementState().reset()
    if (enabled && !this.enabled) {
      domElement.style.touchAction = 'none'
      document.addEventListener('pointerlockchange', this)
      document.addEventListener('pointerlockerror', this)
      document.addEventListener('visibilitychange', this)
      document.addEventListener('keyup', this)
      document.addEventListener('keydown', this)
      domElement.addEventListener('mousedown', this)
      domElement.addEventListener('mouseup', this)
      domElement.addEventListener('pointerdown', this)
      domElement.addEventListener('pointerup', this)
      domElement.addEventListener('pointercancel', this)
      domElement.addEventListener('wheel', this, {passive: true})
    } else if (!enabled && this.enabled) {
      domElement.style.touchAction = null
      document.removeEventListener('pointerlockchange', this)
      document.removeEventListener('pointerlockerror', this)
      document.removeEventListener('visibilitychange', this)
      document.removeEventListener('keyup', this)
      document.removeEventListener('keydown', this)
      domElement.removeEventListener('mousedown', this)
      domElement.removeEventListener('mouseup', this)
      domElement.removeEventListener('pointerdown', this)
      domElement.removeEventListener('pointerup', this)
      domElement.removeEventListener('pointercancel', this)
      domElement.removeEventListener('wheel', this)
      domElement.removeEventListener('pointermove', this)
    }
    this.translationManager.resetVelocity()
    this.rotationManager.resetVelocity()
    this.setPointerLocked(false)
    this.enabled = enabled
    return this
  }
  copy(controls) {
    const p = (this.position = controls.getPosition())
    const q = (this.quaternion = controls.getQuaternion())
    const t = (this.target = controls.getTarget())
    this.domElement = controls.getDomElement()
    this.settings.copy(controls.settings)
    this.rotationManager.setPosition(p).setQuaternion(q).setTarget(t)
    this.translationManager.setPosition(p).setQuaternion(q).setTarget(t)
    return this.lookAt(t)
  }
  clone() {
    const clone = new SpatialControls()
    return clone.copy(this)
  }
  setPointerLocked(locked = true) {
    if (locked) {
      if (document.pointerLockElement !== this.domElement && this.domElement.requestPointerLock !== void 0) {
        this.domElement.requestPointerLock()
      }
    } else if (document.exitPointerLock !== void 0) {
      document.exitPointerLock()
    }
  }
  setRotationEnabled(enabled) {
    if (enabled) {
      this.domElement.addEventListener('pointermove', this, {passive: true})
    } else {
      this.domElement.removeEventListener('pointermove', this)
    }
  }
  handlePointerMoveEvent(event) {
    const settings = this.settings
    const rotation = settings.rotation
    const pointerBehaviour = settings.pointer.getBehaviour()
    const pointerSensitivity = settings.pointer.getSensitivity()
    const rotationManager = this.rotationManager
    if (pointerBehaviour !== PointerBehaviour.LOCK_HOLD || this.dragging) {
      rotationManager.adjustSpherical(
        event.movementX * pointerSensitivity * rotation.getSensitivityX(),
        event.movementY * pointerSensitivity * rotation.getSensitivityY()
      )
    }
  }
  handlePointerButtonEvent(event, pressed) {
    const bindings = this.settings.pointerBindings
    const behaviour = this.settings.pointer.getBehaviour()
    if (bindings.has(event.button)) {
      const action = bindings.get(event.button)
      if (!(event instanceof PointerEvent && event.pointerType === PointerType.MOUSE)) {
        const strategy = this.strategies.get(action)
        strategy.execute(pressed, event)
        if (action === Action.ROTATE) {
          this.dragging = pressed
        }
      }
      if (event instanceof PointerEvent) {
        if (pressed && behaviour === PointerBehaviour.DEFAULT) {
          this.domElement.setPointerCapture(event.pointerId)
        }
      }
    }
  }
  handlePointerCancelEvent(event) {
    this.domElement.removeEventListener('pointermove', this)
  }
  handleWheelEvent(event) {
    this.rotationManager.zoom(Math.sign(event.deltaY))
    this.translationManager.moveSpeedMultiplier = 
     Math.max(this.translationManager.moveSpeedMultiplier - (Math.sign(event.deltaY) * 0.2), 0.4); 
  }
  handleKeyboardEvent(event, pressed) {
    const keyBindings = this.settings.keyBindings
    if (keyBindings.has(event.keyCode)) {
      // event.preventDefault()
      const strategy = this.strategies.get(keyBindings.get(event.keyCode))
      strategy.execute(pressed)
    }
  }
  handlePointerLockEvent() {
    if (document.pointerLockElement === this.domElement) {
      this.domElement.addEventListener('pointermove', this, {passive: true})
    } else {
      this.domElement.removeEventListener('pointermove', this)
    }
  }
  handleVisibilityChangeEvent() {
    if (document.hidden) {
      this.translationManager.getMovementState().reset()
      this.domElement.removeEventListener('pointermove', this)
    }
  }
  onSettingsChanged(event) {
    const rotationManager = this.rotationManager
    const settings = this.settings
    const general = settings.general
    if (!settings.translation.isEnabled()) {
      this.translationManager.resetVelocity()
    }
    if (general.getMode() !== general.getPreviousMode()) {
      if (general.getMode() === ControlMode.THIRD_PERSON) {
        v3.copy(this.target)
        this.target.copy(this.position)
        this.position.sub(v3)
      } else {
        this.position.copy(this.target)
        this.target.set(0, 0, -1).applyQuaternion(this.quaternion)
      }
      rotationManager.updateSpherical()
    } else {
      rotationManager.restrictSpherical()
    }
    rotationManager.updatePosition().updateQuaternion()
    this.previousPosition.copy(this.position)
    this.previousQuaternion.copy(this.quaternion)
    this.previousTarget.copy(this.target)
  }
  synchronize() {
    const mode = this.settings.general.getMode()
    const rotationManager = this.rotationManager
    const previousPosition = this.previousPosition
    const previousQuaternion = this.previousQuaternion
    const previousTarget = this.previousTarget
    const position = this.position
    const quaternion = this.quaternion
    const target = this.target
    if (!previousQuaternion.equals(quaternion)) {
      if (mode === ControlMode.THIRD_PERSON) {
        target.set(0, 0, -1).applyQuaternion(quaternion)
        target.multiplyScalar(rotationManager.getRadius())
        target.add(position)
      } else {
        target.set(0, 0, -1).applyQuaternion(quaternion)
      }
      rotationManager.updateSpherical()
    } else if (!previousTarget.equals(target)) {
      if (!previousPosition.equals(position)) {
        rotationManager.updateSpherical().updateQuaternion()
      } else {
        if (mode === ControlMode.THIRD_PERSON) {
          rotationManager.updatePosition()
        } else {
          rotationManager.updateSpherical().updateQuaternion()
        }
      }
    } else if (!previousPosition.equals(position)) {
      if (mode === ControlMode.THIRD_PERSON) {
        rotationManager.updateSpherical().updateQuaternion()
      }
    }
  }
  handleEvent(event) {
    switch (event.type) {
      case 'pointermove':
        this.handlePointerMoveEvent(event)
        break
      case 'pointerdown':
      case 'mousedown':
        this.handlePointerButtonEvent(event, true)
        break
      case 'pointerup':
      case 'mouseup':
        this.handlePointerButtonEvent(event, false)
        break
      case 'pointercancel':
        this.handlePointerCancelEvent(event)
        break
      case 'keydown':
        this.handleKeyboardEvent(event, true)
        break
      case 'keyup':
        this.handleKeyboardEvent(event, false)
        break
      case 'wheel':
        this.handleWheelEvent(event)
        break
      case 'pointerlockchange':
        this.handlePointerLockEvent()
        break
      case 'visibilitychange':
        this.handleVisibilityChangeEvent()
        break
      case 'change':
        this.onSettingsChanged(event)
        break
      default:
        break
    }
  }
  update(timestamp) {
    this.synchronize()
    this.rotationManager.update(timestamp)
    this.translationManager.update(timestamp)
    this.previousPosition.copy(this.position)
    this.previousQuaternion.copy(this.quaternion)
    this.previousTarget.copy(this.target)
  }
  dispose() {
    this.setEnabled(false)
  }
}
export {
  Action,
  Bindings,
  BoostStrategy,
  ControlMode,
  Direction,
  GeneralSettings,
  KeyCode,
  MovementState,
  MovementStrategy,
  PointerBehaviour,
  PointerButton,
  PointerSettings,
  PointerType,
  RotationManager,
  RotationSettings,
  RotationStrategy,
  ScalarDamper,
  Settings,
  SpatialControls,
  TranslationManager,
  TranslationSettings,
  ZoomSettings,
  ZoomStrategy
}
