import { TypeEntity, UpdateState } from '../Constants';
import ObjectItem from './ObjectItem';
import { inheritConstructor, SerAttr } from '../SerializableObject';

const imgPathToRemove = '_5_08';

export default class Picture extends ObjectItem {
  onConstructor() {
    super.onConstructor(...arguments);

    this.type = TypeEntity.Picture;

    this.addProperty('image', {
      control: 'edit',
      type: 'string',
      visible: false,
      value: {
        default: '',
        get: (property) => property._value,
        set: (property, value) => {
          if (property._value === value) return;
          property._value = value;
          this.setUpdateState(UpdateState.Shape);
        },
      },
    });

    this.addProperty('aspect', {
      control: 'edit',
      type: 'string',
      visible: false,
      value: {
        default: '',
        get: (property) => property._value,
        set: (property, value) => {
          if (property._value === value) return;
          property._value = value;
          this.project.app.events.trigger('change', {
            item: this,
            property: 'aspect',
          });
        },
      },
    });

    this.addProperty('imageMeta', {
      control: 'edit',
      type: 'object',
      visible: false,
      value: {
        default: null,
        get: (property) => property._value,
        set: (property, value) => {
          if (property._value === value) return;
          property._value = value;
          this.setUpdateState(UpdateState.Shape);
        },
      },
    });

    this.addProperty('presentationMeta', {
      control: "edit",
      type: "object",
      visible: false,
      value: {
        default: null,
        get: property => {
          return property._value
        },
        set: (property, value) => {
          if (property._value === value) return
          property._value = value
          this.setUpdateState(UpdateState.Shape)
        }
      }
    });
  }

  parse(obj, data) {
    if (obj && obj.path && obj.path?.includes(imgPathToRemove)) {
      obj.path = obj.path.replace(imgPathToRemove, '');
    }
    super.parse(obj, data);
  }
}
inheritConstructor(Picture, ObjectItem, 'Picture', {
  type: TypeEntity.Picture,
  serializable: [
    new SerAttr('path', { defaultValue: '' }),
    new SerAttr('aspect', { defaultValue: '' }),
    new SerAttr('image', { defaultValue: '' }),
    new SerAttr('imageMeta'),
    new SerAttr('presentationMeta')
  ],
});
