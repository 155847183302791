import Moralis from 'moralis'
import CONFIG from '../config'

export const APP_ID = CONFIG.REACT_APP_MORALIS_APP_ID
export const SERVER_URL = CONFIG.REACT_APP_MORALIS_SERVER_URL

Moralis.initialize(APP_ID)
Moralis.serverURL = SERVER_URL

export default Moralis
