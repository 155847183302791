let downloader = null
let uploader = null
let canvas = null

export const init = () => {
  if (!downloader) {
    downloader = document.createElement('a')

    uploader = document.createElement('input')
    uploader.setAttribute('type', 'file')
    uploader.style.display = 'none'
    document.body.appendChild(uploader)

    canvas = document.createElement('canvas')
  } else console.warn('already inited')
}

export const destroy = () => {
  if (downloader) {
    downloader = null

    document.body.removeChild(uploader)
    uploader = null

    canvas = null
  } else console.warn('already destoryed')
}

export const download = (data, name, type, changeCb) => {
  const file = new Blob([data], {type: type})
  if (changeCb) {
    downloader.onclick = changeCb
    // downloader.onchange = changeCb
  }
  downloader.href = URL.createObjectURL(file)
  downloader.download = name
  downloader.click()
}

export const upload = (changeCb, {readAction} = {}) => {
  uploader.onchange = changeCbService
  uploader.click()

  function changeCbService(/*e*/) {
    const __this = this
    const file = this.files ? this.files[0] : {name: this.value},
      fileReader = window.FileReader ? new FileReader() : null

    if (file) {
      if (fileReader) {
        fileReader.addEventListener(
          'loadend',
          function(e) {
            changeCb(e, __this, file)
            __this.value = ''
          },
          false
        )
        if (readAction) readAction(fileReader, file)
        else fileReader.readAsText(file)
      } else {
        console.log('This browser isn\'t smart enough!')
      }
    } else console.log('uploader: no file')
  }
}

export const createResizedImageSrc = (image, width, height, options) => {
  const resizedContext = canvas.getContext('2d')
  canvas.width = width
  canvas.height = height

  resizedContext.drawImage(image, 0, 0, width, height)

  if (options !== undefined) {
    return canvas.toDataURL(options.type, options.encoderOptions)
  }
  return canvas.toDataURL()
}

export const createResizedImageAspected = (image, size, onSuccess) => {
  const aspect = image.naturalWidth / image.naturalHeight
  let width = size
  let height = width / aspect
  if (height > size) {
    height = size
    width = height * aspect
  }

  const imageNew = new Image()
  imageNew.onload = event => {
    if (onSuccess) onSuccess(event)
  }
  imageNew.src = createResizedImageSrc(image, width, height)
  return imageNew
}
