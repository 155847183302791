import { Box } from '@mui/material'

import PropertiesPanel from './Properties'
import { PanelContainer } from './styles'

import isMobileAndTablet from '../../../../helpers/isMobile'

export default function RightPanel(props) {
  return (
    <PanelContainer left={false}>
      <PropertiesPanel
        openSelector={props.openSelector}
        onSetShowRightPanel={props.onSetShowRightPanel}
        currentItemData={props.currentItemData}
        setLeftTabIndex={props.setLeftTabIndex}
        setModalChildren={props.setModalChildren}
      />
    </PanelContainer>
  )

  // if (isMobileAndTablet) {
  // } else {
  //   return (
  //     <>
  //       {/* <Box sx={style} onClick={props.event} /> */}
  //       <PanelContainer left={false}>
  //         <PropertiesPanel
  //           openSelector={props.openSelector}
  //           onSetShowRightPanel={props.onSetShowRightPanel}
  //           currentItemData={props.currentItemData}
  //           setLeftTabIndex={props.setLeftTabIndex}
  //         />
  //       </PanelContainer>
  //     </>
  //   )
  // }
}
