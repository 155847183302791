import { $host } from '../../editor/api/base';

export class AuthHelperService {
  static async backendAuth(token) {
    const { data: resp } = await $host.post('user/moralis', {
      token,
    });

    localStorage.setItem('apiKey', resp.access_token);
    localStorage.setItem('user_id', resp.user_id);
  }
}
