import { BaseAnimation } from "./BaseAnimation";
import { AnimationStates } from "./const/AnimationStates";

export default class StateWalk extends BaseAnimation {
    constructor(animationAction) {
        super(animationAction, 'STATE_WALK');
        this.transitions = {
            STATE_IDLE: 0.25,
            STATE_RUN: 0.25,
            STATE_DANCE: 0.2,
            STATE_JUMP: 0.2,
            STATE_FALL: 0.4
        };
        this.id = AnimationStates.STATE_WALK;
    }
}