import { UpdateState } from './Core/Constants.js'

class Action {
    constructor(event, object, data) {
        this.event = event;
        this.object = object;
        this.data = data;
        this.oldData = null;
    }
}

export default class ActionManager {
    constructor({ app }) {
        this.app = app

        this.maximumQueueSize = 16;
        this.actionQueue = [];
        this.actionPointer = -1;
        this.activeAction = null;
    }

    isBusy() {
        return !!this.activeAction;
    }

    createAction(event, object, data) {
        this.activeAction = new Action(event, object, data);
    }

    endAction() {
        this.pushAction();
        this.activeAction = null;
    }

    pushAction() {
        if (!this.activeAction) {
            console.warn("ActionManager::pushAction: no active action");
            return;
        }
        this.activeAction.oldData = JSON.parse(JSON.stringify(this.activeAction.object.data))
        this.actionQueue.push(this.activeAction);
        this.actionPointer = this.actionQueue.length;
        if (this.actionQueue.length > this.maximumQueueSize) {
            this.actionQueue.shift();
            this.actionPointer -= 1;
        }
    }

    _applyActionAndUpdateObject(actionFieldName) {
        const action = this.actionQueue[this.actionPointer];
        if (!action) {
            console.warn("ActionManager::_applyActionAndUpdateObject: undefined action");
            return;
        }
        Object.keys(action.object.data).forEach(key => {
            if (action[actionFieldName][key]) {
                action.object.data[key]._value = action[actionFieldName][key]._value;
            }
        });
        action.object.update(UpdateState.Shape);
    }

    /**
     * Reverts latest action
     * @returns { bool | undefined } is allowed to move further 
     */
    goBack() {
        if (this.actionPointer < 1) return;
        this.actionPointer -= 1;
        this._applyActionAndUpdateObject("data");
        return this.actionPointer >= 1;
    }

    /**
     * Returns to latest action
     * @returns { bool | undefined } is allowed to move further 
     */
    goForward() {
        if (this.actionPointer > this.actionQueue.length - 1) return;
        this._applyActionAndUpdateObject("oldData");
        this.actionPointer += 1;
        return this.actionPointer <= this.actionQueue.length - 1;
    }
}