import axios from 'axios'
import CONFIG from '../../../config'

export const pinFileToIPFS = async (file, id = 0) => {
  const data = new FormData()
  data.append('file', file)
  const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`
  return axios
    .post(url, data, {
      headers: {
        pinata_api_key: CONFIG.REACT_APP_PINATA_API_KEY,
        pinata_secret_api_key: CONFIG.REACT_APP_PINATA_API_SECRET,
      },
      onUploadProgress: progressEvent => {
        const progress = 100 * progressEvent.loaded / progressEvent.total;
        console.log(progress, id);
        const loadProgress = new CustomEvent("videoUploadProgress", { detail: { progress, id } });
        document.dispatchEvent(loadProgress);
      }
    })
    .then(function (response) {
      return {
        success: true,
        pinataUrl:
          'https://multygallery.mypinata.cloud/ipfs/' + response.data.IpfsHash,
        pinataHash: response.data.IpfsHash,
      }
    })
    .catch(function (error) {
      console.error(error)
      return {
        success: false,
        message: error.message,
      }
    })
}
