export default class ToolBase {
  constructor ({app}) {
    this.app = app

    this.name = 'Base'
  }

  init () {
    this.app.toolManager['tool' + this.name] = this
  }

  destroy () {
    delete this.app.toolManager['tool' + this.name]
  }

  start () {}

  stop () {}

  onPointerEvent (event) {
    // console.log(this.constructor.name + '.onPointerEvent=', event)
    if (event.type === 'mouseup' && event.button === 2) {
      this.app.toolManager.activeTool = this.app.toolManager.toolArrow
    }
  }

  onKeyEvent (event) {
    // console.log(this.constructor.name + '.onKeyEvent=', event)
    if (event.type === 'keydown' && event.code === 'Escape') {
      this.app.toolManager.activeTool = this.app.toolManager.toolArrow
    }
  }
}