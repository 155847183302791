import { EthereumProvider } from '@walletconnect/ethereum-provider';
import AbstractWeb3Connector from './AbstractWeb3Connector';
import { verifyChainId } from './utils';
import CONFIG from '../../../../config';

export const WalletConnectEvent = Object.freeze({
  ACCOUNTS_CHANGED: 'accountsChanged',
  CHAIN_CHANGED: 'chainChanged',
  DISCONNECT: 'disconnect',
});

class WalletConnectWeb3ConnectorV2 extends AbstractWeb3Connector {
  type = 'WalletConnectV2';

  async activate({ chainId: providedChainId, qrModalOptions, newSession } = {}) {
    const projectId = CONFIG.REACT_APP_WEB3_MODAL_PROJECT_ID;

    // Log out of any previous sessions
    if (newSession) {
      this.cleanup();
    }

    if (!this.provider) {
      const config = {
        projectId,
        chains: [providedChainId ? Number(providedChainId) : 1],
        showQrModal: true,
        qrModalOptions,
      };

      const WalletConnectProvider = EthereumProvider;
      this.provider = await WalletConnectProvider.init(config);
    }

    if (!this.provider) {
      throw new Error('Could not connect via WalletConnect, error in connecting to provider');
    }

    const accounts = await this.provider.enable();
    const account = accounts[0].toLowerCase();
    const { chainId } = this.provider;
    const verifiedChainId = verifyChainId(chainId);

    this.account = account;
    this.chainId = verifiedChainId;

    this.subscribeToEvents(this.provider);

    return { provider: this.provider, account, chainId: verifiedChainId };
  }

  // Cleanup old sessions
  // eslint-disable-next-line class-methods-use-this
  cleanup() {
    try {
      if (window) {
        Object.keys(localStorage).forEach((key) => {
          if (key.startsWith('wagmi') || key.startsWith('wc@2')) {
            window.localStorage.removeItem(key);
          }
        });
      }
    } catch (error) {
      // Do nothing
    }
  }

  async deactivate() {
    this.unsubscribeToEvents(this.provider);

    console.log('Deactivate: provider', this.provider);
    if (this.provider?.deactivate) {
      try {
        await this.provider?.deactivate();
      } catch {
        // Do nothing
      }
    }

    this.web3modal = null;
    this.account = null;
    this.chainId = null;
    this.provider = null;
  }
}

export default WalletConnectWeb3ConnectorV2;
