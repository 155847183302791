import { TabContext, TabPanel } from "@mui/lab"
import { useState } from "react"
import { CustomTab, CustomTabList } from "../styles";
import { CenteredText } from "../../../styles";
import { useContext } from "react";
import { ArtworkList, ButtonPublish, ButtonWrapper, ColunmList } from "./styles";
import { FlexibleModalContext } from "../../../../../contexts/flexible-modal/context";
import { AddOBJ } from "./AddOBJ/AddOBJ";
import { useEffect } from "react";
import Moralis from "../../../../../../../const/moralis";
import { ObjectItem3D } from "./ObjectItem3D";

export const Tab3DObjects = () => {
    const [tabIndex, setTabIndex] = useState('1');
    const [userObjects, setUserObjects] = useState([]);
    const [userObjectsLoading, setUserObjectsLoading] = useState(true);
    const { setFlexibleModalChildren } = useContext(FlexibleModalContext);

    useEffect(() => {
        const loadObjects = async () => {
            setUserObjectsLoading(true);
            const list = await Moralis.Cloud.run('meta-3d-list', { owned: tabIndex === '1' });
            setUserObjects(list);
            setUserObjectsLoading(false);
        }

        loadObjects();
    }, [tabIndex]);

    const onUploadClick = () => {
        setFlexibleModalChildren(<AddOBJ />);
    }

    return <TabContext value={tabIndex}>
        <CustomTabList
            onChange={(event, value) => setTabIndex(value)}
            aria-label='content'
            variant='scrollable'
        >
            <CustomTab label='My objects' value='1' />
            <CustomTab label='All objects' value='2' />
        </CustomTabList>
        <TabPanel value='1' sx={{ '& .MuiTabPanel-root': { padding: '0' } }}>
            <ColunmList>
                <ArtworkList>
                    {userObjectsLoading ? <CenteredText>Loading...</CenteredText> :
                        userObjects.length > 0 ? userObjects.map((el, id) =>
                            <ObjectItem3D key={id} item={el?.item} creator={el?.creator} />) :
                            <CenteredText>No objects found</CenteredText>
                    }
                </ArtworkList>
                <ButtonWrapper>
                    <ButtonPublish onClick={onUploadClick}>Upload new object</ButtonPublish>
                </ButtonWrapper>
            </ColunmList>
        </TabPanel>
        <TabPanel value='2' sx={{ '& .MuiTabPanel-root': { padding: '0' } }}>
            <ArtworkList>
                {userObjectsLoading ? <CenteredText>Loading...</CenteredText> :
                    userObjects.length > 0 ? userObjects.map((el, id) =>
                        <ObjectItem3D key={id} item={el?.item} creator={el?.creator} />) :
                        <CenteredText>No objects found</CenteredText>
                }
            </ArtworkList>
        </TabPanel>
    </TabContext>
}