import { Vector3, Box3, Line3, Matrix4, Mesh, MeshStandardMaterial, BoxGeometry, Quaternion, Euler } from 'three'
import { RoundedBoxGeometry } from 'three/examples/jsm/geometries/RoundedBoxGeometry.js';
import FPSControls from './FPSControls'
import { CustomOrbitControls } from "./CustomOrbitControls";
import AnimationStateMachine from "../../StateMachine/AnimationStateMachine";

let tempVector = new Vector3();
let tempVector2 = new Vector3();
let tempBox = new Box3();
let tempMat = new Matrix4();
let tempSegment = new Line3();
const upVector = new Vector3(0, 1, 0);

export default class PointerLock extends FPSControls {
  constructor(camera, domElement, app) {
    super(app)
    this.camera = camera
    this.domElement = domElement

    this.fwdPressed = false
    this.bkdPressed = false
    this.lftPressed = false
    this.rgtPressed = false

    this.playerVelocity = new Vector3()
    this.direction = new Vector3()
    this.dispatch_quaternion = { y: 0, w: 0 };
    this.local_quaternion = { y: 0, w: 0 };

    this.userBodyObject = null;
    this.playerIsOnGround = false;
    this.playerIsOnGroundTime = 0;
    this.fallDelayTime = 0.5;
    this.controls = new CustomOrbitControls(camera, domElement);

    this.player = new Mesh(
      new RoundedBoxGeometry(1.0, 2.0, 1.0, 10, 0.35),
      new MeshStandardMaterial()
    )
    this.player.capsuleInfo = {
      radius: 0.5,
      segment: new Line3(new Vector3(), new Vector3(0, - 1.0, 0.0))
    };

    document.addEventListener("spaceLoaded", (event) => {
      const playerSpeed = (this.app?.project?.movespeed && parseFloat(this.app?.project?.movespeed));
      if (playerSpeed) this.params.playerSpeed = playerSpeed;
    });

    this.params = {
      firstPerson: true,
      displayCollider: false,
      displayBVH: false,
      visualizeDepth: 10,
      gravity: -30,
      playerSpeed: 3,
      rotationSpeed: 10,
      physicsSteps: 5
    }
    this.animationMixers = []
    this.focused = true
  }

  addAnimationMixer(mixer) {
    this.animationMixers.push(mixer);
    mixer.addEventListener('')
  }

  onAnimationFinish(e) {
    if (e.action === this.stateMachine.stateJump) {
      this.stateMachine.stateFall.reset();
      this.stateMachine.stateFall.play(this.stateMachine.stateJump, 0.2);
    }
  }

  setCameraPosition(newPosition) {
    if (newPosition?.y !== undefined) newPosition.y += this.cameraInitHeight;
    if (newPosition?.prototype?.isVector3) {
      this.camera.position.copy(newPosition);
    } else {
      if (!newPosition.x && !newPosition.y && !newPosition.z) return;
      this.camera.position.copy(new Vector3(newPosition.x, newPosition.y, newPosition.z));
    }
  }

  setTarget(target) {
    this.target = target;
    this.target_object3d = this.target.renderer.meshCollider.parent;
    console.log("setting target,", target);
  }

  createStateMachine() {
    this.stateMachine = new AnimationStateMachine(
      this.animationMixers[0],
      this.app.socket.userBodyAnimationController.idleAnimationAction,
      this.app.socket.userBodyAnimationController.walkAnimationAction,
      this.app.socket.userBodyAnimationController.runAnimationAction,
      this.app.socket.userBodyAnimationController.danceAnimationAction,
      this.app.socket.userBodyAnimationController.jumpAnimationAction,
      this.app.socket.userBodyAnimationController.fallAnimationAction,
    );
  }

  init() {
    this.onKeyDown = event => {
      switch (event.code) {
        case 'ArrowUp':
        case 'KeyW':
          this.fwdPressed = true
          break

        case 'ArrowLeft':
        case 'KeyA':
          this.lftPressed = true
          break

        case 'ArrowDown':
        case 'KeyS':
          this.bkdPressed = true
          break

        case 'ArrowRight':
        case 'KeyD':
          this.rgtPressed = true
          break
        case 'Space':
          this.onJump();
          break;
        case 'ShiftLeft':
          this.running = true;
          break
        case 'KeyG':
          this.dancing = !this.dancing;
          break
        default:
          break
      }
    }

    this.onKeyUp = event => {
      switch (event.code) {
        case 'ArrowUp':
        case 'KeyW':
          this.fwdPressed = false
          break
        case 'ArrowLeft':
        case 'KeyA':
          this.lftPressed = false
          break
        case 'ArrowDown':
        case 'KeyS':
          this.bkdPressed = false
          break
        case 'ArrowRight':
        case 'KeyD':
          this.rgtPressed = false
          break
        case 'ShiftLeft':
          this.running = false;
          break
        default:
          break
      }
    }
    window.addEventListener('keydown', this.onKeyDown)
    window.addEventListener('keyup', this.onKeyUp)
    window.onfocus = () => {
      // this ensures no physcis is updated if tab is closed/swithed 
      this.focused = true
    }
    window.onblur = () => {
      this.focused = false
    }

    this.reset();
  }

  onJump() {
    if (this.playerIsOnGround) {
      this.playerVelocity.y = 10.0;
      this.playerIsOnGround = false;
      this.jumped = true;
      this.stateMachine.transitionTo(this.stateMachine.stateJump);
    }
  }

  dispose() {
    window.removeEventListener('keydown', this.onKeyDown)
    window.removeEventListener('keyup', this.onKeyUp)

    if (this.controls) {
      this.controls.dispose()
      this.controls = null
    }
  }

  switchViewMode() {
    this.params.firstPerson = !this.params.firstPerson;
    this.socketHandler.setBodyVisible(!this.params.firstPerson)
  }

  directionOffset() {
    let directionOffset = 0

    if (this.fwdPressed) {
      if (this.lftPressed) {
        directionOffset = Math.PI / 4 // w+a
      } else if (this.rgtPressed) {
        directionOffset = - Math.PI / 4 // w+d
      }
    } else if (this.bkdPressed) {
      if (this.lftPressed) {
        directionOffset = Math.PI / 4 + Math.PI / 2 // s+a
      } else if (this.rgtPressed) {
        directionOffset = -Math.PI / 4 - Math.PI / 2 // s+d
      } else {
        directionOffset = Math.PI // s
      }
    } else if (this.lftPressed) {
      directionOffset = Math.PI / 2 // a
    } else if (this.rgtPressed) {
      directionOffset = - Math.PI / 2 // d
    }

    return directionOffset + Math.PI;
  }

  updatePlayer(delta) {
    if (this.playerIsOnGround) {
      this.playerVelocity.y = delta * this.params.gravity;
    } else {
      this.playerVelocity.y += delta * this.params.gravity;
    }

    this.player.position.addScaledVector(this.playerVelocity, delta);

    // move the player
    const angle = this.controls.getAzimuthalAngle();
    const speedMultiplier = this.running ? 1.5 : 1;
    if (this.fwdPressed) {
      tempVector.set(0, 0, - 1).applyAxisAngle(upVector, angle);
      this.player.position.addScaledVector(tempVector, speedMultiplier * this.params.playerSpeed * delta);
    }

    if (this.bkdPressed) {
      tempVector.set(0, 0, 1).applyAxisAngle(upVector, angle);
      this.player.position.addScaledVector(tempVector, speedMultiplier * this.params.playerSpeed * delta);
    }

    if (this.lftPressed) {
      tempVector.set(- 1, 0, 0).applyAxisAngle(upVector, angle);
      this.player.position.addScaledVector(tempVector, speedMultiplier * this.params.playerSpeed * delta);
    }

    if (this.rgtPressed) {
      tempVector.set(1, 0, 0).applyAxisAngle(upVector, angle);
      this.player.position.addScaledVector(tempVector, speedMultiplier * this.params.playerSpeed * delta);
    }

    this.player.updateMatrixWorld();
    // adjust player position based on collisions
    const capsuleInfo = this.player.capsuleInfo;
    tempBox.makeEmpty();
    tempMat.copy(this.initialSpaceCollider.matrixWorld).invert();
    tempSegment.copy(capsuleInfo.segment);

    // get the position of the capsule in the local space of the collider
    tempSegment.start.applyMatrix4(this.player.matrixWorld).applyMatrix4(tempMat);
    tempSegment.end.applyMatrix4(this.player.matrixWorld).applyMatrix4(tempMat);

    // get the axis aligned bounding box of the capsule
    tempBox.expandByPoint(tempSegment.start);
    tempBox.expandByPoint(tempSegment.end);

    tempBox.min.addScalar(- capsuleInfo.radius);
    tempBox.max.addScalar(capsuleInfo.radius);

    this.initialSpaceCollider.geometry.boundsTree.shapecast({

      intersectsBounds: box => box.intersectsBox(tempBox),

      intersectsTriangle: tri => {

        // check if the triangle is intersecting the capsule and adjust the
        // capsule position if it is.
        const triPoint = tempVector;
        const capsulePoint = tempVector2;

        const distance = tri.closestPointToSegment(tempSegment, triPoint, capsulePoint);
        if (distance < capsuleInfo.radius) {

          const depth = capsuleInfo.radius - distance;
          const direction = capsulePoint.sub(triPoint).normalize();

          tempSegment.start.addScaledVector(direction, depth);
          tempSegment.end.addScaledVector(direction, depth);

        }

      }

    });

    // get the adjusted position of the capsule collider in world space after checking
    // triangle collisions and moving it. capsuleInfo.segment.start is assumed to be
    // the origin of the player model.
    const newPosition = tempVector;
    newPosition.copy(tempSegment.start).applyMatrix4(this.initialSpaceCollider.matrixWorld);

    // check how much the collider was moved
    const deltaVector = tempVector2;
    deltaVector.subVectors(newPosition, this.player.position);

    let dispatchPos, dispatchRot, dispatchState;
    // if the player was primarily adjusted vertically we assume it's on something we should consider ground
    this.playerIsOnGround = deltaVector.y > Math.abs(delta * this.playerVelocity.y * 0.25);
    if (!this.playerIsOnGround) {
      if (this.target_object3d?.position) dispatchPos = true;
      this.playerIsOnGroundTime += delta;
    }
    else this.playerIsOnGroundTime = 0
    if (this.jumped && this.playerIsOnGround) this.jumped = false;

    const offset = Math.max(0.0, deltaVector.length() - 1e-5);
    deltaVector.normalize().multiplyScalar(offset);

    // adjust the player model
    this.player.position.add(deltaVector);

    if (!this.playerIsOnGround) {
      deltaVector.normalize();
      this.playerVelocity.addScaledVector(deltaVector, - deltaVector.dot(this.playerVelocity));
    } else {
      this.playerVelocity.set(0, 0, 0);
    }

    if (this.fwdPressed || this.bkdPressed || this.lftPressed || this.rgtPressed) {
      // rotate 3d object
      dispatchPos = true;
      const directionOffset = this.directionOffset([this.moveForward, this.moveLeft, this.moveBackward, this.moveRight])
      const rotationQ = new Quaternion().setFromAxisAngle(new Vector3(0, 1, 0), directionOffset + angle)
      this.target_object3d.quaternion.rotateTowards(rotationQ, this.params.rotationSpeed * delta);

      if ((!this.playerIsOnGround && this.playerIsOnGroundTime > this.fallDelayTime) || this.jumped) this.stateMachine.transitionTo(this.stateMachine.stateFall);
      else if (this.running) this.stateMachine.transitionTo(this.stateMachine.stateRun);
      else this.stateMachine.transitionTo(this.stateMachine.stateWalk);
    } else {
      if (this.stateMachine) {
        if ((!this.playerIsOnGround && this.playerIsOnGroundTime > this.fallDelayTime) || this.jumped) this.stateMachine.transitionTo(this.stateMachine.stateFall);
        else if (this.dancing) {
          const transitioned = this.stateMachine.transitionTo(this.stateMachine.stateDance);
          if (transitioned) dispatchState = true;
        }
        else {
          const transitioned = this.stateMachine.transitionTo(this.stateMachine.stateIdle);
          if (transitioned) dispatchState = true;
        }
      }
    }

    if (this.params.firstPerson) {
      if (
        this.camera.quaternion.y !== this.dispatch_quaternion.y ||
        this.camera.quaternion.w !== this.dispatch_quaternion.w
      ) {
        this.dispatch_quaternion.y = this.camera.quaternion.y;
        this.dispatch_quaternion.w = this.camera.quaternion.w;
        dispatchRot = true;
      }
    } else {
      if (
        this.target_object3d?.quaternion && (
          this.local_quaternion.y !== this.target_object3d?.quaternion?.y?.toFixed(7) ||
          this.local_quaternion.w !== this.target_object3d?.quaternion?.w?.toFixed(7)
        )
      ) {
        const dispatchQuaternion = new Quaternion();
        dispatchQuaternion.setFromEuler(new Euler(0, 3.1415, 0, 'XYZ'));
        dispatchQuaternion.multiply(this.target_object3d?.quaternion)
        this.local_quaternion.y = this.target_object3d.quaternion.y.toFixed(7);
        this.local_quaternion.w = this.target_object3d.quaternion.w.toFixed(7);
        this.dispatch_quaternion.y = dispatchQuaternion.y;
        this.dispatch_quaternion.w = dispatchQuaternion.w;
        dispatchRot = true;
      }
    }

    if (dispatchRot || dispatchPos || dispatchState) {
      const movePlayerEvent = new CustomEvent("movePlayer", {
        detail: {
          clientX: (dispatchPos || dispatchState) ? this.target_object3d.position.x : undefined,
          clientY: (dispatchPos || dispatchState) ? this.target_object3d.position.y : undefined,
          clientZ: (dispatchPos || dispatchState) ? this.target_object3d.position.z : undefined,
          quaternion: (dispatchRot || dispatchState) ? this.dispatch_quaternion : undefined,
          state: this.stateMachine?.getId()
        }
      });
      document.dispatchEvent(movePlayerEvent);
    }


    // adjust the camera
    this.camera.position.sub(this.controls.target);
    this.controls.target.copy(this.player.position);
    this.camera.position.add(this.player.position);
    if (this.target_object3d) {
      this.target_object3d.position.set(
        this.player.position.x,
        this.player.position.y - 1.5,
        this.player.position.z,
      );
    }

    // if the player has fallen too far below the level reset their position to the start
    if (this.player.position.y < - 30) {
      this.reset();
    }
  }

  reset() {
    this.playerVelocity.set(0, 0, 0);
    this.player.position.set(0, 3, 0);
    this.camera.position.sub(this.controls.target);
    this.controls.target.copy(this.player.position);
    this.camera.position.add(this.player.position);
    this.controls.update();
  }

  update(delta) {
    // stats.update();

    if (this.params.firstPerson) {
      this.controls.maxPolarAngle = Math.PI;
      this.controls.minDistance = 1e-4;
      this.controls.maxDistance = 1e-4;
    } else {
      this.controls.maxPolarAngle = Math.PI;
      this.controls.minDistance = 1;
      this.controls.maxDistance = 20;
    }

    if (this.socketHandler?.socket) {
      this.socketHandler.onUpdate(delta);
    }
    if (this.animationMixers.length !== 0) {
      this.animationMixers.forEach(mixer => mixer.update(delta));
    }

    if (this.initialSpaceCollider) {
      // collider.visible = this.params.displayCollider;
      // visualizer.visible = this.params.displayBVH;
      const physicsSteps = this.params.physicsSteps;

      for (let i = 0; i < physicsSteps; i++) {
        this.updatePlayer(delta / physicsSteps);
      }

    }

    // TODO: limit the camera movement based on the collider
    // raycast in direction of camera and move it if it's further than the closest point

    this.controls.update();

  }
}
