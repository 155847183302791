const aspects = {
    0.5625: '9x16',
    0.75: '3x4',
    0.85: '4x5',
    1: '1x1',
    1.33: '4x3',
    1.77: '16x9'
}

export const getFittedAspect = (true_aspect) => {
    let minDiff = 99, fitted_aspect;
    for (const aspect of Object.keys(aspects)) {
        const diff = Math.abs(aspect - true_aspect);
        if (diff < minDiff) {
            minDiff = diff;
            fitted_aspect = aspects[aspect];
        }
    }
    return fitted_aspect;
} 