import CodeMirror from "@uiw/react-codemirror";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";
import { javascript } from '@codemirror/lang-javascript';
import { useState, useContext, useEffect } from 'react';
import { compileCustomScript } from "../../../../editor/Core/CustomScripts";
import { defaultSource, templateSource } from "./data";
import { MenuItem } from "@mui/material";
import { TabContext } from "@mui/lab";
import { Context } from "../../../store/Context";
import { BubbleMessage, BubbleMessageContainer, ButtonHull, Container, CustomTab, CustomTabList, ErrorMessage, LoadingText, Messages, Row, SelectLabel, StyledDropdown, StyledInput, StyledTabPanel, SubTitle, Title } from "./styles";
import { Input } from 'antd';

export const CodeEdit = ({ item }) => {
  const [code, setCode] = useState(item?.properties?.script?.value || defaultSource);
  const [error, setError] = useState('');
  const [tab, setTab] = useState('1');
  const [awaitingMessage, setAwaitingMessage] = useState(false);
  const { editorStore } = useContext(Context);
  const [soikaMessages, setSoikaMessages] = useState([{
    soika: true,
    content: "Hi! I'm SOIKA, an AI assistant here to help you generate scripts for your metaspace."
  }]);
  const [soikaInput, setSoikaInput] = useState('');

  useEffect(() => {
    const connectSoika = async () => {
      if (editorStore.editor.soika.subscribedToEvents) return;
      editorStore.editor.soika.subscribedToEvents = true;
      editorStore.editor.soika.events.on('soika_message', onSoikaMessage);
      await editorStore.editor.soika.connectSocket();
    }

    connectSoika();

    return () => {
      if (editorStore.editor?.soika?.events) {
        editorStore.editor.soika.events.off('soika_message', onSoikaMessage);
        editorStore.editor.soika.subscribedToEvents = false;
      }
    }
  }, []);

  const compareMessageIds = (a, b) => {
    if (a.id && b.id) return a.id === b.id;
    return false;
  };

  const onSoikaMessage = (data) => {
    console.log("Soika message", data?.content)
    if (!data?.content) return;
    data.soika = true;
    setSoikaMessages((soikaMessagesOld) => {
      const index = soikaMessagesOld.findIndex((m) =>
        compareMessageIds(m, data)
      );
      console.log("Old message compare", [...soikaMessagesOld], data, index)
      setAwaitingMessage(false);
      if (index === -1) {
        const temp = [...soikaMessagesOld]
        temp.push(data);
        return temp;
      } else {
        const temp = [
          ...soikaMessagesOld.slice(0, index),
          data,
          ...soikaMessagesOld.slice(index + 1)
        ]
        return temp;
      }
    })
  }

  const onSoikaSubmit = () => {
    console.log(soikaInput)
    editorStore.editor.soika.emitMessage(soikaInput);
    setSoikaMessages([...soikaMessages, { content: soikaInput, soika: false }]);
  }

  const onSoikaCodeSubmit = () => {
    editorStore.editor.soika.emitMessage(soikaInput, code);
    setSoikaMessages([...soikaMessages, { content: soikaInput, soika: false }]);
    setSoikaInput('');
    setAwaitingMessage(true);
  }

  const onSave = () => {
    const { update, error, click } = compileCustomScript(code);
    if (error) {
      console.log('stack', error.stack)
      setError(error.message);
      return;
    }
    item.properties.script.value = code;
    item.properties._compiledUpdate = update;
    item.properties._compiledClick = click;
    console.log(item.properties.script.value)
  }

  const onSelectTemplate = (event) => {
    const {
      target: { value },
    } = event;
    setCode(templateSource[value]);
  }

  const copyCode = (value) => {
    if (value.search('```javascript') !== -1) {
      value = value.slice(value.search('```javascript') + 13, value.lastIndexOf('```'));
    } else if (value.search('```') !== -1) {
      value = value.slice(value.indexOf('```'), value.lastIndexOf('```'));
    }
    setCode(value);
    setTab('1');
  }

  return (
    <TabContext value={tab}>
      <CustomTabList
        onChange={(event, value) => setTab(value)}
        aria-label='content'
      >
        <CustomTab label='Edit code' value='1' />
        <CustomTab label='Soika' value='2' />
      </CustomTabList>
      <StyledTabPanel value='1'>
        <Container>
          <Title>Script Editor</Title>
          <Row>
            <SelectLabel>
              Code templates:
            </SelectLabel>
            <StyledDropdown labelId="label" onChange={onSelectTemplate}>
              <MenuItem value="0">Spin object</MenuItem>
              <MenuItem value="1">Hover object</MenuItem>
              <MenuItem value="2">UI</MenuItem>
            </StyledDropdown>
          </Row>
          <CodeMirror
            value={code}
            onChange={setCode}
            style={{ overflow: "auto", height: '70%' }}
            theme={vscodeDark}
            extensions={[javascript()]}
          />
          <ErrorMessage>{error || ''}</ErrorMessage>
          <ButtonHull onClick={onSave}>save</ButtonHull>
        </Container>
      </StyledTabPanel>
      <StyledTabPanel value='2'>
        <Container big={true}>
          <Title>SOIKA AI</Title>
          <Messages>
            {soikaMessages.map(item => <BubbleMessageContainer isSoika={item.soika}>
              <BubbleMessage isSoika={item.soika}>
                {item.content}
                {item.content?.includes('```') || item.content?.includes('update(') ? <ButtonHull onClick={() => copyCode(item.content)}>Use code</ButtonHull> : null}
              </BubbleMessage>
            </BubbleMessageContainer>
            )}
          </Messages>
          {awaitingMessage ? <LoadingText>Loading...</LoadingText> : null}
          <Row>
            <StyledInput
              type="text"
              onChange={e => setSoikaInput(e.target.value)}
              value={soikaInput}
              multiline
              rows={2}
            />
            <ButtonHull onClick={onSoikaCodeSubmit}>Submit</ButtonHull>
          </Row>
        </Container>
      </StyledTabPanel>
    </TabContext >
  );
}