import styled from 'styled-components';
import { Input } from 'antd';
import { Box } from '@mui/system';

export const UploadFileText = styled.div`
  font-size: 15px;
  line-height: 20px;
`;
export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
`;

export const Instructions = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  font-size: 14px;
  cursor: pointer;
  color: #fff;
`;

export const ExplainFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2em;
  @media (max-width: 400px) {
    flex-direction: column;
  }
`;
export const ExplainBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
  width: auto;
  gap: 25px;
  z-index: 1000;
  padding: 20px;
  border-radius: 10px;
  backdrop-filter: blur(34px);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    /* width: 90%; */
  }
`;
export const ItemExplainText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  gap: 24px;
  @media (max-width: 600px) {
    flex-direction: column-reverse;
    justify-content: space-between;
    /* align-items: flex-start; */
    width: 90px;
    height: 114px;
    font-size: 14px;
  }
  @media (max-width: 400px) {
    width: 100%;
    height: auto;
    gap: 10px;
    justify-content: center;
  }
`;

export const Blocker = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
`;
export const UploadFileInput = styled(Input)`
  cursor: pointer;
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  z-index: 99;
`;

export const UploadButton = styled.button`
  color: #fff;
  border: 0;
  background: #000;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 23px;
  margin-top: 10px;
  cursor: pointer;
  @media (max-width: 400px) {
    width: 70%;
  }
`;

export const UploadFile = styled.div`
  border: none;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='36' ry='36' stroke='%23191919FF' stroke-width='1' stroke-dasharray='5%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 36px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: ${(props) => (!props.hasImage ? 'auto' : '250px')};
  padding: 40px;
  margin: 20px 0;
  @media (max-width: 600px) {
    height: ${(props) => (!props.hasImage ? 'auto' : '150px')};
  }
  @media (max-width: 400px) {
    margin: 0;
  }
`;
export const UploadContainer = styled.div`
  width: ${(props) => (props.file ? '100%' : '250px')};
  @media (max-width: 600px) {
    width: ${(props) => (props.file ? '100%' : '150px')};
  }
  @media (max-width: 400px) {
    width: 100%;
  }
`;
export const Loader = styled.div`
  width: ${(props) =>
    props.matches ? 'calc(100% + 4rem)' : 'calc(100% + 2rem)'};
  height: ${(props) =>
    props.matches ? 'calc(100% + 4rem)' : 'calc(100% + 2rem)'};
  background-color: white;
  top: ${(props) => (props.matches ? '-2rem' : '-1rem')};
  left: ${(props) => (props.matches ? '-2rem' : '-1rem')};
  position: absolute;
  z-index: 400;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ControlGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  @media (max-width: 400px) {
    flex-direction: column;
    gap: 5px;
  }
`;

export const Close = styled.div`
  cursor: pointer;
  text-decoration: underline;
  color: #191919;
  font-size: 18px;
  align-self: flex-end;
  margin-bottom: 10px;
  font-weight: 700;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
