import styled from 'styled-components';
import { Select, Tab, TextField } from '@mui/material';
import { TabList, TabPanel } from '@mui/lab';

export const StyledTabPanel = styled(TabPanel)`
    height: 100%;
`;
export const CustomTabList = styled(TabList)`
  padding: 0px 24px 0 24px !important;
  width: 100% !important;
  box-sizing: border-box !important;
  display: flex !important;
  span {
    display: none !important;
  }
  .MuiTab-root {
    font-family: Montserrat !important;
    font-size: 10px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    flex: 1;
    background: #161616 !important;
    color: #7E8390 !important;
    text-transform: uppercase !important;
    border-radius: 50px !important;
  }
  .MuiTabPanel-root {
    padding: 0 !important;
  }
  .Mui-selected {
    flex: 1;
    background: linear-gradient(99.78deg, rgb(159, 11, 238) 0.69%, rgb(20, 91, 253) 98.32%) !important;
    color: #fff !important;
  }
  @media (max-width: 600px) {
    padding: 0px 16px 0 16px !important;
  }
`;
export const CustomTab = styled(Tab)`
  font-size: 14px !important;
  color: black !important;
  text-transform: capitalize !important;
  padding: 8px !important;
  box-sizing: border-box !important;
  min-height: auto !important;
  margin: 8px !important;
  justify-content: space-between !important;
  background: #eee !important;
  border-radius: 7px !important;

  svg {
    margin: 0 !important;
  }
  @media (max-width: 400px) {
    font-size: 12px !important;
    min-width: auto !important;
  }
`;
export const ProductsCentered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`;
export const ProductsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const ProductButton = styled.button`
  all: unset;
`;
export const ProductItem = styled.div`
  width: 100%;
  height: 80px;
  border-radius: 6px;
  display: flex;
  gap: 12px;
  ${({ selected }) => selected && "box-shadow: 0px 0px 0px 1px white inset;"}

  &:hover {
    box-shadow: 0px 0px 0px 1px white inset;
  }
`;
export const Banner = styled.img`
  width: 100px;
  padding: 2px;
  border-radius: 2px;
  object-fit: cover;
`;
export const Title = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  font-weight: 500;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  @media (max-width: 400px) {
    font-size: 12px;
    line-height: 18px;
  }
  span {
    font-style: italic;
    background: #f8f8f8;
    border-radius: 2px;
    padding: 4px;
  }
`;
export const Info = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  cursor: pointer;
`;