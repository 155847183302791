import useAuth from '../../../../helpers/auth';

export const mergeData = (item, data) => ({
  ...item,
  item: {
    ...item?.item,
    likeExist: data?.likeExist,
    likesCount: Number(data?.likesCount),
    shareCount: Number(data?.shareCount),
  },
});

export const useGetProfileCollectiblesBody = () => {
  const { user } = useAuth();

  const getBody = (cursor = null) => ({
    category: 'PROFILE_NFT',
    cursor,
    limit: 12,
    ethAddress: user.get('ethAddress'),
  });

  return { getBody };
};

export const transformUserItemsResp = (data) => {
  const { items, cursor: respCursor, total } = data;

  return { cursor: respCursor, count: total, results: items };
};

const getIDs = (list) =>
  list?.map((nft) => ({
    tokenAddress: nft?.item?.token_address,
    tokenId: nft?.item?.token_id,
    owner: nft?.owner?.ethAddress || nft?.item?.owner_of,
  }));

const joinKey = (nft) =>
  `${nft?.item?.token_address}:${nft?.item?.token_id}:${nft?.owner?.ethAddress}`;

const mergeNFT = (nft, list) => {
  const result = list?.find(
    (item) =>
      nft?.item?.token_address === item?.tokenAddress &&
      nft?.item?.token_id === item?.tokenId &&
      (nft?.owner?.ethAddress === item?.owner ||
        nft?.item?.owner_of === item?.owner)
  );
  if (result) return mergeData(nft, result);
  return nft;
};

const createRequestBody = (nft, objectType = false) => {
  let body = {
    owner: nft?.owner?.ethAddress || nft?.item?.owner_of,
    tokenAddress: nft?.item?.token_address,
    tokenId: nft?.item?.token_id,
  };
  if (objectType) return { ...body };
  return body;
};

export { getIDs, joinKey, mergeNFT, createRequestBody };
