import React, { useEffect } from 'react'

import { BorderBlock } from './styles'
import { CoordinateInput } from '../Input/CoordinateInput'


export default function PropertyColor(props) {
  const [r, setR] = React.useState(props.property.value.r)
  const [g, setG] = React.useState(props.property.value.g)
  const [b, setB] = React.useState(props.property.value.b)

  useEffect(() => {
    setR(props.property.value.r)
    setG(props.property.value.g)
    setB(props.property.value.b)
  }, [props])

  const handleChange = (event, index) => {
    if (index === 0) {
        props.property.value.r = event.target.value
        setR(props.property.value.r)
    }
    else if (index === 1) {
        props.property.value.g = event.target.value
        setG(props.property.value.g)
    }
    else if (index === 2) {
        props.property.value.b = event.target.value
        setB(props.property.value.b)
    }
  }

  return (
    <BorderBlock>
      <CoordinateInput
        label="R"
        value={r}
        handleChange={(e) => handleChange(e, 0)}
      />
      <CoordinateInput
        label="G"
        value={g}
        handleChange={(e) => handleChange(e, 1)}
      />
      <CoordinateInput
        label="B"
        value={b}
        handleChange={(e) => handleChange(e, 2)}
      />
    </BorderBlock>
  )
}
