import styled from 'styled-components'

export const SightDot = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%, -50%);
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 0.1px solid #191919;
`
