import { useContext, useEffect, useState } from "react";
import { TabContext } from "@mui/lab";
import {
  Banner,
  CustomTab,
  CustomTabList,
  Info,
  ProductButton,
  ProductItem,
  ProductsCentered,
  ProductsWrapper,
  StyledTabPanel,
  Title
} from "./styles";
import Moralis from '../../../../../const/moralis';
import useAuth from "../../../../../helpers/auth";
import { getProductInfo, getProjectInfo } from "../../../../api/dbAPI";
import formatIPFSLink from '../../../helpers/formatIPFSLink';
import { PRODUCT_CATEGORIES } from "../../../helpers/productCategories";
import { EditorSnackbarContext } from "../../../contexts/editor-snackbar";

const ProductsList = ({ item, products, loading, type }) => {
  const { triggerSnackbar } = useContext(EditorSnackbarContext);

  // rerender when attachedProduct is changed
  useEffect(() => { }, [item.properties.attachedProduct.value]);

  const selectProduct = (product) => {
    if (item.properties.attachedProduct.value?.objectId === product?.item?.objectId) {
      item.properties.attachedProduct.value = {};
      triggerSnackbar("Product deselected");
      return;
    }
    item.properties.attachedProduct.value = {
      objectId: product?.item?.objectId,
      token_address: product?.item?.token_address || product?.item?.erc721address,
      uid: product?.item?.uid,
      type,
    }
    triggerSnackbar("Product selected");
  }

  if (loading) return <ProductsCentered>Loading...</ProductsCentered>
  if (!products?.length) return <ProductsCentered>Nothing found</ProductsCentered>

  return <ProductsWrapper>
    {products.map(product =>
      <ProductButton onClick={() => selectProduct(product)}>
        <ProductItem selected={item?.properties?.attachedProduct?.value?.objectId === product?.item?.objectId} >
          <Banner src={formatIPFSLink(product?.backendDetails?.image)} alt="banner" />
          <Info>
            <Title>
              {product?.backendDetails?.title || product?.backendDetails?.contractName}
            </Title>
          </Info>
        </ProductItem>
      </ProductButton>
    )}
  </ProductsWrapper>
}

export const MetaProducts = ({ item }) => {
  const [tab, setTab] = useState(PRODUCT_CATEGORIES.PROFILE_STAKING);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [products, setProducts] = useState([]);
  const { user } = useAuth()

  useEffect(() => {
    const loaditems = async () => {
      setLoading(true);
      const productsRes = await Moralis.Cloud.run("user-items", {
        category: tab,
        ethAddress: user?.get("ethAddress"),
        page
      });
      if (!productsRes?.items?.length) return;

      const ids = productsRes?.items?.map(item => item?.item?.objectId)
      const backendRes = await (tab === PRODUCT_CATEGORIES.PROFILE_GOLDLIST ?
        getProjectInfo(ids) : getProductInfo(ids));

      for (let i = 0; i < productsRes?.items?.length; i++) {
        const backendDetails = backendRes?.data?.find(
          item => item?.objectId === productsRes?.items[i]?.item?.objectId
        );
        if (backendDetails) {
          productsRes.items[i] = {
            ...productsRes.items[i],
            backendDetails
          };
        }
      }

      setProducts(productsRes);
      setLoading(false);
    }

    loaditems();
  }, [tab]);

  return <TabContext value={tab}>
    <CustomTabList
      onChange={(event, value) => setTab(value)}
      aria-label='content'
    >
      <CustomTab label='Staking' value={PRODUCT_CATEGORIES.PROFILE_STAKING} />
      <CustomTab label='Airdrop' value={PRODUCT_CATEGORIES.PROFILE_AIRDROP} />
      <CustomTab label='Allocation' value={PRODUCT_CATEGORIES.PROFILE_ALLOCATION} />
      <CustomTab label='Goldlist' value={PRODUCT_CATEGORIES.PROFILE_GOLDLIST} />
    </CustomTabList>
    <StyledTabPanel value={PRODUCT_CATEGORIES.PROFILE_STAKING}>
      <ProductsList
        products={products?.items}
        loading={loading}
        item={item}
        type={PRODUCT_CATEGORIES.PROFILE_STAKING}
      />
    </StyledTabPanel>
    <StyledTabPanel value={PRODUCT_CATEGORIES.PROFILE_AIRDROP}>
      <ProductsList
        products={products?.items}
        loading={loading}
        item={item}
        type={PRODUCT_CATEGORIES.PROFILE_AIRDROP}
      />
    </StyledTabPanel>
    <StyledTabPanel value={PRODUCT_CATEGORIES.PROFILE_ALLOCATION}>
      <ProductsList
        products={products?.items}
        loading={loading}
        item={item}
        type={PRODUCT_CATEGORIES.PROFILE_ALLOCATION}
      />
    </StyledTabPanel>
    <StyledTabPanel value={PRODUCT_CATEGORIES.PROFILE_GOLDLIST}>
      <ProductsList
        products={products?.items}
        loading={loading}
        item={item}
        type={PRODUCT_CATEGORIES.PROFILE_ALLOCATION}
      />
    </StyledTabPanel>
  </TabContext >
}