import { useState } from "react";
import styled from "styled-components";
import { Button } from "antd";
import { useContext } from "react";
import { Context } from "../../../store/Context";
import { ModalInput } from "../../Input/ModalInput";
import { EditorSnackbarContext } from "../../../contexts/editor-snackbar";

const Wrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;
const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`;
const ButtonPublish = styled(Button)`
    border-radius: 50px;
    background: linear-gradient(90deg, #4D44FE 0%, #8552F6 100%);
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px -2px 0px 0px rgba(0, 0, 0, 0.20) inset;
    padding: 0;
    border: none;
    text-transform: uppercase;
    width: 30%;
    height: 40px;
    color: #fff;
    cursor: pointer;
`;
const Title = styled.div`
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 5px;
`;
const InputWrap = styled(Wrap)`
    margin-bottom: 20px;
`;

const TabLivestream = () => {
    const { editorStore } = useContext(Context);
    const [streamUrl, setStreamUrl] = useState('');
    const { triggerSnackbar } = useContext(EditorSnackbarContext);

    const onUrlChange = (e) => {
        setStreamUrl(e.target.value);
    }

    const handleSubmit = () => {
        try {
            const item = editorStore.editor.selector.items.length === 1 ? editorStore.editor.selector.items[0] : null
            if (!item) throw new Error("No frame selected")

            item.data.imageMeta.value = { isLivestream: true, url: streamUrl };
            item.data.name.value = "Livestream";
            triggerSnackbar("Applying livestream", "info");
        } catch (e) {
            console.warn("TabPresentation::savePresentation error:", e);
        }
    }

    return <Wrap>
        <Container>
            <Title>
                Livestream URL
            </Title>
            <InputWrap>
                <ModalInput
                    handleChange={onUrlChange}
                    value={streamUrl}
                />
            </InputWrap>
            <Wrap>
                <ButtonPublish onClick={handleSubmit}>
                    Publish
                </ButtonPublish>
            </Wrap>
        </Container>
    </Wrap>
}

export default TabLivestream;