import { EDITOR_CONTEXT_CONSTS } from './const';
import { createContext, useContext } from 'react';

export const defaultState = {
  space: null,
  isLoading: true,
  isError: false,
};

export const EditorStateContext = createContext(defaultState);
export const EditorDispatchContext = createContext();

export const useEditorContext = () => useContext(EditorStateContext);
export const useEditorDispatch = () => useContext(EditorDispatchContext);

export const useEditor = () => ({
  ...useEditorContext(),
  ...useEditorDispatch(),
});

export const dispatchProvider = (dispatch) => ({
  setSpace: (payload) =>
    dispatch({ type: EDITOR_CONTEXT_CONSTS.SET_SPACE, payload }),
  setLoading: (payload) =>
    dispatch({ type: EDITOR_CONTEXT_CONSTS.SET_LOADING, payload }),
  setError: (payload) =>
    dispatch({ type: EDITOR_CONTEXT_CONSTS.SET_ERROR, payload }),
});
