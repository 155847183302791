import React from 'react'
// eslint-disable-next-line import/no-anonymous-default-export
export default ({fill = '#191919', className = '', width = 13}) => (
  <svg
    width={width}
    height={width}
    viewBox={'0 0 13 13'}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    fill={'fill'}
  >
    <path
      d="M0.646448 1.35355L6.14645 6.85355L6.85355 6.14645L1.35355 0.646447L0.646448 1.35355ZM6.14645 6.85355L11.6464 12.3536L12.3536 11.6464L6.85355 6.14645L6.14645 6.85355ZM11.6464 0.646447L6.14645 6.14645L6.85355 6.85355L12.3536 1.35355L11.6464 0.646447ZM6.14645 6.14645L0.646447 11.6464L1.35355 12.3536L6.85355 6.85355L6.14645 6.14645Z"
      fill={fill}
    />
  </svg>
)
