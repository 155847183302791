import {
  Scene,
  PerspectiveCamera,
  Vector3,
  Color,
  PointLight,
  AmbientLight,
  DirectionalLight,
  HemisphereLight,
  Clock,
  MOUSE,
  Mesh,
  BoxGeometry,
  MeshStandardMaterial,
  Box3,
  MeshBasicMaterial,
  Matrix4,
  InstancedMesh
} from 'three'

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { FirstPersonControls } from 'three/examples/jsm/controls/FirstPersonControls'
import PointerLock from './CameraControllers/PointerLock'
//import {SpatialControls} from 'spatial-controls' // https://github.com/vanruesc/spatial-controls
import { SpatialControls } from './CameraControllers/spatial-controls' // https://github.com/vanruesc/spatial-controls
import ThirdPersonControls from './CameraControllers/ThirdPersonControls'
import SwitchableControls from './CameraControllers/SwitchableControls'
import Light from '../../../Core/Entities/Light'
import Box from '../../../Core/Entities/Box'
import Picture from '../../../Core/Entities/Picture'
import isMobileAndTablet from '../../../../../helpers/isMobile'
import TouchControls from './CameraControllers/ToutchControls/TouchControls'

export default class SceneThree {
  constructor(engineComponent) {
    this.engineComponent = engineComponent

    this.backgroundColor = 0xffffff
  }

  init() {
    this.clock = new Clock()
    this.time = 0

    this.scene = new Scene()
    this.scene.background = new Color(this.backgroundColor)

    // Camera
    this.camera = new PerspectiveCamera(
      45,
      this.engineComponent.container.clientWidth / this.engineComponent.container.clientHeight,
      0.01,
      1000
    );
    this.setCameraControlMode(0)

    // Light

    // this.lightDirection.intensity = 0.5

    // this.lightHemisphereLight = new HemisphereLight() // soft white light
    // this.lightHemisphereLight.intensity = 1.2
    // this.scene.add(this.lightHemisphereLight)

    // this.lightDirection = new DirectionalLight()
    // this.lightDirection.intensity = 0.5
    // this.lightDirection.castShadow = true
    // // this.scene.add(this.lightDirection)
    // console.log('project exists?', !!this.engineComponent.app.project)
    // const lightDirectionObject = new Light({ project: this.engineComponent.app.project })
    // lightDirectionObject.data.children = this.lightDirection
    // lightDirectionObject.data.name = "Directional light"
    // console.log(lightDirectionObject)
    // console.log("UPDATE", this.engineComponent.app.project.itemsToUpdate)
    // this.engineComponent.app.project.dispatchUpdateStates()

    // this.lightAmbient = new AmbientLight() // soft white light
    // this.lightAmbient.intensity = 1.6
    // this.lightAmbient.castShadow = true
    // // this.scene.add(this.lightAmbient)


    // console.log("SCENE", this.scene, this.engineComponent.app)

    // this.engineComponent.app.project.add(lightDirectionObject)
    // console.log("PROJECT:", this.engineComponent.app.project)

    // this.lightDirection = new DirectionalLight()
    // this.lightDirection.intensity = 0.5
    // this.scene.add(this.lightDirection)

    // this.helperLightProbeHelper = new LightProbeHelper(0, 1)
    // this.scene.add(this.helperLightProbeHelper)
    /*// demo
    const geometry = new BoxGeometry(1, 1, 1)
    const material = new MeshStandardMaterial({color: 0xff0000})
    this.mesh = new Mesh(geometry, material)
    this.scene.add(this.mesh)*/
  }

  destroy() {
    if (this.scene) {
      this.scene = null
    }
    if (this.camera) {
      this.camera = null
    }
  }

  resize() {
    this.camera.aspect = this.engineComponent.container.clientWidth / this.engineComponent.container.clientHeight
    this.camera.updateProjectionMatrix()
    // console.log('resize=', this, 'w=', this.engineComponent.container.clientWidth, 'h=', this.engineComponent.container.clientHeight, 'aspect=', this.camera.aspect)
  }

  render(timestamp) {
    const dt = Math.min(this.clock.getDelta(), 0.1)
    const delta = this.controlsMode === 3 ? timestamp : dt;
    this.updateCameraControls(delta)
    this.updateObjectScripts(dt)
    this.engineComponent.engine.render(this.scene, this.camera)
  }

  setCameraControlMode(mode) {
    // console.log('setCameraControlMode=', mode, this)
    if (this.controlsMode === mode) return
    this.controlsMode = mode

    if (this.controls) {
      this.controls.dispose()
      this.controls = null
    }

    if (mode === 0) {
      // Its mode for scene on Room Detail Page
      this.camera.position.copy(new Vector3(0, 0, -10))
      this.camera.lookAt(this.scene.position)
      // this.camera.updateMatrixWorld()
      this.controls = new OrbitControls(this.camera, this.engineComponent.engine.domElement)
      // this.controls.mouseButtons.MIDDLE = MOUSE.ROTATE
    } else if (mode === 1) {
      this.camera.position.copy(new Vector3(-10, 0, 0))
      this.camera.lookAt(new Vector3(0, 0, 0))
      this.controls = new FirstPersonControls(this.camera, this.engineComponent.engine.domElement)
      //this.controls.movementSpeed = 150
      //this.controls.lookSpeed = 0.1
    } else if (mode === 2) {
      if (isMobileAndTablet()) {
        const touchOptions = {
          moveSpeed: 1.1, // speed of movement
          rotationSpeed: 0.0016, // coefficient of rotation
          maxPitch: 55, // max camera pitch angle
          hitTest: true, // stop on hitting objects
          hitTestDistance: 40, // distance to test for hit
        };
        this.camera.position.copy(new Vector3(0, 1.63, 0));
        this.controls = new TouchControls(
          this.camera,
          this.engineComponent.engine.domElement.parentNode,
          this.engineComponent.app,
          touchOptions
        );
        this.controls.addToScene(this.scene)
      } else {
        this.controls = new PointerLock(this.camera, this.engineComponent.engine.domElement, this.engineComponent.app)
      }
      this.controls.init();
    } else if (mode === 3) {
      //this.camera.position.copy(new Vector3(0, 1.75, 0))
      //this.camera.lookAt(new Vector3(1, 1.75, 0))
      const { position, quaternion } = this.camera
      this.controls = new SpatialControls(position, quaternion, this.engineComponent.engine.domElement)
      const settings = this.controls.settings
      settings.rotation.setSensitivity(2.2)
      settings.rotation.setDamping(0.05)
      settings.translation.setSensitivity(3.0)
      settings.translation.setDamping(0.1)
      //this.controls.setPosition(new Vector3(0, 1.75, 0))
      //this.controls.lookAt(new Vector3(1, 1.75, 0))
      this.controls.setPosition(0, 1.75, 0)
      this.controls.lookAt(1, 1.75, 0)
    }
  }

  updateObjectScripts(dt) {
    this.engineComponent.app.project.objects.forEach(obj => {
      if (obj.data?._compiledUpdate) {
        try {
          obj.data._compiledUpdate(obj, dt);
        } catch (e) {
          console.log(e)
        }
      }
    });
  }

  updateCameraControls(delta) {
    if (!this.controls) return
    if (this.controls.update) {
      this.controls.update(delta);
    }
  }

  setCameraControlState(state) {
    // console.log('setCameraControlState=', this)
    if (this.controlsMode === 3) {
      if (this.controls.isEnabled() === state) return
      this.controls.setEnabled(state)
    } else {
      this.controls.enabled = state
    }
  }

  cameraFitView() {
    // console.log('cameraFitView=', this.camera)
    if (this.insideMap) return
    const boxAll = this.getBounds(this.scene)
    if (boxAll) {
      const centerAll = boxAll.getCenter(new Vector3())
      const sizeAll = boxAll.getSize(new Vector3())
      this.camera.position.copy(centerAll.clone().addScalar(sizeAll.length()))
      this.controls.target.copy(centerAll)
    } else console.warn('no boxAll')
  }

  getBounds(object3D) {
    object3D.updateMatrixWorld(true)

    let boxAll = null // new Box3()
    const box = new Box3()
    const showBounds = false

    let meshesCount, meshI, mesh, matrix
    if (showBounds) {
      meshesCount = 0
      object3D.traverse(obj => {
        if ((obj.isMesh || obj.type === 'Box3Helper') && obj.geometry) meshesCount++
      })

      const material = new MeshBasicMaterial({ color: 0xffffff, opacity: 1, transparent: true })
      const geometry = new BoxGeometry(1, 1, 1)
      matrix = new Matrix4()
      mesh = new InstancedMesh(geometry, material, meshesCount)
      meshI = 0
    }

    object3D.traverse(obj => {
      if ((obj.isMesh || obj.type === 'Box3Helper') && obj.geometry && obj.geometry.boundingBox) {
        // obj.geometry.computeBoundingBox()
        box.copy(obj.geometry.boundingBox).applyMatrix4(obj.matrixWorld)
        if (!boxAll) boxAll = box.clone()
        else boxAll = boxAll.union(box)

        if (showBounds) {
          const center = box.getCenter(new Vector3())
          const size = box.getSize(new Vector3())

          matrix.copy(new Matrix4().makeTranslation(center.x, center.y, center.z).multiply(new Matrix4().makeScale(size.x, size.y, size.z)))
          mesh.setMatrixAt(meshI, matrix)
          meshI++
        }
      }
    })

    if (showBounds) {
      console.log('meshesCount=' + meshesCount + ', meshI=' + meshI)
      object3D.add(mesh)
    }

    // console.log('getBounds=', object3D, ', boxAll=', boxAll)
    return boxAll
  }
}
