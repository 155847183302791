// import useOnClickOutside from '../helpers/useOnClickOutside'
// import {useRef} from 'react'
import ContentTabs from '../ContentTabs/ContentTabs'
import { PanelContainer } from './styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Box } from '@mui/material'
import useOnClickOutside from '../../helpers/useOnClickOutside'
import { useRef } from 'react'
import SceneMenu from './SceneMenu'

export default function LeftPanel(props) {
  const panelRef = useRef(null)
  useOnClickOutside(panelRef, props.event)
  const style = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    bgcolor: 'rgba(0, 0, 0, 0.5);',
  }
  const matches600 = useMediaQuery('(min-width:600px)')
  return (
    <PanelContainer left>
      <SceneMenu close={props.event} setModal={props.setModal} />
    </PanelContainer>
  )

  // return (
  //   <>
  //     {/* <Box sx={style} ref={panelRef} onClick={props.event} /> */}
  //     <PanelContainer left>
  //       <ContentTabs
  //         tabIndex={props.tabIndex}
  //         close={props.event}
  //         setTabIndex={props.setTabIndex}
  //       />
  //     </PanelContainer>
  //   </>
  // )

}
