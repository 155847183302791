import { TypeEntity, UpdateState } from '../Constants'
import RenderableItem from '../RenderableItem'
import { inheritConstructor, SerAttr } from '../SerializableObject'

export default class ObjectItem extends RenderableItem {
  onConstructor() {
    //super.onConstructor.apply(this, arguments)
    super.onConstructor(...arguments)

    this.type = TypeEntity.ObjectItem

    const that = this

    this.addProperty('scale', {
      control: 'editFloatSlider',
      type: 'float',
      value: {
        get xyz() {
          return that.data.scale._value.xyz
        },
        set xyz(value) {
          if (that.data.scale._value.xyz === value) return
          that.data.scale._value.xyz = value
          that.data.size._value.x = value
          that.data.size._value.y = value
          that.data.size._value.z = value
          that.setUpdateState(UpdateState.Shape)
        }
      }
    })
    this.data.scale._value = { xyz: 1 }

    this.addProperty('position', {
      control: 'editFloat3',
      type: 'float',
      value: {
        get x() {
          return that.data.position._value.x
        },
        set x(value) {
          if (that.data.position._value.x === value) return
          that.data.position._value.x = value
          that.setUpdateState(UpdateState.Shape)
        },
        get y() {
          return that.data.position._value.y
        },
        set y(value) {
          if (that.data.position._value.y === value) return
          that.data.position._value.y = value
          that.setUpdateState(UpdateState.Shape)
        },
        get z() {
          return that.data.position._value.z
        },
        set z(value) {
          if (that.data.position._value.z === value) return
          that.data.position._value.z = value
          that.setUpdateState(UpdateState.Shape)
        }
      }
    })
    this.data.position._value = { x: 0, y: 0, z: 0 }

    this.addProperty('rotation', {
      control: 'editFloat3',
      type: 'float',
      value: {
        get x() {
          return that.data.rotation._value.x
        },
        set x(value) {
          if (that.data.rotation._value.x === value) return
          that.data.rotation._value.x = value
          that.setUpdateState(UpdateState.Shape)
        },
        get y() {
          return that.data.rotation._value.y
        },
        set y(value) {
          if (that.data.rotation._value.y === value) return
          that.data.rotation._value.y = value
          that.setUpdateState(UpdateState.Shape)
        },
        get z() {
          return that.data.rotation._value.z
        },
        set z(value) {
          if (that.data.rotation._value.z === value) return
          that.data.rotation._value.z = value
          that.setUpdateState(UpdateState.Shape)
        }
      }
    })
    this.data.rotation._value = { x: 0, y: 0, z: 0 }

    this.addProperty('size', {
      control: 'editFloat3',
      type: 'float',
      value: {
        get x() {
          return that.data.size._value.x
        },
        set x(value) {
          if (that.data.size._value.x === value) return
          that.data.size._value.x = value
          that.setUpdateState(UpdateState.Shape)
        },
        get y() {
          return that.data.size._value.y
        },
        set y(value) {
          if (that.data.size._value.y === value) return
          that.data.size._value.y = value
          that.setUpdateState(UpdateState.Shape)
        },
        get z() {
          return that.data.size._value.z
        },
        set z(value) {
          if (that.data.size._value.z === value) return
          that.data.size._value.z = value
          that.setUpdateState(UpdateState.Shape)
        }
      }
    })
    this.data.size._value = { x: 1, y: 1, z: 1 }

    this.addProperty('path', {
      control: 'edit',
      type: 'string',
      visible: false,
      value: {
        default: '',
        get: property => {
          return property._value
        },
        set: (property, value) => {
          if (property._value === value) return
          property._value = value
          this.setUpdateState(UpdateState.Shape)
        }
      }
    })
    
    this.addProperty('script', {
      control: 'edit',
      type: 'string',
      visible: false,
      value: {
        default: '',
        get: property => {
          return property._value
        },
        set: (property, value) => {
          if (property._value === value) return
          property._value = value
          this.setUpdateState(UpdateState.Script)
        }
      }
    })
    
    this.addProperty('attachedProduct', {
      control: 'edit',
      type: 'object',
      visible: false,
      value: {
        default: {},
        get: property => {
          return property._value
        },
        set: (property, value) => {
          if (property._value === value) return
          property._value = value
          this.setUpdateState(UpdateState.Shape)
        }
      }
    })
  }

  setPosition(value) {
    // console.log('setPosition=', value, this)
    this.data.position.value.x = value.x
    this.data.position.value.y = value.y
    this.data.position.value.z = value.z
  }

  setRotation(value) {
    // console.log('setRotation=', value, this)
    this.data.rotation.value.x = value.x
    this.data.rotation.value.y = value.y
    this.data.rotation.value.z = value.z
  }

  setSize(value) {
    // console.log('setSize=', value, this)
    this.data.size.value.x = value.x
    this.data.size.value.y = value.y
    this.data.size.value.z = value.z
  }

  setScale(value) {
    // console.log('setSize=', value, this)
    this.data.scale.value.xyz = value.xyz
  }
}
inheritConstructor(ObjectItem, RenderableItem, 'ObjectItem', {
  type: TypeEntity.ObjectItem,
  serializable: [
    new SerAttr('position', { defaultValue: { x: 0, y: 0, z: 0 }, setter: (_this, value) => _this.setPosition(value) }),
    new SerAttr('rotation', { defaultValue: { x: 0, y: 0, z: 0 }, setter: (_this, value) => _this.setRotation(value) }),
    new SerAttr('size', { defaultValue: { x: 1, y: 1, z: 1 }, setter: (_this, value) => _this.setSize(value) }),
    new SerAttr('scale', { defaultValue: { xyz: 1 }, setter: (_this, value) => _this.setScale(value) }),
    new SerAttr('path', { defaultValue: '' }),
    new SerAttr('script', { defaultValue: '' }),
    new SerAttr('attachedProduct', { defaultValue: {} })
  ]
})
