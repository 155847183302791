import { TypeEntity } from './Constants';
import SerializableObject, {
  inheritConstructor,
  SerAttr,
} from './SerializableObject';

export default class Project extends SerializableObject {
  constructor(app) {
    super(...arguments);
    if (this.type === undefined) this.type = TypeEntity.Project;
    this.version = 0.05;
    this.app = app;
    this.objects = [];
    this.itemsToUpdate = new Set();
    this.data = {};
  }

  add(item) {
    if (!item) {
      console.warn(this.constructor.name + '.add: null item');
      return;
    }
    this.app.events.trigger('add', item);
    if (!this.objects?.includes(item)) {
      this.objects.push(item);
      item.project = this;
    } else console.warn('item is already added=' + item.id, item);
  }

  remove(item) {
    if (!item) {
      console.warn(this.constructor.name + '.remove: null item');
      return;
    }
    this.app.events.trigger('remove', item);
    const iIndex = this.objects.indexOf(item);
    if (iIndex !== -1) {
      this.objects.splice(iIndex, 1);
      item.project = null;
    } else console.warn('item is not found in project=' + item.id);
  }

  destroy() {
    while (this.objects.length > 0) {
      this.objects[0].destroy();
      this.remove(this.objects[0]);
    }
  }

  dispatchUpdateStates(states) {
    this.itemsToUpdate.forEach((val, item) =>
      item.dispatchUpdateStates(states)
    );
    this.itemsToUpdate.clear();
  }

  getById(id) {
    for (let i = 0, ol = this.objects.length; i < ol; i++) {
      const obj = this.objects[i];
      if (obj.id === id) return obj;
    }
    return super.getById.apply(this, arguments);
  }
}
inheritConstructor(Project, SerializableObject, 'Project', {
  type: TypeEntity.Project,
  serializable: [
    new SerAttr('version', { isData: false, flags: 3 }),
    new SerAttr('objects', {
      isData: false,
      isIdObject: true,
      setter: null,
      inserter: null /*, remover: null*/,
    }),
    new SerAttr('spawn', { isData: false }),
    new SerAttr('movespeed', { isData: false }),
    new SerAttr('shadowsEnabled', { isData: false, defaultValue: false }),
    //new SerAttr('roomId', {defaultValue: -1}),
  ],
});
