
import { Context } from "../../../ui/store/Context";
import { useState, useContext } from "react";

import { VolumeOff, VolumeUp, Mic, MicOff, Close } from '@mui/icons-material';
import { ContentMPSettings, PanelMPSettings } from "./styles";
import isMobileAndTablet from "../../../../helpers/isMobile";

const UserSettings = ({ user }) => {
    const { editorStore } = useContext(Context);
    const [userMuted, setUserMuted] = useState(user?.stream?.muted);
    const [hovered, setHovered] = useState(false);

    const switchPlayerMute = async (uuid) => {
        if (!uuid || !editorStore.editor.socket) return;
        const _userMuted = editorStore.editor.socket.switchPlayerMute(uuid);
        setUserMuted(_userMuted);
    }

    return (
        <div
            onClick={() => { switchPlayerMute(user.uuid) }}
            onTouchStart={() => { switchPlayerMute(user.uuid) }}
            onMouseEnter={() => setHovered(!hovered)}
            onMouseLeave={() => setHovered(!hovered)}
            style={{
                color: hovered ? 'white' : 'grey',
                width: "fit-content",
                userSelect: "none"
            }}
            title={userMuted ? 'unmute' : 'mute'}
        >
            • {user?.username}
            {
                userMuted ?
                    <VolumeOff style={{
                        position: "relative",
                        height: "15px",
                        top: "4px"
                    }} /> : <VolumeUp style={{
                        position: "relative",
                        height: "15px",
                        top: "4px"
                    }} />
            }
        </div>
    )
}

export const MultiplayerSettingsPanel = ({ roomData, setShowMPSettings }) => {
    const isMobile = isMobileAndTablet();

    return <PanelMPSettings style={{ marginLeft: isMobile && "40px", top: isMobile && "8px" }}>
        <ContentMPSettings>
            <div style={{ color: "white" }}>
                Metaplayers in this space:
            </div>
            {roomData.length !== 0 ?
                roomData.map((user, index) => user && <UserSettings user={user} key={user?.uuid} />)
                : "No one here yet"
            }
        </ContentMPSettings>
    </PanelMPSettings>
}