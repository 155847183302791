
// /* eslint-disable */
// function evaluator () {
//     with (this.scopeGuard) {
//         with (this.evalOnce) {
//             return function() {
//                 "use strict";
//                 return eval(arguments[0]);
//             }
//         }
//     }
// }
// /* eslint-enable */

// const safeEval = (source, globalObject = Object.create(null)) => {
//     const evalate = evaluator.call({
//         evalOnce: Object.freeze(
//             Object.defineProperty(Object.create(null), 'eval', {
//                 get: Array.prototype.pop.bind([eval])
//             })
//         ),
//         scopeGuard: new Proxy(globalObject, {
//             has: () => true
//         }),
//     })
//     return evalate.call(globalObject, source);
// }

export const compileCustomScript = (source, item) => {
    try {
        const formattedSource = (source += '\ntry {return { start, update, click }} catch(e) {console.log(e)}');
        const scope = Function(formattedSource);
        return { start: scope()?.start, update: scope()?.update, click: scope()?.click };
    } catch (e) {
        return { error: e }
    }
}