export default class Selector {
  constructor({ app }) {
    this.app = app;

    this.items = [];
  }

  add(item) {
    if (!item) {
      console.warn(this.constructor.name + '.add: null item');
      return;
    }

    if (!this.items?.includes(item)) {
      this.items.push(item);
      item.data.selected.value = true;
    } else console.warn('item is already added=' + item.id, item);
  }

  remove(item) {
    if (!item) {
      console.warn(this.constructor.name + '.remove: null item');
      return;
    }

    const iIndex = this.items.indexOf(item);
    if (iIndex !== -1) {
      this.items.splice(iIndex, 1);
      item.data.selected.value = false;
    } else console.warn('item is not found=' + item.id);
  }

  clear() {
    while (this.items.length > 0) {
      this.remove(this.items[0]);
    }
  }
}
