import { useEffect, useState } from 'react'
import Moralis from '../../../const/moralis'
import isMobileAndTablet from '../../../helpers/isMobile'
import Web3 from 'web3'
import CONFIG from '../../../config'
import WalletConnectWeb3ConnectorV2 from './WalletConnect'
import { useMoralis } from "react-moralis";

const connector = isMobileAndTablet() ? WalletConnectWeb3ConnectorV2 : undefined;

const useAuthProvider = () => {
  const [user, setUser] = useState(Moralis.User.current())
  const [web3, setWeb3] = useState()
  const { authenticate: moralisAuthenticate } = useMoralis();

  if (typeof window?.ethereum?.on === 'function')
    window?.ethereum?.on('accountsChanged', async function (accounts) {
      try {
        if (user?.get('ethAddress')?.toLowerCase() !== accounts[0]) {
          const temp = await authenticateSelfHosted();
          setUser(temp);
        }
      } catch (e) {
        console.error(e);
      }
    })

  const changeNetwork = async (web3js) => {
    if (isMobileAndTablet()) return; // wallet_switchEthereumChain fails on walletconnect
    if ((await (web3js || web3).eth.getChainId()) !== CONFIG.REACT_APP_CHAIN_ID)
      await Moralis.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${CONFIG.REACT_APP_CHAIN_ID}` }],
      });
  };

  const authenticateSelfHosted = async (account, network) => {
    const { message } = await Moralis.Cloud.run("requestMessage", {
      address: account,
      chain: network.chainId.toString(16),
      network: "evm",
    });

    return await moralisAuthenticate({
      signingMessage: message,
      throwOnError: true,
    });
  }

  const authenticate = async () => {
    const ethers = await Moralis.enableWeb3({ connector });
    const web3Js = new Web3(Moralis.provider);
    setWeb3(web3Js);
    changeNetwork(web3Js);
    const accounts = await ethers.listAccounts();
    const network = await ethers.getNetwork();

    const account = accounts[0];
    if (user?.get('ethAddress')?.toLowerCase() !== account?.toLowerCase()) {
      const temp = await authenticateSelfHosted(account, network);
      setUser(temp);
      return { user: temp, web3: web3Js };
    }

    return { user, web3: web3Js };
  }

  const checkAndAuthenticate = async () => {
    if (user?.get('ethAddress')) return user;

    return await authenticate();
  }

  useEffect(() => {
    const load = async () => {
      try {
        await Moralis.start({
          appId: CONFIG.REACT_APP_MORALIS_APP_ID,
          serverUrl: CONFIG.REACT_APP_MORALIS_SERVER_URL,
        });
        authenticate();
      } catch (e) {
        console.warn(e);
      }
    }
    load();
  }, [window.location.href]);

  return {
    user,
    web3,
    changeNetwork,
    authenticate,
    checkAndAuthenticate
  }
}

export default useAuthProvider
