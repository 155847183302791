import React, { useContext } from 'react'
import styled from 'styled-components'
import { Context } from '../../store/Context'

const Percent = styled.div`
  width: 350px;
  height: 80%;
  // position: relative;
  svg {
    width: 150px;
    height: 150px;
    position: relative;
  }
  svg circle {
    width: 150px;
    height: 150px;
    fill: none;
    stroke-width: 2;
    stroke: transparent;
    transform: translate(5px, 5px);
    stroke-dasharray: 440;
    stroke-dashoffset: 440;
    stroke-linecap: round;
    :nth-child(1) {
      stroke-dashoffset: 0;
      stroke: transparent;
    }
    :nth-child(2) {
      stroke: #f4f4f4;
    }
  }
`
const Box = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 400px;
  // position: relative;
  flex-direction: column;
  transition: transform 0.2s;
`
const Count = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  position: absolute;
  h3 {
    color: #fff;
    font-size: 12px;
  }
`
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`
const Name = styled.div`
  font-size: 36px;
  font-style: bold;
  font-weight: 500;
  line-height: 20px;
  font-family: Montserrat;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`

const ProgressTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  font-family: Montserrat;
`

const LoaderProgressBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  width: ${props => props?.width || "0"}%;
  background-color: #fff;
  transition: width 1s;
`

export const LoaderProgress = () => {
  const { editorStore } = useContext(Context);
  const spaceName = editorStore?.editor?.project?.baseSpaceName;

  return (
    <Container id={'loader_circle'}>
      <Box>
        {spaceName ? <Name>{spaceName}</Name> : null}
        <Row>
          <ProgressTitle>Loading MetaSpace...</ProgressTitle>
          {/* <ProgressTitle id={'progress'}>0%</ProgressTitle> */}
        </Row>
        {/* <Percent>
        <svg>
            <circle cx="70" cy="70" r="70"></circle>
            <circle cx="70" cy="70" r="70" id={'processCircle'}></circle>
          </svg>  
        <Count>
        </Count>
        </Percent> */}
      </Box>
      <LoaderProgressBar id='processCircle' />
    </Container>
  )
}
