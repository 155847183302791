import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 225px;
  height: 319px;
  padding: 16px;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease;
  backdrop-filter: blur(34px);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  border-radius: 12px;
  opacity: ${(props) => (props.isSelected ? 1 : 0.7)};
  transition: all 0.3 ease;
  ${props => props.isSelected ? 'border: 1px solid white' : ''};
`;

export const Image = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
`;

export const NftInfo = styled.div`
  font-size: 12px;
  line-height: 16px;
  margin: 2px 0;
  color: #717171;
`;

export const NftName = styled.div`
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
  word-break: break-word;
  color: #f1f1f1;

  @media screen and (max-width: 500px) {
    max-width: 100%;
    margin-top: 10px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 6px;
`;

export const NftPrice = styled.div`
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
  word-break: break-word;
  color: #f1f1f1;
`;

export const TextContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
