import ObjectItem from "../editor/Core/Entities/ObjectItem";
import { io } from "socket.io-client";
import { Peer } from "peerjs";
import { AnimationMixer, LoadingManager, Euler, Quaternion } from "three";
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import { EventDispatcher } from 'EventDispatcher'
import walkAnimation from "../editor/Animations/walk.fbx";
import runAnimation from "../editor/Animations/run.fbx";
import danceAnimation from "../editor/Animations/dance.fbx";
import idleAnimation from "../editor/Animations/idle.fbx";
import jumpAnimation from "../editor/Animations/jump.fbx";
import fallAnimation from "../editor/Animations/fall.fbx";
import Moralis from '../../const/moralis';
import CONFIG from "../../config";
import { deleteItem } from "../editor/Core/Algs/Basic";
import { AnimationStates } from "../editor/Renderer/Interfaces/StateMachine/const/AnimationStates";

const MODEL_URL_DEFAULT = "https://models.readyplayer.me/65314750614f936f482ab4bf.glb";

const getOptimizedModel = (url) => {
    return url //+ '?textureFormat=webp&lod=1&textureAtlas=512&textureSizeLimit=1024';
}

class StreamSplit {
    constructor(stream, { left = 1, right = 1 } = {}) {
        this.stream = stream;

        // create audio context using the stream as a source
        this.track = stream.getAudioTracks()[0];
        // this.track.enabled = false; mute other player
        this.context = new AudioContext();
        this.source = this.context.createMediaStreamSource(new MediaStream([this.track]));

        // create a channel for each ear (left, right)
        this.channels = {
            left: this.context.createGain(),
            right: this.context.createGain(),
        };

        // connect the gains
        this.source.connect(this.channels.left);
        this.source.connect(this.channels.right);

        // create a merger to join the two gains
        const merger = this.context.createChannelMerger(2);
        this.channels.left.connect(merger, 0, 0);
        this.channels.right.connect(merger, 0, 1);

        // set the volume for each side
        this.setVolume(left, right);
        this.muted = false;

        // connect the merger to the audio context
        merger.connect(this.context.destination);

        this.destination = this.context.createMediaStreamDestination();
    }

    unmute() {
        this.muted = false;
        this.setVolume(0.5, 0.5);
    }

    mute() {
        this.setVolume(0, 0);
        this.muted = true;
    }

    // set the volume
    setVolume(left = 0, right = 0) {
        if (this.muted) return;
        // clamp volumes between 0 and 1
        left = Math.max(Math.min(left, 1), 0);
        right = Math.max(Math.min(right, 1), 0);

        // disable the stream if the volume is 0
        this.stream.enabled = left !== 0 && right !== 0;

        // set the volumes for each channel's gain
        this.channels.left.gain.value = left;
        this.channels.right.gain.value = right;
    }

    // close the context, stop the audio
    close() {
        return this.context.close();
    }
}

export default class socketHandler {
    /**
     * Note: all of the socket backend callback function names should start
     * with "callback_" (except handshake)
     */
    constructor({ app }) {
        this.app = app;
        this.callbackFunctions =
            Object.getOwnPropertyNames(socketHandler.prototype).filter(i => i.startsWith('callback_'));

        this.connectionTimeoutMs = 5000;
        this.host = CONFIG.REACT_APP_MULTIPLAYER_API_URL;
        this.totalTime = 0;
        this.events = new EventDispatcher()

        this.loadedAnimations = {
            idle: null,
            walk: null,
            run: null,
            jump: null,
            fall: null,
            dance: null
        }

        // available after successfull handshake
        this.userBodyAnimationController = {};
        this.socket = null;
        this.uuid = null;
        this.roomData = null; // users in the room
        this.myPos = null; // format: { x, y, z, quaternion: { w, y } }
        this.call = null;

        // Restrict data amount sent from client
        this.moveThrottleInterval = 66; // 66 ms - 15 times a second
        this.moveThrottled = this._throttle(
            this._clientMove,
            this.moveThrottleInterval
        );

        // Peer data
        this.SOUND_NEAR_RANGE = 4;
        this.SOUND_CUTOFF_RANGE = 20;
        this.peer = null;
        this.peerId = null;
        this.broadcastingSound = true;
    }

    /**
     * Internal helper fuction that time limit function calls
     * @param {*} func Function to be throttled
     * @param {*} limit time delay between function calls
     */
    _throttle(func, ms) {
        let isThrottled = false, savedArgs, savedThis;
        function wrapper() {
            if (isThrottled) {
                savedArgs = arguments;
                savedThis = this;
                return;
            }
            func.apply(this, arguments);
            isThrottled = true;
            setTimeout(function () {
                isThrottled = false;
                if (savedArgs) {
                    wrapper.apply(savedThis, savedArgs);
                    savedArgs = savedThis = null;
                }
            }, ms);
        }
        return wrapper;
    }

    _dist(pos1, pos2) {
        return Math.hypot(pos1.x - pos2.x, pos1.z - pos2.z);
    }

    /**
     * Sets user position to x, z
     * @param {number} userId - id of user in the room array
     * @param {number} x - x coordinate
     * @param {number} z - z coordinate
     * @param {object} quaternion - user rotation as a w, y vector
     */
    _setPosition(userId, x, y, z, quaternion, rotation) {
        if (x || z) {
            this.roomData[userId].userObject.data.position.value.x = x;
            this.roomData[userId].userObject.data.position.value.y = y;
            this.roomData[userId].userObject.data.position.value.z = z;
        }
        // if (quaternion) {
        //     const rot = 2 * Math.acos(quaternion.w) * (quaternion.y > 0 ? 1 : -1) + 3.141592;
        //     this.roomData[userId].userObject.data.rotation.value.y = rot;
        // }
        if (rotation) {
            this.roomData[userId].userObject.data.rotation.value.y = rotation;
        }
        this.roomData[userId].userObject.update(8); // update three.js object
    }

    /**
     * Internal move function (throttled)
     * @param {*} clientX 
     * @param {*} clientZ 
     */
    _clientMove(clientX, clientY, clientZ, quaternion, state) {
        if (clientX) this.myPos.x = clientX;
        if (clientX) this.myPos.y = clientY;
        if (clientZ) this.myPos.z = clientZ;
        if (quaternion) this.myPos.quaternion = quaternion;
        this.socket.emit(
            'move',
            { x: clientX, y: clientY, z: clientZ, quaternion, state }
        );
    }

    _calcVolumes(listenerPos, soundPos) {
        // calulate angle and distance from listener to sound
        const theta = Math.atan2(soundPos.x - listenerPos.x, soundPos.z - listenerPos.z);
        const dist = Math.hypot(soundPos.x - listenerPos.x, soundPos.z - listenerPos.z);
        const scale = 1 - (dist - this.SOUND_NEAR_RANGE) / (this.SOUND_CUTOFF_RANGE - this.SOUND_NEAR_RANGE);

        // target is too far away, no volume
        if (dist > this.SOUND_CUTOFF_RANGE)
            return [0, 0];

        // target is very close, max volume
        if (dist < this.SOUND_NEAR_RANGE)
            return [1, 1];

        const cos = Math.cos(theta);
        const sin = Math.sin(theta);

        return [
            1 - 2 * dist / this.SOUND_CUTOFF_RANGE,
            1 - 2 * dist / this.SOUND_CUTOFF_RANGE,
        ]

        // return [
        //     (Math.pow((cos < 0 ? cos : 0), 2) + Math.pow(sin, 2)) * scale,
        //     (Math.pow((cos > 0 ? cos : 0), 2) + Math.pow(sin, 2)) * scale,
        // ];
    }

    lerp(a, b, t) {
        if (a === undefined || b === undefined) return a;
        return a + t * (b - a);
    }

    lerpRotation(a, b, t) {
        if (a === undefined || b === undefined) return a;
        if (a < Math.PI / 2 && b > 1.5 * Math.PI) {
            a += 2 * Math.PI;
        } else if (b < Math.PI / 2 && a > 1.5 * Math.PI) {
            a -= 2 * Math.PI;
        }
        return a + t * (b - a);
    }

    lerp2(x1, x2, z1, z2, t) {
        const x = this.lerp(x1, x2, t);
        const z = this.lerp(z1, z2, t);
        return { x, z };
    }

    lerp3(x1, x2, y1, y2, z1, z2, t) {
        const x = this.lerp(x1, x2, t);
        const y = this.lerp(y1, y2, t);
        const z = this.lerp(z1, z2, t);
        return { x, y, z };
    }

    quaternionToEuler(quaternion) {
        const threeQuaternion = new Quaternion(null, quaternion.y, null, quaternion.w);
        const euler = new Euler().setFromQuaternion(threeQuaternion, "YXZ");
        return euler._y + Math.PI;
    }

    /**
     * Update function, called on every draw from controls
     * Lerps position for all moving users
     */
    onUpdate(deltaTime) {
        this.totalTime += deltaTime
        this.roomData.forEach((user, userId) => {
            if (!user?.animations || !user.animations[user.state]) {
                // console.warn(`Error playing animation #${user?.state}`, user)
                return;
            }
            if (user.currentStateId !== user.state) {
                const transitionTime = 0.2;
                user.animations[user.state].reset().crossFadeFrom(
                    user.animations[user.currentStateId],
                    transitionTime
                ).play();
                this.roomData[userId].currentStateId = user.state;
            }
            const { x, y, z, quaternion } = user.desiredPosition
            const lerpAmount = 0.1;
            const currentPosition = this.lerp3(
                user.userObject.data.position.value.x, x,
                user.userObject.data.position.value.y, y,
                user.userObject.data.position.value.z, z,
                lerpAmount
            );
            let rotationCurrent;
            if (quaternion) {
                rotationCurrent = this.lerpRotation(
                    user.userObject.data.rotation.value.y,
                    this.quaternionToEuler(quaternion),
                    lerpAmount
                );
            }
            this._setPosition(userId, currentPosition.x, currentPosition.y, currentPosition.z, quaternion, rotationCurrent);
        });
    }

    curatorSetGlobalMute(allMuted) {
        this.socket.emit("curatorSetGlobalMute", { allMuted });
    }

    curatorSetSlide(slideId) {
        this.socket.emit("curatorSetSlide", { slideId });
    }

    setBodyVisible(visible) {
        this.userBodyVisible = !!visible;
        if (this.userBodyObject?.renderer?.meshCollider?.parent?.visible === undefined) {
            console.warn("Socket::setBodyVisible: missing body renderer, body obj:", this.userBodyObject);
            return;
        }
        this.userBodyObject.renderer.meshCollider.parent.visible = this.userBodyVisible;
    }

    async loadAnimations() {
        return new Promise((resolve) => {
            const manager = new LoadingManager();
            const loader = new FBXLoader(manager);
            let loadedCount = 0;
            const incAndCheckLoaded = () => {
                loadedCount++;
                if (loadedCount === 6) {
                    resolve();
                }
            }

            loader.load(danceAnimation, (ani) => {
                this.loadedAnimations.dance = ani.animations[0]
                incAndCheckLoaded();
            });
            loader.load(idleAnimation, (ani) => {
                this.loadedAnimations.idle = ani.animations[0]
                incAndCheckLoaded();
            });
            loader.load(walkAnimation, (ani) => {
                this.loadedAnimations.walk = ani.animations[0]
                incAndCheckLoaded();
            });
            loader.load(runAnimation, (ani) => {
                this.loadedAnimations.run = ani.animations[0]
                incAndCheckLoaded();
            });
            loader.load(jumpAnimation, (ani) => {
                this.loadedAnimations.jump = ani.animations[0]
                incAndCheckLoaded();
            });
            loader.load(fallAnimation, (ani) => {
                this.loadedAnimations.fall = ani.animations[0]
                incAndCheckLoaded();
            });
        });
    }

    loadUserModel() {
        const avatar3D = Moralis.User.current()?.get("avatar3D");
        const userObject = new ObjectItem({ project: this.app.project });
        userObject.data.name.value = "user_body";
        userObject.data.draggable.value = false;
        userObject.data.path.value = getOptimizedModel(avatar3D || MODEL_URL_DEFAULT);
        this.userBodyObject = userObject;
        this.userBodyVisible = false;


        this.app.events.on('objectItemLoaded', (payload) => {
            if (payload?.item?.data?.name?.value !== "user_body") {
                return;
            }
            this.setBodyVisible(this.userBodyVisible);
            this.app.engineComponent.sceneComponent.controls.setTarget(this.userBodyObject);

            const userModel = userObject.renderer.group.children[1].children[0];
            userModel.traverse(obj => obj.frustumCulled = false);
            // console.log(userModel)
            const userAnimationMixer = new AnimationMixer(userModel);
            this.app.engineComponent.sceneComponent.controls.addAnimationMixer(userAnimationMixer);

            this.userBodyAnimationController.idleAnimationAction = userAnimationMixer.clipAction(this.loadedAnimations.idle);
            this.userBodyAnimationController.idleAnimationAction.play();
            this.userBodyAnimationController.danceAnimationAction = userAnimationMixer.clipAction(this.loadedAnimations.dance);
            this.userBodyAnimationController.walkAnimationAction = userAnimationMixer.clipAction(this.loadedAnimations.walk);
            this.userBodyAnimationController.runAnimationAction = userAnimationMixer.clipAction(this.loadedAnimations.run);
            this.userBodyAnimationController.jumpAnimationAction = userAnimationMixer.clipAction(this.loadedAnimations.jump);
            this.userBodyAnimationController.fallAnimationAction = userAnimationMixer.clipAction(this.loadedAnimations.fall);

            this.app.engineComponent.sceneComponent.controls.createStateMachine()
        })
    }

    /**
     * External move function
     */
    clientMove(payload) {
        const { clientX, clientY, clientZ, quaternion, state } = payload;
        this.moveThrottled(clientX, clientY, clientZ, quaternion, state);
    }

    /// Callbacks (called from backend)

    callback_otherPlayerMove(payload) {
        const { uuid, x, y, z, quaternion, state } = payload;
        const userId = this.roomData.findIndex(i => i?.uuid === uuid);
        if (userId === -1) return;
        if (state !== undefined) {
            this.roomData[userId].state = state;
        }
        this.roomData[userId].desiredPosition = { x, y, z, quaternion };
        if (this.roomData[userId].stream && this.broadcastingSound) {
            // recalculate volume
            const [left, right] = this._calcVolumes(this.myPos, this.roomData[userId].userObject.data.position.value);
            this.roomData[userId].stream.setVolume(left, right);
        }
    }

    callback_userDisconnected(payload) {
        const { uuid } = payload;
        console.log(`${uuid} disconnected`);
        const disconnectedUserIndex = this.roomData.findIndex(i => i?.uuid === uuid);
        const projectUserIndex = this.app.project.objects.findIndex(i => i?.data?.uuid === uuid);
        // delete 3d model from scene
        if (this.app.project.objects[projectUserIndex]?.renderer) {
            this.app.project.objects[projectUserIndex].renderer.destroy();
        }
        if (projectUserIndex !== -1) {
            this.app.project.objects.splice(projectUserIndex, 1);
        }
        this.roomData.splice(disconnectedUserIndex, 1);
        this.events.trigger("userDisconnected");
    }

    /**
     * When new user is connected to a server
     */
    callback_userConnected(payload) {
        if (!payload) return;
        const { uuid, pos, avatar3D, username } = payload;
        const { x, y, z, quaternion } = pos;
        if (uuid === this.uuid) return;
        console.log('User', payload, `connected @x=${x}, z=${z}, avatar=${avatar3D}`);
        const userObject = new ObjectItem({ project: this.app.project });
        userObject.data.path.value = getOptimizedModel(avatar3D || MODEL_URL_DEFAULT);
        userObject.data.name.value = `player_${uuid}`;
        userObject.data.draggable.value = false;
        userObject.data.type = "user";
        userObject.data.uuid = uuid;
        let userIndex = this.roomData.findIndex(i => i?.uuid === uuid);
        if (userIndex === -1) {
            // new user joined
            this.roomData.push({ uuid, pos });
            userIndex = this.roomData.length - 1;
        }
        this.roomData[userIndex].desiredPosition = pos;
        this.roomData[userIndex].x = x;
        this.roomData[userIndex].y = y;
        this.roomData[userIndex].z = z;
        this.roomData[userIndex].username = username;
        this.roomData[userIndex].userObject = userObject;
        this.roomData[userIndex].currentStateId = 0;
        this.roomData[userIndex].state = 0;
        this.callback_otherPlayerMove({ uuid, x, y, z, quaternion });

        this.events.trigger("userConnected");
        this.app.events.on('objectItemLoaded', (object) => {
            if (object?.item?.data?.uuid !== uuid) {
                // console.warn("Socket.js::callback_userConnected error: uuid is incorrect", object)
                return;
            }

            const userModel = userObject.renderer.group.children[1].children[0];
            const userAnimationMixer = new AnimationMixer(userModel);
            this.roomData[userIndex].animationMixer = userAnimationMixer;
            this.app.engineComponent.sceneComponent.controls.addAnimationMixer(userAnimationMixer);
            userModel.traverse(obj => {
                obj.frustumCulled = false
                obj.castShadow = true
            });
            this.roomData[userIndex].animations = {}

            const idleAction = userAnimationMixer.clipAction(this.loadedAnimations.idle);
            idleAction.play();
            this.roomData[userIndex].animations[AnimationStates.STATE_IDLE] = idleAction;

            const walkAction = userAnimationMixer.clipAction(this.loadedAnimations.walk);
            this.roomData[userIndex].animations[AnimationStates.STATE_WALK] = walkAction;

            const runAction = userAnimationMixer.clipAction(this.loadedAnimations.run);
            this.roomData[userIndex].animations[AnimationStates.STATE_RUN] = runAction;

            const jumpAction = userAnimationMixer.clipAction(this.loadedAnimations.jump);
            this.roomData[userIndex].animations[AnimationStates.STATE_JUMP] = jumpAction;

            const fallAction = userAnimationMixer.clipAction(this.loadedAnimations.fall);
            this.roomData[userIndex].animations[AnimationStates.STATE_FALL] = fallAction;

            const danceAction = userAnimationMixer.clipAction(this.loadedAnimations.dance);
            this.roomData[userIndex].animations[AnimationStates.STATE_DANCE] = danceAction;
        });

        // Setup peer js connection
        console.log('calling', uuid);
        this.startCall(uuid);
    }

    callback_setGlobalMute(payload) {
        const { allMuted } = payload;
        this.globalMute = allMuted;
        this.changeLocalMicInput(allMuted);
    }

    callback_setSlideId(payload) {
        const { slideId } = payload;
        if (slideId === undefined) return;
        this.app.project.objects.forEach(obj => {
            if (obj.data?.presentationMeta?.value) {
                obj.renderer.setPresentationFrame(slideId);
            }
        })
    }

    callback_curatorPublish({ serObj, nfts }) {
        console.log("NEW VER:", serObj, "OLD:", [...this.app.project.objects]);
        const parseData = { log: { errors: [] } }
        let newObjects = [];
        const removeNamesWhitelist = ["player_", "user_body"];
        this.app.project.objects.forEach(oldObj => {
            if (serObj.objects.findIndex(el => el.id === oldObj.id) === -1) {
                if (removeNamesWhitelist.some(name => oldObj.data?.name?.value?.startsWith(name))) {
                    // object is whitelisted, do not delete
                    return;
                }
                // something is deleted
                deleteItem(oldObj)
                this.app.events.trigger("deleteItem", { item: oldObj });
            }
        });
        if (nfts) {
            this.app.managerNFT.init(nfts);
            console.log("Updated manager nfts", this.app.managerNFT)
        }
        serObj.objects.forEach(newObj => {
            const oldObjId = this.app.project.objects.findIndex(el => el.id === newObj.id);
            if (oldObjId === -1) {
                // new object, push it
                newObjects.push(newObj);
            } else {
                // object exists
                const oldObj = this.app.project.objects[oldObjId];
                Object.keys(newObj).forEach(key => {
                    const oldObjValue = oldObj?.data[key]?.value;
                    const newObjValue = newObj[key];
                    if (
                        oldObjValue !== newObjValue &&
                        newObjValue !== undefined &&
                        oldObjValue !== undefined
                    ) {
                        switch (typeof oldObjValue) {
                            case 'null':
                                oldObj.data[key].value = newObjValue;
                                break;

                            case 'object':
                                if (JSON.stringify(oldObjValue) !== JSON.stringify(newObjValue)) {
                                    console.log("Updating JSON data", oldObjValue, newObjValue)
                                    oldObj.data[key].value = { ...newObjValue };
                                }
                                break;

                            default:
                                console.log(`Update ${key} value, old ${oldObj?.data[key]?.value}, new: ${newObj[key]}`)
                                oldObj.data[key].value = newObjValue;
                                break;
                        }
                    }
                })
            }
        })
        serObj.objects = newObjects;
        this.app.project.parse(serObj, parseData);
        this.app.project.dispatchUpdateStates();
    }

    callback_error(payload) {
        console.warn("socket error:", payload?.message);
    }

    onKeyPress(event) {
        if (event.key === "f" && !this.broadcastingSound) {
            this.broadcastingSound = true;
            this.changeLocalMicInput(!this.broadcastingSound)
        }
    }

    onKeyUp(event) {
        if (event.key === "f" && this.broadcastingSound) {
            this.broadcastingSound = false;
            this.changeLocalMicInput(!this.broadcastingSound)
        }
    }

    /**
     * Callback from socket server after connection. Contains user and room data
     * @param {*} payload { user, roomData }
     */
    handshake(payload) {
        this.resolve(payload.user.uuid);
        this.uuid = payload.user.uuid;
        this.myPos = { x: 0, y: 0, z: 0, quaternion: { y: 1, w: 0 } };
        for (let _callback of this.callbackFunctions) {
            this.socket.on(
                _callback.replace('callback_', ''), (payload) => this[_callback](payload)
            );
        }
        this.roomDetails = payload.roomDetails;
        this.roomData = payload.roomData;
        this.roomData.forEach(_user => {
            if (_user?.uuid !== payload.user.uuid) {
                // ignore ourselves, spawn models for other players already in the room
                this.callback_userConnected(_user);
            }
        });

        document.addEventListener("keypress", (event) => this.onKeyPress(event));
        document.addEventListener("keyup", (event) => this.onKeyUp(event));

        // init peer connection
        this.initPeer(payload.roomDetails?.allMuted);
    }

    handshakeEdit(payload) {
        this.resolve(payload.user.uuid);
        console.log("Edit mode handshake success!");
    }

    spaceSaved(serObj, nfts) {
        this.socket.emit(
            "curatorPublish", { serObj, nfts }
        );
    }

    closeConnection() {
        this?.socket?.disconnect();
        this?.peer?.destroy();
    }

    /**
     * Connects to socket server and awaits server resonse
     * @returns { Promise<void> | Error } empty promise
     */
    async connectSocket(editEnabled) {
        return new Promise((resolve, reject) => {
            this.socket = new io(this.host, {
                forceNew: true,
                query: {
                    session: Moralis.User.current()?.get("sessionToken") || "guest",
                    roomId: this.app.project.id,
                    editEnabled
                },
                path: '/api/socket.io',
                transports: ['websocket']
            });
            if (!editEnabled) {
                this.socket.on("handshake", (payload) => this.handshake(payload));
                this.loadUserModel();
            } else {
                this.socket.on("handshakeEdit", (payload) => this.handshakeEdit(payload));
            }
            this.resolve = resolve;
            setTimeout(() => reject("Socket connection timeout"), this.connectionTimeoutMs);
        });
    }

    /// WebRTC functionality

    initPeer(isMuted) {
        this.peer = new Peer(this.uuid);

        this.peer.on('open', id => { console.log('My peer ID is:', id); });
        this.peer.on('disconnected', id => { console.log('lost connection'); });
        this.peer.on('error', err => { console.error(err); });

        // when someone calls us, answer the call
        this.peer.on('call', async call => {
            this.call = call;
            call.answer(await this.getAudioStream(isMuted));
            this.receiveCall(call);
        });
    }

    async getAudioStream(isMuted) {
        const localMediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.localAudioTracks = localMediaStream.getAudioTracks();
        this.changeLocalMicInput(isMuted);
        return localMediaStream;
    }

    changeLocalMicInput(mute = false) {
        if (this.globalMute && !mute) {
            console.warn("Cant unmute now: global mute is on");
            return false;
        }
        if (!this.localAudioTracks) {
            console.warn(`muteLocalInput: no localAudioTraks`);
            return false;
        }
        this.localAudioTracks.forEach(audioTrack => { audioTrack.enabled = !mute });
        return true;
    }

    /**
     * Switches mute state between mute / unmute via uuid 
     * @param {*} uuid player to be muted / unmuted
     * @returns { bool } mute status (true for mute / false for unmute) 
     */
    switchPlayerMute(uuid) {
        const player = this.roomData.find(p => p?.uuid === uuid);
        if (!player?.stream) {
            console.warn("Socket.js::switchPlayerMute: no stream / user");
            return;
        }
        if (player.stream.muted) {
            player.stream.unmute();
        } else {
            player.stream.mute();
        }
        return player.stream.muted;
    }

    receiveCall(call) {
        call.on('stream', stream => {
            stream.noiseSuppression = true;
            const player = this.roomData.find(p => p?.uuid === call.peer);
            if (!player) {
                console.log('couldn\'t find player for stream', call.peer);
            } else {
                player.stream = new StreamSplit(stream, { left: 1, right: 1 });
                this.playAudioStream(stream, call.peer);
                console.log('created stream for', player);
            }
        });
    }

    async startCall(target) {
        if (!this.peer) return;
        const call = this.peer.call(target, await this.getAudioStream());
        this.receiveCall(call);
    }

    playAudioStream(stream, target) {
        // create the video element for the stream
        const elem = document.createElement('video');
        elem.srcObject = stream;
        elem.muted = true;
        elem.setAttribute('data-peer', target);
        elem.onloadedmetadata = () => elem.play();
    }
}