import { InputAdornment, TextField } from "@mui/material"
import { Typography } from "antd"

export const CoordinateInputWide = (props) => {
    return (
        <TextField
            sx={{
                width: '100%',
                boxShadow: 'none',
                borderColor: 'transparent',
                '& .MuiOutlinedInput-root': {
                    padding: '6px 4px',
                    overflow: 'hidden',
                    height: 32,
                    color: '#B4B4B4',
                    borderRadius: 1,
                    background: '#161616',
                    '& .MuiOutlinedInput-input': {
                        padding: 0,
                        fontSize: 12,
                        fontFamily: 'Montserrat',
                        width: '100%',
                        textAlign: 'center',
                        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                            WebkitAppearance: 'none',
                        },
                    },

                    '& fieldset': {
                        '&.MuiOutlinedInput-notchedOutline': {
                            border: 0,
                        },
                    },
                },
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Typography style={{
                            color: '#01F9FF',
                            fontSize: 12,
                            lineHeight: '20px',
                            fontFamily: 'Montserrat'
                        }}>{props?.label}</Typography>
                    </InputAdornment>
                ),
            }}
            onChange={props?.handleChange}
            value={props?.value}
        />
    )
}