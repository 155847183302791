const isProduction = () => {
  return (
    window.location.hostname === 'meta.metaplayerone.app' ||
    window.location.hostname === 'meta.alpha.metaplayerone.app'
  )
}
const isStage = () => {
  return window.location.hostname === 'meta.stage.metaplayerone.app'
}
const isLocal = () => {
  return window.location.hostname === 'meta.localhost'
}

// собираем конфиг для окружения
const common = {
  SKIP_PREFLIGHT_CHECK: true,
  SELF_HOSTED: false,
  REACT_APP_CHAIN_ID: 5,
  REACT_APP_API_URL: 'https://dev.metaplayerone.app/api/v2',
  REACT_APP_PINATA_API_KEY: '187f83e623a73e03a330',
  REACT_APP_PINATA_API_SECRET:
    'c378ea0332200d359b41204e28b4d842b74edf6848eabd7ad6db4630864f460d',
  REACT_APP_MORALIS_SERVER_URL:
    'https://nrnhzxloybjl.usemoralis.com:2053/server',
  REACT_APP_MORALIS_APP_ID: 'HhJtTqzu8ZZmrChihL0g5vRzgp5LyzswXIJ27qrj',
  REACT_APP_SERVER_PROXY_URL: 'https://meta.dev.metaplayerone.app/api/p?q=',
  REACT_APP_MULTIPLAYER_API_URL: 'https://meta.dev.metaplayerone.app/',
  REACT_APP_WEB3_MODAL_PROJECT_ID: '5274860330becb5d59d108f66536ab81',
  REACT_APP_SOIKA_WS_URL: 'wss://soika.metaplayerone.app'
}

const prod = {
  SKIP_PREFLIGHT_CHECK: true,
  SELF_HOSTED: true,
  REACT_APP_CHAIN_ID: 1,
  REACT_APP_API_URL: 'https://alpha.metaplayerone.app/api/v2',
  REACT_APP_PINATA_API_KEY: '187f83e623a73e03a330',
  REACT_APP_PINATA_API_SECRET:
    'c378ea0332200d359b41204e28b4d842b74edf6848eabd7ad6db4630864f460d',
  REACT_APP_MORALIS_SERVER_URL:
    'https://moralis.mp1.site/server',
  REACT_APP_MORALIS_APP_ID: '1704',
  REACT_APP_SERVER_PROXY_URL: 'https://meta.alpha.metaplayerone.app/api/p?q=',
  REACT_APP_MULTIPLAYER_API_URL: 'https://meta.alpha.metaplayerone.app/',
  REACT_APP_METASPACE_CONTROLLER_ADDRESS: '0x0679c5f521Bc9218A6fE5Ac377b987B62e98Db8b'
}

const dev = {
  SKIP_PREFLIGHT_CHECK: true,
  SELF_HOSTED: true,
  REACT_APP_CHAIN_ID: 5,
  REACT_APP_API_URL: 'https://dev.metaplayerone.app/api/v2',
  REACT_APP_PINATA_API_KEY: '187f83e623a73e03a330',
  REACT_APP_PINATA_API_SECRET:
    'c378ea0332200d359b41204e28b4d842b74edf6848eabd7ad6db4630864f460d',
  REACT_APP_MORALIS_SERVER_URL: 'https://dev.moralis.mp1.site/server',
  REACT_APP_MORALIS_APP_ID: '1704',
  REACT_APP_SERVER_PROXY_URL: 'https://meta.dev.metaplayerone.app/api/p?q=',
  REACT_APP_MULTIPLAYER_API_URL: 'https://meta.dev.metaplayerone.app/',
  REACT_APP_METASPACE_CONTROLLER_ADDRESS: '0x131a5a7b341F2f40c076702D9a954AC45DAb41Ea',
  REACT_APP_DAO_AIRDROP_ADDRESS: '0x8c94c68e92640Fb8dAC8958d128EEF9A920f3993',
  REACT_APP_COLLECTION_AIRDROP_ADDRESS: '0x90eE52aDaa332e149b8Eca690F9F7dEA25fD0A87',
}

const stage = {
  SKIP_PREFLIGHT_CHECK: true,
  SELF_HOSTED: true,
  REACT_APP_CHAIN_ID: 5,
  REACT_APP_API_URL: 'https://dev.metaplayerone.app/api/v2',
  REACT_APP_PINATA_API_KEY: '187f83e623a73e03a330',
  REACT_APP_PINATA_API_SECRET:
    'c378ea0332200d359b41204e28b4d842b74edf6848eabd7ad6db4630864f460d',
  REACT_APP_MORALIS_SERVER_URL:
    'https://nrnhzxloybjl.usemoralis.com:2053/server',
  REACT_APP_MORALIS_APP_ID: 'HhJtTqzu8ZZmrChihL0g5vRzgp5LyzswXIJ27qrj',
  REACT_APP_SERVER_PROXY_URL: 'https://meta.dev.metaplayerone.app/api/p?q=',
  REACT_APP_MULTIPLAYER_API_URL: 'https://meta.dev.metaplayerone.app/'
}

let CONFIG = {}

if (isProduction()) {
  CONFIG = { ENV: 'production', ...common, ...prod }
} else if (isStage()) {
  CONFIG = { ENV: 'stage', ...common, ...stage }
} else {
  if (isLocal()) {
    CONFIG = { ENV: 'local', ...common, ...dev }
  } else {
    CONFIG = { ENV: 'dev', ...common, ...dev }
  }
}

// экспортируем свойства
export default CONFIG
