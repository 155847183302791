/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext, useRef, createContext } from 'react'

import { Context } from '../../../store/Context'
import { IconButton } from "@mui/material";
import { ArtIMG, ArtInfo, ArtInfoTitle, ArtModalTitle, ArtSaved, ArtType, ArtVID, ArtWorkItem, ArtworkList } from '../styles';
import { TypeEntity } from '../../../../editor/Core/Constants'
import CloseIcon from '@mui/icons-material/Close';
import { pinFileToIPFS } from '../../../helpers/pinata'
import styled from 'styled-components'
import { Input, Button, Row } from 'antd';
import { CloseModalIcon } from '../../../assets/icons/CloseModalIcon';
import { EditorSnackbarContext } from '../../../contexts/editor-snackbar';
import { FlexibleModalContext } from '../../../contexts/flexible-modal/context'

export const ViewContext = createContext()

export const ControlGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  @media (max-width: 400px) {
    flex-direction: column;
    gap: 5px;
  }
`;

export const UploadContainer = styled.div`
  width: ${(props) => (props.file ? '100%' : '250px')};
  @media (max-width: 600px) {
    width: ${(props) => (props.file ? '100%' : '150px')};
  }
  @media (max-width: 400px) {
    width: 100%;
  }
`;
export const Loader = styled.div`
  width: ${(props) =>
        props.matches ? 'calc(100% + 4rem)' : 'calc(100% + 2rem)'};
  height: ${(props) =>
        props.matches ? 'calc(100% + 4rem)' : 'calc(100% + 2rem)'};
  background-color: white;
  top: ${(props) => (props.matches ? '-2rem' : '-1rem')};
  left: ${(props) => (props.matches ? '-2rem' : '-1rem')};
  position: absolute;
  z-index: 400;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const UploadFile = styled.div`
  border: none;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='36' ry='36' stroke='%23191919FF' stroke-width='1' stroke-dasharray='5%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 36px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media (max-width: 600px) {
    height: ${(props) => (!props.hasImage ? 'auto' : '150px')};
  }
  @media (max-width: 400px) {
    margin: 0;
  }
`;
export const UploadFileText = styled.div`
  font-size: 15px;
  line-height: 20px;
`;
export const UploadFileInput = styled.input`
  cursor: pointer;
  opacity: 0;
  position: absolute;
  z-index: 99;
  height: 42px;
  width: 380px;
`;
export const UploadButton = styled.button`
  color: #fff;
  border: 0;
  background: #000;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 23px;
  margin-top: 10px;
  cursor: pointer;
  @media (max-width: 400px) {
    width: 70%;
  }
`;
const ButtonPublish = styled(Button)`
    border-radius: 50px;
    background: ${({disabled}) => disabled ? 'transparent' : 'linear-gradient(90deg, #4D44FE 0%, #8552F6 100%)'};
    box-shadow: inset 0px 0px 0px 2px ${({disabled}) => disabled ? '#969696' : '#4D44FE'};
    padding: 0;
    border: none;
    text-transform: uppercase;
    width: 48%;
    height: 40px;
    color: #fff;
    cursor: ${props => props?.disabled ? "not-allowed" : "pointer"};
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
`;
const ButtonLoadMore = styled(Button)`
    border-radius: 50px;
    width: 48%;
    height: 40px;
    background: transparent;
    box-shadow: inset 0px 0px 0px 2px #4D44FE;
    padding: 0;
    border: none;
    text-transform: uppercase;
    color: #fff;
    cursor: pointer;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
`;
const Wrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
const WrapCentered = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: #7E8390;
`;
const ArtWorkItemCentered = styled(ArtWorkItem)`
  align-items: center;
  cursor: auto;
`;
export const Close = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  background: #1C1C1C;
  cursor: pointer;
`;

const UploadProgressBar = styled.div`
  background: white;
  height: 2px;
  width: ${props => props?.progress || 0}%;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const TypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
`

const FileItem = ({ slide, index, slides, setSlides }) => {
    const { editorStore } = useContext(Context);
    const [progress, setProgress] = useState(0);

    const removeSlide = (slide) => {
        const id = slides.indexOf(slide);
        setSlides([...slides.slice(0, id), ...slides.slice(id + 1, slides.length)]);
    }

    const imageItemClick = (event, slideObject) => {
        const item = editorStore.editor.selector.items.length === 1 ? editorStore.editor.selector.items[0] : null

        if (item && item.type === TypeEntity.Picture) {
            item.renderer.applyImage(slideObject.media, slideObject.type === "video", true)
        }
    }

    useEffect(() => {
        const onUploadProgress = (event) => {
            console.log("Upload progress", event)
            const { id, progress } = event.detail;
            if (id === index) setProgress(progress);
        }

        document.addEventListener("videoUploadProgress", onUploadProgress);

        return () => {
            document.removeEventListener("videoUploadProgress", onUploadProgress);
            console.log("remove event listener w id", index)
        }
    }, []);

    return <Container>
        <ArtWorkItemCentered key={index}>
            {slide.type === "image" &&
                <ArtIMG
                    src={slide.media}
                    srcSet={slide.media}
                    alt="presentImg"
                    onClick={e => imageItemClick(e, slide)}
                ></ArtIMG>}
            {slide.type === "video" &&
                <ArtVID
                    src={slide.media}
                    srcSet={slide.media}
                    alt="presentVid"
                    preload="auto"
                    muted={true}
                    onClick={e => imageItemClick(e, slide)}
                ></ArtVID>}
            <ArtInfo>
                <ArtInfoTitle>{'File #' + (index + 1)}</ArtInfoTitle>
                <TypeContainer>
                    <ArtType>{slide.type}</ArtType>
                    {slide?.saved ? <ArtSaved>saved</ArtSaved> : null}
                </TypeContainer>
            </ArtInfo>
            <Close onClick={() => removeSlide(slide)}>
                <CloseModalIcon />
            </Close>
        </ArtWorkItemCentered>
        <UploadProgressBar progress={progress} />
    </Container>
}

export default function TabPresentation() {
    const [slides, setSlides] = useState([])
    const [curSaving, setCurSaving] = useState(0)
    const [doneSaving, setDoneSaving] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const { editorStore, userStore } = useContext(Context)
    const { triggerSnackbar } = useContext(EditorSnackbarContext);
    const { setFlexibleModalChildren } = useContext(FlexibleModalContext);

    const [img, setImg] = useState()
    const [file, setFile] = useState()
    const [name, setName] = useState()
    const [isValid, setValid] = useState(false)
    const [choose, setChoose] = useState(false)

    const disabledStyle = {
        padding: 0,
        border: 'none',
        background: 'none'
    }

    useEffect(() => {
        setDoneSaving(false)
    }, [slides])

    useEffect(() => {
        const slides = editorStore.editor.selector.items[0]?.data?.presentationMeta?.value?.slides;
        console.log(slides)
        if (slides && typeof slides === 'object' && Object.keys(slides)?.length > 0) setSlides(
            Object.keys(slides).map(id => {
                return {
                    type: slides[id]?.isVideo ? "video" : "image",
                    media: slides[id]?.url,
                    saved: true
                }
            })
        );
    }, []);

    // Execute when user select a file
    const onFileSelected = async (e) => {
        const fileList = e.target.files;
        let _slides = [...slides];
        if (fileList?.length > 0) {
            for (let i = 0; i < fileList.length; i++) {
                _slides.push(createSlide(fileList[i]));
            }
        }
        setSlides(_slides);
    };

    const createSlide = (file) => {
        const slideObject = {};
        let type;
        if (file?.type?.startsWith("video")) type = "video";
        if (file?.type?.startsWith("image")) type = "image";
        if (type === undefined) {
            console.warn("TabPresentation: unknown filetype");
        }
        slideObject.type = type;
        slideObject.media = URL.createObjectURL(file);
        slideObject.file = file;
        return slideObject;
    }

    const savePresentation = async () => {
        try {
            setIsSaving(true);
            const item = editorStore.editor.selector.items.length === 1 ? editorStore.editor.selector.items[0] : null
            if (!item) throw new Error("No frame selected")
            const slideMeta = { slides: {} }
            const updateFirst = !slides[0]?.saved;
            for (let i = 0; i < slides.length; i++) {
                const slide = slides[i];
                if (slide?.saved) continue;
                setCurSaving(i + 1);
                const uploaded = await pinFileToIPFS(slide.file, i);
                slideMeta["slides"][i] = {
                    url: uploaded.pinataUrl,
                    isVideo: slide.type === "video"
                };
                const _slides = slides;
                _slides[i] = { ...slide, saved: true };
                setSlides(_slides);
            }
            setDoneSaving(true);
            setIsSaving(false);
            if (updateFirst) {
                item.data.presentationMeta.value = slideMeta;
                item.data.imageMeta.value = { isPresentation: true };
                item.data.name.value = "Presentation";
                item.renderer.applyImage(slides[0].media, slides[0].type === "video", true)
                console.log(item)
                triggerSnackbar('Applying media', 'info');
            }
            setFlexibleModalChildren(null);
        } catch (e) {
            console.warn("TabPresentation::savePresentation error:", e);
        }
    }

    return (
        <>
            <ArtModalTitle>
                Add Media Files
            </ArtModalTitle>
            <ArtworkList>
                {slides.length === 0 && <WrapCentered>
                    No files uploaded
                </WrapCentered>}
                {slides.map((slide, index) => {
                    return <FileItem
                        slide={slide}
                        slides={slides}
                        setSlides={setSlides}
                        index={index}
                    />
                })}
            </ArtworkList >
            <Wrap>
                <UploadFileInput
                    accept={'image/*, video/*'}
                    type="file"
                    onChange={onFileSelected}
                    id="file-selector"
                    multiple
                />
                <ButtonLoadMore htmlType='submit'>
                    {!file ? 'Upload' : 'Replace'}
                </ButtonLoadMore>
                <ButtonPublish
                    onClick={savePresentation}
                    disabled={slides.length === 0 || isSaving}
                >
                    {isSaving ? "Publishing..." : "Publish"}
                </ButtonPublish>
            </Wrap>
        </>
    )
}
