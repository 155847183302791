import { useEditor } from "../../../../../store/editor-context"
import { ArtModalTitle, ArtworkList, CenteredText } from "../../../styles";
import { ArtworkItem } from "../../ArtworkItem";

export const Tab3DNFTs = () => {
    const { space } = useEditor();
    const filtered_tokens = space?.all_tokens && space?.all_tokens.filter(token => ["3D", "object"].includes(token?.item?.metadata?.type));

    return <>
        <ArtModalTitle>
            Select NFT
        </ArtModalTitle>
        <ArtworkList>
            {filtered_tokens?.length > 0 ?
                filtered_tokens.map((token, index) => {
                    return <ArtworkItem token={token} key={index} />
                }) :
                <CenteredText>
                    No 3D NFTs found
                </CenteredText>
            }
        </ArtworkList >
    </>
}