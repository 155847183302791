import EngineBase from '../../EngineBase'
import SceneThree from './Scene'
import HovererThree from './Hoverer'
import * as THREE from 'three'
import { getUuid } from '../../../Core/SerializableObject'

import { WebGLRenderer } from 'three'
import { acceleratedRaycast } from 'three-mesh-bvh'

import WebGPURenderer from 'three/examples/jsm/renderers/webgpu/WebGPURenderer';
import WebGPU from 'three/examples/jsm/capabilities/WebGPU';
import WebGL from 'three/examples/jsm/capabilities/WebGL';

export default class EngineThree extends EngineBase {
  constructor({ app, canvas, container } = {}) {
    super(...arguments)
    this.app = app
    this.canvas = canvas
    this.container = container
    this.sceneComponent = new SceneThree(this)
    this.hoverer = new HovererThree(this)

    THREE.Mesh.prototype.raycast = acceleratedRaycast;
    THREE.SkinnedMesh.prototype.raycast = acceleratedRaycast;

    this.shadowsEnabled = false

    this._requestProjectUpdate = false
    this.loadingItems = new Set()

    console.log(`WebGPU: ${WebGPU.isAvailable()} WebGL: ${WebGL.isWebGL2Available()}`);
  }

  startBlockingOperation() {
    const guid = getUuid()
    this.loadingItems.add(guid)
    if (this.loadingItems.size === 1) this.app.events.trigger('loadingItemsStarted')
    return guid
  }

  finishBlockingOperation(guid) {
    if (this.loadingItems.has(guid)) {
      this.loadingItems.delete(guid)
      if (this.loadingItems.size === 0) this.app.events.trigger('loadingItemsFinished')
    } else console.warn('no guid in loadingItems=', guid, this.loadingItems)
  }

  async init() {
    if (this.canvas) {
      this.engine = new WebGLRenderer({ preserveDrawingBuffer: true, canvas: this.canvas, antialias: true })
    } else {
      this.engine = new WebGLRenderer({ antialias: true })
      this.container.appendChild(this.engine.domElement)
    }
    // await this.engine.init()
    this.engine.setPixelRatio(window.devicePixelRatio)
    this.engine.gammaFactor = 2.2;
    this.engine.outputColorSpace = THREE.SRGBColorSpace // fix color encoding
    super.init()

    this.resize()
  }

  setShadowMap(enabled, shadowType) {
    this.shadowsEnabled = enabled
    this.engine.shadowMap.type = shadowType
    this.engine.shadowMap.enabled = this.shadowsEnabled
  }

  destroy() {
    super.destroy()

    if (this.engine) {
      this.engine.dispose()
      this.engine = null
    }
  }

  resize() {
    this.sceneComponent.resize()
    this.engine.setSize(this.container.clientWidth, this.container.clientHeight)
  }

  start() {
    this.isStarted = true
    this.renderFunction()
  }

  stop() {
    this.isStarted = false
  }

  renderFunction(timestamp) {
    if (!this.isStarted) return
    requestAnimationFrame(this.renderFunction.bind(this))
    this.render(timestamp)
    // requestAnimationFrame(this.renderFunction.bind(this))
  }

  render(timestamp) {
    if (this.stats) {
      this.stats.update()
      this.stats.drawPanel.update(this.engine.info.render.calls, 100000)
    }

    if (this._requestProjectUpdate) {
      // console.log('requestProjectUpdate')
      this._requestProjectUpdate = false
      this.app.project.dispatchUpdateStates()
    }

    if (this.sceneComponent) this.sceneComponent.render(timestamp)
  }
}
