export default class EngineBase {
  constructor (/*{canvas, container}*/) {
    this.renderers = []
  }

  init () {
    this.onResize = () => {this.resize()}
    window.addEventListener('resize', this.onResize)

    const container = this.getRenderingElement() // config.component2D.container2D//config.component2D.engine.container//config.component2D.engine.pixi.view.parentNode
    container.onmouseover = (/*event*/) => {
      // console.log('+engine focused')
      this.focused = true
    }
    container.onmouseout = (/*event*/) => {
      // console.log('-engine unfocused')
      this.focused = false
    }

    this.sceneComponent.init()
  }

  destroy () {
    if (this.stats) {
      this.stats = null
    }

    if (this.sceneComponent) {
      this.sceneComponent.destroy()
      this.sceneComponent = null
    }

    window.removeEventListener('resize', this.onResize)
    this.onResize = null
  }

  getRenderingElement () {
    return this.container
  }

  resize () {}

  start () {}

  stop () {}

  addRenderer ({renderer, type} = {}) {
    this.renderers.push({renderer, type})
  }

  removeRenderer (renderer) {
    const index = this.getRendererIndexByParam('renderer', renderer)
    if (index !== -1) this.renderers.splice(index, 1)
  }

  getRendererIndexByParam (param, value) {
    for (let i = 0; i < this.renderers.length; i++) {
      if (this.renderers[i][param] === value) return i
    }
    return -1
  }

  getConstructorByType (type) {
    const index = this.getRendererIndexByParam('type', type)
    if (index !== -1) {
      return this.renderers[index].renderer
    }
    console.warn(this.constructor.name + '.getConstructorByType: not found renderer by type=', type)
    return null
  }
}
