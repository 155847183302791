import { Context } from "../../../ui/store/Context";
import { useState, useContext } from "react"
import {
    Group,
    RecordVoiceOver,
    Mic,
    MicOff,
    VoiceOverOff,
    CoPresent,
    ArrowBack,
    ArrowForward,
    Cameraswitch,
    Publish
} from '@mui/icons-material';
import styled from "styled-components";
import isMobileAndTablet from "../../../../helpers/isMobile";
import { SubmitTokenModal } from "../Modals";

const RoundButton = styled.div`
    backdrop-filter: blur(34px);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    color: #fff;
    
    cursor: pointer;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-right: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
`

const MultiplayerControlsContainer = styled.div`
    position: absolute;
    display: flex;
    
    left: ${(props) => !props.isMobile && "18px"};

    ${(props) => props.isMobile ? "top: 20px;" : "bottom: 20px;"}
    
    flex-direction: row;
    ${(props) => props.isMobile && ` 
        left: 50%;
        transform: translate(-50%, 0%);
    `}
`

const PresentationPanelContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 7rem;

    border-radius: 10%;
    backdrop-filter: blur(34px);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    color: #fff;
`


function PresentationPanel({ currentFrame, switchPresentationFrame, setModal }) {
    return <PresentationPanelContainer>
        <ArrowBack
            style={{ position: "relative", right: "1rem", cursor: "pointer" }}
            onClick={() => switchPresentationFrame(false)}
            onTouchStart={() => switchPresentationFrame(false)}
        />
        {currentFrame}
        <ArrowForward
            style={{ position: "relative", left: "1rem", cursor: "pointer" }}
            onClick={() => switchPresentationFrame(true)}
            onTouchStart={() => switchPresentationFrame(true)}
        />
    </PresentationPanelContainer>
}

export default function MultiplayerControlsPanel({ isCurator, setShowMPSettings, showMPSettings, setModal, allowSubmit }) {
    const { editorStore } = useContext(Context);
    const [muted, setMuted] = useState(false);
    const [curatorAllMuted, setCuratorAllMuted] = useState(false);
    const [showPresentationPanel, setShowPresentationPanel] = useState(false);
    const [currentFrame, setCurrentFrame] = useState(1);
    const isMobile = isMobileAndTablet();
    const socket = editorStore.editor.socket;

    const switchMuteAll = () => {
        socket.curatorSetGlobalMute(!curatorAllMuted);
        setCuratorAllMuted(!curatorAllMuted);
    }

    const switchMuteSelf = () => {
        socket.changeLocalMicInput(!muted);
        setMuted(!muted);
    }

    const onShowAttendees = () => {
        setShowMPSettings(!showMPSettings);
    }

    const onSubmitTokens = () => {
        setModal(<SubmitTokenModal />)
    }

    const switchPresentationFrame = (next) => {
        editorStore.editor.engineComponent.app.project.objects.forEach(obj => {
            if (obj.data?.presentationMeta?.value) {
                const nextSlideId = obj.renderer.applyPresentationFrame(next);
                socket.curatorSetSlide(nextSlideId);
                setCurrentFrame(nextSlideId + 1);
            }
        })
    }

    const onCameraSwitch = () => {
        editorStore.editor.engineComponent.app.engineComponent.sceneComponent.controls.switchViewMode();
    }

    return socket ? <MultiplayerControlsContainer isMobile={isMobile}>
        <RoundButton
            onClick={switchMuteSelf}
            onTouchStart={switchMuteSelf}
            title={`${muted ? "Unmute" : "Mute"} microphone`}
        >
            {muted ? <MicOff /> : <Mic />}
        </RoundButton>
        <RoundButton
            title="Metaspace attendees"
            onClick={onShowAttendees}
            onTouchStart={onShowAttendees}
        >
            <Group />
        </RoundButton>
        <RoundButton title="Switch camera" onClick={onCameraSwitch} onTouchStart={onCameraSwitch}>
            <Cameraswitch />
        </RoundButton>
        {(!isCurator && allowSubmit) && <RoundButton
            onClick={onSubmitTokens}
            onTouchStart={onSubmitTokens}
            title="Submit tokens"
        >
            <Publish />
        </RoundButton>}
        {isCurator && <RoundButton
            onClick={switchMuteAll}
            onTouchStart={switchMuteAll}
            title={`${muted ? "Unmute" : "Mute"} everyone`}
        >
            {curatorAllMuted ? <VoiceOverOff /> : <RecordVoiceOver />}
        </RoundButton>}
        {isCurator && <RoundButton
            onClick={() => setShowPresentationPanel(!showPresentationPanel)}
            onTouchStart={switchMuteAll}
        >
            <CoPresent />
        </RoundButton>}
        {showPresentationPanel && <PresentationPanel
            currentFrame={currentFrame}
            switchPresentationFrame={switchPresentationFrame}
            title={'Presentation controls'}
        />}
    </MultiplayerControlsContainer> : <></>;
}