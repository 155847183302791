import {
  Vector2,
  Vector3,
  Plane,
  Raycaster,
  SphereGeometry,
  MeshStandardMaterial,
  Mesh,
  MeshBasicMaterial,
} from 'three';
import { TypeEntity } from '../../../Core/Constants';

/*function intersectPlaneByRay(ray, plane) {
  const t = -(ray.origin.clone().dot(plane.normal) + plane.constant) / ray.direction.clone().dot(plane.normal)
  const pt = ray.origin.clone().add(ray.direction.clone().multiplyScalar(t))
  return pt
}*/

export default class HovererThree {
  constructor(engineComponent) {
    this.engineComponent = engineComponent;

    this.mouseFloat = new Vector2();
    this.mouseScreen = new Vector2();
    this.mouseRayCaster = new Raycaster();

    this.pickInfo = null;

    this.planePt = new Vector3(0, 0, 0);
    this.plane = new Plane();
    this.plane.set(new Vector3(0, 1, 0), 0);

    this.enableSphereHelper = true;
    this.editorClickPoint = null;
  }

  updateMouseRay(event) {
    // console.log('Hoverer.updateMouseRay=', event)
    const element = event.target; /*toElement*/
    if (!element) {
      console.warn('no event element=', event);
      return;
    }

    const bbox = element.getBoundingClientRect();
    const x = event.clientX - bbox.left;
    const y = event.clientY - bbox.top;
    // console.log('x=', x, 'y=', y)

    this.mouseScreen.x = x;
    this.mouseScreen.y = y;

    const elem = this.engineComponent.engine.domElement;
    this.mouseFloat.x = (x / elem.clientWidth) * 2 - 1;
    this.mouseFloat.y = -(y / elem.clientHeight) * 2 + 1;

    this.mouseRayCaster.setFromCamera(
      this.mouseFloat,
      this.engineComponent.sceneComponent.camera
    );

    // this.planePt = intersectPlaneByRay(this.mouseRayCaster.ray, this.plane)
    // const t =
    //   -(
    //     this.mouseRayCaster.ray.origin.clone().dot(this.plane.normal) +
    //     this.plane.constant
    //   ) / this.mouseRayCaster.ray.direction.clone().dot(this.plane.normal);
    // this.planePt.copy(
    //   this.mouseRayCaster.ray.origin
    //     .clone()
    //     .add(this.mouseRayCaster.ray.direction.clone().multiplyScalar(t))
    // );

    /* if (!this.mesh) {
      const geometry = new SphereGeometry(1, 16, 16)
      const material = new MeshStandardMaterial({color: 0xff0000})
      this.mesh = new Mesh(geometry, material)
      this.engineComponent.sceneComponent.scene.add(this.mesh)
    }
    this.mesh.position.copy(this.mouseRayCaster.ray.origin.clone().add(this.mouseRayCaster.ray.direction.clone().multiplyScalar(100))) */
  }
  hoverImageByPick() {
    // view mode only
    if (this.engineComponent.sceneComponent.controlsMode !== 2) return;
    const sceneImages = this.engineComponent.sceneComponent.scene.children.filter(
      (item) => {
        return ["Picture", "NFT3D"].includes(item?._itemMP?.constructor?.__constructorName)
      }
    )
    const intersects = this.mouseRayCaster.intersectObjects(sceneImages, true)
    for (let i = 0; i < intersects.length; i++) {
      if (intersects[i]?.object?.visible) return intersects[i]
    }
    return intersects[0]
  }

  playVideoElement(item) {
    if (item?.object?.videoState === "play" && item?.object) {
      if (!item.object.videoHtml) return;
      item.object.videoState = "pause"
      try {
        if (item.object?._itemMP?.data?.imageMeta?.value?.isLivestream) {
          // seek live time for livestream
          if (item.object.videoHtml.currentTime && item.object.videoHtml.duration) {
            item.object.videoHtml.currentTime = item.object.videoHtml.duration - 1;
          }
        }
        item.object.videoHtml.muted = false;
        item.object.videoHtml.play()
          .then(() => { })
          .catch(error => { console.log("Hoverer::video::play error:", error) });
      } catch (e) {
        console.warn("Hoverer::video error:", e)
      }
    } else {
      item.object.videoState = "play";
      item.object.videoHtml.pause()
    }
  }

  pick(event, project, hoverer, isImage) {
    this.updateMouseRay(event)
    if (isImage) {
      this.pickInfo = this.hoverImageByPick()
      // this.playVideoElement(this.pickInfo)
    } else {
      this.pickInfo = this.hoverByPick(project, hoverer)
    }
    // console.log('pickInfo=', this.pickInfo)
    const item = this.pickInfo && this.pickInfo.object ? this.pickInfo.object._itemMP : null
    return item
  }

  /**
   * @returns three object
   */
  findIntersectionFromCenter() {
    const mouseFloat = { x: 0, y: 0 }
    this.mouseRayCaster.setFromCamera(mouseFloat, this.engineComponent.sceneComponent.camera)
    const intersects = this.mouseRayCaster.intersectObjects(this.engineComponent.sceneComponent.scene.children, true)
    return intersects[0];
  }

  resetEditorClickPoint() {
    this.editorClickPoint = null;
    if (this.sphereHelper) {
      this.sphereHelper.visible = false;
    }
  }

  findEditorClickPoint(event) {
    this.updateMouseRay(event)
    const intersects = this.mouseRayCaster.intersectObjects(this.engineComponent.sceneComponent.scene.children, true)
    this.editorClickPoint = intersects[0];
    if (this.enableSphereHelper && !this.sphereHelper) {
      const geometry = new SphereGeometry(0.05);
      const material = new MeshBasicMaterial({ color: 0xffff00 });
      const sphereHelper = new Mesh(geometry, material);
      this.engineComponent.sceneComponent.scene.add(sphereHelper);
      this.sphereHelper = sphereHelper;
    }
    if (this.sphereHelper) {
      this.sphereHelper.visible = true;
      this.sphereHelper.position.copy(intersects[0].point);
    }
  }

  hoverByPick(project, hoverer) {
    const intersects = this.mouseRayCaster.intersectObjects(this.engineComponent.sceneComponent.scene.children, true)
    // intersects.sort((a, b) => a.distance - b.distance)
    // intersects.sort((a, b) => { return a.distance - b.distance }) // already in raycaster
    const il = intersects.length
    if (intersects && il > 0) {
      for (let i = 0; i < il; i++) {
        const intersect = intersects[i]
        if (intersect.object._isPickable === false) continue
        if (!(intersect.object && intersect.object._itemMP)) continue // only entities
        if (hoverer.filter.items.includes(intersect.object._itemMP) || !intersect.object._itemMP.hoverable)
          continue
        return intersect
      }
    }
    return null
  }
}
