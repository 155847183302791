import styled from 'styled-components';

export const EngineContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

export const BlueBall = styled.button`
  position: fixed;
  top: 24px;
  left: 24px;
  margin: ${(props) => (props.margin ? props.margin : '0')};
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: radial-gradient(
      47.92% 47.92% at 72.92% 25%,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0) 100%
    ), #0f31e3;
  border: none;
  cursor: pointer;
`;