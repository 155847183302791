import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Logo = styled.div`
  color: #191919;
  cursor: pointer;

  & div {
    text-transform: uppercase;
    font-weight: 400;

    font-size: 18px;
    line-height: 18px;
  }

  @media screen and (max-width: 540px) {
    & div {
      font-size: 14px;
      line-height: 14px;
    }
  }
`;

export const LogoImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const LogoText = ({ logo, noLayout = false }) => {
  const push = useNavigate();

  return (
    <Logo onClick={() => !noLayout && push('/')}>
      <img
        src={'https://dev.metaplayerone.app/static/media/logo.a09879a9.svg'}
        alt=""
      />
    </Logo>
  );
};

export default LogoText;
