import React, { useEffect, useState, useContext } from 'react'
import Switch from '@mui/material/Switch'
import { BlackSwitch } from '../Switches/CustomSwich'
import { Checkbox } from '@mui/material'

export default function PropertyBool(props) {
  const [checked, setChecked] = React.useState(props.property.value)

  useEffect(() => {
    //console.log('PropertyBool props=', props)
    setChecked(props.property.value)
  }, [props.property])

  //useEffect(() => {console.log('PropertyBool props=', props)}, [])

  const handleToggle = event => {
    // console.log('handleToggle=', event)
    props.property.value = !props.property.value
    setChecked(props.property.value)
  }

  return <Checkbox
    onChange={handleToggle}
    checked={checked}
    sx={{
      color: '#838383',
      '&.Mui-checked': {
        color: '#6C43E0',
      },
    }}
  />
}
