import { LoopOnce } from "three";
import { BaseAnimation } from "./BaseAnimation";
import { AnimationStates } from "./const/AnimationStates";

export default class StateJump extends BaseAnimation {
    constructor(jumpAction) {
        super(jumpAction, 'STATE_JUMP');
        jumpAction.setLoop(LoopOnce);
        this.transitions = {
            STATE_IDLE: 0.2,
            STATE_WALK: 0.25,
            STATE_RUN: 0.25,
            STATE_DANCE: 0.2,
            STATE_FALL: 0.1
        };
        this.id = AnimationStates.STATE_JUMP;
    }

    play(newStateAction, transitionTime) {
        this.animationAction.reset().crossFadeFrom(newStateAction, transitionTime).play();
    }
}