import Moralis from '../../const/moralis'

export default class ManagerNFT {
  constructor({ app }) {
    this.app = app
    this.roomArtworks = null
  }

  init(roomArtworks_) {
    this.roomArtworks = roomArtworks_
  }

  destroy() {
    this.roomArtworks = null
  }
}
