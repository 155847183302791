
export class BaseAnimation {
    constructor(animationAction, name) {
        this.animationAction = animationAction;
        this.name = name
    }

    play(newStateAction, transitionTime) {
        newStateAction.reset().crossFadeFrom(this.animationAction, transitionTime).play();
    }
}