const gConstructorsMap = new Map();
// console.warn('gConstructorsMap=', gConstructorsMap)

export function inheritConstructor(ctr, ctrParent, ctrName, metaData) {
  // console.log('inheritConstructor: ctr', ctr, 'ctrParent=', ctrParent, 'ctrName=', ctrName, 'metaData=', metaData)
  if (ctrParent) ctr.__parentConstructor = ctrParent;
  registerConstructor(ctr, ctrName, metaData);
}

function registerConstructor(ctr, ctrName, metaData) {
  if (!gConstructorsMap.has(ctrName))
    gConstructorsMap.set(ctrName, { ctr, metaData });
  else {
    console.warn(
      'registerConstructor: constructor is already added=' + ctrName
    );
    return;
  }

  ctr.__constructorName = ctrName;

  // fill __serializable
  ctr.__serializable = undefined;

  const hierarchy = [];
  function collectHierarchy(obj) {
    if (!obj) return; // recursive approach
    hierarchy.push(obj);
    const parentClass = obj.__parentConstructor;
    collectHierarchy(parentClass);
  }
  collectHierarchy(ctr);
  // console.log('registerConstructor=', ctrName, 'hierarchy=', hierarchy)

  hierarchy.reverse();
  hierarchy.forEach((obj) => {
    const ctrObjData = getConstructorDataByName(obj.__constructorName);
    if (ctrObjData && ctrObjData.metaData) {
      if (ctrObjData.metaData.serializable) {
        if (!ctr.__serializable) {
          // с классами иначе чем с прототипами, тут будут общие члены в ctr, т.е. __serializable будет браться из SerializableObject
          ctr.__serializable = ctrObjData.metaData.serializable.slice();
        } else {
          ctrObjData.metaData.serializable.forEach((sa) => {
            const sameItemI = ctr.__serializable.findIndex(
              (sai) => sai.name === sa.name
            );
            if (sameItemI === -1) ctr.__serializable.push(sa);
            else {
              // console.warn('replace same serializable item=', ctr.__serializable[sameItemI], sa, ctrName, ctr, ctr.__serializable)
              ctr.__serializable[sameItemI] = sa; // override
            }
          });
        }
      }
    }
  });
}

function getConstructorByName(ctrName) {
  return gConstructorsMap.get(ctrName).ctr;
}

function getConstructorDataByName(ctrName) {
  return gConstructorsMap.get(ctrName);
}

function getObjectConstructorName(item) {
  if (item.constructor.__constructorName !== undefined) {
    return item.constructor.__constructorName;
  } else {
    console.log(
      item.constructor.name +
        '.getObjectConstructorName: NO __constructorName=',
      item
    );
    return item.constructor.name;
  }
}

export class SerAttr {
  constructor(
    name,
    {
      isData = true,
      defaultValue = null,
      isLink = false,
      isIdObject = false,
      flags = -1,
      setter,
      setterParsed,
      inserter,
      inserterParsed,
      remover,
    } = {}
  ) {
    this.name = name;

    this.isData = isData;
    this.defaultValue = defaultValue;
    this.isLink = isLink; // just link by id
    this.isIdObject = isIdObject; // object with id - for arrays parsing
    this.flags = flags; // 1=skip warning, 2=skip if===0, 3=only save
    this.setter = setter;
    this.setterParsed = setterParsed; // parsed - generally for json diff
    this.inserter = inserter;
    this.inserterParsed = inserterParsed; // parsed - generally for json diff
    this.remover = remover; // for json diff
  }
}

export function addLog(data, logObj, item) {
  if (data && data.log && data.log.errors) data.log.errors.push(logObj);
  else {
    if (!(item && item.warning === false))
      console.warn('addLog no log data=', logObj);
  }
}

export class LogError {
  constructor({ text, from, extra }) {
    this.text = text;
    this.from = from;
    this.extra = extra !== undefined ? extra : '';
  }
}

export class LogWarning extends LogError {}

function compareDefault(value, valueDefault) {
  if (value instanceof Array) {
    return JSON.stringify(valueDefault) === JSON.stringify(value);
  } else if (value instanceof Object) {
    return JSON.stringify(valueDefault) === JSON.stringify(value);
  } else {
    return valueDefault === value;
  }
}

function parseByPath(data, owner, itemName, item, serItem, flags) {
  // console.log('parseByPath: data=', data, 'owner=', owner, 'itemName=', itemName, 'item=', item, 'serItem=', serItem, 'flags=', flags)
  // const thisItem = item.isData ? this.data[itemName].value : this[itemName]
  const refObj =
    serItem === undefined && flags === 1 ? null : owner?.getById(serItem);
  if (refObj) {
    if (item && item.setter) item.setter(owner, refObj);
    else {
      if (owner[itemName] instanceof Array) owner[itemName].push(refObj);
      else owner[itemName] = refObj;
    }
  } else {
    if (
      item.defaultValue !== null &&
      compareDefault(serItem, item.defaultValue)
    ) {
      // console.log(this.constructor.name + '.parse: use defaults=' + itemName)
      // this[itemName] = defaults[itemName] // it sets by default in constructor
    } else {
      if (flags !== 1)
        addLog(
          data,
          new LogError({ text: 'no ref object', from: owner, extra: itemName })
        );
    }
  }
}

function parseSetItemValue(_this, item, itemName, value) {
  // item.isData ? _this.data[itemName].value : _this[itemName] = value
  if (item.isData) _this.data[itemName].value = value;
  else _this[itemName] = value;
}

export function getUuid() {
  let d = Date.now();
  const uuid = 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.replace(/[xy]/g, () => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return r.toString(16);
  });
  return uuid;
}

export default class SerializableObject {
  constructor({ id } = {}) {
    this.id = id !== undefined ? id : getUuid();
  }

  addProperty(name, { control, type, visible = true, value } = {}) {
    this.data[name] = { control, type, visible };

    if (value instanceof Object && value.get && value.set) {
      this.data[name]._value = value.default;

      Object.defineProperty(this.data[name], 'value', {
        // get: value.get, set: value.set
        get() {
          return value.get(this);
        },
        set(valueNew) {
          value.set(this, valueNew);
        },
      });
    } else this.data[name].value = value;
  }

  removeProperty(name) {
    delete this.data[name];
  }

  serialize(data) {
    const obj = {};
    this.onSerialize(obj, data);
    return obj;
  }

  onSerialize(obj, data) {
    obj['#type'] = getObjectConstructorName(this);
    if (!this.constructor.__serializable) {
      console.error(
        this.constructor.name + '.onSerialize: no __serializable=',
        this
      );
      return;
    }
    // console.log('onSerialize=', this, this.constructor.__serializable)
    this.constructor.__serializable.forEach((item) => {
      const itemName = item.name;
      const isLink = item.isLink;
      const flags = item.flags;
      const thisItem = item.isData ? this.data[itemName].value : this[itemName];
      // console.log('itemName=' + itemName + ', isLink=' + isLink, 'item=', item, 'thisItem=', thisItem)

      if (thisItem instanceof SerializableObject) {
        if (isLink) obj[itemName] = thisItem.id;
        else obj[itemName] = thisItem.serialize(data);
      } else if (thisItem instanceof Array) {
        if (thisItem.length > 0) {
          if (
            item.defaultValue !== null &&
            compareDefault(thisItem, item.defaultValue)
          ) {
            // console.log(this.constructor.name + '.onSerialize: skip defaults=' + itemName)
          } else {
            obj[itemName] = [];
            thisItem.forEach((itemChild, iChild) => {
              const thisItemChild = itemChild; // this[itemName][iChild]
              if (thisItemChild instanceof SerializableObject) {
                if (isLink) obj[itemName].push(thisItemChild.id);
                else obj[itemName].push(thisItemChild.serialize(data));
              } else {
                if (thisItem === null || thisItem === undefined)
                  addLog(
                    data,
                    new LogError({ text: itemName, from: this, extra: iChild })
                  );
                else obj[itemName].push(thisItemChild);
              }
            });
          }
        }
      } else {
        if (thisItem === null || thisItem === undefined) {
          if (flags !== 1)
            addLog(
              data,
              new LogError({
                text: itemName,
                from: this,
                extra: 'no ' + itemName,
              })
            );
        } else {
          if (isLink) {
            if (flags === 2) {
              if (thisItem !== 0) obj[itemName] = thisItem;
            }
          } else if (thisItem.serialize)
            obj[itemName] = thisItem.serialize(data);
          else {
            if (
              item.defaultValue !== null &&
              compareDefault(thisItem, item.defaultValue)
            ) {
              // console.log(this.constructor.name + '.onSerialize: skip defaults=' + itemName)
            } else obj[itemName] = thisItem;
          }
        }
      }
    });
  }

  parse(obj, data) {
    // console.log(this.constructor.name + '.parse=', obj, data)
    if (!this.constructor.__serializable) {
      console.error(this.constructor.name + '.parse: no __serializable=', this);
      return;
    }
    this.constructor.__serializable.forEach((item) => {
      const itemName = item.name;
      const isLink = item.isLink;
      const flags = item.flags;
      const thisItem = item.isData ? this.data[itemName].value : this[itemName];
      // console.log('itemName=' + itemName + ', isLink=' + isLink + ', flags=', flags, 'item=', item, 'thisItem=', thisItem)

      const serItem = obj[itemName];
      if (serItem instanceof Array || thisItem instanceof Array) {
        if (serItem) {
          const constructedObjects = [];
          serItem.forEach((serItemChild) => {
            const isObj = serItemChild instanceof Object;
            if (isObj && !isLink) {
              const objConstructorName = serItemChild['#type']; // old format support
              if (objConstructorName !== undefined) {
                const ObjConstructor = getConstructorByName(objConstructorName);
                if (ObjConstructor !== undefined) {
                  const newObj = new ObjConstructor({
                    owner: this,
                    parsingFlag: true,
                    id: serItemChild.id,
                  }); // parsingFlag - some items can create other items in constructor - no need on parsing, only owner must be used in constructor
                  if (newObj) {
                    constructedObjects.push({
                      obj: newObj,
                      serObj: serItemChild,
                    });

                    // only id parsing, to find each other components in overrided parse
                    // newObj.id = serItemChild.id
                    // if (item.setter) item.setter(this, newObj) // add if need addChild call for arrays
                  }
                } else {
                  addLog(
                    data,
                    new LogError({
                      text: 'unknown object=' + objConstructorName,
                      from: this,
                    })
                  );
                }
              } else {
                // console.warn('add js objects from json=' + itemName, 'serItem=', serItem, this)
                thisItem.push(serItemChild);
              }
            } else if (!isObj && isLink)
              parseByPath(data, this, itemName, item, serItemChild, flags);
          });

          constructedObjects.forEach((constructedObject) =>
            constructedObject.obj.parse(constructedObject.serObj, data)
          );
        }

        // array values
        if (!item.isIdObject && !isLink && !item.setter) {
          // !arrayPath
          if (serItem === null || serItem === undefined) {
            // no current array
            if (item.defaultValue !== null) {
              // console.log(this.constructor.name + '.parse: use defaults=' + itemName)
              // setSerItem(this, item, itemName, defaults[itemName]) // it sets by default in constructor
            } else {
              if (flags !== 1 && flags !== 2)
                addLog(data, new LogError({ text: itemName, from: this }));
            }
          } else {
            if (flags !== 3) {
              // console.log(this.constructor.name + '.parse: set array from json=' + itemName, serItem, this)
              parseSetItemValue(this, item, itemName, serItem);
            }
          }
        }
      } else if (isLink) {
        parseByPath(data, this, itemName, item, serItem, flags);
      } else {
        if (serItem instanceof Object && !isLink) {
          const objConstructorName = serItem['#type']; // old format support
          if (objConstructorName !== undefined) {
            const ObjConstructor = getConstructorByName(objConstructorName);
            if (ObjConstructor !== undefined) {
              const newObj = new ObjConstructor({
                owner: this,
                parsingFlag: true,
              }); // parsingFlag - some items can create other items in constructor - no need on parsing, only owner must be used in constructor
              if (newObj) {
                parseSetItemValue(this, item, itemName, newObj);
                newObj.parse(serItem, data);
              }
            } else
              addLog(
                data,
                new LogError({
                  text: 'unknown object=' + objConstructorName,
                  from: this,
                })
              );
          } else {
            // console.warn('set js objects from json=' + itemName, 'serItem=', serItem, this)
            if (item && item.setter) item.setter(this, serItem);
            else parseSetItemValue(this, item, itemName, serItem);
          }
        } else if (serItem === null || serItem === undefined) {
          if (item.defaultValue !== null) {
            // console.log(this.constructor.name + '.parse: use defaults=' + itemName)
            // setSerItem(this, item, itemName, defaults[itemName]) // it sets by default in constructor
          } else {
            if (flags !== 1 && flags !== 2)
              addLog(data, new LogError({ text: itemName, from: this }));
          }
        } else {
          if (flags !== 3) parseSetItemValue(this, item, itemName, serItem);
        }
      }
    });
  }

  getById(id) {
    console.warn(this.constructor.name + '.getById - not implemented=', id);

    return super.getById.apply(this, arguments);
  }
}
inheritConstructor(SerializableObject, null, 'SerializableObject', {
  serializable: [new SerAttr('id', { isData: false })],
});
