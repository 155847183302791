import React from 'react';
import {
  Wrapper,
  Image,
  NftInfo,
  NftName,
  TextWrapper,
  NftPrice,
  TextContentWrapper,
} from './style';
import { CONTRACT_TYPES } from '../../../../../const/contract-types';

const NfcCard = ({ nft, onClick, isSelected }) => {
  const { item } = nft;
  console.log(item)

  return (
    <Wrapper onClick={onClick} isSelected={isSelected}>
      <Image src={item.metadata.image} />

      <TextContentWrapper>
        <TextWrapper>
          <NftName>{item.metadata.name}</NftName>
          <NftInfo>{item.metadata.description}</NftInfo>
        </TextWrapper>

        <TextWrapper>
          {item.lastest_price && (
            <NftPrice>
              {(Number(item.lastest_price) / 10 ** 18).toFixed(2)} ETH
            </NftPrice>
          )}

          {item.contract_type === CONTRACT_TYPES.ERC1155 && (
            <NftPrice>{item.amount} </NftPrice>
          )}
        </TextWrapper>
      </TextContentWrapper>
    </Wrapper>
  );
};

export default NfcCard;
