import { useContext } from 'react'
import { useParams } from 'react-router-dom';
import { Context } from '../../../store/Context'
import {
    ArtIMG,
    ArtVID,
    ArtInfoTitle,
    ArtistName,
    ArtType,
    OptionsButtonWrapper,
    Row,
    ArtApproved,
    PublishButton,
    PublishButtonHull
} from '../styles'
import NFT3D from '../../../../editor/Core/Entities/NFT3D'
import Web3 from 'web3'
import { EditorSnackbarContext } from '../../../contexts/editor-snackbar'
import { FlexibleModalContext } from '../../../contexts/flexible-modal/context'
import Moralis from '../../../../../const/moralis'
import styled from 'styled-components'
import { TypeEntity } from '../../../../editor/Core/Constants';

const ArtWorkItem = styled.div`
  display: flex;
  gap: 20px;
  padding: 20px;
  background: #161616;
  border-radius: 6px;
`;
const ArtInfo = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  width: 80%;
`;

export const ArtworkMagicItem = ({ token, setSelectedNFT }) => {
    const { editorStore } = useContext(Context)
    const { triggerSnackbar } = useContext(EditorSnackbarContext);
    const { setFlexibleModalChildren } = useContext(FlexibleModalContext);
    const { uid: space_uid } = useParams();

    const imageItemClick = async (event, token) => {
        const itemImage = token?.item;
        let duplicate = false;
        editorStore.editor.managerNFT.roomArtworks.forEach(({ item }) => {
            if (item?.token_address === itemImage.token_address && item?.token_id === itemImage.token_id) duplicate = true;
        });
        if (!duplicate) {
            editorStore.editor.managerNFT.roomArtworks.push(token);
            const nftData = {
                space_uid,
                token_address: itemImage?.token_address,
                token_id: itemImage?.token_id,
                amount: itemImage?.amount,
                contract_type: itemImage?.contract_type
            }
            await Moralis.Cloud.run("meta-add-nft", nftData);
        }

        if (itemImage?.metadata?.type?.includes("3D") || itemImage?.metadata?.type?.includes("object")) {
            console.log('handleItemClick=', itemImage);
            const url = itemImage?.metadata?.animation_url || itemImage?.metadata?.threeObj;
            if (!url) {
                console.warn("Unsupported metadata", itemImage?.metadata);
                return;
            }
            const NFT3DObject = new NFT3D({ project: editorStore.editor.project });
            NFT3DObject.data.path.value = url;
            NFT3DObject.data.name.value = itemImage?.metadata?.name;
            NFT3DObject.data.imageMeta.value = {
                token_id: itemImage.token_id,
                token_address: itemImage.token_address
            };

            editorStore.editor.toolManager.toolMagicWand.on3DObjectSelected(NFT3DObject);
            editorStore.editor.project.dispatchUpdateStates()
        } else {
            const selectorItem =
                editorStore.editor.selector.items.length === 1
                    ? editorStore.editor.selector.items[0]
                    : null
            
            if (selectorItem?.type === TypeEntity.Picture) {
                // Frame selected, apply NFT
                selectorItem.data.imageMeta.value = {
                    token_id: itemImage.token_id,
                    token_address: itemImage.token_address,
                }
                selectorItem.data.name.value = itemImage?.metadata?.name
                selectorItem.data.image.value = itemImage?.metadata?.image
                editorStore.editor.project.dispatchUpdateStates()
            } else {
                // No frames selected, create new frame
                editorStore.editor.toolManager.toolMagicWand.onPictureSelected(itemImage);
            }
        }
        triggerSnackbar(`Applying NFT ${token?.item?.metadata?.name}`, "info");
        setFlexibleModalChildren(null);
    }

    const isSubmitted = !!token?.item?.submitted;

    return <ArtWorkItem
        key={`${token?.item?.token_address}:${token?.item?.token_id}`}
    >
        {token?.item?.metadata?.image ? (
            <ArtIMG
                src={`${token?.item?.metadata?.image}`}
                srcSet={`${token?.item?.metadata?.image}`}
                alt={token?.item?.metadata?.name}
                loading="lazy"
            />
        ) : (
            <ArtVID
                crossorigin="anonymous"
                id={`vid:${token?.item?.token_address}:${token?.item?.token_id}`}
                type="video/mp4"
                src={`${token?.item?.metadata?.animation_url}`}
                preload='metadata'
            />
        )}
        <ArtInfo>
            <ArtInfoTitle>{token?.item?.metadata?.name}{token?.item?.price ? `: ${Web3.utils.fromWei(token?.item?.price)} ETH` : ""}</ArtInfoTitle>
            <ArtistName>Owner: {token?.owner?.username}</ArtistName>
            <Row>
                <ArtType>{token?.item?.contract_type}</ArtType>
                <ArtApproved approved={isSubmitted}>
                    {`${isSubmitted ? 'Sale approved' : 'Sale not approved'}`}
                </ArtApproved>
            </Row>
        </ArtInfo>

        <OptionsButtonWrapper>
            <PublishButtonHull onClick={(e) => imageItemClick(e, token)}>
                Select
            </PublishButtonHull>
        </OptionsButtonWrapper>

        {!isSubmitted ?
            <OptionsButtonWrapper>
                <PublishButton onClick={() => setSelectedNFT({ ...token, imageItemClick })}>
                    Push to market
                </PublishButton>
            </OptionsButtonWrapper>
            : null
        }
    </ArtWorkItem>
}