/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext, useRef } from 'react'

import { Context } from '../../../store/Context'
import { Button, Checkbox, InputAdornment, Tab, TextField } from "@mui/material";

import { pinFileToIPFS } from '../../../helpers/pinata'
import styled from 'styled-components'
import Moralis from '../../../../../const/moralis'
import { TabContext, TabList, TabPanel } from '@mui/lab';
import useAuth from '../../../../../helpers/auth';

import IERC721 from "../../../../../const/abis/standards/ERC721.json";
import MetaSpaceABI from '../../../../../const/abis/Space.json';
import { CoordinateInput } from '../../Input/CoordinateInput';
import { CoordinateInputWide } from '../../Input/CoordinateInputWide';
import CONFIG from '../../../../../config';
import { useParams } from 'react-router-dom';
import { CloseModalIcon } from '../../../assets/icons/CloseModalIcon';
import ObjectItem from '../../../../editor/Core/Entities/ObjectItem';
import { useEditor } from '../../../store/editor-context';
import { deleteItem } from '../../../../editor/Core/Algs/Basic';
import Light from '../../../../editor/Core/Entities/Light';
import { EditorSnackbarContext } from '../../../contexts/editor-snackbar';
import { PCFSoftShadowMap } from 'three';

const ButtonPublish = styled(Button)`
  min-width: auto !important;
  border-radius: 50px !important;
  text-transform: uppercase;
  bottom: 5px;
  width: 50%;
  margin-left: 10px;
  font-family: Montserrat !important;
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  color: #fff;
  background: ${({ disabled }) => disabled ? '#969696' : 'linear-gradient(90deg, #4D44FE 0%, #8552F6 100%)'} !important;
  font-size: 10px;
`;

const Subtitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: #bbb;
`;
const TitleFrame = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: #fff;
`;
const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 50px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: #161616;
    border-radius: 10px;
    -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
  }
  &::-webkit-scrollbar-thumb {
    background: #7E8390;
    border-radius: 10px;
  }
`;

const ItemName = styled.div`
  font-size: 14px;
  color: #353535;
  font-weight: 600;
  vertical-align: middle;
  margin-bottom: 1%;
  
  span {
    color: #a4a4a4;
    font-size: 12px;
    margin-right: 15px;
  }
`;


const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props?.small ? '20px' : '40px'};
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const BorderBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 0.4em;
`

export const CustomTabList = styled(TabList)`
  padding: 0px 24px 0 24px !important;
  width: 100% !important;
  box-sizing: border-box !important;
  display: flex !important;
  span {
    display: none !important;
  }
  .MuiTab-root {
    font-family: Montserrat !important;
    font-size: 10px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    flex: 1;
    background: #161616 !important;
    color: #7E8390 !important;
    text-transform: uppercase !important;
    border-radius: 50px !important;
  }
  .Mui-selected {
    flex: 1;
    background: linear-gradient(99.78deg, rgb(159, 11, 238) 0.69%, rgb(20, 91, 253) 98.32%) !important;
    color: #fff !important;
  }
  @media (max-width: 600px) {
    padding: 0px 16px 0 16px !important;
  }
`;

export const CustomTab = styled(Tab)`
  font-size: 14px !important;
  color: black !important;
  text-transform: capitalize !important;
  padding: 8px !important;
  box-sizing: border-box !important;
  min-height: auto !important;
  margin: 8px !important;
  justify-content: space-between !important;
  background: #eee !important;
  border-radius: 7px !important;

  svg {
    margin: 0 !important;
  }
  @media (max-width: 400px) {
    font-size: 12px !important;
    min-width: auto !important;
  }
`;

const CenteredButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
const Close = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  background: #161616;
  cursor: pointer;
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const StyledInput = styled(TextField)`
  box-shadow: none;
  border-color: transparent;

  .MuiInputLabel-root {
    color: #fff;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 0;
  }

  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
    webkit-appearance: none;
  }
  .MuiFormLabel-root {
    display: none;
  }

  & .MuiTypography-root {
    color: #fff;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
  }

  .MuiOutlinedInput-root {
    padding-right: 20px;
    overflow: 'hidden';
    height: 32px;
    color: #fff;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    background: #161616;
    border-radius: 6px;
    
    

    & fieldset {
      &.MuiOutlinedInput-notchedOutline {
          border: 0;
      }
    }
  }
`
const GradientText = styled.div`
  background: linear-gradient(90deg, #01A6FF 0%, #01F9FF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  width: fit-content;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`


export default function TabMetaspaceOptions() {
  const { editorStore } = useContext(Context)
  const [spawnPoint, setSpawnPoint] = useState(editorStore.editor?.project?.spawn || { x: 0, y: 0, z: 0 });
  const [moveSpeed, setMoveSpeed] = useState(editorStore.editor?.project?.movespeed || 3);
  const [shadowsEnabled, setShadowsEnabled] = useState(editorStore?.editor?.project?.shadowsEnabled || false);
  const [tabIndex, setTabIndex] = useState('1');
  const [curators, setCurators] = useState([]);
  const [spaceOptions, setSpaceOptions] = useState({});
  const [updatingPreview, setUpdatingPreview] = useState(false);
  const { address, uid } = useParams()
  const { user, web3, changeNetwork } = useAuth()
  const { space, setSpace } = useEditor()
  const { triggerSnackbar } = useContext(EditorSnackbarContext);
  const [isUpdatingTerms, setUpdatingTerms] = useState(false);

  useEffect(() => {
    const fetchSpaceOptions = async () => {
      await changeNetwork();
      const MetaspaceContract = new web3.eth.Contract(MetaSpaceABI, CONFIG.REACT_APP_METASPACE_CONTROLLER_ADDRESS);
      const metaspaceInfo = await MetaspaceContract.methods
        .getSpace(uid)
        .call({ from: user.get('ethAddress') });
      // console.log("meta info", metaspaceInfo);
      let uri = {}
      if (metaspaceInfo?.uri) {
        uri = await fetch(metaspaceInfo.uri).then(res => res.json())
      }
      console.log(metaspaceInfo)
      setSpaceOptions({ ...metaspaceInfo, ...uri });
      // Copy curators from info object, as it is readonly
      setCurators([...metaspaceInfo?.curators?.map(cur => [...cur])]);
    }

    fetchSpaceOptions();
  }, []);

  const changeSpaceInfo = (field, value) => {
    const temp = JSON.parse(JSON.stringify(spaceOptions));
    temp[field] = value;
    setSpaceOptions(temp);
  }

  const updateTerms = async () => {
    setUpdatingTerms(true);
    try {
      const partners = spaceOptions?.partners.map(i => i[0]);
      const partner_fees = spaceOptions?.partners.map(i => i[1]);
      const p_curators = curators?.map(i => i[0]);
      const p_curator_fees = curators?.map(i => i[1]);

      const options = [
        uid,
        [
          spaceOptions?.access_token_address,
          spaceOptions?.access_fee,
          spaceOptions?.submition_fee
        ],
        partners,
        partner_fees,
        [
          spaceOptions?.owner_of,
          spaceOptions?.owner_fee
        ],
        p_curators,
        p_curator_fees,
      ]

      const MetaspaceContract = new web3.eth.Contract(MetaSpaceABI, CONFIG.REACT_APP_METASPACE_CONTROLLER_ADDRESS);
      const res = await MetaspaceContract.methods
        .updateTerms(...options)
        .send(
          { from: user.get('ethAddress') }
        );
      console.log(res);
      triggerSnackbar('Terms updated successfully', 'success');
    } catch (e) {
      console.error(e);
      triggerSnackbar(`Terms update failed ${e.message}`, 'error');
    } finally {
      setUpdatingTerms(false);
    }
  }

  const updatePreview = async () => {
    setUpdatingPreview(true);
    triggerSnackbar('Updating preview', 'info')
    const strMime = "image/png";
    const imgData = editorStore.editor.engineComponent.engine.domElement.toDataURL(strMime);
    const image = await fetch(imgData).then(res => res.blob());
    const file = new File([image], "preview", { type: "image/png" });
    try {
      const pinata = await pinFileToIPFS(file);
      console.log(pinata)
      const res = await Moralis.Cloud.run("update-preview", { room_uuid: editorStore.editor.project.id, type: "update", preview: pinata.pinataUrl });
      if (res?.status === "success") {
        triggerSnackbar('Preview updated', 'success');
      }
      setUpdatingPreview(false);
    } catch (e) {
      triggerSnackbar(`Error updating preview ${e.message}`, 'error');
      setUpdatingPreview(false);
      console.warn("Error updating preview:", e);
    }
  }

  const resetPreview = async () => {
    try {
      const res = await Moralis.Cloud.run("update-preview", { room_uuid: editorStore.editor.project.id, type: "reset" });
      if (res?.status === "success") {
        console.log("Preview reset successfull");
        triggerSnackbar('Preview reset', 'success');
      }
    } catch (e) {
      triggerSnackbar(`Error resetting preview ${e.message}`, 'error');
      console.warn("Error reseting preview: ", e);
    }
  }

  const deleteMeta = async () => {
    try {
      const ZERO_ADDRESS = "0x0000000000000000000000000000000000000001";
      const MetaspaceNFTContract = new web3.eth.Contract(IERC721, address);
      await MetaspaceNFTContract.methods.transferFrom(
        user.get("ethAddress"), ZERO_ADDRESS, uid
      ).call({ from: user.get('ethAddress') });
      await MetaspaceNFTContract.methods.transferFrom(
        user.get("ethAddress"), ZERO_ADDRESS, uid
      ).send({ from: user.get('ethAddress') });
      await Moralis.Cloud.run('delete-meta', { space_uid: uid, del: true });
      triggerSnackbar('Metaspace deleted', 'success');
    } catch (e) {
      triggerSnackbar(`Error deleting Metaspace ${e.message}`, 'error');
      console.warn("Error deleting metaspace: ", e);
    }
  }

  const changeAllowSubmit = async (e) => {
    try {
      const allow = e.target.checked;
      await Moralis.Cloud.run('allow-submit-meta', { space_uid: uid, allow });
      setSpace({ ...space, submit_allowed: allow });
      triggerSnackbar('Submit status changed', 'success');
    } catch (e) {
      triggerSnackbar(`Error saving submit status ${e.message}`, 'error');
      console.warn("Error saving submit status", e);
    }
  }

  const changeEnableShadows = (e) => {
    const enabled = e.target.checked
    setShadowsEnabled(enabled);
    editorStore.editor.project.shadowsEnabled = enabled;
    editorStore.editor.engineComponent.setShadowMap(enabled, PCFSoftShadowMap);
  }

  const resetMeta = () => {
    editorStore.editor.project.destroy()

    const demoObj = new ObjectItem({ project: editorStore.editor.project })
    demoObj.data.path.value = spaceOptions?.animation_url
    demoObj.data.name.value = spaceOptions?.name
    demoObj.data.draggable.value = false
    editorStore.editor.project.baseSpaceName = spaceOptions?.name

    const ambientLightObject = new Light({ project: editorStore.editor.project })
    ambientLightObject.data.intensity.value = 1
    ambientLightObject.data.castShadow.value = false
    ambientLightObject.data.name.value = "Ambient Light"
    ambientLightObject.data.type.value = "ambient"

    const directionalLightObject = new Light({ project: editorStore.editor.project })
    directionalLightObject.data.intensity.value = 1
    directionalLightObject.data.castShadow.value = true
    directionalLightObject.data.name.value = "Directional Light"
    directionalLightObject.data.type.value = "directional"
    directionalLightObject.data.position.value.y = 2;

  }

  const onMovespeedUpdate = (value) => {
    setMoveSpeed(value);
    const floatSpeed = parseFloat(value);
    if (floatSpeed && editorStore?.editor?.project) {
      editorStore.editor.project.movespeed = floatSpeed;
    }
  }

  const handleSpawnChange = (event, property) => {
    const _spawnPoint = JSON.parse(JSON.stringify(spawnPoint));
    _spawnPoint[property] = event.target.value;
    editorStore.editor.project.spawn = _spawnPoint;
    console.log(editorStore.editor);
    setSpawnPoint(_spawnPoint);
    // if (event.nativeEvent?.data === '-') {
    //   const _spawnPoint = JSON.parse(JSON.stringify(spawnPoint));
    //   _spawnPoint[property] = -_spawnPoint[property];
    //   setSpawnPoint(_spawnPoint);
    //   editorStore.editor.project.spawn = _spawnPoint;
    //   return;
    // }
    // if (event.target.value.includes(',') && event.target.value?.replaceAll) {
    //   event.target.value.replaceAll(',', '.');
    // }
    // if (isNaN(parseFloat(event.target.value))) {
    //   event.target.value = 0;
    // }
    // const _spawnPoint = JSON.parse(JSON.stringify(spawnPoint));
    // _spawnPoint[property] = parseFloat(event.target.value);
    // setSpawnPoint(_spawnPoint);
    // 
  }

  const addCurator = () => {
    // Schema: [address, share]
    setCurators([...curators, ['', '']]);
  }

  const removeCurator = (index) => {
    const temp = [...curators];
    temp.splice(index, 1);
    setCurators(temp);
  }

  const changeCuratorInfo = (curator_id, param_id, event) => {
    const temp = [...curators];
    temp[curator_id][param_id] = event.target.value;
    console.log(curators)
    setCurators(temp);
  }

  return (
    <OptionsContainer>
      <TabContext value={tabIndex}>
        <CustomTabList
          onChange={(event, value) => setTabIndex(value)}
          aria-label='content'
          variant='scrollable'
        >
          <CustomTab label='Space appearance' value='1' />
          <CustomTab label='Space info' value='2' />
          <CustomTab label='Space terms' value='3' />
        </CustomTabList>
        <TabPanel value='1'>
          <OptionContainer small={true}>
            <Column>
              <TitleFrame>Update Metaspace preview</TitleFrame>
              <Subtitle>Update Metaspace preview by taking a screenshot from your current camera position</Subtitle>
              <RowContainer style={{ gap: '10px' }}>
                <ButtonPublish
                  variant="contained"
                  onClick={updatePreview}
                >
                  {updatingPreview ? 'Updating...' : 'Update preview'}
                </ButtonPublish>
                <ButtonPublish
                  variant="contained"
                  onClick={resetPreview}
                >
                  Reset preview
                </ButtonPublish>
              </RowContainer>
            </Column>
            <Column>
              <TitleFrame>Set avatar spawn point</TitleFrame>
              <Subtitle>Select inital avatar position in Metaspace</Subtitle>
              <RowContainer>
                <BorderBlock>
                  <CoordinateInputWide
                    onWheel={(e) => e.target.blur()}
                    type="text"
                    handleChange={e => handleSpawnChange(e, 'x')}
                    align="right"
                    value={spawnPoint?.x?.toString()}
                    label="x"
                  />
                  <CoordinateInputWide
                    onWheel={(e) => e.target.blur()}
                    type="text"
                    handleChange={e => handleSpawnChange(e, 'y')}
                    align="right"
                    value={spawnPoint?.y?.toString()}
                    label="y"
                  />
                  <CoordinateInputWide
                    onWheel={(e) => e.target.blur()}
                    type="text"
                    handleChange={e => handleSpawnChange(e, 'z')}
                    align="right"
                    value={spawnPoint?.z?.toString()}
                    label="z"
                  />
                </BorderBlock>
              </RowContainer>
            </Column>
            <Column>
              <TitleFrame>Avatar speed</TitleFrame>
              <Subtitle>Set avatar movement speed</Subtitle>
              <StyledInput
                onWheel={(e) => e.target.blur()}
                type="text"
                onChange={e => onMovespeedUpdate(e.target.value)}
                sx={{ width: '100%', color: 'white' }}
                align="right"
                value={moveSpeed}
                label="Movement speed"
              />
            </Column>
            <Column>
              <RowContainer style={{ gap: '10px' }}>
                <ButtonPublish
                  sx={{ background: "red !important" }}
                  variant="contained"
                  onClick={deleteMeta}
                >
                  Delete Metaspace
                </ButtonPublish>
                <ButtonPublish
                  variant="contained"
                  onClick={resetMeta}
                  title='Reset space to initial state'
                >
                  Reset Metaspace
                </ButtonPublish>
              </RowContainer>
            </Column>
            <Column>
              <Row style={{ gap: '10px', color: "#fff" }}>
                Allow others to submit NFTs
                <Checkbox
                  onChange={changeAllowSubmit}
                  checked={space?.submit_allowed}
                  sx={{
                    color: '#838383',
                    '&.Mui-checked': {
                      color: '#6C43E0',
                    },
                  }}
                />
              </Row>
              <Row style={{ gap: '10px', color: "#fff" }}>
                Enable shadows
                <Checkbox
                  onChange={changeEnableShadows}
                  checked={shadowsEnabled}
                  sx={{
                    color: '#838383',
                    '&.Mui-checked': {
                      color: '#6C43E0',
                    },
                  }}
                />
              </Row>
            </Column>
          </OptionContainer>
        </TabPanel>
        <TabPanel value='2'>
          <OptionContainer>
            <Column>
              <TitleFrame>Space title</TitleFrame>
              <StyledInput
                onWheel={(e) => e.target.blur()}
                type="text"
                onChange={e => changeSpaceInfo('name', e.target.value)}
                sx={{ width: '100%', color: 'white' }}
                align="right"
                value={spaceOptions?.name}
                label="Space title"
              />
            </Column>
            <Column>
              <TitleFrame>Space description</TitleFrame>
              <StyledInput
                onWheel={(e) => e.target.blur()}
                type="text"
                onChange={e => changeSpaceInfo('description', e.target.value)}
                sx={{ width: '100%', marginTop: '10px' }}
                align="right"
                value={spaceOptions?.description}
                label="Space description"
              />
            </Column>
            {/* <TitleFrame style={{ fontSize: "16px", fontWeight: "500", marginTop: "1rem" }}>
              Upload preview
            </TitleFrame>
            <input
              multiple
              type="file"
              id="file-selector"
              name="file-selector"
              accept="image/*"
            // onChange={onFileSelected}
            /> */}
            <CenteredButtonContainer>
              <ButtonPublish
                variant="contained"
                onClick={updateTerms}
                disabled={isUpdatingTerms}
              >
                {isUpdatingTerms ? 'Updating terms...' : 'Update terms'}
              </ButtonPublish>
            </CenteredButtonContainer>
          </OptionContainer>
        </TabPanel>
        <TabPanel value='3'>
          <OptionContainer small>
            <GradientText>
              Curator options
            </GradientText>
            <Subtitle>Manage curators of your Metaspace and their revenue share</Subtitle>
            {curators.map((curator, index) => <Column key={index}>
              <TitleFrame>
                Curator #{index + 1}
              </TitleFrame>
              <SpaceBetween>
                <StyledInput
                  onWheel={(e) => e.target.blur()}
                  type="text"
                  onChange={e => changeCuratorInfo(index, 0, e)}
                  sx={{ width: '80%' }}
                  align="right"
                  value={curator[0]}
                  placeholder="address"
                />
                <StyledInput
                  onWheel={(e) => e.target.blur()}
                  type="text"
                  onChange={e => changeCuratorInfo(index, 1, e)}
                  sx={{ width: '15%' }}
                  align="right"
                  value={curator[1]}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />
                <Close onClick={e => removeCurator(index)}>
                  <CloseModalIcon />
                </Close>
              </SpaceBetween>
            </Column>)}
            {curators.length < 4 &&
              <CenteredButtonContainer>
                <ButtonPublish
                  onClick={addCurator}
                  sx={{ width: "40%" }}
                  variant="contained"
                >
                  Add curator
                </ButtonPublish>
              </CenteredButtonContainer>}
            <GradientText>
              Access terms
            </GradientText>
            <Subtitle>Manage NFT and access terms of your Metaspace</Subtitle>
            <Column>
              <TitleFrame>Owner fee</TitleFrame>
              <StyledInput
                onWheel={(e) => e.target.blur()}
                type="text"
                onChange={e => changeSpaceInfo('owner_fee', e.target.value)}
                sx={{ width: '100%' }}
                align="right"
                value={spaceOptions?.owner_fee}
              />
            </Column>
            <Column>
              <TitleFrame>Access token</TitleFrame>
              <StyledInput
                onWheel={(e) => e.target.blur()}
                type="text"
                onChange={e => changeSpaceInfo('access_token_address', e.target.value)}
                sx={{ width: '100%' }}
                align="right"
                value={spaceOptions?.access_token_address}
              />
            </Column>
            <Column>
              <TitleFrame>Access fee</TitleFrame>
              <StyledInput
                onWheel={(e) => e.target.blur()}
                type="text"
                onChange={e => changeSpaceInfo('access_fee', e.target.value)}
                sx={{ width: '100%' }}
                align="right"
                value={spaceOptions?.access_fee}
                label="Access fee"
              />
            </Column>
            <Column>
              <TitleFrame>NFT submission fee</TitleFrame>
              <StyledInput
                onWheel={(e) => e.target.blur()}
                type="text"
                onChange={e => changeSpaceInfo('submition_fee', e.target.value)}
                sx={{ width: '100%' }}
                align="right"
                value={spaceOptions?.submition_fee}
              />
            </Column>
            <CenteredButtonContainer>
              <ButtonPublish
                onClick={updateTerms}
                variant="contained"
                disabled={isUpdatingTerms}
              >
                {isUpdatingTerms ? 'Updating terms...' : 'Update terms'}
              </ButtonPublish>
            </CenteredButtonContainer>
          </OptionContainer>
        </TabPanel>
      </TabContext>

    </OptionsContainer>
  )
}
