export default class BaseRendererThree {
  constructor ({app, item}) {
    this.app = app
    this.engineComponent = app.engineComponent
    this.item = item
  }

  init () {}

  destroy () {}

  update () {}

  extractDataToItem () {}
}