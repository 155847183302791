import { Box, Modal, useMediaQuery } from '@mui/material';
import { useRef, createContext, useState, useContext } from 'react';
import useOnClickOutside from '../../../helpers/useOnClickOutside';
import { Close } from '../styles';
import { ButtonPublish, ButtonWrapper, getStyle, LoadingContainer, ModalCotnentWrapper, NftWrapper, Title, Wrapper } from './style';
import { MyNftsProvider, useMyNfts } from '../../../contexts/submit-token';
import { useFetchMyNfts } from '../../../contexts/submit-token/fetch';
import { NftCardBig, NftCard, NftCardBigSuccess } from '../../Cards';
import { LoadMoreButton } from '../../Buttons/LoadMoreButton';
import { CustomBlackBtn } from '../../Buttons/CustomBlackBtn';
import abi from '../../../../../const/abis/Space.json';
import { useParams } from 'react-router-dom';
import useAuth from '../../../../../helpers/auth';
import { useEditor } from '../../../store/editor-context';
import erc721approve from '../../../../../helpers/approve/erc721approve';
import erc1155approve from '../../../../../helpers/approve/erc1155approve';
import { SubmitButton } from './style';
import CONFIG from '../../../../../config';
import { $host } from '../../../../api/base';
import { Context } from '../../../store/Context';
import { EditorSnackbarContext } from '../../../contexts/editor-snackbar';

export const ViewContext = createContext();


const SubmitTokenChild = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const { address, uid } = useParams();
  const { editorStore } = useContext(Context)
  const matches = useMediaQuery('(max-width:600px)');
  const { list, isLoading, isLoaded, setPage } = useMyNfts();
  const { setSpace } = useEditor();
  const { web3, user } = useAuth();
  const [selectedNFT, setSelectedNFT] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const { triggerSnackbar } = useContext(EditorSnackbarContext);

  useFetchMyNfts();

  const NftList = list.map((item, index) => (
    <NftCard
      key={index}
      isSelected={
        item?.item?.token_id === selectedItem?.item?.token_id &&
        item?.item?.token_address === selectedItem?.item?.token_address
      }
      onClick={() =>
        item?.item?.token_id === selectedItem?.item?.token_id &&
          item?.item?.token_address === selectedItem?.item?.token_address
          ? setSelectedItem(null)
          : setSelectedItem(item)
      }
      nft={item}
    />
  ));

  function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const onSubmit = async (price) => {
    try {
      setSubmitSuccess(false);
      const contract = new web3.eth.Contract(abi, CONFIG.REACT_APP_METASPACE_CONTROLLER_ADDRESS);
      console.log(
        uid,
        selectedItem.item.token_address,
        selectedItem.item.token_id,
        1,
        selectedItem.item.contract_type === 'ERC721' ? 0 : 1,
        web3.utils.toWei(price)
      );
      if (selectedItem.item.contract_type === 'ERC721') {
        await erc721approve(
          selectedItem.item.token_id,
          selectedItem.item.token_address,
          CONFIG.REACT_APP_METASPACE_CONTROLLER_ADDRESS,
          user.get('ethAddress'),
          web3
        );
      } else {
        await erc1155approve(
          selectedItem.item.token_address,
          CONFIG.REACT_APP_METASPACE_CONTROLLER_ADDRESS,
          user.get('ethAddress'),
          web3
        );
      }
      await contract.methods
        .submitToken(
          uid,
          selectedItem.item.token_address,
          selectedItem.item.token_id,
          1,
          selectedItem.item.contract_type === 'ERC721' ? 0 : 1,
          web3.utils.toWei(price)
        )
        .call({ from: user.get('ethAddress') });
      await contract.methods
        .submitToken(
          uid,
          selectedItem.item.token_address,
          selectedItem.item.token_id,
          1,
          selectedItem.item.contract_type === 'ERC721' ? 0 : 1,
          web3.utils.toWei(price)
        )
        .send({ from: user.get('ethAddress') });

      await timeout(1000);
      const { data } = await $host.get(`/space/${address}&${uid}`);
      await editorStore.editor.managerNFT.init(data?.all_tokens);
      
      await contract.methods
        .approvedToken(data?.all_tokens?.at(-1)?.item?.uid)
        .call({ from: user.get('ethAddress') });
      await contract.methods
        .approvedToken(data?.all_tokens?.at(-1)?.item?.uid)
        .send({ from: user.get('ethAddress') });

      await timeout(1000);
      const { data: dataApproved } = await $host.get(`/space/${address}&${uid}`);
      await editorStore.editor.managerNFT.init(dataApproved?.all_tokens);

      setSpace(data);
      setSubmitSuccess(true);
    } catch (e) {
      triggerSnackbar(`Token submit failed ${e.message}`, 'error');
      console.error(e);
    }
  };

  return selectedNFT ? (
    <Wrapper>
      <Title>
        Submit NFT
      </Title>
      {submitSuccess ? <NftCardBigSuccess onBackClick={() => setSelectedNFT(null)} /> :
        <NftCardBig
          nft={selectedItem}
          onBackClick={() => setSelectedNFT(null)}
          onSubmitClick={onSubmit}
        />
      }
    </Wrapper>
  ) : (
    <Wrapper>
      <Title>Submit Tokens</Title>
      {isLoading && !list.length ? (
        <LoadingContainer>
          Loading...
        </LoadingContainer>
      ) : (
        <NftWrapper>
          {NftList}
        </NftWrapper>
      )}
      <ButtonWrapper>
        <LoadMoreButton
          isLoaded={isLoaded}
          isLoading={isLoading}
          onClick={setPage.bind(null)}
        />
        <ButtonPublish
          onClick={() => setSelectedNFT(selectedItem)}
          disabled={!selectedItem}
        >
          Submit Token
        </ButtonPublish>
      </ButtonWrapper>
    </Wrapper>
  )
};

const SubmitTokenModal = () => (
  <MyNftsProvider>
    <SubmitTokenChild />
  </MyNftsProvider>
);

export default SubmitTokenModal;
