import React from 'react'
import styled from 'styled-components'
const BntBlack = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  margin-left: 10px;
  gap: 10px;
  border-radius: 7px;
  ${props =>
    props.isActive
      ? `  background: #eee;
  color: #191919;`
      : `  background: #191919;
  color: #fff;`};

  /* &:hover {
    color: #191919;
    background: #eeeeee;
  } */
  &:active {
    color: #fff;
  }
`

export const CustomBlackChoose = ({children, onClick, isActive = false}) => {
  return (
    <BntBlack isActive={isActive} onClick={onClick}>
      {children}
    </BntBlack>
  )
}
