import { BaseAnimation } from "./BaseAnimation";
import { AnimationStates } from "./const/AnimationStates";

export default class StateFall extends BaseAnimation {
    constructor(fallAction) {
        super(fallAction, 'STATE_FALL');
        this.transitions = {
            STATE_IDLE: 0.2,
            STATE_WALK: 0.25,
            STATE_RUN: 0.25,
            STATE_DANCE: 0.2
        };
        this.id = AnimationStates.STATE_FALL;
    }
}