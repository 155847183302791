
export default class MultiplayerControls {
    constructor() {
        this.animationMixers = [];
        // flags if position or rotation data is changed
        this.dispatchWsEvent = { pos: false, rot: false };
        this.socketHandler = null;
        // camera rotation, w and y properties for multiplayer. only changes if camera is rotated
        this.quaternion = {};

        this.cameraInitHeight = 1.63;
    }

    addAnimationMixer(mixer) {
        this.animationMixers.push(mixer);
    }

    setSocketHandler(socketHandler) {
        this.socketHandler = socketHandler;
    }
}
