import Toolbase from './Base'
import { TypeEntity, InputEventType } from '../Core/Constants'
import { deleteItem, attachPictureByPick } from '../Core/Algs/Basic'

import { ArrowHelper, Vector2, Vector3/*, Mesh, MeshStandardMaterial, SphereGeometry*/ } from 'three'
//import {VertexNormalsHelper} from 'three/examples/jsm/helpers/VertexNormalsHelper.js'

//const geometry = new SphereGeometry(0.1, 20, 20)
//const material = new MeshStandardMaterial()

export default class ToolArrow extends Toolbase {
  constructor() {
    super(...arguments)

    this.name = 'Pointer'

    this._itemCur = null
    this.dataDrag = null

    // where objects will be located when added th scene
    this.placePosition = null
    this.liveStreamClicked = false
  }

  // get placePosition() {
  //   return this.placePosition
  // }

  // set placePosition(value) {
  //   if (this.placePosition === value) return
  //   this.placePosition = value
  // }

  get itemCur() {
    return this._itemCur
  }

  set itemCur(value) {
    if (this._itemCur === value) return

    if (this._itemCur) this.app.hoverer.filter.remove(this._itemCur)

    this._itemCur = value

    if (this._itemCur) this.app.hoverer.filter.add(this._itemCur)
    else this.dataDrag = null
  }

  createFullscreenVideoElement() {
    const removeElement = () => {
      div.remove();
    }
    const video_url = this.app.hoverer?.item?.data?.imageMeta?.value?.url;
    const threeVideoElement = document.getElementById(video_url + '_html5_api');
    if (threeVideoElement) {
      threeVideoElement.pause();
    }
    const source = document.createElement('source');
    const videoElement = document.createElement('video');
    const div = document.createElement('div');
    div.setAttribute('style', 'z-index: 9999; position: absolute; top: 0; width: 100vw; height: 100vh; background: black;')
    const closeDiv = document.createElement('div');
    closeDiv.setAttribute('style', `
      position: absolute;
      top: 10px;
      right: 10px;
      background: #1b1b1c;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 10000;
      font-size: 16px;
      user-select: none;
    `);
    closeDiv.onclick = removeElement
    closeDiv.ontouchstart = removeElement
    const textNode = document.createTextNode("X");
    closeDiv.appendChild(textNode);
    div.appendChild(closeDiv);
    videoElement.appendChild(source);
    videoElement.setAttribute('crossorigin', 'anonymous');
    videoElement.setAttribute('id', 'fullscreenPlayer');
    videoElement.setAttribute('class', 'video-js vjs-big-play-centered');
    videoElement.setAttribute('style', 'width: 100%; height: 100%;')
    videoElement.autoplay = true;
    videoElement.addEventListener('loadedmetadata', () => {
      videoElement.currentTime = videoElement.duration - 1
    });
    source.setAttribute('type', video_url.includes('www.youtube.com') ? 'video/youtube' : 'application/x-mpegURL');
    source.src = video_url;
    div.appendChild(videoElement);
    const root = document.getElementById('root');
    root.appendChild(div);
    window.videojs(videoElement, { controls: true });
    window
      .addEventListener('keydown', (event) => {
        if (event.key === 'Escape') {
          window.removeEventListener('keydown', this);
          removeElement();
        }
      });
  }

  playVideoElement() {
    const item = this.app.engineComponent.hoverer.pickInfo;
    if (!item?.object?.videoState) return;
    if (item?.object?.videoState === "play" && item?.object) {
      if (!item.object.videoHtml) return;
      item.object.videoState = "pause"
      try {
        if (item.object?._itemMP?.data?.imageMeta?.value?.isLivestream) {
          // seek live time for livestream
          if (item.object.videoHtml.currentTime && item.object.videoHtml.duration) {
            item.object.videoHtml.currentTime = item.object.videoHtml.duration - 1;
          }
        }
        item.object.videoHtml.muted = false;
        item.object.videoHtml.play()
          .then(() => { })
          .catch(error => { console.log("Hoverer::video::play error:", error) });
      } catch (e) {
        console.warn("Hoverer::video error:", e)
      }
    } else {
      item.object.videoState = "play";
      item.object.videoHtml.pause()
    }
  }

  onPointerEvent(event) {
    // console.log(this.constructor.name + '.onPointerEvent=', event, event.type)
    // if (event.type !== 'pointermove') console.log(this.constructor.name + '.onPointerEvent=', event, 'type=', event.type, 'button=', event.button)
    const leftButton = event.buttons & 1
    if (!this.app.editMode) {
      this.app.hoverer.update(event, false);
    }

    if (event.type === InputEventType.move) {
      this.app.hoverer.update(event, !this.app.editMode)

      if (this.itemCur && this.app.editMode && this.itemCur.data.draggable.value) {
        if (this.itemCur.data.position) {
          // console.log('moving=', this.itemCur, this.app.engineComponent.hoverer.planePt.clone())
          this.itemCur.data.position.value.x = this.app.engineComponent.hoverer.planePt.x + this.dataDrag.delta.x
          this.itemCur.data.position.value.y = this.app.engineComponent.hoverer.planePt.y + this.dataDrag.delta.y
          this.itemCur.data.position.value.z = this.app.engineComponent.hoverer.planePt.z + this.dataDrag.delta.z

          // const positionUpdatedEvent = new CustomEvent(
          //   "positionUpdated", { "position": this.itemCur.data.position.value, "payload": true }
          // );
          // document.dispatchEvent(positionUpdatedEvent);

          if (this.itemCur.type === TypeEntity.Picture) {
            //console.log('picture moving=', this.itemCur, this.app.engineComponent.hoverer) // todo: use hover filter
            const pickInfo = this.app.engineComponent.hoverer.pickInfo
            //console.log('pickInfo=', pickInfo)
            if (pickInfo) {
              const pickPt = pickInfo.point

              this.itemCur.data.position.value.x = pickPt.x
              this.itemCur.data.position.value.y = pickPt.y
              this.itemCur.data.position.value.z = pickPt.z

              if (this.arrowHelper) {
                this.arrowHelper.visible = true
                this.arrowHelper.position.copy(pickInfo.point)
                const normalWorld = attachPictureByPick(this.itemCur, pickInfo)
                if (normalWorld) {
                  this.arrowHelper.setDirection(normalWorld)
                  //this.arrowHelper.setDirection(pickInfo.face.normal)
                }
              }

              /*if (this._lastHoveredObj !== pickInfo.object) {
                this._lastHoveredObj = pickInfo.object
                if (this._objectNormalsHelper) {
                  this.app.engineComponent.sceneComponent.scene.remove(this._objectNormalsHelper)
                  delete this._objectNormalsHelper
                }
                this._objectNormalsHelper = new VertexNormalsHelper(pickInfo.object, 2, 0x00ff00, 1)
                this.app.engineComponent.sceneComponent.scene.add(this._objectNormalsHelper)
              }*/
            }
          }
        }
      }
    } else if (event.type === InputEventType.down && leftButton && !this.app.editMode) {
      // console.log("Click on", this.app.hoverer?.item)
      if (this.app.hoverer?.item?.data?._compiledClick) this.app.hoverer?.item?.data?._compiledClick()
      // update raycast
      this.app.hoverer.update(event, !this.app.editMode)

      // if raycast hit an NFT, show NFT data
      const isArtwork = this.app.hoverer?.item?.renderer?._artWork
      const isVisiblePicture = this.app.hoverer?.item?.renderer?.fameMesh?.visible
      const isVisibleObjectItem = this.app.hoverer?.item?.renderer?.meshCollider?.visible
      this.playVideoElement();
      if (isArtwork && (isVisiblePicture || isVisibleObjectItem)) {
        this.app.events.trigger('showItem', { item: this.app.hoverer?.item })
      }
      if (this.app.hoverer?.item?.data?.imageMeta?.value?.isLivestream) {
        if (this.liveStreamClicked) {
          // double clicked on livestream
          this.liveStreamClicked = false
          this.createFullscreenVideoElement();
        }
        this.liveStreamClicked = true
        setTimeout(() => { this.liveStreamClicked = false }, 500);
      }
      if (this.app.hoverer?.item?.data?.attachedProduct?.value?.objectId) {
        // Click on item with attached product
        this.app.events.trigger('showAttachedProduct', { item: this.app.hoverer.item.data.attachedProduct.value });
      }
    } else if (event.type === InputEventType.up) {
      // console.log(this.constructor.name + '.onPointerEvent - mouseup=', event)
      this.app.engineComponent.hoverer.resetEditorClickPoint();
      this.app.engineComponent.sceneComponent.setCameraControlState(true)

      //console.log('dataDrag=', this.dataDrag, 'event=', event)
      if (this.dataClick && this.dataClick.x === event.clientX && this.dataClick.y === event.clientY) {
        // console.log('select item=', this.app.hoverer.item)
        this.app.selector.clear()
        if (this.app.hoverer.item) this.app.selector.add(this.app.hoverer.item)
        this.app.engineComponent.hoverer.findEditorClickPoint(event);
        const item = this.app.selector.items.length === 1 ? this.app.selector.items[0] : null
        this.app.events.trigger('showItem', { item })
      }

      if (this.arrowHelper) {
        this.app.engineComponent.sceneComponent.scene.remove(this.arrowHelper)
        delete this.arrowHelper
      }

      this.itemCur = null
      this.dataClick = null
    } else if (event.type === InputEventType.down && leftButton) {
      this.dataClick = { x: event.clientX, y: event.clientY }
      this.itemCur = this.app.hoverer.item
      if (this.itemCur && this.app.editMode) {
        //const pickPt = this.app.engineComponent.hoverer.pickInfo.point
        const planePt = this.app.engineComponent.hoverer.planePt
        // console.log('*planePt=', planePt.clone()/*, 'pickPt=', pickPt.clone()*/, 'dataDrag=', this.dataDrag, 'itemCur=', this.itemCur)
        if (this.itemCur && this.itemCur.data.draggable.value && this.itemCur.data.position) {
          this.app.engineComponent.sceneComponent.setCameraControlState(false)
          this.dataDrag = {
            delta: {
              x: this.itemCur.data.position.value.x - planePt.x,
              y: this.itemCur.data.position.value.y - planePt.y,
              z: this.itemCur.data.position.value.z - planePt.z
            }
          }
        }
        // console.log('*planePt=', planePt.clone(), 'pickPt=', pickPt.clone(), 'dataDrag=', this.dataDrag)

        /*const mesh = new Mesh(geometry, material)
        this.app.engineComponent.sceneComponent.scene.add(mesh)
        mesh.position.copy(this.app.engineComponent.hoverer.pickInfo.point)*/

        if (this.itemCur.type === TypeEntity.Picture) {
          this.arrowHelper = new ArrowHelper(new Vector3(1, 0, 0), new Vector3(0, 0, 0), 1, 0xffff00)
          this.arrowHelper.visible = false
          this.app.engineComponent.sceneComponent.scene.add(this.arrowHelper)
        }
      }
    }

    super.onPointerEvent(event)
  }

  onDeleteItem() {
    while (this.app.selector.items.length > 0) {
      const item0 = this.app.selector.items[0]
      console.log('delete=', item0)
      this.app.selector.remove(item0)
      deleteItem(item0)
      this.app.events.trigger("deleteItem", { item: item0 });
    }
  }

  onKeyEvent(event) {
    // console.log(this.constructor.name + '.onKeyEvent=', event, this.app.selector)

    if (this.app.editMode && event.type === 'keydown' && event.code === 'Delete') {
      this.onDeleteItem();
    }

    const item = this.app.selector.items.length === 1 && this.app.selector.items[0].data.position ? this.app.selector.items[0] : null
    if (this.app.editMode && item && event.type === 'keydown') {
      if (event.key === 'ArrowUp') item.data.position.value.z++
      else if (event.key === 'ArrowDown') item.data.position.value.z--
      else if (event.key === 'ArrowLeft') item.data.position.value.x--
      else if (event.key === 'ArrowRight') item.data.position.value.x++
    }

    super.onKeyEvent(event)
  }
}