import styled from "styled-components";
import { HeaderDropDown } from "../../assets/icons/HeaderDropDown";
import { MP1LogoHeader } from "../../assets/icons/MP1LogoHeader";
import { Gear } from "../../assets/icons/Gear";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { TitleCloseIcon } from "../../assets/icons/TitleCloseIcon";
import { useNavigate } from "react-router-dom";
import TabArtroomOptions from "../ContentTabs/Tabs/TabArtroomOptions"
import { getUrlProfile } from "../../../../helpers/getUrlProfile";
import { Button } from "antd";

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    position: absolute;
    width: 100%;
    height: 48px;
    left: 0px;
    top: 0px;

    background: #121212;

    border-bottom: 1px solid #303030;
`

const MP1Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 12px 0px 12px;
    margin: 0 12px 0 24px;
    gap: 10px;

    width: 70px;
    height: 100%;

    background: #262626;
    border-radius: 4px;
`

const MP1LogoContainer = styled.div`
    height: 16px;

    :hover {
        cursor: pointer;
        box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);  
    }
`

const GearContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;

    width: 32px;
    height: 100%;

    background: #262626;
    border-radius: 4px;

    :hover {
        cursor: pointer;
        box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);  
    }
`

const SpaceNameContainer = styled.div`
    display: flex;
    flex-direction: row
    height: 100%;
    background: #262626;
    border-radius: 4px;
`

const SpaceName = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    color: white;
    padding: 0 8px 0 8px;
    box-sizing: border-box;
`

const CloseIconWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    width: 16px;
    margin: 0 4px;
    box-sizing: border-box;
`

const CloseIconBox = styled.div`
    height: 16px;
    width: 16px;

    :hover {
        cursor: pointer;
    }
`

const PublishButton = styled(Button)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;

    width: 94px;
    height: 100%;

    background: #6C43E0;
    border-radius: 4px;
    border: none;

    color: #F0F0F0;

    :hover {
      cursor: pointer;
      box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);  
    }
`
const PublishButtonHull = styled(Button)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;

    width: 94px;
    height: 100%;

    border-radius: 4px;
    border: 2px solid ${({ disabled }) => disabled ? '#969696' : '#6C43E0'};
    box-sizing: border-box;

    color: #F0F0F0;
    background: transparent;
    cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};

    :hover {
      box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);  
    }
`

const LeftContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 32px;
`

const RightContainer = styled.div`
    height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 24px;
    gap: 4px;
`

const HeaderDropdownStyled = styled.div`
    width: 16px;
    height: 16px;

    :hover {
        cursor: pointer;
    }
`

export default function Header({
    setModalChildren,
    switchShowLeftPanel,
    saveRoomClick,
    isSavingSpace,
    title
}) {
    const push = useNavigate();

    const viewRoom = () => {
        push('../view', { relative: "path" });
        push(0);
    }

    const redirectToProfile = () => {
        window.location.replace(getUrlProfile());
    }

    return <HeaderContainer>
        <LeftContainer>
            <MP1Container>
                <MP1LogoContainer onClick={() => push(-1)}>
                    <MP1LogoHeader />
                </MP1LogoContainer>
                <HeaderDropdownStyled onClick={switchShowLeftPanel}>
                    <HeaderDropDown />
                </HeaderDropdownStyled>
            </MP1Container>
            {title &&
                <SpaceNameContainer>
                    <SpaceName>
                        {title}
                    </SpaceName>
                    <CloseIconWrapper>
                        <CloseIconBox onClick={() => push(-1)}>
                            <TitleCloseIcon />
                        </CloseIconBox>
                    </CloseIconWrapper>
                </SpaceNameContainer>
            }
        </LeftContainer>
        <RightContainer>
            <GearContainer onClick={redirectToProfile} title="exit editor">
                <LogoutOutlinedIcon sx={{ color: "#fff" }} />
            </GearContainer>
            <GearContainer onClick={() => setModalChildren(<TabArtroomOptions />)}>
                <Gear />
            </GearContainer>
            <PublishButtonHull disabled={isSavingSpace} onClick={saveRoomClick}>
                {isSavingSpace ? 'Saving...' : 'Save'}
            </PublishButtonHull>
            <PublishButton onClick={viewRoom}>
                View
            </PublishButton>
        </RightContainer>
    </HeaderContainer>
}