import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Wrapper = styled.div`
  width: 100%;
`;

const Center = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 20px;
`;

const LoaderSpin = ({ fontSize = 24, color = '#191919', padding = 20 }) => {
  const antIcon = <LoadingOutlined style={{ fontSize, color, padding }} spin />;

  return (
    <Wrapper>
      <Center style={{ padding }}>
        <Spin indicator={antIcon} />
      </Center>
    </Wrapper>
  );
};

export default LoaderSpin;
