import { useContext, useEffect, useState } from "react";
import formatIPFSLink from "../../../helpers/formatIPFSLink";
import {
    Avatar,
    Banner,
    BannerWrapper,
    BottomRow,
    DarkTransparentBg,
    Property,
    PropertyTitle,
    PropertyValue,
    TopRow,
    Wrapper,
    ClaimButton,
    PropertyCentered,
    BottomRowWrapper,
    ModalTitle,
    ProgressBarWrapper,
    ProgressBarRow,
    ProgressValue,
    Progress,
    StatsContainer,
    StatsWrapper,
    StatsTitle,
    StatsSubTitle,
    StatsValue,
    Title
} from "./styles";
import Moralis from '../../../../../const/moralis';
import { getETH } from "../../../helpers/getEth";
import { replaceNaN } from "../../../helpers/replaceNaN";
import useAuth from "../../../../../helpers/auth";
import { EditorSnackbarContext } from "../../../contexts/editor-snackbar";
import daoAirdropAbi from '../../../../../const/abis/DaoAirdrop.json';
import collectionAirdropAbi from '../../../../../const/abis/CollectionAirdrop.json';
import CONFIG from "../../../../../config";
import { ArrowDownIcon } from "../../../assets/icons/ArrowDown";

export const ClaimAirdrop = ({ item }) => {
    const [moralisData, setMoralisData] = useState(null);
    const [claiming, setClaiming] = useState(false);
    const [claimed, setClaimed] = useState(true);
    const { triggerSnackbar } = useContext(EditorSnackbarContext);
    const { user, web3 } = useAuth();

    const hasEnded = moralisData?.item?.end_time < Date.now() / 1000;

    const stats = [
        {
            title: "Patricipants",
            value: moralisData?.participants?.length || '-',
            subtitle: "Users"
        },
        {
            title: "Amount claimed",
            value: replaceNaN(getETH(moralisData?.item?.claimed)),
            subtitle: `${moralisData?.item?.token_name} (${moralisData?.item?.token_symbol})`
        },
        {
            title: "Amount remaining",
            value: replaceNaN(getETH(moralisData?.item?.remaining)),
            subtitle: `${moralisData?.item?.token_name} (${moralisData?.item?.token_symbol})`
        },
    ]

    useEffect(() => {
        Moralis.Cloud.run('dao-airdrop-bank-details', {
            uid: item?.uid
        }).then(data => {
            setMoralisData(data);
            setClaimed(false);
        });
    }, []);

    const claim = async () => {
        setClaiming(true);
        try {
            const isDao = item?.backendData?.entityType === 'dao';
            const contract = new web3.eth.Contract(
                isDao ? daoAirdropAbi : collectionAirdropAbi,
                isDao ? CONFIG.REACT_APP_DAO_AIRDROP_ADDRESS : CONFIG.REACT_APP_COLLECTION_AIRDROP_ADDRESS
            );
            console.log(item?.uid, isDao)
            await contract.methods.claim(
                item?.uid, moralisData?.item?.max_per_user?.toString()
            ).call({ from: user.get("ethAddress") });
            await contract.methods.claim(
                item?.uid, moralisData?.item?.max_per_user?.toString()
            ).send({ from: user.get("ethAddress") });
            setClaiming(false);
            setClaimed(true);
            triggerSnackbar("Airdrop claimed!");
        } catch (e) {
            triggerSnackbar(`Error claiming airdrop: ${e.message}`, 'error');
            setClaiming(false);
        }
    }

    const getDate = (start_time) => {
        if (start_time) {
            const date = new Date(start_time * 1000);
            return `${new Date(date).getDate()} 
              ${new Date(date).toLocaleString("en", { month: "long" })}
          `;
        }
        return null;
    };

    return <Wrapper>
        <ModalTitle>Claim Airdrop</ModalTitle>
        <BannerWrapper>
            <Banner alt='banner' src={formatIPFSLink(item?.backendData?.image)} />
            <TopRow>
                <DarkTransparentBg>
                    {hasEnded ? "Ended" : "Active"}
                </DarkTransparentBg>
            </TopRow>
            <BottomRow>
                <Title>{item?.backendData?.title}</Title>
                <DarkTransparentBg>
                    <BottomRowWrapper>
                        <Avatar alt='logo' src={moralisData?.creator?.avatar} />
                        {getDate(moralisData?.item?.start_date) ?
                            <Property>
                                <PropertyTitle>Start date</PropertyTitle>
                                <PropertyValue>{getDate(moralisData?.item?.start_date)}</PropertyValue>
                            </Property>
                            : null}
                        <Property>
                            <PropertyTitle>Token amount</PropertyTitle>
                            <PropertyValue>{replaceNaN(getETH(moralisData?.item?.amount))} {moralisData?.item?.token_symbol || ''}</PropertyValue>
                        </Property>
                        <Property>
                            <PropertyTitle>Limit per wallet</PropertyTitle>
                            <PropertyValue>{replaceNaN(getETH(moralisData?.item?.max_per_user))} {moralisData?.item?.token_symbol}</PropertyValue>
                        </Property>
                        <PropertyCentered>
                            <ClaimButton disabled={hasEnded || claimed || claiming} onClick={claim}>
                                <ArrowDownIcon /> Claim
                            </ClaimButton>
                        </PropertyCentered>
                    </BottomRowWrapper>
                </DarkTransparentBg>
            </BottomRow>
        </BannerWrapper>
        <ProgressBarWrapper>
            <ProgressBarRow>
                <ProgressValue>Drop progress</ProgressValue>
                <ProgressValue>{replaceNaN(getETH(moralisData?.item?.claimed))}/{replaceNaN(getETH(moralisData?.item?.amount))}</ProgressValue>
            </ProgressBarRow>
            <Progress width={replaceNaN(moralisData?.item?.claimed / moralisData?.item?.amount * 100, 0)} />
        </ProgressBarWrapper>
        <StatsWrapper>
            {stats.map(item =>
                <StatsContainer>
                    <StatsTitle>{item.title}</StatsTitle>
                    <StatsValue>{item.value}</StatsValue>
                    <StatsSubTitle>{item.subtitle}</StatsSubTitle>
                </StatsContainer>
            )}
        </StatsWrapper>
    </Wrapper>
}