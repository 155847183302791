import BaseRendererThree from './Base'
import { AmbientLight, DirectionalLight, PointLight, Sprite, SpriteMaterial, TextureLoader } from 'three'
import { TypeEntity, UpdateState } from '../../../../Core/Constants'

const threeObjectByType = {
  "ambient": AmbientLight,
  "point": PointLight,
  "directional": DirectionalLight
}

export default class LightRendererThree extends BaseRendererThree {
  init() {
    this.lightObject = null
    this.lightSprite = null
  }

  destroy() {
    if (!this.lightObject) return
    this.lightObject.dispose()
    this.engineComponent.sceneComponent.scene.remove(this.lightObject)
    if (!this.lightSprite) return;
    this.engineComponent.sceneComponent.scene.remove(this.lightSprite)
  }

  update(flags) {
    if (!this.lightObject) this.createLight(flags)
    this.updatePosition(flags)
    this.updateColor(flags)
    this.updateProperties(flags)
  }

  updateProperties(flags) {
    if (!this.lightObject) return;
    const properties = ["intensity", "castShadow"]
    properties.forEach(property => {
      if (this.item.data[property]?.value !== undefined) {
        this.lightObject[property] = this.item.data[property].value
      }
    });
  }

  createLight(flags) {
    if (!(flags & UpdateState.Shape)) return;
    const lightType = this.item?.data?.type?.value
    if (Object.keys(threeObjectByType).includes(lightType)) {
      const lightObject = new threeObjectByType[lightType]()
      this.lightObject = lightObject
      if (lightType !== 'ambient') {
        // fix shadow artifacts
        this.lightObject.shadow.bias = -0.0004;
      }
      this.engineComponent.sceneComponent.scene.add(this.lightObject)
      this.engineComponent.app.events.trigger("objectItemLoaded", {
        item: this.item,
      });
      if (!this.engineComponent.app.editMode) return; 
      const loader = new TextureLoader();
      loader.loadAsync('https://metaplayerone.mypinata.cloud/ipfs/QmbBsdg5y8xpozeWNnf3gUDssqTUK2qNgnepWTkFhys79d').then((map) => {
        const material = new SpriteMaterial({ map });
        const sprite = new Sprite(material);
        this.lightSprite = sprite;
        this.item.data.size.value.y = 0.5;
        this.item.data.size.value.x = 0.39;
        this.lightSprite._itemMP = this.item;
        this.engineComponent.sceneComponent.scene.add(sprite);
        this.updatePosition(UpdateState.Shape);
      });
    } else {
      console.warn(`Light.js::createLight, light type ${lightType} is not avaliable`)
    }
  }

  updatePosition(flags) {
    if (!(flags & UpdateState.Shape) || !this.lightObject) return;
    this.lightObject.scale.set(this.item.data.size.value.x, this.item.data.size.value.y, this.item.data.size.value.z)
    this.lightObject.rotation.set(this.item.data.rotation.value.x, this.item.data.rotation.value.y, this.item.data.rotation.value.z)
    this.lightObject.position.set(this.item.data.position.value.x, this.item.data.position.value.y, this.item.data.position.value.z)
    if (!this.lightSprite) return;
    this.lightSprite.scale.set(this.item.data.size.value.x, this.item.data.size.value.y, this.item.data.size.value.z)
    this.lightSprite.rotation.set(this.item.data.rotation.value.x, this.item.data.rotation.value.y, this.item.data.rotation.value.z)
    this.lightSprite.position.set(this.item.data.position.value.x, this.item.data.position.value.y, this.item.data.position.value.z)
  }

  updateColor(flags) {
    if (!(flags & UpdateState.Shape) || !this.lightObject) return;
    this.lightObject.color.setRGB(this.item.data.color.value.r, this.item.data.color.value.g, this.item.data.color.value.b)
  }

  extractDataToItem() {
    // no events to update
    this.item.data.size._value.x = this.group.scale.x
    this.item.data.size._value.y = this.group.scale.y
    this.item.data.size._value.z = this.group.scale.z

    this.item.data.position._value.x = this.group.position.x
    this.item.data.position._value.y = this.group.position.y
    this.item.data.position._value.z = this.group.position.z

    this.item.data.rotation._value.x = this.group.rotation.x
    this.item.data.rotation._value.y = this.group.rotation.y
    this.item.data.rotation._value.z = this.group.rotation.z
  }
}