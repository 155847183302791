import styled from 'styled-components';
import { Column } from '../styles';

export const getStyle = (matches) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  overflow: 'hidden',
  minHeight: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: !matches ? '800px' : '98%',
  borderRadius: '20px',
  bgcolor: 'background.paper',
  boxShadow: 4,
  background: '#1b1b1c',
  display: 'flex',
  flexDirection: 'column',
  p: !matches ? 4 : 1,
});

export const NftWrapper = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  flex-wrap: wrap;
  max-height: 380px;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ModalCotnentWrapper = styled(Column)`
  gap: 12px;
  height: 100%;
  flex: 1;
  justify-content: space-between;
`;

export const SubmitButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(99.78deg, #9f0bee 0.69%, #9f0bee 0.69%);
  border-radius: 4px;
  padding: 10px 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  cursor: ${(props) => (props.blocked ? 'auto;' : 'pointer')};
  opacity: ${(props) => (props.blocked ? '0.6' : '1')};
  transition: 0.3s;
  &:hover {
    box-shadow: ${(props) =>
      props.blocked ? 'none' : '0px 10px 20px rgba(86, 54, 247, 0.3)'};
  }
  &:disabled {
    box-shadow: none !important;
    opacity: 0.5;
    cursor: not-allowed;
  }
  @media screen and (max-width: 767px) {
    &:hover {
      box-shadow: none;
    }
  }
`;

export const Close = styled.div`
  position: absolute;
  cursor: pointer;
  text-decoration: underline;
  color: #191919;
  font-size: 18px;
  align-self: flex-end;
  margin-bottom: 10px;
  font-weight: 700;
`;