import { Button } from '@mui/material'
import styled from 'styled-components'
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk'
import { useLocation, useNavigate } from 'react-router-dom'

const ButtonPreview = styled(Button)`
  background-color: transparent !important;
  min-width: auto !important;
  position: absolute !important;
  text-transform: capitalize !important;
  padding: 5px -1px !important;
  bottom: 70px;
  width: 70px;
  right: 24px;
  border: 1px rgb(159, 11, 238) solid !important;
`
export const PreviewBtn = ({
  onSave,
  setEditMode,
  hash,
  setShowRightPanel,
  setShowLeftPanel,
}) => {
  const push = useNavigate()
  return (
    <ButtonPreview
      title={'walk mode'}
      onClick={() => {
        onSave()
        push(`/space/${hash}/preview`)
        push(0)
      }}
      variant="contained"
    >
      <DirectionsWalkIcon sx={{ color: 'white' }} />
    </ButtonPreview>
  )
}
