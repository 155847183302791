import StateIdle from "./StateIdle";
import StateWalk from "./StateWalk";
import StateRun from "./StateRun";
import StateDance from "./StateDance";
import StateJump from "./StateJump";
import StateFall from "./StateFall";

export default class AnimationStateMachine {
    constructor(
        animationMixer,
        idleAction,
        walkAction,
        runAction,
        danceAction,
        jumpAnimation,
        fallAnimation
    ) {
        this.stateIdle = new StateIdle(idleAction);
        this.stateWalk = new StateWalk(walkAction);
        this.stateRun = new StateRun(runAction);
        this.stateDance = new StateDance(danceAction);
        this.stateJump = new StateJump(jumpAnimation);
        this.stateFall = new StateFall(fallAnimation);

        this.state = this.stateIdle;
    }

    transitionTo(newState) {
        if (newState.name === this.state.name) return false;
        const transitionTime = this.state.transitions[newState.name];
        if (!transitionTime) {
            console.warn("Transition to unknown state, reverting");
            return;
        }
        const oldStateAction = this.state.animationAction;
        // newState.play(oldStateAction, transitionTime);
        newState.animationAction.reset().crossFadeFrom(oldStateAction, transitionTime).play();
        this.state = newState;
        return true;
    }

    getId() {
        return this.state.id;
    }
}