import { Button } from 'antd';
import styled from 'styled-components';
import LoaderSpin from '../Loaders/LoaderSpin';

const Options = styled(Button)`
  border-radius: 50px;
  width: 48%;
  height: 40px;
  background: transparent;
  box-shadow: inset 0px 0px 0px 2px #4D44FE;
  padding: 0;
  border: none;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`;


export const LoadMoreButton = ({ isLoading, isLoaded, onClick }) => {
  if (isLoaded) {
    return null;
  }

  return (
    <Options onClick={onClick}>
      {isLoading ? (
        <LoaderSpin fontSize={18} color={'#fff'} padding={3} />
      ) : (
        'Load more'
      )}
    </Options>
  );
};
