import Toolbase from './Base'
import {TypeEntity, InputEventType} from '../Core/Constants'

import {Vector3, MathUtils} from 'three'
//import {TransformControls} from 'three/examples/jsm/controls/TransformControls'
import {TransformControls} from '../Renderer/Interfaces/Three/TransformControls'

export default class ToolTransformer extends Toolbase {
  constructor () {
    super(...arguments)

    this.name = 'Transformer'
    this.selectEnabled = true
  }

  start () {
    this.control = new TransformControls(this.app.engineComponent.sceneComponent.camera, this.app.engineComponent.engine.domElement)
    this.control.addEventListener('change', event => {
      //console.log('change=', event, this.control.object)
      if (this.control.object && this.control.object._itemMP) {
        this.control.object._itemMP.renderer.extractDataToItem()
        //console.log('change position=', this.control.object._itemMP.data.position._value)
      }
    })

    this.control.addEventListener('dragging-changed', event => {
      // console.log('dragging-changed=', event)
      this.app.engineComponent.sceneComponent.setCameraControlState(!event.value)
      this.selectEnabled = !event.value
    })

		// this.app.engineComponent.sceneComponent.scene.add(this.control)
  }

  stop () {
    this.control.detach()
    this.app.engineComponent.sceneComponent.scene.remove(this.control)
    this.control.dispose()
    this.control = null
  }

  onPointerEvent (event) {
    const leftButton = event.buttons & 1
    if (event.type === InputEventType.move) {
      this.app.hoverer.update(event)
    } else if (event.type === InputEventType.up) {
      if (this.dataClick && this.dataClick.x === event.clientX && this.dataClick.y === event.clientY) {
        if (this.app.hoverer.item && this.app.editMode) {
          //if (!this.control.object) {
          if (this.selectEnabled) {
            console.log('start transform=', this.app.hoverer.item)
            // this.control.attach(this.app.hoverer.item.renderer.group.children[1])
            this.control.attach(this.app.hoverer.item.renderer.group)
            this.app.engineComponent.sceneComponent.scene.add(this.control)
          }
        } else {
          this.control.detach()
          this.app.engineComponent.sceneComponent.scene.remove(this.control)
        }
      }

      this.dataClick = null
    } else if (event.type === InputEventType.down && leftButton) {
      this.dataClick = {x: event.clientX, y: event.clientY}
      //this.app.engineComponent.sceneComponent.setCameraControlState(false)
    }

    super.onPointerEvent(event)
  }

  onKeyEvent (event) {
    // console.log(this.constructor.name + '.onKeyEvent=', event, this.app.selector)

    if (this.app.editMode && event.type === 'keydown') {
      //console.log('event=', event)
      switch (event.keyCode/*event.code*/) {
        case 81: // Q
          this.control.setSpace(this.control.space === 'local' ? 'world' : 'local')
          break

        case 16: // Shift
          /*this.control.setTranslationSnap(100)
          this.control.setRotationSnap(MathUtils.degToRad(15))
          this.control.setScaleSnap(0.25)*/
          this.control.setTranslationSnap(0.1)
          this.control.setRotationSnap(MathUtils.degToRad(5))
          this.control.setScaleSnap(0.1)
          break

        case 84/*87*//*'KeyT'*/: // T W
          this.control.setMode('translate')
          break

        case 69: // E
          this.control.setMode('rotate')
          break

        case 82: // R
          this.control.setMode('scale')
          break

        /*case 67: // C
          const position = currentCamera.position.clone()

          currentCamera = currentCamera.isPerspectiveCamera ? cameraOrtho : cameraPersp
          currentCamera.position.copy(position)

          orbit.object = currentCamera
          this.control.camera = currentCamera

          currentCamera.lookAt(orbit.target.x, orbit.target.y, orbit.target.z)
          onWindowResize()
          break*/

        /*case 86: // V
          const randomFoV = Math.random() + 0.1
          const randomZoom = Math.random() + 0.1

          cameraPersp.fov = randomFoV * 160
          cameraOrtho.bottom = -randomFoV * 500
          cameraOrtho.top = randomFoV * 500

          cameraPersp.zoom = randomZoom * 5
          cameraOrtho.zoom = randomZoom * 5
          onWindowResize()
          break*/

        case 187:
        case 107: // +, =, num+
          this.control.setSize(this.control.size + 0.1)
          break

        case 189:
        case 109: // -, _, num-
          this.control.setSize(Math.max(this.control.size - 0.1, 0.1))
          break

        case 88: // X
          this.control.showX = !this.control.showX
          break

        case 89: // Y
          this.control.showY = !this.control.showY
          break

        case 90: // Z
          this.control.showZ = !this.control.showZ
          break

        /*case 32: // Spacebar
          this.control.enabled = !this.control.enabled
          break*/

        /*case 27: // Esc
          this.control.reset()
          break*/
      }
    }

    if (this.app.editMode && event.type === 'keyup') {
      switch (event.keyCode) {
        case 16: // Shift
          this.control.setTranslationSnap(null)
          this.control.setRotationSnap(null)
          this.control.setScaleSnap(null)
          break
      }
    }

    super.onKeyEvent(event)
  }
}