import styled from 'styled-components';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { Button } from 'antd';

export const CustomTab = styled(Tab)`
  font-size: 14px !important;
  color: black !important;
  text-transform: capitalize !important;
  padding: 8px !important;
  box-sizing: border-box !important;
  min-height: auto !important;
  margin: 8px !important;
  justify-content: space-between !important;
  background: #eee !important;
  border-radius: 7px !important;

  svg {
    margin: 0 !important;
  }
  @media (max-width: 400px) {
    font-size: 12px !important;
    min-width: auto !important;
  }
`;
export const EventsItem = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  gap: 0.5em;
`;

export const CustomTabContext = styled(TabContext)``;
export const CustomTabList = styled(TabList)`
  padding: 24px 24px 0 24px !important;
  span {
    display: none !important;
  }
  .MuiTab-root {
    background: #000 !important;
    color: #fff !important;
  }
  .Mui-selected {
    background: linear-gradient(99.78deg, rgb(159, 11, 238) 0.69%, rgb(20, 91, 253) 98.32%) !important;
    border-radius: 7px;
    color: #fff !important;
  }
  @media (max-width: 600px) {
    padding: 16px 16px 0 16px !important;
  }
`;
export const AspectButton = styled.div`
  background: #000;
  color: #fff;
  border: 2px solid rgb(159, 11, 238);
  border-radius: 6px;
  height: ${(props) => `${props.h}px`};
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  width: ${(props) => `${props.w}px`};
  &:hover {
    border: 2px solid #ab9e9e;
  }
`;
export const StackGrid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const CenteredText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: #fff;
`;
export const AspectSpan = styled.span`
  text-align: center;
  display: block;
  color: #353535;
  margin-top: 10px;
`;
export const StackBlock = styled.div`
  margin: 20px;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
`;
export const StackColumn = styled.div`
  position: relative;
  cursor: pointer;
  color: #fff;
  span {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 13px;
    transform: translate(-50%, -50%);
  }
`;
export const TitleFrame = styled.div`
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px;
`;
export const ContainerFlexStack = styled.div`
  margin-bottom: 20px;
`;
export const ItemID = styled.div`
  color: #a4a4a4;
`;
export const ItemName = styled.div`
  font-size: 14px;
  color: #353535;
  font-weight: 600;
  vertical-align: middle;
  cursor: pointer;
  &:hover {
    color: #ccc;
  }
  span {
    color: #a4a4a4;
    font-size: 12px;
    margin-right: 15px;
  }
`;
export const ItemAspect = styled.div`
  color: #000000;
  min-width: 40px;
  letter-spacing: 0.1em;
  text-align: center;
  border-radius: 4px;
  border: 1px rgb(159, 11, 238) solid;
  padding: 4px;
`;
export const ListItem = styled.div`
  display: flex;
  padding: 6px;
  justify-content: space-between;
  align-items: center;
`;
const Scrollbar = styled.div`
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: #161616;
    border-radius: 10px;
    -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
  }
  &::-webkit-scrollbar-thumb {
    background: #7E8390;
    border-radius: 10px;
  }
`;
export const ExplainData = styled.h2`
  display: block;
  margin: 0 auto;
  font-size: 16px;
  line-height: 14px;
`;

export const ListNFTS = styled(Scrollbar)`
  overflow-y: auto;
  margin-top: 10px;
  width: 100%;
  min-height: 10px;
  max-height: 230px;
`;
export const ArtworkList = styled(Scrollbar)`
  min-height: 400px;
  max-height: 460px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const ArtWorkItem = styled.div`
  display: flex;
  gap: 20px;
  padding: 20px;
  background: #161616;
  border-radius: 6px;
  ${({ isSelected }) => isSelected ? 'box-shadow: 0px 0px 0px 1px white inset;' : ''}

  &:hover {
    box-shadow: 0px 0px 0px 1px white inset;
  }
`;
export const ArtType = styled.div`
  border: 1px solid #01A7FF;
  color: #fff;
  margin-top: 2px;
  border-radius: 20px;
  font-size: 12px;
  padding: 2px 5px;
`;
export const ArtSaved = styled(ArtType)`
  border: 1px solid green;
`
export const ArtApproved = styled(ArtType)`
  border: 1px solid ${(props) => props?.approved ? '#01A7FF' : '#4D44FE'};
`
export const ArtistName = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  color: #7E8390;
`;
export const ArtModalTitle = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: #fff;
`;
export const ArtInfoTitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  font-weight: 500;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  @media (max-width: 400px) {
    font-size: 12px;
    line-height: 18px;
  }
  span {
    font-style: italic;
    background: #f8f8f8;
    border-radius: 2px;
    padding: 4px;
  }
`;
export const ArtInfo = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  width: 80%;
  cursor: pointer;
`;
export const ArtIMG = styled.img`
  width: 100px;
  height: 70px;
  border-radius: 2px;
  object-fit: cover;
  cursor: pointer;
`;
export const ArtVID = styled.video`
  width: 100px;
  height: 70px;
  border-radius: 2px;
  object-fit: cover;
`;
export const OptionsButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
export const OptionsButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border: none;
  // width: 32px;
  padding: 4px;
  height: ${({ height }) => height || '32px'};
  color: #fff;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.09);
`;
export const OptionsContainer = styled.div`
  position: absolute;
  width: 200px;
  height 180px;
`;
export const OptionsCard = styled.div`
  width: 100%;
  height: 20px;
  color: white;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`
export const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
`;
export const PublishButton = styled(Button)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;

    width: 94px;
    height: 42px;

    background: #6C43E0;
    border-radius: 4px;
    border: none;

    color: #F0F0F0;

    :hover {
      cursor: pointer;
      box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);  
    }
`
export const PublishButtonHull = styled(Button)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;

    width: 94px;
    height: 42px;

    border-radius: 4px;
    border: 2px solid ${({ disabled }) => disabled ? '#969696' : '#6C43E0'};
    box-sizing: border-box;

    color: #F0F0F0;
    background: transparent;
    cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};

    :hover {
      box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);  
    }
`;