import { Checkbox, TextField, useMediaQuery } from "@mui/material"
import LoaderSpin from "../../../../../Loaders/LoaderSpin"
import { PreLoader } from "../../../../../Loaders/PreLoader"
import { ViewContext } from "./context"
import {
    ButtonWrapper,
    CheckBoxContainer,
    Column,
    ColumnReverse,
    ControlGroup,
    Loader,
    TitleFrame,
    UploadButton,
    UploadContainer,
    UploadFile,
    UploadFileInput,
    disabledStyle,
} from "./styles"
import { useEffect } from "react"
import { useState } from "react"
import { BorederedButton, ButtonPublish } from "../styles"
import { pinFileToIPFS } from "../../../../../../helpers/pinata"
import { dataURLtoFile } from "../../../../../../helpers/dataURLtoFile"
import { CoordinateInputWide } from "../../../../../Input/CoordinateInputWide"
import Moralis from '../../../../../../../../const/moralis';
import { useContext } from "react"
import { FlexibleModalContext } from "../../../../../../contexts/flexible-modal/context"
import ObjectsList3D from "../../index"
import { EditorSnackbarContext } from "../../../../../../contexts/editor-snackbar"

export const AddOBJ = () => {
    const [img, setImg] = useState();
    const [file, setFile] = useState();
    const [name, setName] = useState();
    const [isValid, setValid] = useState(false);
    const [choose, setChoose] = useState(false);
    const [isPublic, setIsPublic] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const { setFlexibleModalChildren } = useContext(FlexibleModalContext);
    const { triggerSnackbar } = useContext(EditorSnackbarContext);

    const closeAndClean = () => {
        triggerSnackbar('File uploaded', 'success');
        setFile(null);
        setImg(null);
        setFlexibleModalChildren(<ObjectsList3D tab='2' />);
    }
    const checkIsValid = () => {
        return name && file
    }
    useEffect(() => {
        setValid(checkIsValid())
    }, [file, name])

    useEffect(() => {
        if (img) {
            AddOBJ3D();
            setInProgress(true);
        }
    }, [img])

    useEffect(() => {
        if (file) setName(file.name)
    }, [file])

    const AddOBJ3D = async () => {
        if (!isValid || inProgress) {
            return
        }
        const { pinataUrl: imageUrl } = await pinFileToIPFS(dataURLtoFile(img, `${name}.png`));
        const { pinataUrl: objectUrl } = await pinFileToIPFS(file);
        console.log(imageUrl, objectUrl);

        await Moralis.Cloud.run('meta-3d-upload', {
            object_url: objectUrl,
            image_url: imageUrl,
            name,
            is_public: isPublic
        });

        closeAndClean();
    }

    return <>
        {/* {choose && (
            <Loader matches>
                <LoaderSpin />
            </Loader>
        )} */}
        <ViewContext.Provider value={{ img, setImg, choose, setChoose }}>
            <Column>
                <ColumnReverse>
                    <ControlGroup>
                        <UploadContainer file={!file}>
                            <UploadFile hasImage={!file} style={file ? disabledStyle : {}}>
                                <UploadFileInput accept={'.glb,.gltf'} type="file" onChange={e => setFile(e.target.files[0])} id="fileAvatar" />
                                <BorederedButton>{!file ? 'Upload' : 'Replace'}</BorederedButton>
                            </UploadFile>
                        </UploadContainer>
                    </ControlGroup>
                    {file && <PreLoader file={file} setFile={setFile} />}
                </ColumnReverse>
            </Column>
        </ViewContext.Provider>

        <TitleFrame>Name</TitleFrame>
        <CoordinateInputWide
            value={name}
            handleChange={e => {
                setName(e.target.value)
            }}
            placeholder="Enter the name of the 3D object"
        />
        <ButtonWrapper>
            <CheckBoxContainer>
                Make public
                <Checkbox
                    onChange={() => setIsPublic(!isPublic)}
                    checked={isPublic}
                    sx={{
                        color: '#838383',
                        '&.Mui-checked': {
                            color: '#6C43E0',
                        },
                    }}
                />
            </CheckBoxContainer>
            <ButtonPublish
                disabled={!isValid || inProgress}
                line={'true'}
                onClick={() => setChoose(true)}
            >
                {inProgress ? "Saving..." : "Save"}
            </ButtonPublish>
        </ButtonWrapper>
    </>
}