import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom';
import {
    ArtworkList,
    ArtModalTitle,
    CenteredContainer,
} from '../styles'
import { useEditor } from '../../../store/editor-context'
import { ArtworkMagicItem } from './ArtworkMagicItem'
import { MyNftsProvider, useMyNfts } from '../../../contexts/submit-token';
import { useFetchMyNfts } from '../../../contexts/submit-token/fetch';
import { LoadMoreButton } from '../../Buttons/LoadMoreButton';
import { NftCardBig, NftCardBigSuccess } from '../../Cards';
import abi from '../../../../../const/abis/Space.json';
import Moralis from '../../../../../const/moralis';
import styled from 'styled-components';
import useAuth from '../../../../../helpers/auth';
import CONFIG from '../../../../../config';
import erc721approve from '../../../../../helpers/approve/erc721approve';
import erc1155approve from '../../../../../helpers/approve/erc1155approve';
import { $host } from '../../../../api/base';
import { Context } from '../../../store/Context';
import { EditorSnackbarContext } from '../../../contexts/editor-snackbar';
import { FlexibleModalContext } from '../../../contexts/flexible-modal/context';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;  
  width: 100%;
  height: 100%;
`;
export const Title = styled.div`
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 5px;
`;

const SubmitNFT = ({ selectedItem, setSelectedNFT }) => {
    const { web3, user } = useAuth();
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const { address, uid } = useParams();
    const { editorStore } = useContext(Context);
    const { triggerSnackbar } = useContext(EditorSnackbarContext);
    const { setSpace } = useEditor();
    const { setFlexibleModalChildren } = useContext(FlexibleModalContext);

    function timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const onSubmit = async (price) => {
        try {
            setSubmitSuccess(false);
            const contract = new web3.eth.Contract(abi, CONFIG.REACT_APP_METASPACE_CONTROLLER_ADDRESS);
            console.log(
                uid,
                selectedItem.item.token_address,
                selectedItem.item.token_id,
                1,
                selectedItem.item.contract_type === 'ERC721' ? 0 : 1,
                web3.utils.toWei(price)
            );
            if (selectedItem.item.contract_type === 'ERC721') {
                await erc721approve(
                    selectedItem.item.token_id,
                    selectedItem.item.token_address,
                    CONFIG.REACT_APP_METASPACE_CONTROLLER_ADDRESS,
                    user.get('ethAddress'),
                    web3
                );
            } else {
                await erc1155approve(
                    selectedItem.item.token_address,
                    CONFIG.REACT_APP_METASPACE_CONTROLLER_ADDRESS,
                    user.get('ethAddress'),
                    web3
                );
            }
            await contract.methods
                .submitToken(
                    uid,
                    selectedItem.item.token_address,
                    selectedItem.item.token_id,
                    1,
                    selectedItem.item.contract_type === 'ERC721' ? 0 : 1,
                    web3.utils.toWei(price)
                )
                .call({ from: user.get('ethAddress') });
            await contract.methods
                .submitToken(
                    uid,
                    selectedItem.item.token_address,
                    selectedItem.item.token_id,
                    1,
                    selectedItem.item.contract_type === 'ERC721' ? 0 : 1,
                    web3.utils.toWei(price)
                )
                .send({ from: user.get('ethAddress') });

            if (selectedItem.item?.objectId) {
                await Moralis.Cloud.run('meta-remove-nft', { objectId: selectedItem.item?.objectId, space_uid: uid });
            }

            await timeout(1000);
            const { data } = await $host.get(`/space/${address}&${uid}`);
            await editorStore.editor.managerNFT.init(data?.all_tokens);

            const submitted_token = data?.all_tokens?.filter(el => el?.item?.submitted)?.at(-1);
            const submitted_token_uid = submitted_token?.item?.uid;

            await contract.methods
                .approvedToken(submitted_token_uid)
                .call({ from: user.get('ethAddress') });
            await contract.methods
                .approvedToken(submitted_token_uid)
                .send({ from: user.get('ethAddress') });

            await timeout(1000);
            const { data: dataApproved } = await $host.get(`/space/${address}&${uid}`);
            await editorStore.editor.managerNFT.init(dataApproved?.all_tokens);

            setSpace(data);
            selectedItem.imageItemClick(null, submitted_token);
            triggerSnackbar('Token submitted successfully');
            setFlexibleModalChildren(null);
        } catch (e) {
            triggerSnackbar(`Token submit failed ${e.message}`, 'error');
            console.error(e);
        }
    };

    return <Wrapper>
        <Title>
            Submit NFT
        </Title>
        {submitSuccess ? <NftCardBigSuccess onBackClick={() => setSelectedNFT(null)} /> :
            <NftCardBig
                nft={selectedItem}
                onBackClick={() => setSelectedNFT(null)}
                onSubmitClick={onSubmit}
            />
        }
    </Wrapper>
}

const TabMagicNFTChild = () => {
    const { space, isLoading } = useEditor();
    const [selectedItem, setSelectedNFT] = useState(null);
    const { list, isLoading: NFTsLoading, isLoaded, setPage } = useMyNfts();
    useFetchMyNfts();

    const NFTs = [...(space?.all_tokens || []), ...list];

    if (selectedItem) return <SubmitNFT
        selectedItem={selectedItem}
        setSelectedNFT={setSelectedNFT}
    />

    return <>
        <ArtModalTitle>
            Select NFT
        </ArtModalTitle>
        <ArtworkList>
            {NFTsLoading && !NFTs.length ? (
                <CenteredContainer>
                    Loading...
                </CenteredContainer>
            ) : (
                NFTs?.map((token, index) =>
                    <ArtworkMagicItem setSelectedNFT={setSelectedNFT} token={token} key={index} />
                )
            )}
        </ArtworkList>
        <CenteredContainer>
            <LoadMoreButton
                isLoaded={isLoaded}
                isLoading={NFTsLoading}
                onClick={setPage.bind(null)}
            />
        </CenteredContainer>
    </>;
}

export default function TabMagicNFT() {
    return (
        <MyNftsProvider>
            <TabMagicNFTChild />
        </MyNftsProvider>
    )
}
