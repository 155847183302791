import { Euler, Quaternion, Vector3 } from "three";
import FPSControls from "./FPSControls";
import { EventDispatcher } from "EventDispatcher";
import AnimationStateMachine from "../../StateMachine/AnimationStateMachine";
import { CustomOrbitControls } from "./CustomOrbitControls";

export default class ThirdPersonControls extends FPSControls {

    constructor(camera, domElement, app) {
        super();

        this.camera = camera;
        this.domElement = domElement;
        this.app = app;

        this.events = new EventDispatcher();

        this.walkSpeed = 1.7;
        this.runSpeed = 3;
        this.running = false;
        this.rotationSpeed = 10;

        this.cameraTarget = new Vector3(0, 1, 0);
        this.velocity = new Vector3(0, 0, 0);

        this.onKeyDown = event => {
            switch (event.code) {
                case 'ArrowUp':
                case 'KeyW':
                    this.moveForward = true
                    break

                case 'ArrowLeft':
                case 'KeyA':
                    this.moveLeft = true
                    break

                case 'ArrowDown':
                case 'KeyS':
                    this.moveBackward = true
                    break

                case 'ArrowRight':
                case 'KeyD':
                    this.moveRight = true
                    break

                case 'ShiftLeft':
                    this.running = true;
                    break

                case 'KeyG':
                    this.dancing = !this.dancing;
                    break

                default:
                    break
            }
        }

        this.onKeyUp = event => {
            switch (event.code) {
                case 'ArrowUp':
                case 'KeyW':
                    this.moveForward = false
                    break

                case 'ArrowLeft':
                case 'KeyA':
                    this.moveLeft = false
                    break

                case 'ArrowDown':
                case 'KeyS':
                    this.moveBackward = false
                    break

                case 'ArrowRight':
                case 'KeyD':
                    this.moveRight = false
                    break

                case 'ShiftLeft':
                    this.running = false;
                    break

                default:
                    break
            }
        }
    }

    init(camera) {
        this.orbitControls = new CustomOrbitControls(camera, this.domElement);
        this.orbitControls.enablePan = false;
        this.orbitControls.maxDistance = 15;
        this.orbitControls.maxPolarAngle = Math.PI / 2 + 0.3;
        this.orbitControls.update();

        this.app.socket.setBodyVisible(true);
        this.stateMachine = new AnimationStateMachine(
            this.app.socket.userBodyAnimationController.idleAnimationAction,
            this.app.socket.userBodyAnimationController.walkAnimationAction,
            this.app.socket.userBodyAnimationController.runAnimationAction,
            this.app.socket.userBodyAnimationController.danceAnimationAction,
        );

        this.transitionCamera = camera;
        const cameraPoisitonWithoutHeightOffest = this.transitionCamera.position.sub(
            new Vector3(0, this.cameraInitHeight, 0)
        )
        this.target_object3d.position.copy(cameraPoisitonWithoutHeightOffest);

        this.updateCameraTarget(0, 0);
        document.addEventListener('keydown', this.onKeyDown);
        document.addEventListener('keyup', this.onKeyUp);
    }

    updateCameraTarget(moveX, moveZ) {
        this.camera.position.x += moveX
        this.camera.position.z += moveZ

        this.cameraTarget.x = this.target_object3d.position.x
        this.cameraTarget.y = this.target_object3d.position.y + 1.63
        this.cameraTarget.z = this.target_object3d.position.z
        this.orbitControls.target = this.cameraTarget
    }

    dispose() {
        this.orbitControls.dispose()
        delete this.orbitControls;
        this.events.trigger("transitionToFPComplete");
        this.app.socket.setBodyVisible(false);
        document.removeEventListener('keydown', this.onKeyDown);
        document.removeEventListener('keyup', this.onKeyUp);
    }

    setTarget(target) {
        this.target = target;
        this.target.data.position.value.y = this.cameraInitHeight;
        this.target_object3d = this.target.renderer.meshCollider.parent;
        console.log("setting target,", target);
    }

    dispatchSocketEvents() {

        const movePlayerEvent = new CustomEvent("movePlayer", {
            detail: {
                clientX: this.target_object3d.position.x,
                clientY: this.target_object3d.position.y,
                clientZ: this.target_object3d.position.z,
                quaternion: {
                    y: this.target_object3d.quaternion._y,
                    w: this.target_object3d.quaternion._w,
                },
                state: this.stateMachine.state.name
            }
        });
        document.dispatchEvent(movePlayerEvent);

    }

    directionOffset(keysPressed) {
        let directionOffset = 0

        if (keysPressed[0]) {
            if (keysPressed[1]) {
                directionOffset = Math.PI / 4 // w+a
            } else if (keysPressed[3]) {
                directionOffset = - Math.PI / 4 // w+d
            }
        } else if (keysPressed[2]) {
            if (keysPressed[1]) {
                directionOffset = Math.PI / 4 + Math.PI / 2 // s+a
            } else if (keysPressed[3]) {
                directionOffset = -Math.PI / 4 - Math.PI / 2 // s+d
            } else {
                directionOffset = Math.PI // s
            }
        } else if (keysPressed[1]) {
            directionOffset = Math.PI / 2 // a
        } else if (keysPressed[3]) {
            directionOffset = - Math.PI / 2 // d
        }

        return directionOffset + Math.PI;
    }

    update(dt) {
        if (!this.target || !this.orbitControls) {
            return;
        };

        this.orbitControls.update()
        const moveEvent = this.moveRight || this.moveLeft || this.moveForward || this.moveBackward;

        let posAdjusted = new Vector3().copy(this.target_object3d.position);
        posAdjusted.y += 1.63;
        posAdjusted = this.applyGravity3P(this.velocity, posAdjusted, dt);
        this.target_object3d.position.copy(posAdjusted);
        posAdjusted.y += 1.63;

        if (moveEvent) {
            this.stateMachine.transitionTo(this.running ?
                this.stateMachine.stateRun :
                this.stateMachine.stateWalk
            );

            const angle = Math.atan2(
                (this.camera.position.x - this.target_object3d.position.x),
                (this.camera.position.z - this.target_object3d.position.z)
            )
            const directionOffset = this.directionOffset([this.moveForward, this.moveLeft, this.moveBackward, this.moveRight])
            const rotationQ = new Quaternion().setFromAxisAngle(new Vector3(0, 1, 0), angle + directionOffset)
            this.target_object3d.quaternion.rotateTowards(rotationQ, this.rotationSpeed * dt);

            const speed = this.running ? this.runSpeed : this.walkSpeed;
            const velocityVector = new Vector3(0, 0, speed);
            velocityVector.applyQuaternion(this.target_object3d.quaternion);
            velocityVector.multiplyScalar(dt);

            this.updatePosition3P(velocityVector, posAdjusted);
            this.target_object3d.position.add(velocityVector);

            this.updateCameraTarget(velocityVector.x, velocityVector.z)

            this.dispatchSocketEvents();
        } else {
            this.stateMachine.transitionTo(
                this.dancing ? this.stateMachine.stateDance : this.stateMachine.stateIdle
            );
        }
    }

    setCameraPosition(newPosition) {
        if (newPosition?.y !== undefined) newPosition.y += this.cameraInitHeight;
        if (newPosition?.prototype?.isVector3) {
            this.camera.position.copy(newPosition);
        } else {
            if (!newPosition.x && !newPosition.y && !newPosition.z) return;
            this.camera.position.copy(new Vector3(newPosition.x, newPosition.y, newPosition.z));
        }
    }

}
