import Loader from '../Loaders/Loader'
import Logo from '../../assets/images/logo-white.svg'
import { LoaderProgress } from '../Loaders/LoaderProgress'
export const ProgressScreen = (props) => {
  return (
    <div
      id="progress-screen"
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#000',
        backgroundImage: props?.background ? `url(${props?.background}` : undefined,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        zIndex: 10
      }}
    >
      <div
        id="progress-screen-info"
        style={{
          width: '100%',
          height: '100%',
          zIndex: 11,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#fff',
          textAlign: 'center',
          fontSize: '14px',
          cursor: 'pointer',
          textShadow: '0px 0px 6px rgba(0,0,0,0.3)'
        }}
      >
        {/* <Loader color="#fff" logo={Logo} /> */}
        <LoaderProgress />
      </div>
    </div>
  )
}
