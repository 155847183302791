import multipleTokenAbi from '../../const/abis/standards/ERC1155.json'

const erc1155approve = async (
  token_contract_address,
  sale_contract_address,
  user_eth_address,
  web3,
) => {
  const contract = new web3.eth.Contract(
    multipleTokenAbi,
    token_contract_address,
  )
  const approvedAddress = await contract.methods
    .isApprovedForAll(user_eth_address, sale_contract_address)
    .call()
  if (!approvedAddress) {
    await contract.methods
      .setApprovalForAll(sale_contract_address, true)
      .call({ from: user_eth_address })
    await contract.methods
      .setApprovalForAll(sale_contract_address, true)
      .send({ from: user_eth_address })
  }
}

export default erc1155approve
