import React, { useEffect, useState, useContext } from 'react'

import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation'
import OpenWithIcon from '@mui/icons-material/OpenWith'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import Filter3Icon from '@mui/icons-material/Filter3'
import FitScreenIcon from '@mui/icons-material/FitScreen'

import PropertyVector3 from '../../Properties/PropertyVector3'
import PropertySlider from '../../Properties/PropertySlider'
import PropertyName from '../../Properties/PropertyName'

import { TypeEntity } from '../../../../editor/Core/Constants'
import { CustomList, ListItemOptions, Text, Property } from '../../styles'
import { CustomBlackBtn } from '../../Buttons/CustomBlackBtn'
import { IconButton } from '@mui/material'
import IconClose from '../../../assets/icons/IconClose'
import { CustomBlackChoose } from '../../Buttons/CustomButtonChoose'
import { Context } from '../../../store/Context'
import PropertyBool from '../../Properties/PropertyBool'
import PropertyColor from '../../Properties/PropertyColor'
import {
  ButtonWrapper,
  CustomButtonContainer,
  CustomButtonSticky,
  FlexEnd,
  FlexJustifySpaceBetween,
  NameContainer,
  PropertyContainer,
  PropertyLabel,
  PublishButton,
  PublishButtonHull,
  Separator
} from './styles'
import { ImageProperties } from './ImageProperties'
import { CodeEdit } from '../../Modals/CodeEdit'
import { MetaProducts } from '../../Modals/MP1Products'

export default function PropertiesPanel(props) {
  const [isActive, setActive] = useState(false)
  const { editorStore } = useContext(Context)
  const [tools, setTools] = useState([])
  const [snappingSelected, setSnappingSelected] = useState('off');

  const snappingProperties = ['grid', 'off']
  const positionProperties = ['position', 'rotation', 'size', 'color']

  useEffect(() => {
    const toolsNew = []
    editorStore.editor.toolManager.tools.forEach(tool => {
      toolsNew.push({ name: tool.name })
    })
    setTools(toolsNew)
  }, [])

  const handleItemClick = (event, itemTool) => { }
  /*//const [currentItemData, setCurrentItemData] = useState(null)
  useEffect(() => {
    //console.log('PropertiesPanel props=', props, 'currentItemData=', props.currentItemData)
    //setCurrentItemData(props.currentItemData)
  }, [props.currentItemData])*/

  /*useEffect(() => {
    console.log('PropertiesPanel props=', props, 'currentItemData=', props.currentItemData)
  }, [props.currentItemData])*/

  /*useEffect(() => {
    console.log('PropertiesPanel props=', props, 'currentItemData=', props.currentItemData)
  }, [props.currentItemData.properties.position._value.x])*/ // do not want to watch for changes

  // useEffect(() => {console.log('PropertiesPanel props=', props, 'currentItemData=', props.currentItemData)}, [])

  // const handlePositionUpdate = (e) => {
  //   // e.preventDefault();
  //   console.log(e.position);
  // }

  // useEffect(() => {
  //   document.addEventListener("positionUpdated", (e) => handlePositionUpdate(e));
  // })

  const propertyByType = propertyName => {
    const property = props.currentItemData.properties[propertyName]

    switch (propertyName) {
      case 'color':
        return <PropertyColor property={property} />
      case 'rotation':
      case 'position':
      case 'size':
        return <PropertyVector3 property={property} />
      // case 'scale':
      //   return <PropertySlider property={property} />
      case 'name':
        return <PropertyName property={property} item={props.currentItemData} />
      default:
        return <PropertySlider property={property} />
    }
  }

  const chooseTransformer = () => {
    setActive(!isActive)
    if (isActive) {
      const toolKey = tools[0].name
      const tool = editorStore.editor.toolManager.tools.find(tool => tool.name === toolKey)
      console.log('select tool=', toolKey, tool)
      if (tool) editorStore.editor.toolManager.activeTool = tool
      else console.warn('tool not found=', toolKey)
    } else {
      const toolKey = tools[1].name
      const tool = editorStore.editor.toolManager.tools.find(tool => tool.name === toolKey)
      console.log('select tool=', toolKey, tool)
      if (tool) editorStore.editor.toolManager.activeTool = tool
      else console.warn('tool not found=', toolKey)
    }
  }
  const iconByType = propertyName => {
    const property = props.currentItemData.properties[propertyName]

    console.log(propertyName)
    switch (propertyName) {
      case 'rotation':
        return <ThreeDRotationIcon />
      case 'position':
        return <OpenWithIcon />
      case 'size':
        return <FitScreenIcon />
      case 'scale':
        return <ZoomInIcon />
      default:
        return <Filter3Icon />
    }
    // switch (property.type) {
    //   case 'string':
    //     return <BorderColorIcon />
    //   case 'bool':
    //     return <DownloadDoneIcon />
    // }
    // return <DeviceUnknownIcon />
  }

  const handleSelectImage = event => {
    props.openSelector()
    props.setLeftTabIndex(event, '2')
  }

  const onDelete = () => {
    const toolArrow = editorStore.editor.toolManager.tools.find(tool => tool.name === "Pointer");
    if (toolArrow?.onDeleteItem) toolArrow.onDeleteItem();
  }

  const imageListButton = () =>
    props.currentItemData &&
    props.currentItemData.type === TypeEntity.Picture && (
      <ListItem sx={{ justifyContent: 'space-between' }} key={'Select image'}>
        <CustomBlackBtn onClick={handleSelectImage}>Select image</CustomBlackBtn>
        <CustomBlackChoose isActive={isActive} onClick={chooseTransformer}>
          Transform
        </CustomBlackChoose>
      </ListItem>
    )

  return (
    <CustomList disablePadding sx={{ display: "flex", flexDirection: "column" }}>
      <NameContainer>
        {props.currentItemData?.properties['name'] && propertyByType('name')}
      </NameContainer>

      {props.currentItemData &&
        Object.keys(props.currentItemData.properties).map(
          propertyName =>
            props.currentItemData.properties[propertyName].visible &&
            positionProperties.includes(propertyName) && (
              <ListItemOptions key={propertyName}>
                <Text>{propertyName[0].toUpperCase() + propertyName.slice(1)}</Text>
                {propertyByType(propertyName)}
              </ListItemOptions>
            )
        )}
      {/* {imageListButton()} */}
      <Separator />
      {/* <PropertyContainer>
        <PropertyLabel>Snapping Settings</PropertyLabel>
        <ListItemOptions>
          <Text>Snapping</Text>
          <FlexJustifySpaceBetween style={{ paddingLeft: "12px" }}>
            {snappingProperties.map(item => {
              const text = item[0].toUpperCase() + item.slice(1);
              return <CustomButtonSticky
                key={item}
                text={text}
                active={snappingSelected === item}
                onClick={(item) => setSnappingSelected(item)}
              />
            })}
          </FlexJustifySpaceBetween>
        </ListItemOptions>
      </PropertyContainer>
      <Separator /> */}
      <PropertyContainer>
        <PropertyLabel>Shape</PropertyLabel>
        {props.currentItemData.properties['scale'] &&
          <ListItemOptions>
            <Text>Scale</Text>
            <PropertySlider property={props.currentItemData.properties['scale']} propertyName="xyz" />
          </ListItemOptions>}
        {props.currentItemData.properties['intensity'] &&
          <ListItemOptions>
            <Text>Intensity</Text>
            <PropertySlider property={props.currentItemData.properties['intensity']} propertyName={null} />
          </ListItemOptions>}
        {props.currentItemData.properties['castShadow'] && <ListItemOptions>
          <Text>Cast Shadows</Text>
          <PropertyBool property={props.currentItemData.properties['castShadow']} propertyName={null} />
        </ListItemOptions>}
        {/* {props.currentItemData.properties['color'] && <ListItemOptions>
          <Text>Color</Text>
          <PropertyColor property={props.currentItemData.properties['color']} />
        </ListItemOptions>} */}
      </PropertyContainer>
      <Separator />
      <PropertyContainer>
        {props.currentItemData.properties['image'] &&
          <ImageProperties
            currentItemData={props.currentItemData}
            setModalChildren={props.setModalChildren}
          />
        }
        {
          props.currentItemData.properties['script'] && <ListItemOptions>
            <Text>Script</Text>
            <CustomButtonContainer
              onClick={() => props.setModalChildren(<CodeEdit item={props.currentItemData} />)}
            >
              Edit
            </CustomButtonContainer>
          </ListItemOptions>
        }
        {
          props.currentItemData.properties['attachedProduct'] && <ListItemOptions>
            <Text>Web3 products</Text>
          <CustomButtonContainer
              onClick={() => props.setModalChildren(<MetaProducts item={props.currentItemData} />)}
            >
              Edit
            </CustomButtonContainer>
          </ListItemOptions>
        }
      </PropertyContainer>
      <FlexEnd>
        <ButtonWrapper>
          {editorStore?.editor?.project?.baseSpaceName !== props?.currentItemData?.properties?.name?.value ?
            <PublishButtonHull onClick={onDelete}>Delete</PublishButtonHull>
            : null
          }
          <PublishButton onClick={() => props.onSetShowRightPanel(false)}>Done</PublishButton>
        </ButtonWrapper>
      </FlexEnd>
    </CustomList>
  )
}
