import CONFIG from '../../config';
import Moralis from '../../const/moralis';
import { io } from "socket.io-client";
import { EventDispatcher } from 'EventDispatcher'

const uuidv4 = () => {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export default class Soika {
    constructor({ app }) {
        this.app = app;
        this.host = CONFIG.REACT_APP_SOIKA_WS_URL;

        this.socket = null;
        this.sessionId = null;
        this.resolveConnection = null;
        this.pendingMessage = null;
        this.connected = false;
        this.subscribedToEvents = false;

        this.events = new EventDispatcher();
    }

    onMessage(data) {
        this.pendingMessage = data;
    }

    onTaskEnd(data) {
        if (this.connected) {
            if (!this.pendingMessage) return;
            if (this.pendingMessage?.content && this.pendingMessage?.content?.startsWith("Hi! I'm SOIKA, your ultimate web3 marketplace")) return;
            this.events.trigger("soika_message", this.pendingMessage);
            this.pendingMessage = null;
        } else {
            this.connected = true;
            this.resolveConnection();
        }
    }

    emitMessage(message, code = '') {
        const codeBuildWrapped = `Update the code ${code} to ${message} if object position can be changed like obj.data.position.value.x = 2, rotation can be changed like obj.data.rotation.value.y = 3.14, add an obj.update(8) at the end of update function, reply with code only, no additional comments`
        console.log(codeBuildWrapped)
        this.socket.emit("ui_message", {
            files: [],
            message: {
                id: uuidv4(),
                author: "User",
                authorIsUser: true,
                content: code ? codeBuildWrapped : message,
                createdAt: new Date().toISOString()
            }
        });
    }

    async connectSocket() {
        return new Promise((resolve, reject) => {
            this.resolveConnection = resolve;
            if (!Moralis.User.current()?.get("sessionToken")) reject("Unauthorized");
            this.sessionId = uuidv4();
            this.socket = new io(this.host, {
                path: '/ws/socket.io',
                extraHeaders: {
                    'Authorization': ' ',
                    'User-Env': '{}',
                    'X-Soika-Session-Id': this.sessionId
                }
            });
            this.socket.on('new_message', (data) => this.onMessage(data));
            this.socket.on('task_end', (data) => this.onTaskEnd(data));
            this.socket.on('connect', () => {
                this.socket.emit('connection_successful');
            });
        });
    }

}