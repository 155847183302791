import { InputEventType } from './Core/Constants';

export default class ToolManager {
  constructor({ app }) {
    this.app = app;

    this._activeTool = null;
    this.tools = [];
  }

  init() {}

  destroy() {
    this.stop();
    while (this.tools.length > 0) this.remove(this.tools[0]);
  }

  add(item) {
    if (!item) {
      console.warn(this.constructor.name + '.add: null item');
      return;
    }

    if (!this.tools?.includes(item)) {
      item.init();
      this.tools.push(item);
    } else console.warn('item is already added=' + item.id, item);

    item.toolManager = this;
  }

  remove(item) {
    if (!item) {
      console.warn(this.constructor.name + '.remove: null item');
      return;
    }

    const iIndex = this.tools.indexOf(item);
    if (iIndex !== -1) {
      item.destroy();
      this.tools.splice(iIndex, 1);
    } else console.warn('item is not found=' + item.id);

    item.toolManager = null;
  }

  start() {
    this.onMouse = (event) => {
      // console.log('onMouse: ', 'type=', event.type, 'button=', event.button, 'buttons=', event.buttons, 'e=', event)
      this.onPointerEvent(event);
      this.app.project.dispatchUpdateStates();
    };
    this.onKeyboard = (event) => {
      this.onKeyEvent(event);
      this.app.project.dispatchUpdateStates();
    };

    const eventObj = window;
    // const eventObj = this.app.engineComponent.container
    // eventObj.addEventListener('mousedown', this.onMouse, false)
    eventObj.addEventListener(InputEventType.down, this.onMouse, false);
    // eventObj.addEventListener('mouseup', this.onMouse, false)
    eventObj.addEventListener(InputEventType.up, this.onMouse, false);
    // eventObj.addEventListener('mousemove', this.onMouse, false)
    eventObj.addEventListener(InputEventType.move, this.onMouse, false);
    eventObj.addEventListener('dblclick', this.onMouse, false);
    eventObj.addEventListener('wheel', this.onMouse, false);

    eventObj.addEventListener('mouseenter', this.onMouse, false);
    eventObj.addEventListener('mouseleave', this.onMouse, false);
    eventObj.addEventListener('mouseover', this.onMouse, false);
    eventObj.addEventListener('mouseout', this.onMouse, false);

    eventObj.addEventListener('keydown', this.onKeyboard, false);
    eventObj.addEventListener('keyup', this.onKeyboard, false);
  }

  stop() {
    const eventObj = window;
    //const eventObj = this.app.engineComponent.container
    if (this.onMouse) {
      // eventObj.removeEventListener('mousedown', this.onMouse, false)
      eventObj.removeEventListener(InputEventType.down, this.onMouse, false);
      // eventObj.removeEventListener('mouseup', this.onMouse, false)
      eventObj.removeEventListener(InputEventType.up, this.onMouse, false);
      // eventObj.removeEventListener('mousemove', this.onMouse, false)
      eventObj.removeEventListener(InputEventType.move, this.onMouse, false);
      eventObj.removeEventListener('dblclick', this.onMouse, false);
      eventObj.removeEventListener('wheel', this.onMouse, false);

      eventObj.removeEventListener('mouseenter', this.onMouse, false);
      eventObj.removeEventListener('mouseleave', this.onMouse, false);
      eventObj.removeEventListener('mouseover', this.onMouse, false);
      eventObj.removeEventListener('mouseout', this.onMouse, false);
      delete this.onMouse;
      delete this.onMouseCustomUp;
      delete this.onMouseCustomDown;
      delete this.onMouseCustomMove;
    }

    if (this.onKeyboard) {
      eventObj.removeEventListener('keydown', this.onKeyboard, false);
      eventObj.removeEventListener('keyup', this.onKeyboard, false);
      delete this.onKeyboard;
    }
  }

  onPointerEvent(event) {
    if (!this.app.engineComponent.focused) {
      // console.warn(this.constructor.name + '.onPointerEvent: engine not focused')
      return;
    }

    if (this.activeTool)
      try {
        this.activeTool.onPointerEvent(event);
      } catch (e) {
        console.warn('tool mouse error=', e);
      }
  }

  onKeyEvent(event) {
    if (!this.app.engineComponent.focused) {
      // console.warn(this.constructor.name + '.onKeyEvent: engine not focused')
      return;
    }

    /*if (event.target !== document.body) {
      console.log(this.constructor.name + '.onKeyEvent: no body focused=', event)
      return
    }*/

    if (this.activeTool)
      try {
        this.activeTool.onKeyEvent(event);
      } catch (e) {
        console.warn('tool key error=', e);
      }
  }

  get activeTool() {
    return this._activeTool;
  }

  set activeTool(value) {
    if (this._activeTool === value) return;

    if (this._activeTool) this._activeTool.stop();
    this._activeTool = value;
    if (this._activeTool) this._activeTool.start();
  }
}
