import React, { useState } from 'react';
import {
  Wrapper,
  Image,
  NftInfo,
  NftName,
  TextWrapper,
  NftPrice,
  TextContentWrapper,
  RowWrapper,
  ButtonBordered,
  ImageContainer,
  StyledInput,
  ButtonFilled
} from './style';
import { CONTRACT_TYPES } from '../../../../../const/contract-types';

const NftCardBig = ({ nft, onBackClick, onSubmitClick }) => {
  const { item } = nft;
  const [price, setPrice] = useState('');
  const [isSubmiting, setIsSubmiting] = useState(false);

  const onSubmit = async () => {
    setIsSubmiting(true);
    await onSubmitClick(price);
    setIsSubmiting(false);
  }

  return (
    <Wrapper>
      <RowWrapper>
        <ImageContainer>
          <Image src={item.metadata.image} />
        </ImageContainer>
        <TextContentWrapper>
          <TextWrapper>
            <NftName>{item.metadata.name}</NftName>
            <NftInfo>
              {item.metadata.description}
            </NftInfo>
          </TextWrapper>

          <TextWrapper>
            <NftName>NFT Price</NftName>
            <StyledInput
              onWheel={(e) => e.target.blur()}
              type="text"
              onChange={e => setPrice(e.target.value)}
              sx={{ width: '100%', marginTop: '10px' }}
              align="right"
              value={price}
              placeholder="1 ETH"
            />
            {/* {item.lastest_price && (
              <NftPrice>
                {(Number(item.lastest_price) / 10 ** 18).toFixed(2)} ETH
              </NftPrice>
            )} */}

            {/* {item.contract_type === CONTRACT_TYPES.ERC1155 && (
              <NftPrice>{item.amount} </NftPrice>
            )} */}
          </TextWrapper>
        </TextContentWrapper>
      </RowWrapper>
      <RowWrapper>
        <ButtonBordered onClick={onBackClick}>
          Back
        </ButtonBordered>
        <ButtonFilled onClick={onSubmit} disabled={price === '' || isSubmiting}>
          {isSubmiting ? "Submitting..." : "Submit NFT"}
        </ButtonFilled>
      </RowWrapper>
    </Wrapper>
  );
};

export default NftCardBig;
