import styled from 'styled-components';

export const ContainerLoader = styled.div`
  position: relative;
  border: none;
  display: flex;
  height: 360px;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px;
  box-sizing: border-box;
  flex-direction: column;
  @media (max-width: 768px) {
    padding: 0;
  }
`;
export const Chooser = styled.div`
  width: 100%;
  height: 260px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 600px) {
    flex-direction: column;
    height: auto;
  }
`;

export const HalfPreview = styled.div`
  width: 100%;
  height: 100%;
  /* background: #d9d9d9; */
  overflow: hidden;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='%23333' stroke-width='2' stroke-dasharray='5%2c 5' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 24px;
`;
export const Wrapper = styled.div`
  max-width: 100%;
  width: 100vw;
  height: calc(100% - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const Text = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  color: #fff !important;
  transition: opacity 2s;
  margin-top: 10px;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 16px;
  backdrop-filter: blur(34px);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  border-radius: 12px;
`;
export const ErrorMessage = styled.div`
  margin-top: 30px;
  width: max-content;
  flex: auto;
`;
