import { useContext } from 'react'
import { Context } from '../../../store/Context'
import useAuth from '../../../../../helpers/auth'
import abi from '../../../../../const/abis/Space.json'
import {
    ArtIMG,
    ArtVID,
    ArtInfo,
    ArtInfoTitle,
    ArtistName,
    ArtType,
    ArtWorkItem,
    OptionsButtonWrapper,
    OptionsButton,
    Row,
    ArtApproved
} from '../styles'
import { TypeEntity } from '../../../../editor/Core/Constants'
import CONFIG from '../../../../../config'
import ObjectItem from '../../../../editor/Core/Entities/ObjectItem'
import NFT3D from '../../../../editor/Core/Entities/NFT3D'
import { useState } from 'react'
import Web3 from 'web3'
import { EditorSnackbarContext } from '../../../contexts/editor-snackbar'
import { FlexibleModalContext } from '../../../contexts/flexible-modal/context'

export const ArtworkItem = ({ token }) => {
    const { editorStore } = useContext(Context)
    const { user, web3 } = useAuth()
    const [approveStatus, setApproveStatus] = useState("Approve");
    const { triggerSnackbar } = useContext(EditorSnackbarContext);
    const { setFlexibleModalChildren } = useContext(FlexibleModalContext);

    const selected =
        editorStore?.editor?.selector?.items?.length === 1
            ? editorStore?.editor?.selector?.items?.[0]
            : null
    const isSelected = selected?.data?.imageMeta?.value?.token_address === token?.item?.token_address &&
        selected?.data?.imageMeta?.value?.token_id === token?.item?.token_id;
        
    const approve = async (token, event) => {
        event.stopPropagation();
        setApproveStatus("Approving...");
        try {
            console.log("Approve token", token.item.uid)
            const contract = new web3.eth.Contract(
                abi,
                CONFIG.REACT_APP_METASPACE_CONTROLLER_ADDRESS
            );
            await contract.methods
                .approvedToken(token.item.uid)
                .call({ from: user.get('ethAddress') })
            await contract.methods
                .approvedToken(token.item.uid)
                .send({ from: user.get('ethAddress') })
            setApproveStatus("Approved");
        } catch (e) {
            setApproveStatus("Approve");
            triggerSnackbar(`Approval failed, ${e.message}`, 'error');
        }
    }

    const imageItemClick = (event, itemImage) => {
        if (itemImage?.metadata?.type?.includes("3D") || itemImage?.metadata?.type?.includes("object")) {
            console.log('handleItemClick=', itemImage);
            const url = itemImage?.metadata?.animation_url || itemImage?.metadata?.threeObj;
            if (!url) {
                console.warn("Unsupported metadata", itemImage?.metadata);
                return;
            }
            const NFT3DObject = new NFT3D({ project: editorStore.editor.project });
            NFT3DObject.data.path.value = url;
            NFT3DObject.data.name.value = itemImage?.metadata?.name;
            NFT3DObject.data.imageMeta.value = {
                token_id: itemImage.token_id,
                token_address: itemImage.token_address
            };

            editorStore.editor.project.dispatchUpdateStates()
        } else {
            const item =
                editorStore.editor.selector.items.length === 1
                    ? editorStore.editor.selector.items[0]
                    : null
            if (item?.type === TypeEntity.Picture) {
                item.data.imageMeta.value = {
                    token_id: itemImage.token_id,
                    token_address: itemImage.token_address,
                }
                item.data.name.value = itemImage?.metadata?.name
                item.data.image.value = itemImage?.metadata?.image
                editorStore.editor.project.dispatchUpdateStates()
            }
        }
        triggerSnackbar(`Applying NFT ${token?.item?.metadata?.name}`, "info");
        setFlexibleModalChildren(null);
    }

    return <ArtWorkItem
        key={`${token?.item?.token_address}:${token?.item?.token_id}`}
        onClick={(e) => imageItemClick(e, token?.item)}
        isSelected={isSelected}
    >
        {token?.item?.metadata?.image ? (
            <ArtIMG
                src={`${token?.item?.metadata?.image}`}
                srcSet={`${token?.item?.metadata?.image}`}
                alt={token?.item?.metadata?.name}
                loading="lazy"
            />
        ) : (
            <ArtVID
                crossorigin="anonymous"
                id={`vid:${token?.item?.token_address}:${token?.item?.token_id}`}
                type="video/mp4"
                src={`${token?.item?.metadata?.animation_url}`}
                preload='metadata'
            />
        )}
        <ArtInfo>
            <ArtInfoTitle>{token?.item?.metadata?.name}{token?.item?.price ? `: ${Web3.utils.fromWei(token?.item?.price)} ETH` : ""}</ArtInfoTitle>
            <ArtistName>Owner: {token?.owner?.username}</ArtistName>
            <Row>
                <ArtType>{token?.item?.contract_type}</ArtType>
                <ArtApproved approved={token?.item?.approved}>
                    {`${token?.item?.approved ? 'Sale approved' : 'Sale not approved'}`}
                </ArtApproved>
            </Row>
        </ArtInfo>

        {!token?.item?.approved &&
            <OptionsButtonWrapper>
                <OptionsButton onClick={(e) => approve(token, e)}>
                    {/* <ArtOptionsIcon /> */}
                    {approveStatus}
                </OptionsButton>
            </OptionsButtonWrapper>
        }
        {/* <OptionsButtonWrapper>
        <OptionsButton onClick={() => approve(token)}>
          Deny
        </OptionsButton>
      </OptionsButtonWrapper> */}
    </ArtWorkItem>
}