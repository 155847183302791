import React, { useReducer } from 'react';
import useReducerFactory from './reducer';

import {
  EditorDispatchContext,
  EditorStateContext,
  defaultState,
  dispatchProvider,
  useEditorContext,
  useEditorDispatch,
} from './context';

const EditorProvider = ({ children }) => {
  const [state, dispatch] = useReducer(useReducerFactory, defaultState);
  const caller = dispatchProvider(dispatch);

  return (
    <EditorStateContext.Provider value={state}>
      <EditorDispatchContext.Provider value={caller}>
        {children}
      </EditorDispatchContext.Provider>
    </EditorStateContext.Provider>
  );
};

const useEditor = () => ({
  ...useEditorContext(),
  ...useEditorDispatch(),
});

export { useEditor, EditorProvider };
