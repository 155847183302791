import {
    FramesContainer,
    FramesWrapper
} from "./styles";
import { useContext } from 'react'

import styled from "@emotion/styled";
import { Context } from "../../store/Context";
import { attachPictureByPick } from "../../../editor/Core/Algs/Basic";
import Picture from "../../../editor/Core/Entities/Picture";

export const AspectButton = styled.div`
  color: #fff;
  border: 2px solid #484848;
  border-radius: 6px;
  height: ${(props) => `${props.h}px`};
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  width: ${(props) => `${props.w}px`};
`;
export const StackGrid = styled.div`
  display: flex;
  align-items: center;
  width: 95%;
  justify-content: space-between;
`;
export const AspectSpan = styled.span`
  text-align: center;
  display: block;
  color: #353535;
  margin-top: 10px;
`;
export const StackBlock = styled.div`
  margin: 20px;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
`;
export const StackColumn = styled.div`
  position: relative;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 13px;
    transform: translate(-50%, -50%);
    color: #eee;

    :hover {
        color: #fff;
    }
  }
`;

export const TitleFrame = styled.div`
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px;
`;

export const ContainerFlexStack = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export default function FramesPanel() {
    const { editorStore } = useContext(Context)

    const ratios = [
        { w: `60`, h: `34`, aspectX: 16, aspectY: 9 },
        { w: `34`, h: `60`, aspectX: 9, aspectY: 16 },
        { w: `45`, h: `60`, aspectX: 3, aspectY: 4 },
        { w: `60`, h: `45`, aspectX: 4, aspectY: 3 },
        { w: `48`, h: `60`, aspectX: 4, aspectY: 5 },
        { w: `60`, h: `60`, aspectX: 1, aspectY: 1 }
    ]

    const handleRatioItemClick = (event, ratio) => {
        const url = '../../models3d/picture-frames/Frame_' + ratio.aspectX + 'x' + ratio.aspectY + '.glb'
        console.log(1)
        const frame = new Picture({ project: editorStore.editor.project })
        const cameraRayHit = editorStore.editor.engineComponent.hoverer.editorClickPoint ||
            editorStore.editor.engineComponent.hoverer.findIntersectionFromCenter()

        if (editorStore.editor.engineComponent.hoverer.editorClickPoint) {
          // reset editorClickPoint when adding frame
          editorStore.editor.engineComponent.hoverer.editorClickPoint = null;
        }

        frame.data.position.value.x = cameraRayHit?.point?.x;
        frame.data.position.value.y = cameraRayHit?.point?.y;
        frame.data.position.value.z = cameraRayHit?.point?.z;

        frame.data.aspect.value = `${ratio.aspectX}:${ratio.aspectY}`
        frame.data.path.value = url
        frame.data.name.value = 'Empty frame'

        // attach frame to a wall 
        attachPictureByPick(frame, cameraRayHit);

        editorStore.editor.project.dispatchUpdateStates()
        editorStore.editor.selector.clear();
        editorStore.editor.selector.add(frame);
        editorStore.editor.events.trigger('showItem', { item: frame });
    }

    return <FramesWrapper>
        <FramesContainer>
            <ContainerFlexStack>
                <StackGrid>
                    {ratios.map((ratio, id) => (
                        <StackColumn onClick={e => handleRatioItemClick(e, ratio)} key={id}>
                            <AspectButton w={ratio.w} h={ratio.h} />
                            <span>
                                {ratio.aspectX}:{ratio.aspectY}
                            </span>
                        </StackColumn>
                    ))}
                </StackGrid>
            </ContainerFlexStack>
        </FramesContainer>
    </FramesWrapper>
}