import {$host} from './base'

import Moralis from '../../const/moralis'

export const getObjects = async () => {
  const result = await $host.post('api/object/get_objects', {sessionToken: Moralis.User.current().get('sessionToken')})
  return result
}

export const getRoom = async guid => {
  const result = await $host
    .post('api/room/get_room', {guid})
    .then(
      response => {
        // console.log(response)
        return response
      },
      reject => {
        console.warn('reject=', reject)
        return null
      }
    )
    .catch(error => {
      console.log('error=', error)
      return null
    })
  return result
}

export const getRoomLocal = async guid => {
  const result = await fetch('../rooms/' + guid + '.json').then(res => {
    if (res.ok) {
      return res.json().then(
        resJson => {
          // console.log('resJson=', resJson)
          return resJson
        },
        errorJson => {
          console.warn('errorJson=', errorJson)
          return null
        }
      )
    } else console.warn('bad res=', res)
  })
  return result
}

export const saveRoom = async (address, data) => {
  const result = await $host.post(`space/${address}`, { data })
  return result
}

export const Add3DObj = async (guid, name, file/*path*/, extention, img/*imageLink*/, data) => {
  const result = await $host.post('api/object/save_object', {
    guid,
    name,
    file,
    //path,
    image: img,
    extention,
    //image_path: imageLink,
    data,

    sessionToken: Moralis.User.current().get('sessionToken')
  })
  return result
}


export const getProductInfo = async (ids) => {
  return await $host.post('product/info', { ids });
}

export const getProjectInfo = async (ids) => {
  return await $host.post('project/info', { ids });
}