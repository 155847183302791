import { useState } from 'react'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { CustomTab, CustomTabList, Wrapper } from './styles'
import { Tab3DNFTs } from './Tab3DNFTs'
import { Tab3DObjects } from './Tab3DObjects'

export default function ObjectsList3D({ tab = '1' }) {
  const [tabIndex, setTabIndex] = useState(tab);

  return (
    <Wrapper>
      <TabContext value={tabIndex}>
        <CustomTabList
          onChange={(event, value) => setTabIndex(value)}
          aria-label='content'
        >
          <CustomTab label='3D NFTs' value='1' />
          <CustomTab label='Decorations' value='2' />
        </CustomTabList>
        <TabPanel
          value='1'
          sx={{
            '& .MuiTabPanel-root': {
              padding: '0'
            }
          }}
        >
          <Tab3DNFTs />
        </TabPanel>
        <TabPanel
          value='2'
          sx={{
            '& .MuiTabPanel-root': {
              padding: '0'
            }
          }}
        >
          <Tab3DObjects />
        </TabPanel>
      </TabContext>
    </Wrapper>
  )
}
