import React, { useContext, useEffect } from 'react';
import { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Html, Center, Bounds } from '@react-three/drei';
import { LoadGLTF } from './LoadGLTF';
import { useThree } from '@react-three/fiber';
import { ContainerLoader, HalfPreview } from './style';

import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallBack } from './ErrorFallBack';
import { ViewContext } from '../ContentTabs/Tabs/ObjectsList3D/Tab3DObjects/AddOBJ/context';
const PreLoaderEL = ({ file }) => {
  let url = URL.createObjectURL(file);
  let format = file.name.split('.').pop().toLowerCase();

  return (
    <ContainerLoader>
      <ErrorBoundary FallbackComponent={ErrorFallBack} resetKeys={[file]}>
        <LoadModel url={url} format={format} />
      </ErrorBoundary>
    </ContainerLoader>
  );
};

const LoadModel = ({ url, format }) => {
  switch (format) {
    case 'glb':
    case 'gltf':
      return <Scene scenes={<LoadGLTF url={url} />} />;
    default:
      return <div>Can not load</div>;
  }
};

const Scene = ({ scenes }) => {
  const { img, setImg, choose, setChoose } = useContext(ViewContext);
  useEffect(() => {
    setTimeout(() => {
      setChoose(false);
    }, 2000);
  }, [img]);

  const DetectRender = () => {
    const { gl, scene, camera } = useThree();
    useEffect(() => {
      if (choose) {
        setTimeout(() => {
          gl.render(scene, camera);
          let image = gl.domElement.toDataURL();
          setImg(image);
        }, 500);
      }
    }, [choose]);
    return <></>;
  };
  return (
    // to remove control you need to remove this -> "pointerEvents"
    <>
      <HalfPreview>
        <Canvas
          gl={{ preserveDrawingBuffer: true }}
          style={{ pointerEvents: 'visible' }}
          shadows
        >
          <OrbitControls makeDefault />
          <ambientLight intensity={0.6} />
          <directionalLight intensity={0.5} />
          <Suspense fallback={<Html center>Loading...</Html>}>
            {/* scaling and alignment of the model in the center */}
            <Bounds fit clip observe damping={6} margin={1.2}>
              <Center center>
                <DetectRender />
                {/* add coordinate axes */}
                {/* <primitive object={new THREE.AxesHelper(10)} /> */}
                {scenes}
              </Center>
            </Bounds>
          </Suspense>
        </Canvas>
      </HalfPreview>
    </>
  );
};

export const PreLoader = React.memo(PreLoaderEL);
