/* eslint-disable react-hooks/exhaustive-deps */
import Moralis from '../../../../const/moralis';
import { useEffect } from 'react';
import { useMyNfts } from '.';
import {
  useGetProfileCollectiblesBody,
  transformUserItemsResp,
} from './helpers';

export const useFetchMyNfts = () => {
  const { cursor, page, setIsLoading, setMoralisData } = useMyNfts();
  const { getBody } = useGetProfileCollectiblesBody();

  const fetchNFTs = async (reinit) => {
    try {
      const data = transformUserItemsResp(
        await Moralis.Cloud.run('user-items', getBody(cursor))
      );

      setMoralisData(data, reinit);
      return data?.results;
    } catch (e) {
      console.log(e);
    }
  };

  const fetchNFTsList = async (reinit) => {
    setIsLoading(true);
    await fetchNFTs(reinit);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchNFTsList();
  }, [page]);
};
