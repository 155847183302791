import React, { useEffect, useState, useContext } from 'react';

import { Context } from '../../store/Context';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, IconButton, Input } from '@mui/material';

import {
  PanelBuy,
  People,
  ContainerName,
  ImageContainer,
  Image,
  Price,
  TypeWork,
  ContentInfo,
  Name,
  SpanType,
  ContentNFtFlex,
  BuyButton,
  Wrap
} from './styles';

import IconClose from '../../../../editor/ui/assets/icons/IconClose';
import { CustomBlackBtn } from '../Buttons/CustomBlackBtn';
import useAuth from '../../../../helpers/auth';
import { useParams } from 'react-router-dom';
import { useEditor } from '../../store/editor-context';
import abi from '../../../../const/abis/Space.json';
import CONFIG from '../../../../config';
import { EditorSnackbarContext } from '../../contexts/editor-snackbar';

export const BuyNFTPanel = ({ NFTData, event }) => {
  const { uid } = useParams();
  const { web3, user, changeNetwork, authenticate } = useAuth();
  const { space } = useEditor();
  const { triggerSnackbar } = useContext(EditorSnackbarContext);

  const buyNFT = async () => {
    try {
      let w3 = web3, _user = user;
      if (!user?.get('ethAddress')) {
        const { web3: web3Js, user: authUser } = await authenticate();
        w3 = web3Js
        _user = authUser;
      }
      await changeNetwork();
      const contract = new w3.eth.Contract(abi, CONFIG.REACT_APP_METASPACE_CONTROLLER_ADDRESS);
      await contract.methods
        .buy(NFTData?.item?.uid)
        .call({ from: _user.get('ethAddress'), value: NFTData?.item?.price });
      await contract.methods
        .buy(NFTData?.item?.uid)
        .send({ from: _user.get('ethAddress'), value: NFTData?.item?.price });
    } catch (e) {
      triggerSnackbar(`Buy NFT failed: ${e.message}`, 'error');
    }
  };

  const style = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    bgcolor: 'rgba(0, 0, 0, 0.5);',
  };

  const styleClose = {
    position: 'absolute',
    top: 0,
    right: 0,
  };
  const matches600 = useMediaQuery('(max-width:600px)');
  console.log(NFTData)
  return (
    <>
      {matches600 && <Box sx={style} onClick={event} />}
      <PanelBuy left={false}>
        <IconButton sx={styleClose} onClick={event} onTouchStart={event}>
          <IconClose fill={'#fff'} />
        </IconButton>
        <ContentNFtFlex>
          <ImageContainer>
            {NFTData?.item?.metadata?.image && <Image src={NFTData?.item?.metadata?.image} />}
          </ImageContainer>
          <ContentInfo>
            <ContainerName>
              <Name>{NFTData?.item?.metadata?.name}</Name>
              <SpanType>{NFTData?.item?.contract_type}</SpanType>
            </ContainerName>
            <People>
              <span>Owner: @{NFTData?.owner?.username}</span>
            </People>
            {
              NFTData?.item?.approved ?
                <Price>
                  Price
                  <span>
                    <b>{NFTData?.item?.price / 10 ** 18}</b>
                    <b>ETH</b>
                  </span>
                </Price> : null
            }
            <TypeWork>
              {NFTData.item?.contract_type === 'ERC1155'
                ? ` Multiple copies ${NFTData?.item?.amount} ERC1155`
                : 'Single copy'}
            </TypeWork>
            {NFTData?.item?.approved ?
              <Wrap>
                <BuyButton onClick={buyNFT}>Buy NFT</BuyButton>
              </Wrap> : null
            }
          </ContentInfo>
        </ContentNFtFlex>
      </PanelBuy>
    </>
  );
};
