import Web3 from 'web3';

export const getETH = (price) => {
  try {
    if (price === undefined) return NaN;
    return Web3.utils.fromWei(price.toLocaleString('fullwide', { useGrouping: false }));
  } catch (e) {
    console.warn(`getETH error converting ${price} of type ${typeof price}`, e)
    return NaN;
  }
};
