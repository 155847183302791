import styled from 'styled-components'

export const FlexEnd = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
`
export const PublishButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;

    width: 94px;
    height: 32px;

    background: #6C43E0;
    border-radius: 4px;

    color: #F0F0F0;

    :hover {
      cursor: pointer;
      box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);  
    }
`
export const PublishButtonHull = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;

    width: 94px;
    height: 32px;

    border-radius: 4px;
    border: 2px solid #6C43E0;
    box-sizing: border-box;

    color: #F0F0F0;

    :hover {
      cursor: pointer;
      box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);  
    }
`
export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  min-height: 50px;
  border-bottom: 1px solid #303030;
`;
export const Separator = styled.div`
  width: 100%;
  height: 0px;
  border-bottom: 1px solid #303030;
`;
export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  padding-bottom: 12px;
`
export const PropertyLabel = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  height: 20px;
`;
export const PropertyContainer = styled.div`
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
`;
export const FlexJustifySpaceBetween = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
export const CustomButtonContainer = styled.div`
  width: ${(props) => props?.width || '60'}px;
  height: 100%;
  background: ${(props) => props?.background || '#262626'};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  :hover {
    cursor: pointer;
    background: ${(props) => props?.background || '#303030'};
  }
`;
export const CustomButton = (props) => {
  return <CustomButtonContainer {...props}>
    {props?.text}
  </CustomButtonContainer>
}
export const CustomButtonSticky = (props) => {
  return <CustomButtonContainer {...props}
    style={{
      background: props?.active ? '#6C43E0' : null,
      color: props?.active ? '#F0F0F0' : null,
    }}
    onClick={() => props?.onClick(props?.text?.toLowerCase())}
  >
    {props?.text}
  </CustomButtonContainer>
}
