import { Button } from 'antd';
import styled from 'styled-components';

const EditButton = styled(Button)`
  position: absolute;
  bottom: 20px;
  right: 18px;
  border-radius: 50px;
  background: transparent;
  box-shadow: inset 0px 0px 0px 2px #4D44FE;
  padding: 0;
  border: none;
  color: #fff;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
  width: 120px;
  height: 40px;
  cursor: pointer;
  user-select: none;
`;

export const EditBtn = ({ event }) => {
  return (
    <EditButton onClick={event}>
      Edit
    </EditButton>
  );
};
