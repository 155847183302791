import axios from 'axios'
import CONFIG from '../../config'
const $host = axios.create({
  baseURL: CONFIG.REACT_APP_API_URL,
})

$host.interceptors.request.use((config) => {
  const token = localStorage.getItem('apiKey')

  try {
    if (token) {
      config.headers.Authorization = 'Bearer ' + token
    } else {
      delete config.headers.Authorization
    }
    return config
  } catch (e) {
    console.log("$host request error:", e)
  }
})

export { $host }
