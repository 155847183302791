import { useEffect, useState } from "react"
import { getProductInfo, getProjectInfo } from "../../../../api/dbAPI";
import { PRODUCT_CATEGORIES } from "../../../helpers/productCategories";
import { ClaimStaking } from "./staking";
import { ClaimAirdrop } from "./airdrop";

export const ClaimProduct = ({ item }) => {
    const [product, setProduct] = useState(item);

    useEffect(() => {
        const loadBackendData = async () => {
            const backendRes = await (item?.type === PRODUCT_CATEGORIES.PROFILE_GOLDLIST ?
                getProjectInfo([item.objectId]) : getProductInfo([item.objectId]))

            setProduct({
                ...product,
                backendData: backendRes.data[0]
            });
        }
        loadBackendData();
    }, []);

    switch (item?.type) {
        case PRODUCT_CATEGORIES.PROFILE_STAKING:
            return <ClaimStaking item={product} />
        case PRODUCT_CATEGORIES.PROFILE_AIRDROP:
            return <ClaimAirdrop item={product} />
    }

    return null;
}