export const TypeEntity = {
  None: -1,
  BaseObject: 0,
  Object: 1,

  Project: 50,

  RenderableItem: 100,

  Box: 203,
  ObjectItem: 210,
  Picture: 211,
  Light: 212,

  CameraView: 300
}

export const UpdateState = {
  None: 0,

  Compute: 1 << 0, // 1
  Scale: 1 << 1, // 2
  Style: 1 << 2, // 4
  Shape: 1 << 3, // 8
  Script: 1 << 4,

  Bound: 1 << 12,
  ScaleEnd: 1 << 13,

  AllVisual: 15, // Compute | Scale | Style | Shape
  All: 0xFFFFFFFF
  // All: 0x60001FFF // 1100000000000000001111111111111
}

export const InputEventType = {
  /*down: 'mousedown',
  up: 'mouseup',
  move: 'mousemove',*/ // in react, buttons, button = 0
  down: 'pointerdown',
  up: 'pointerup',
  move: 'pointermove',
}
