import { Box, Modal, useMediaQuery } from '@mui/material';
import { useRef } from 'react';
import useOnClickOutside from '../../../helpers/useOnClickOutside';
import { getStyle, ModalCotnentWrapper, Close, CloseContainer } from './style';
import styled from 'styled-components';
import { CloseModalIcon } from '../../../assets/icons/CloseModalIcon';


const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
`

const FlexibleModal = ({ children, setChildren, width = '800' }) => {
  const matches = useMediaQuery('(max-width:600px)');

  const close = () => {
    setChildren(null);
  }

  const modal = useRef(null);
  // useOnClickOutside(modal, close);

  return (
    <Modal open={!!children} onClose={close}>
      <Container>
        <CloseContainer matches={matches} onClick={close}>
          <Close>
            <CloseModalIcon />
          </Close>
        </CloseContainer>
        <Box ref={modal} sx={getStyle(matches, width)}>
          <ModalCotnentWrapper>
            {children}
          </ModalCotnentWrapper>
        </Box>
      </Container>
    </Modal>
  );
};

export default FlexibleModal;
