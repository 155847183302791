import { useContext, useState } from 'react'
import { Context } from '../../../../../../store/Context'
import { ArtIMG, ArtInfo, ArtInfoTitle, ArtWorkItem, ArtistName } from '../../../../styles'
import ObjectItem from '../../../../../../../editor/Core/Entities/ObjectItem'
import { EditorSnackbarContext } from '../../../../../../contexts/editor-snackbar'
import { FlexibleModalContext } from '../../../../../../contexts/flexible-modal/context'

export const ObjectItem3D = ({ item, creator }) => {
    const { editorStore } = useContext(Context);
    const { triggerSnackbar } = useContext(EditorSnackbarContext);
    const [singleClickCount, setSingleClickCount] = useState(0);
    const { setFlexibleModalChildren } = useContext(FlexibleModalContext);
    if (!item || !creator) return <></>;

    const handleItemClick = (e) => {
        // Only create 3d objects on double click
        if (e.detail !== 2) {
            if (singleClickCount > 0) triggerSnackbar("Use double click to create 3D objects", "info")
            setSingleClickCount(prev => prev + 1)
            return
        };
        const obj3d = new ObjectItem({ project: editorStore.editor.project });
        obj3d.data.position.value.x = 0;
        obj3d.data.position.value.y = 1.5;
        obj3d.data.position.value.z = 0;
        obj3d.data.path.value = item.object_url;
        obj3d.data.name.value = item.name;
        editorStore.editor.project.dispatchUpdateStates()
        triggerSnackbar("Loading 3D model", "info");
        setFlexibleModalChildren(null);
    }

    return <ArtWorkItem>
        <ArtIMG
            src={item?.image_url}
            srcSet={item?.image_url}
            alt={'preview'}
            onClick={handleItemClick}
        />
        <ArtInfo onClick={handleItemClick}>
            <ArtInfoTitle>{item?.name}</ArtInfoTitle>
            <ArtistName>Creator: {creator?.username}</ArtistName>
        </ArtInfo>

    </ArtWorkItem>
}