import Toolbase from './Base'
import { InputEventType } from '../Core/Constants'
import Picture from '../Core/Entities/Picture'
import { attachPictureByPick } from '../Core/Algs/Basic'
import { getFittedAspect } from '../Helpers/getFittedAspect'
import { getImageMeta } from '../Helpers/getImageMeta'

export default class ToolMagicWand extends Toolbase {
    constructor() {
        super(...arguments)

        this.name = 'MagicWand';
        this.inited = false;

        this.reactModal = null;
        this.reactCallback = null;
        this.clickEvent = null;
        this.mousePressed = false;
        this.mouseDragged = false;
    }

    start() {

    }

    stop() {

    }

    initReactCallback(callback, modal) {
        this.inited = true;
        this.reactCallback = callback;
        this.reactModal = modal;
    }

    getCameraRayHit() {
        this.app.engineComponent.hoverer.findEditorClickPoint(this.clickEvent);
        const cameraRayHit = this.app.engineComponent.hoverer.editorClickPoint;
        return cameraRayHit;
    }

    on3DObjectSelected(NFT3DObject) {
        const cameraRayHit = this.getCameraRayHit();

        NFT3DObject.data.position.value.x = cameraRayHit?.point?.x;
        NFT3DObject.data.position.value.y = cameraRayHit?.point?.y + 1.5;
        NFT3DObject.data.position.value.z = cameraRayHit?.point?.z;

        this.updateSelector(NFT3DObject);
        this.clickEvent = null;
    }

    updateSelector(item) {
        this.app.project.dispatchUpdateStates();
        this.app.selector.clear();
        this.app.selector.add(item);
        this.app.events.trigger('showItem', { item });
    }

    async onPictureSelected(nft) {
        const picture = await getImageMeta(nft.metadata.image);
        const fitted_aspect = getFittedAspect(picture.width / picture.height);

        const url = '../../models3d/picture-frames/Frame_' + fitted_aspect + '.glb';
        const frame = new Picture({ project: this.app.project })

        const cameraRayHit = this.getCameraRayHit();
        if (this.app.engineComponent.hoverer.editorClickPoint) {
            // reset editorClickPoint when adding frame
            this.app.engineComponent.hoverer.editorClickPoint = null;
        }

        frame.data.position.value.x = cameraRayHit?.point?.x;
        frame.data.position.value.y = cameraRayHit?.point?.y;
        frame.data.position.value.z = cameraRayHit?.point?.z;

        frame.data.aspect.value = `${fitted_aspect.split('x')[0]}:${fitted_aspect.split('x')[1]}`;
        frame.data.path.value = url;
        frame.data.name.value = nft.metadata.name;

        // attach frame to a wall 
        attachPictureByPick(frame, cameraRayHit);

        // add NFT metadata
        frame.data.imageMeta.value = {
            token_id: nft.token_id,
            token_address: nft.token_address
        }
        frame.data.image.value = nft.metadata.image;
        frame.data.scale.value.xyz = 4;

        this.updateSelector(frame);
        this.clickEvent = null;
    }

    onPicturePlace(event) {
        if (this.reactCallback && this.reactModal) {
            this.clickEvent = event;
            this.reactCallback(this.reactModal);
        } else {
            console.warn("ToolMagicWand error: undefined values for react callback || modal", this.reactCallback, this.reactModal);
        }
    }

    onPointerEvent(event) {
        const leftButton = event.buttons & 1;

        if (event.type === InputEventType.down) {
            this.mousePressed = true;
        }
        if (event.type === InputEventType.move && this.mousePressed) {
            this.mouseDragged = true
        }

        if (event.type === InputEventType.up) {
            if (!this.mouseDragged) {
                this.app.engineComponent.hoverer.resetEditorClickPoint();
                this.onPicturePlace(event);
            }
            this.mousePressed = false;
            this.mouseDragged = false;
        }

        super.onPointerEvent(event)
    }

}