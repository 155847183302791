import React, { useEffect } from 'react'

import { BorderBlock } from './styles'
import { CoordinateInput } from '../Input/CoordinateInput'


export default function PropertyVector3(props) {
  const [x, setX] = React.useState(props.property.value.x)
  const [y, setY] = React.useState(props.property.value.y)
  const [z, setZ] = React.useState(props.property.value.z)

  useEffect(() => {
    setX(props.property.value.x)
    setY(props.property.value.y)
    setZ(props.property.value.z)
  }, [props])

  //useEffect(() => {console.log('PropertyVector3 props=', props)}, [])

  const handleChange = (event, index) => {
    if (index === 0) {
      props.property.value.x = event.target.value
      setX(props.property.value.x)
  }
  else if (index === 1) {
      props.property.value.y = event.target.value
      setY(props.property.value.y)
  }
  else if (index === 2) {
      props.property.value.z = event.target.value
      setZ(props.property.value.z)
  }
  }

  return (
    <BorderBlock>
      <CoordinateInput
        label="X"
        value={x}
        handleChange={(e) => handleChange(e, 0)}
      />
      <CoordinateInput
        label="Y"
        value={y}
        handleChange={(e) => handleChange(e, 1)}
      />
      <CoordinateInput
        label="Z"
        value={z}
        handleChange={(e) => handleChange(e, 2)}
      />
    </BorderBlock>
  )
}
