import {List} from '@mui/material'
import styled from 'styled-components'

export const CustomList = styled(List)`
  height: 100%;
  max-width: 100%;
  color: #F0F0F0;
`
export const ListItemOptions = styled.div`
  width: calc(100% - 24px);
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 8px 12px 8px 12px;
  height: 32px;
`

export const Text = styled.div`
  min-width: 52px;
  width: 52px;
  margin-right: 8px;
  flex-shrink: 0;
  flex-grow: 1;

  font-size: 12px;
  color: #838383;
`

export const Property = styled.div`
  width: 100%;
`

export const FlexIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2em;
`
