import { useContext, useState, useEffect, useCallback } from "react";
import { Context } from "../../store/Context";

import { SceneSearch } from "../Input/SceneSearch";
import { SceneMenuIcon } from "../../assets/icons/SceneMenuIcon";
import {
    ArrowDown,
    ArrowDownContainer,
    ArrowRight,
    ArrowRightContainer,
    BottomMenuIconHolder,
    BottomMenuItem,
    BottomMenuItems,
    CubeIcon,
    FeedbackIcon,
    ItemIconContainer,
    ItemLabel,
    LightIcon,
    SceneContainer,
    SceneItem,
    SceneItems,
    SearchBoxContainer,
    SquareIcon,
    SumbitTokensIcon,
    ToggleMenuIconContainer
} from "./styles";
import { SubmitTokenModal } from "../Modals";
import { FlexibleModalContext } from "../../contexts/flexible-modal/context";
import { Tab3DObjects } from "../ContentTabs/Tabs/ObjectsList3D/Tab3DObjects";

const iconByType = (type) => {
    if (type === "Picture") return <SquareIcon />
    if (type === "Light") return <LightIcon />
    return <CubeIcon />
}

const isPicture = (sceneItem) => {
    return sceneItem?.item?.constructor?.name === "Picture";
}

const SceneComponent = ({ sceneItem, sceneItemIndex, sceneComponents }) => {
    const { editorStore } = useContext(Context);

    let pictureId;
    if (isPicture(sceneItem)) {
        const filtered = sceneComponents.filter(el => isPicture(el));
        pictureId = filtered.findIndex(el => el?.item?.id === sceneItem?.item?.id);
    }

    const showItem = (sceneItem) => {
        editorStore.editor.selector.clear();
        editorStore.editor.selector.add(sceneItem.item);
        editorStore.editor.events.trigger('showItem', { item: sceneItem.item });
    }

    return <SceneItem
        onClick={() => showItem(sceneItem)}
        level={sceneItem?.level}
        key={sceneItemIndex}
    >
        <ArrowRightContainer
            sceneItemIndex={sceneItemIndex}
            sceneComponents={sceneComponents}
        >
            <ArrowRight />
        </ArrowRightContainer>
        {sceneItemIndex !== sceneComponents.length - 1 &&
            <ArrowDownContainer>
                <ArrowDown />
            </ArrowDownContainer>
        }
        <ItemIconContainer>
            {iconByType(sceneItem?.item?.constructor?.name)}
        </ItemIconContainer>
        <ItemLabel>
            {pictureId !== undefined && `#${pictureId + 1}`} {sceneItem?.item?.data?.name?.value}
        </ItemLabel>
    </SceneItem>
}

export default function SceneMenu({ close, setModal }) {
    const { editorStore } = useContext(Context);
    const [searchValue, setSearchValue] = useState('');
    const [sceneComponents, setSceneComponents] = useState([]);
    const { setFlexibleModalChildren } = useContext(FlexibleModalContext);

    // const scene = editorStore.editor?.engineComponent?.sceneComponent?.scene?.children

    const updateComponents = useCallback(() => {
        setSceneComponents(
            editorStore.editor?.project?.objects?.map(item => {
                return { item, level: 0, expanded: false }
            })
        );
    }, [editorStore.editor?.project?.objects])

    useEffect(() => {
        updateComponents();
        editorStore.editor.events.on('objectItemLoaded', updateComponents);
        editorStore.editor.events.on('deleteItem', updateComponents);
    }, [editorStore.editor.events, updateComponents]);

    const submitTokens = () => {
        setModal(<SubmitTokenModal />)
    }

    const expand = (item) => {
        console.log(item)
        const temp = [...sceneComponents];
        const index = temp.findIndex(x => x === item);
        const children = temp[index]?.item?.children;
        if (!children || temp[index]?.expanded) return;

        const newItems = children.map(child => {
            return { item: child, level: temp[index].level + 1, expanded: false }
        });
        temp[index]["expanded"] = true;
        temp.splice(index + 1, 0, ...newItems);

        setSceneComponents(temp);
    }

    const onSearch = (query) => {
        const temp = editorStore.editor.project.objects.map(item => {
            return { item, level: 0, expanded: false }
        });
        const searchRegex = new RegExp('(^| )' + query, 'i');
        setSceneComponents(
            temp.filter(component => searchRegex.test(component.item?.data?.name?.value))
        );
        setSearchValue(query);
    }

    return <SceneContainer>
        <SearchBoxContainer>
            <SceneSearch
                placeholder="Search"
                value={searchValue}
                handleChange={(e) => onSearch(e.target.value)}
            />
            <ToggleMenuIconContainer onClick={close}>
                <SceneMenuIcon />
            </ToggleMenuIconContainer>
        </SearchBoxContainer>
        <SceneItems>
            {sceneComponents?.map((sceneItem, sceneItemIndex) =>
                <SceneComponent
                    sceneItem={sceneItem}
                    sceneItemIndex={sceneItemIndex}
                    sceneComponents={sceneComponents}
                    key={sceneItemIndex}
                />
            )}
        </SceneItems>
        <BottomMenuItems>
            <BottomMenuItem onClick={submitTokens}>
                <BottomMenuIconHolder>
                    <SumbitTokensIcon />
                </BottomMenuIconHolder>
                Submit tokens
            </BottomMenuItem>
            <BottomMenuItem onClick={() => setFlexibleModalChildren(<Tab3DObjects />)}>
        <BottomMenuIconHolder>
            <SumbitTokensIcon />
        </BottomMenuIconHolder>
        Submit decorations
    </BottomMenuItem>
        </BottomMenuItems >
    </SceneContainer >
}