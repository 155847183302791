import styled from 'styled-components';
import { Select, Tab, TextField } from '@mui/material';
import { TabList, TabPanel } from '@mui/lab';

export const CustomTabList = styled(TabList)`
  padding: 0px 24px 0 24px !important;
  width: 100% !important;
  box-sizing: border-box !important;
  display: flex !important;
  span {
    display: none !important;
  }
  .MuiTab-root {
    font-family: Montserrat !important;
    font-size: 10px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    flex: 1;
    background: #161616 !important;
    color: #7E8390 !important;
    text-transform: uppercase !important;
    border-radius: 50px !important;
  }
  .MuiTabPanel-root {
    padding: 0 !important;
  }
  .Mui-selected {
    flex: 1;
    background: linear-gradient(99.78deg, rgb(159, 11, 238) 0.69%, rgb(20, 91, 253) 98.32%) !important;
    color: #fff !important;
  }
  @media (max-width: 600px) {
    padding: 0px 16px 0 16px !important;
  }
`;
export const CustomTab = styled(Tab)`
  font-size: 14px !important;
  color: black !important;
  text-transform: capitalize !important;
  padding: 8px !important;
  box-sizing: border-box !important;
  min-height: auto !important;
  margin: 8px !important;
  justify-content: space-between !important;
  background: #eee !important;
  border-radius: 7px !important;

  svg {
    margin: 0 !important;
  }
  @media (max-width: 400px) {
    font-size: 12px !important;
    min-width: auto !important;
  }
`;
export const Container = styled.div`
  height: 100%;
  ${({ big }) => big ? 'min-height: 500px;' : ''}
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
`;
export const Messages = styled.div`
  display: flex;
  height: 100%;
  max-height: 500px;
  flex-direction: column;
  overflow-y: auto;
  gap: 5px;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: #161616;
    border-radius: 10px;
    -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
  }
  &::-webkit-scrollbar-thumb {
    background: #7E8390;
    border-radius: 10px;
  }
`;
export const Title = styled.div`
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 5px;
`;

export const LoadingText = styled.div`
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
`;
export const SubTitle = styled(Title)`
  font-size: 14px;
  margin-bottom: 0;
`;
export const BubbleMessageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ isSoika }) => isSoika ? 'row' : 'row-reverse'};
`
export const BubbleMessage = styled(Title)`
  font-size: 14px;
  margin-bottom: 0;
  background: ${({ isSoika }) => isSoika ? '#161616' : '#444'};
  border-radius: 5px;
  padding: 8px;
  width: fit-content;
`;
export const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  font-style: normal;
`
export const ButtonHull = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 40px;

  border-radius: 4px;
  border: 2px solid #6C43E0;
  box-sizing: border-box;

  color: #F0F0F0;
  background-color: transparent;
  text-transform: uppercase;

  :hover {
    cursor: pointer;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);  
  }
`;
export const StyledDropdown = styled(Select)`
  height: 30px;
  & .MuiOutlinedInput-notchedOutline {
    border-color: #6C43E0 !important; 
  }

  & .MuiInputBase-input {
    color: #fff;
  }
  
  .MuiSvgIcon-root {
    color: #fff !important;
  }
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;
export const SelectLabel = styled.div`
  color: #fff;
`
export const StyledTabPanel = styled(TabPanel)`
    height: 100%;
`;
export const StyledInput = styled(TextField)`
  box-shadow: none;
  border-color: transparent;
  width: 100%;

  .MuiInputLabel-root {
    color: #fff;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 0;
  }

  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
    webkit-appearance: none;
  }
  .MuiFormLabel-root {
    display: none;
  }

  .MuiOutlinedInput-root {
    padding-right: 20px;
    overflow: 'hidden';
    height: 64px;
    color: #fff;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    background: #161616;
    border-radius: 6px;

    & fieldset {
      &.MuiOutlinedInput-notchedOutline {
          border: 0;
      }
    }
  }
`