import { Input } from "antd";
import styled from "styled-components";

export const disabledStyle = {
    padding: 0,
    border: 'none',
    background: 'none'
}
export const UploadFile = styled.div`
  border: none;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='36' ry='36' stroke='%23B4B4B4' stroke-width='1' stroke-dasharray='5%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 36px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: ${(props) => (!props.hasImage ? 'auto' : '250px')};
  padding: 40px;
  margin: 20px 0;
  @media (max-width: 600px) {
    height: ${(props) => (!props.hasImage ? 'auto' : '150px')};
  }
  @media (max-width: 400px) {
    margin: 0;
  }
`;
export const UploadContainer = styled.div`
  width: ${(props) => (props.file ? '100%' : '250px')};
  @media (max-width: 600px) {
    width: ${(props) => (props.file ? '100%' : '150px')};
  }
  @media (max-width: 400px) {
    width: 100%;
  }
`;
export const Loader = styled.div`
  width: ${(props) =>
        props.matches ? 'calc(100% + 4rem)' : 'calc(100% + 2rem)'};
  height: ${(props) =>
        props.matches ? 'calc(100% + 4rem)' : 'calc(100% + 2rem)'};
  background-color: white;
  top: ${(props) => (props.matches ? '-2rem' : '-1rem')};
  left: ${(props) => (props.matches ? '-2rem' : '-1rem')};
  position: absolute;
  z-index: 400;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ControlGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  @media (max-width: 400px) {
    flex-direction: column;
    gap: 5px;
  }
`;
export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 50px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ColumnReverse = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

export const UploadFileInput = styled(Input)`
  cursor: pointer;
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  z-index: 99;
`;

export const UploadButton = styled.button`
  color: #fff;
  border: 0;
  background: #000;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 23px;
  margin-top: 10px;
  cursor: pointer;
  @media (max-width: 400px) {
    width: 70%;
  }
`;

export const TitleFrame = styled.div`
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: #fff;
`;
export const CheckBoxContainer = styled.div`
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
`;