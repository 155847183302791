import {TypeEntity, UpdateState} from './Constants'
import SerializableObject, {inheritConstructor, SerAttr} from './SerializableObject'

export default class RenderableItem extends SerializableObject {
  constructor({project, owner, skipAdd, parsingFlag}) {
    super(...arguments)

    this.updateStates = UpdateState.None

    this.hoverable = true
    this.interactiveColors = false

    this.data = {}

    //RenderableItem.prototype.onConstructor.apply(this, arguments)
    //this.onConstructor(arguments)
    //this.onConstructor.apply(this, arguments)
    this.onConstructor(...arguments)

    if (!project) project = owner // && owner.type === TypeEntity.Project ? owner : (owner ? owner.project : owner)

    // console.log('RenderableItem ctr=', this, 'skipAdd=', skipAdd, 'project=', project, 'owner=', owner)
    if (project && project.add) {
      if (skipAdd !== true) project.add(this)
      else this.project = project
    } else {
      console.warn('no project=', this)
      // debugger /*eslint no-debugger: 'off'*/
    }

    if (parsingFlag) this.setUpdateState(UpdateState.All)

    this.init()
  }

  onConstructor({project, owner, skipAdd, parsingFlag}) {
    // console.log('onConstructor=', this.constructor.name, 'arguments=', arguments, 'params=', project, owner, skipAdd, parsingFlag)

    if (this.type === undefined) this.type = TypeEntity.RenderableItem
    if (this.hasRenderer === undefined) this.hasRenderer = true

    this.addProperty('hovered', {
      control: 'edit',
      type: 'bool',
      visible: false,
      value: {
        default: false,
        get: property => {
          return property._value
        },
        set: (property, value) => {
          if (property._value === value) return
          property._value = value
          this.setUpdateState(UpdateState.Style)
        }
      }
    })

    this.addProperty('selected', {
      control: 'edit',
      type: 'bool',
      visible: false,
      value: {
        default: false,
        get: property => {
          return property._value
        },
        set: (property, value) => {
          if (property._value === value) return
          property._value = value
          this.setUpdateState(UpdateState.Style)
        }
      }
    })

    this.addProperty('visible', {control: 'edit', type: 'bool', visible: false, value: true}) // not used for now

    this.addProperty('draggable', {control: 'edit', type: 'bool', visible: false, value: true})

    this.addProperty('name', {
      control: 'edit',
      type: 'string',
      value: {
        default: '',
        get: property => {
          return property._value
        },
        set: (property, value) => {
          if (property._value === value) return
          property._value = value
          this.project.app.events.trigger('change', {item: this, property: 'name'})
        }
      }
    })
  }

  init() {
    if (this.hasRenderer) {
      const rendererCtr = this.project.app.engineComponent.getConstructorByType(this.type)
      if (rendererCtr) this.renderer = new rendererCtr({app: this.project.app, item: this})

      if (this.renderer) this.renderer.init()
    }
  }

  destroy() {
    if (this.renderer) {
      this.renderer.destroy()
      this.renderer = null
    }
  }

  update(flags) {
    if (this.renderer) this.renderer.update(flags)
  }

  setUpdateState(updateState, state = true) {
    // console.log(this.constructor.name + '.setUpdateState=' + updateState + ', state=' + state)
    if (state) this.updateStates |= updateState
    else this.updateStates &= ~updateState

    this.onSetUpdateState()
  }

  onSetUpdateState() {
    if (this.project) {
      if (this.updateStates === UpdateState.None) this.project.itemsToUpdate.delete(this)
      else this.project.itemsToUpdate.add(this)
    }
  }

  dispatchUpdateStates(states) {
    states = this.updateStates

    if (states === UpdateState.None) return
    // console.log('dispatchUpdateStates=', this)

    // if (states & UpdateState.Compute && this.compute) this.compute()
    // if (states & UpdateState.Style) this.updateActiveStyle()
    if (states !== UpdateState.None) this.update(states)

    this.updateStates = UpdateState.None
  }

  getById(id) {
    if (this.project) return this.project.getById(id)
    else console.warn('getById - no project=', this)

    return super.getById.apply(this, arguments)
  }
}
inheritConstructor(RenderableItem, SerializableObject, 'RenderableItem', {
  type: TypeEntity.RenderableItem,
  serializable: [new SerAttr('visible', {defaultValue: true}), new SerAttr('draggable', {defaultValue: true}), new SerAttr('name', {defaultValue: ''})]
})
