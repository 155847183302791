import { useState, useContext, useEffect } from "react"
import {
    DecorationsIconStyled,
    DragIconStyled,
    FramesIconStyled,
    MagicWandIconStyled,
    IconBox,
    IconContainerLeft,
    IconContainerRight,
    LightIconStyled,
    MenuIconStyled,
    PointerIconStyled,
    Separator,
    ToolsContainer,
    ToolsContainerSmall,
    ToolsWrapper
} from "./styles";
import { Context } from "../../store/Context";
import ObjectsList3D from "../ContentTabs/Tabs/ObjectsList3D/index";
import LightList from "../ContentTabs/Tabs/LightList";
import TabMagicNFT from "../ContentTabs/Tabs/TabMagicNFT";

export default function ToolsPanel({ setModalChildren, setShowFramesPanel, showFramesPanel }) {
    const { editorStore } = useContext(Context)
    const [expanded, setExpanded] = useState(true);
    const [selectedToolId, setSelectedToolId] = useState(0);

    useEffect(() => {
        if (selectedToolId !== 3) {
            setShowFramesPanel(false)
        }
    }, [selectedToolId, setShowFramesPanel]);

    const menuContents = [
        {
            id: 0,
            name: "Pointer",
            icon: (props) => <PointerIconStyled {...props} />,
            type: "tool"
        },
        {
            id: 1,
            name: "Transformer",
            icon: (props) => <DragIconStyled {...props} />,
            type: "tool"
        },
        {
            id: 2,
            name: "MagicWand",
            icon: (props) => <MagicWandIconStyled {...props} />,
            type: "tool"
        },
        {
            id: 3,
            name: "Frames",
            icon: (props) => <FramesIconStyled {...props} />,
            type: "tool",
            onClick: () => setShowFramesPanel(!showFramesPanel)
        },
        {
            id: 4,
            name: "Decorations",
            icon: (props) => <DecorationsIconStyled {...props} />,
            type: "tool",
            onClick: () => setModalChildren(<ObjectsList3D />)
        },
        {
            id: 5,
            name: "Light",
            icon: (props) => <LightIconStyled {...props} />,
            type: "tool",
            onClick: () => setModalChildren(<LightList />)
        }
    ]

    const onToolClick = (id) => {
        if (menuContents[id]?.type === "button") return menuContents[id].onClick();
        if (menuContents[id]?.type !== "tool") return;
        setSelectedToolId(id);
        const tool = editorStore.editor.toolManager.tools[id];
        if (!tool) {
            console.warn(`Tool with id ${id} not found`);
            return;
        }
        if (tool?.name === "MagicWand" && !tool?.inited) {
            editorStore.editor.toolManager.toolMagicWand.initReactCallback(
                setModalChildren, 
                <TabMagicNFT />
            );
        }
        editorStore.editor.toolManager.activeTool = tool;
    }

    return <ToolsWrapper>
        {expanded ?
            <ToolsContainer>
                <IconContainerLeft>
                    {menuContents.map(menuItem => {
                        const isSelected = menuItem?.id === selectedToolId;

                        return <IconBox
                            onClick={() => {
                                if (menuItem?.onClick) menuItem?.onClick()
                                onToolClick(menuItem?.id)
                            }}
                            selected={isSelected}
                            key={menuItem?.id}
                        >
                            {menuItem?.icon({ selected: isSelected })}
                        </IconBox>
                    })}
                    <Separator />
                </IconContainerLeft>
                <IconContainerRight>
                    <IconBox onClick={() => setExpanded(!expanded)}>
                        <MenuIconStyled inverted="true" />
                    </IconBox>
                </IconContainerRight>
            </ToolsContainer>
            :
            <ToolsContainerSmall>
                <IconContainerRight style={{ width: "100%" }}>
                    <IconBox onClick={() => setExpanded(!expanded)}>
                        <MenuIconStyled />
                    </IconBox>
                </IconContainerRight>
            </ToolsContainerSmall>
        }
    </ToolsWrapper>
}