import { joinKey, mergeNFT } from './helpers';
import { MARKET_LIST_CONTEXT } from './const';

const isIncludeNFT = (list = [], nft) =>
  list?.length
    ? Boolean(list?.find((item) => joinKey(item) === joinKey(nft)))
    : false;

const marketListReducer = (state, action) => {
  switch (action.type) {
    case MARKET_LIST_CONTEXT.SET_PAGE: {
      return {
        ...state,
        page: state.page + 1,
      };
    }
    case MARKET_LIST_CONTEXT.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case MARKET_LIST_CONTEXT.SET_BACKEND_DATA: {
      const { data } = action.payload;

      if (!Array.isArray(data)) return state;

      return {
        ...state,
        list: state.list?.map((nft) => mergeNFT(nft, data)),
      };
    }
    case MARKET_LIST_CONTEXT.SET_MORALIS_DATA: {
      const { data, reinit } = action.payload;
      const { results, count, cursor } = data || {};

      let copy_state = { ...state };

      if (!Array.isArray(results)) return state;

      if (reinit) {
        copy_state = {
          page: 0,
          list: 0,
          isLoaded: false,
        };
      }

      if (cursor) {
        copy_state = { ...copy_state, cursor };
      }
      if (count === 0 || count === copy_state.list?.length + results?.length)
        copy_state = {
          ...copy_state,
          isLoaded: true,
        };

      const list = [];

      for (const nft of results) {
        const isInclude = isIncludeNFT(copy_state.list, nft);
        const isDuplicated = isIncludeNFT(list, nft);

        if (!isInclude && !isDuplicated) list.push(nft);
      }

      if (list.length)
        copy_state = {
          ...copy_state,
          list: copy_state.list.length ? [...copy_state.list, ...list] : list,
        };

      return {
        ...state,
        ...copy_state,
      };
    }

    default:
      return state;
  }
};

export default marketListReducer;
