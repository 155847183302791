import { InputAdornment, TextField } from "@mui/material"
import { SceneSearchIcon } from "../../assets/icons/SceneSearchIcon"

export const SceneSearch = (props) => {
    return (
        <TextField
            sx={{
                zIndex: 1,
                maxWidth: 176,
                boxShadow: 'none',
                borderColor: 'transparent',
                '& .MuiOutlinedInput-root': {
                    padding: '6px 4px',
                    paddingRight: '10px',
                    overflow: 'hidden',
                    height: 32,
                    color: '#B4B4B4',
                    borderRadius: 1,
                    background: '#262626',
                    '& .MuiOutlinedInput-input': {
                        padding: 0,
                        fontSize: 12,
                        fontFamily: 'Montserrat',
                        width: 140,
                        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                            WebkitAppearance: 'none',
                        },
                    },

                    '& fieldset': {
                        '&.MuiOutlinedInput-notchedOutline': {
                            border: 0,
                        },
                    },
                },
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SceneSearchIcon />
                    </InputAdornment>
                ),
            }}
            onChange={props?.handleChange}
            value={props?.value}
            placeholder={props?.placeholder}
        />
    )
}