import styled from "styled-components";
import DoneIcon from '@mui/icons-material/Done';
import { Button } from "antd";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;
  color: #fff;
`;
export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  font-size: 16px;
`;
export const ButtonPublish = styled(Button)`
    border-radius: 50px;
    background: ${({ disabled }) => disabled ? '#969696' : 'linear-gradient(90deg, #4D44FE 0%, #8552F6 100%)'};
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px -2px 0px 0px rgba(0, 0, 0, 0.20) inset;
    padding: 0;
    border: none;
    text-transform: uppercase;
    width: 200px;
    height: 40px;
    color: ${({ disabled }) => disabled ? '#bbb' : '#fff'};
    cursor: ${({ disabled }) => disabled ? 'auto' : 'pointer'};
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
`;

const NftCardBigSuccess = ({ onBackClick }) => {
    return <Wrapper>
        <RowWrapper>
            NFT successfully submitted <DoneIcon sx={{ color: 'green' }} />
        </RowWrapper>
        <RowWrapper>
            <ButtonPublish onClick={onBackClick}>
                Back
            </ButtonPublish>
        </RowWrapper>
    </Wrapper>
}
export default NftCardBigSuccess;