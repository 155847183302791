import BaseRendererThree from './Base'

import {Mesh, BoxGeometry, MeshStandardMaterial, Color} from 'three'

import {UpdateState} from '../../../../Core/Constants'

const geometry = new BoxGeometry(1, 1, 1)
// const material = new MeshStandardMaterial()
// console.log('create box geometry=', geometry)

export default class BoxRendererThree extends BaseRendererThree {
  // constructor () {super(...arguments)}

  init () {
    const material = new MeshStandardMaterial()
    this.mesh = new Mesh(geometry, material)
    this.engineComponent.sceneComponent.scene.add(this.mesh)

    this.mesh._itemMP = this.item
  }

  destroy () {
    if (this.mesh) {
      this.mesh.material.dispose()
      this.mesh.geometry.dispose()
      this.engineComponent.sceneComponent.scene.remove(this.mesh)
      this.mesh = null
    }
  }

  update (flags) {
    if (this.mesh) {
      if (flags & UpdateState.Shape) {
        this.mesh.scale.set(this.item.data.size.value.x, this.item.data.size.value.y, this.item.data.size.value.z)
        this.mesh.position.set(this.item.data.position.value.x, this.item.data.position.value.y, this.item.data.position.value.z)
      }

      if (flags & UpdateState.Style) {
        this.mesh.material.color = this.item.data.hovered.value ? new Color(0x00ff00) : this.item.data.selected.value ? new Color(0xff0000) : new Color(0xffffff)
      }
    }
  }
}