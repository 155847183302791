import { Object3D, Raycaster, Vector3 } from "three";
import MultiplayerControls from "./MultiplayerControls";

export default class FPSControls extends MultiplayerControls {
    constructor(app) {
        super();
        this.app = app;

        document.addEventListener("spaceLoaded", (event) => {
            console.log("space loaded", event.detail.collider)
            this.initialSpace = event.detail.object.renderer.meshCollider.parent;
            this.initialSpaceCollider = event.detail.collider
        });

        this.userBodyObject = null;
        this.minCollisionDist = 0.25;
        this.rayCaster = new Raycaster();
    }

    /**
     * This method is called every time player moves to update collisions with 3d space
     * and position of the player
     * @param { Vector3 } velocity - players velocity vector (dt nomralized)
     * @param { Object3D } body - players body 3d object
     */
    updatePosition(velocity, body) {
        if (!this.initialSpace) return;

        // Cast rays in +x, -x, +z, -z directions from player
        const boundsVectors = [new Vector3(1, 0, 0), new Vector3(-1, 0, 0), new Vector3(0, 0, 1), new Vector3(0, 0, -1)];
        for (let vector_i = 0; vector_i < boundsVectors.length; vector_i++) {
            // update raycaster
            this.rayCaster.set(body.position, boundsVectors[vector_i]);

            // find intersections with 3d space
            const intersections = this.rayCaster.intersectObject(this.initialSpace.children[1], true);
            if (intersections.length > 0) {
                const nearestHit = intersections[0];
                if (!nearestHit?.distance) continue;

                if (nearestHit.distance + velocity.length() <= this.minCollisionDist) {
                    const normalWorld = nearestHit.face.normal.clone().transformDirection(nearestHit.object.matrixWorld);
                    if (velocity.dot(normalWorld) < 0) {
                        // "silde against wall" formula
                        velocity.sub(normalWorld.multiplyScalar(velocity.dot(normalWorld)));
                    }
                }
            }
        }
    }

    applyGravity(velocity, body, dt) {
        if (!this.initialSpace) return;
        // this piece of code is casting a ray from player's waist to the ground
        // body.position.y is 1.63, so subtracting 1 from y gives us 0.63
        // if raycaster distance is greater than 0.63, apply the force of gravity 
        const maxStepHeight = new Vector3(0, -1, 0);
        this.rayCaster.set(maxStepHeight.add(body.position), new Vector3(0, -1, 0));
        const intersections = this.rayCaster.intersectObject(this.initialSpace.children[1], true);
        const distance = intersections[0]?.distance;

        // be aware that distance could be undefined if raycasted did not hit anything
        if (distance >= 0.63) {
            body.position.y += 0.63 - distance;
        } else {
            const downForce = -9.8 * dt;
            const distance_diff = 0.63 - distance;
            // if downForce is greater than actual distance to the floor it will
            // result in user's avatar falling through the floor. To fix that,
            // distance to the floor check is implemented 
            velocity.y += distance_diff > downForce ? distance_diff : downForce;
        }
        body.position.add(velocity);

        if (body.position.y < -50) {
            body.position.copy(new Vector3(0, 1.63, 0));
        }
    }

    updatePosition3P(velocity, bodyPosAdjusted) {
        if (!this.initialSpace) return;

        // Cast rays in +x, -x, +z, -z directions from player
        const boundsVectors = [new Vector3(1, 0, 0), new Vector3(-1, 0, 0), new Vector3(0, 0, 1), new Vector3(0, 0, -1)];
        for (let vector_i = 0; vector_i < boundsVectors.length; vector_i++) {
            // update raycaster
            this.rayCaster.set(bodyPosAdjusted, boundsVectors[vector_i]);

            // find intersections with 3d space
            const intersections = this.rayCaster.intersectObject(this.initialSpace.children[1], true);
            if (intersections.length > 0) {
                const nearestHit = intersections[0];
                if (!nearestHit?.distance) continue;

                if (nearestHit.distance + velocity.length() <= this.minCollisionDist) {
                    const normalWorld = nearestHit.face.normal.clone().transformDirection(nearestHit.object.matrixWorld);
                    if (velocity.dot(normalWorld) < 0) {
                        // "silde against wall" formula
                        velocity.sub(normalWorld.multiplyScalar(velocity.dot(normalWorld)));
                    }
                }
            }
        }
    }

    applyGravity3P(velocity, bodyPosAdjusted, dt) {
        if (!this.initialSpace) return;
        // this piece of code is casting a ray from player's waist to the ground
        // body.position.y is 1.63, so subtracting 1 from y gives us 0.63
        // if raycaster distance is greater than 0.63, apply the force of gravity 
        const maxStepHeight = new Vector3(0, -1, 0);
        this.rayCaster.set(maxStepHeight.add(bodyPosAdjusted), new Vector3(0, -1, 0));
        const intersections = this.rayCaster.intersectObject(this.initialSpace.children[1], true);
        const distance = intersections[0]?.distance;

        // be aware that distance could be undefined if raycasted did not hit anything
        if (distance >= 0.63) {
            bodyPosAdjusted.y += 0.63 - distance;
        } else {
            const downForce = -9.8 * dt;
            const distance_diff = 0.63 - distance;
            // if downForce is greater than actual distance to the floor it will
            // result in user's avatar falling through the floor. To fix that,
            // distance to the floor check is implemented 
            velocity.y += distance_diff > downForce ? distance_diff : downForce;
        }
        bodyPosAdjusted.add(velocity);

        if (bodyPosAdjusted.y < -50) {
            bodyPosAdjusted.copy(new Vector3(0, 1.63, 0));
            velocity.y = 0;
        }
        bodyPosAdjusted.y -= 1.63;
        return bodyPosAdjusted; 
    }
}