import {Button} from '@mui/material'
import OptionsIcon from '../../assets/icons/OptionsIcon'
import styled from 'styled-components'

const Options = styled(Button)`
  background: linear-gradient(99.78deg, rgb(159, 11, 238) 0.69%, rgb(20, 91, 253) 98.32%) !important;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.08) !important;
  border-radius: 4px !important;
  padding: 8px !important;
  position: absolute !important;
  bottom: 24px;
  left: 24px;
  gap: 8px;
  text-transform: capitalize !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: white !important;
`

export const OptionsBtn = ({event}) => {
  return (
    <Options onClick={event}>
      <OptionsIcon /> Options
    </Options>
  )
}
