import {createContext} from 'react'

import EditorStore from './EditorStore'
import ServiceStore from './ServiceStore'
import UserStore from './UserStore'

export const defaultContext = {
  editorStore: new EditorStore(),
  serviceStore: new ServiceStore(),
  userStore: new UserStore()
}
export const Context = createContext(defaultContext)
