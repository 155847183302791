import { Button } from "antd"
import styled from "styled-components"
import Light from "../../../../editor/Core/Entities/Light"
import { useContext } from "react"
import { Context } from "../../../store/Context"

const Container = styled.div`
    display: flex;
    flex-direction: column;
    color: #fff;
    height: 100%;
    gap: 20px;
`;
const LightItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    border-radius: 6px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    background: #161616;
`;
const ButtonCreate = styled(Button)`
    background: linear-gradient(99.78deg, rgb(159, 11, 238) 0.69%, rgb(20, 91, 253) 98.32%) !important;
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px -2px 0px 0px rgba(0, 0, 0, 0.20) inset;
    width: 150px;
    height: 39px;
    margin-left: 10px;
    color: #fff !important;
    border-radius: 50px;
    padding: 0;
    border: none;
    text-transform: uppercase;
    font-size: 10px;
    cursor: pointer;
`;
const Title = styled.div`
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
`;

export default function LightList() {
    const { editorStore } = useContext(Context)

    const buttons = [
        {
            label: "Ambient",
            type: "ambient"
        },
        {
            label: "Directional",
            type: "directional"
        },
        {
            label: "Point",
            type: "point"
        }
    ]

    const create = (type) => {
        const lightObject = new Light({ project: editorStore.editor.project })
        lightObject.data.intensity.value = 1
        lightObject.data.castShadow.value = true
        lightObject.data.name.value = `${type.slice(0, 1).toUpperCase()}${type.slice(1)} Light`
        lightObject.data.type.value = type
    }

    return <Container>
        <Title>
            Create Light
        </Title>
        {buttons.map(item =>
            <LightItem>
                {item.label}
                <ButtonCreate
                    onClick={() => create(item.type)}
                >
                    Create
                </ButtonCreate>
            </LightItem>
        )}
    </Container>
}