import Stats from 'stats.js/src/Stats'
import { EventDispatcher } from 'EventDispatcher'

import ManagerNFT from './ManagerNFT'
import Selector from './Selector'
import Hoverer from './Hoverer'
import ToolManager from './ToolManager'
import ActionManager from './ActionManager'
import LoadingManager from './LoadingManager';
import socketHandler from '../api/Socket';
import Soika from './Soika'

import ToolArrow from './Tools/ToolArrow'
import ToolTransformer from './Tools/ToolTransformer'

//engine specific start
import EngineThree from './Renderer/Interfaces/Three/Engine'

import BoxRendererThree from './Renderer/Interfaces/Three/Entities/Box'
import ObjectItemRendererThree from './Renderer/Interfaces/Three/Entities/ObjectItem'
import PictureRendererThree from './Renderer/Interfaces/Three/Entities/Picture'
//engine specific end

import Box from './Core/Entities/Box'
import ObjectItem from './Core/Entities/ObjectItem'
import Picture from './Core/Entities/Picture'
import CameraView from './Core/Entities/CameraView'

import Project from './Core/Project'
import { TypeEntity, UpdateState } from './Core/Constants'
import LightRendererThree from './Renderer/Interfaces/Three/Entities/Light'
import ToolMagicWand from './Tools/ToolMagicWand'

export default class ArtRoomsEditor {
  constructor() {
    this.events = new EventDispatcher()

    this.editMode = true
  }

  create({ canvas, container } = {}) {
    this.managerNFT = new ManagerNFT({ app: this })
    this.selector = new Selector({ app: this })
    this.hoverer = new Hoverer({ app: this })
    this.toolManager = new ToolManager({ app: this })
    this.socket = new socketHandler({ app: this })
    this.soika = new Soika({ app: this })
    this.loadingManager = new LoadingManager({ app: this })
    this.actionManager = new ActionManager({ app: this })

    this.toolManager.add(new ToolArrow({ app: this }))
    this.toolManager.add(new ToolTransformer({ app: this }))
    this.toolManager.add(new ToolMagicWand({ app: this }))

    this.project = new Project(this)
    
    //engine specific start
    this.engineComponent = new EngineThree({ app: this, canvas, container })

    this.engineComponent.sceneComponent.backgroundColor = 0xebeaef

    this.engineComponent.addRenderer({ renderer: LightRendererThree, type: TypeEntity.Light })
    this.engineComponent.addRenderer({ renderer: BoxRendererThree, type: TypeEntity.Box })
    this.engineComponent.addRenderer({ renderer: ObjectItemRendererThree, type: TypeEntity.ObjectItem })
    this.engineComponent.addRenderer({ renderer: PictureRendererThree, type: TypeEntity.Picture })
    //engine specific end
  }

  destroy() {
    this.events.trigger('editorDestroy')

    if (this.toolManager) {
      this.toolManager.destroy()
      this.toolManager = null
    }

    if (this.stats) {
      // if (this.stats.dom.parentNode) this.stats.dom.parentNode.removeChild(this.stats.dom)
      this.stats.dom.remove()
      this.stats = null
    }

    if (this.engineComponent) {
      this.engineComponent.destroy()
      this.engineComponent = null
    }

    this.events.trigger('editorDestroyed')
  }

  async init() {
    this.events.trigger('editorInit')

    const debugPerformance = false
    if (debugPerformance) {
      this.stats = new Stats()
      this.engineComponent.container.appendChild(this.stats.dom)
      this.stats.dom.style.bottom = '0px'
      this.stats.dom.style.top = ''
      this.stats.drawPanel = this.stats.addPanel(new Stats.Panel('draw', '#ff8', '#221'))

      this.engineComponent.stats = this.stats
    }

    this.toolManager.init()
    this.toolManager.start()

    await this.engineComponent.init()
    this.engineComponent.start()

    const toolKey = 'Pointer'
    const tool = this.toolManager.tools.find(tool => tool.name === toolKey)
    // console.log('select tool=', toolKey, tool)
    if (tool) this.toolManager.activeTool = tool
    else console.warn('tool not found=', toolKey)

    //this.demo()
    const view = new CameraView({ project: this.project })

    this.engineComponent.focused = true
    this.project.dispatchUpdateStates()

    this.events.trigger('editorInited')
  }

  demo() {
    const box1 = new Box({ project: this.project })
    box1.data.position.value.y = 1
    box1.data.size.value.x = 1.5
    box1.data.size.value.y = 0.5
    box1.data.name.value = 'box1'

    const object1 = new ObjectItem({ project: this.project })
    //object1.data.position.value.x = 3
    //object1.data.position.value.y = 1.5
    object1.data.path.value = 'https://threejs.org/examples/models/gltf/MaterialsVariantsShoe/glTF/MaterialsVariantsShoe.gltf'
    object1.data.name.value = 'Materials variants shoe'

    const interior1 = new ObjectItem({ project: this.project })
    //interior1.data.path.value = 'models3d/brius_33.glb'
    //interior1.data.path.value = 'models3d/racks/sklad.glb'
    //interior1.data.path.value = 'http://localhost:3005/models3d/racks/sklad.glb'
    //interior1.data.path.value = './models3d/racks/sklad.glb'
    interior1.data.path.value = '../models3d/racks/sklad.glb'
    //interior1.data.path.value = 'models3d/racks/sklad_SMALL.glb'
    interior1.data.size.value.x = interior1.data.size.value.y = interior1.data.size.value.z = 0.1
    interior1.data.name.value = 'brius_33'
    //interior1.hoverable = false
    interior1.data.draggable.value = false

    const ratio = { w: 1, h: 1 }
    const url = '../../models3d/picture-frames/Frame_' + ratio.w + 'x' + ratio.h + '.glb'
    const frame = new Picture({ project: this.project })
    // frame.data.position.value.x = 3
    // frame.data.position.value.y = 1.5
    //frame.data.rotation.value.x = Math.PI * 0.5
    //quaternion
    frame.data.path.value = url
    frame.data.name.value = 'new frame ' + ratio.w + ':' + ratio.h
    frame.data.image.value = 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6'
  }

  setEditMode(editMode) {
    this.editMode = editMode
  }
}

