import React, { useEffect, useState, useContext } from 'react'

import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import Slider from '@mui/material/Slider'
import Box from '@mui/material/Box'
import { BorderBlock } from './styles'
import Stack from '@mui/material/Stack'
import styled from 'styled-components'
import { CoordinateInput } from '../Input/CoordinateInput'

export default function PropertySlider(props) {
  const [sliderValue, setSliderValue] = React.useState(
    props.propertyName ? props.property.value[props.propertyName] : props.property.value || 1
  )

  useEffect(() => {
    setSliderValue(
      props.propertyName ? props.property.value[props.propertyName] : props.property.value || 1
    );
  }, [props])

  const handleChange = (event) => {
    if (props.propertyName) props.property.value[props.propertyName] = event.target.value;
    else props.property.value = event.target.value;
    setSliderValue(
      props.propertyName ? props.property.value[props.propertyName] : props.property.value
    )
  };

  const marks = [
    {
      value: 1,
      label: '1x'
    },
    {
      value: 5,
      label: '5x'
    },
    {
      value: 10,
      label: '10x'
    }
  ]

  return (
    <BorderBlock>
      <CoordinateInput
        label=""
        value={sliderValue}
        handleChange={handleChange}
      />
      <Slider
        sx={{
          width: '60%',
          '& .MuiSlider-track': {
            display: "none"
          },
          '& .MuiSlider-rail': {
            color: "#262626",
            opacity: 1,
            height: "100%",
            borderRadius: "4px"
          },
          '& .MuiSlider-thumb': {
            color: "#838383",
            borderRadius: "4px",
            height: "100%",
            width: "12px"
          },
          '& .MuiSlider-thumb:hover': {
            boxShadow: "unset"
          },
          '& .MuiSlider-thumb:active': {
            boxShadow: "unset"
          }
        }}
        step={0.01}
        type="range"
        min={0.05}
        max={40}
        value={sliderValue}
        onChange={handleChange}
      />
    </BorderBlock>
  )
}
