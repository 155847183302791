import styled from 'styled-components';

export const ModalTitle = styled.div`
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: #fff;
`;
export const Wrapper = styled.div`
    position: relative;    
    display: flex;
    flex-direction: column;
    gap: 12px;
    color: #fff;

    --banner-padding: 10px;
`;
export const Banner = styled.img`
    width: 100%;
    height: 250px;
    border-radius: 10px;
`;
export const BannerWrapper = styled.div`
    position: relative;
    display: flex;
    height: 250px;
`;
export const DarkTransparentBg = styled.div`
    background: rgba(0, 0, 0, 0.5);
    padding: 6px;
    border-radius: 4px;
    height: fit-content;
    display: flex;
`;
export const TopRow = styled.div`
    position: absolute;
    top: var(--banner-padding);
    right: var(--banner-padding);
`;
export const BottomRow = styled.div`
    position: absolute;
    bottom: var(--banner-padding);
    left: var(--banner-padding);
`;
export const BottomRowWrapper = styled.div`
    display: flex;
    gap: 12px;
`;
export const Title = styled.div`
    position: relative;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    padding: 8px 0px;
    text-align: start;
    word-break: break-word;
`;
export const Avatar = styled.img`
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 100%;
`;
export const Property = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
`;
export const PropertyTitle = styled.div`
    white-space: nowrap;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: rgb(126, 131, 144);
`;
export const PropertyValue = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
`;
export const PropertyCentered = styled(Property)`
    justify-content: center;
    align-items: center;
`;
export const ClaimButton = styled.button`
    all: unset;
    height: 16px;
    width: 50px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ disabled }) => disabled ? '#969696' : 'linear-gradient(99.78deg, rgb(159, 11, 238) 0.69%, rgb(20, 91, 253) 98.32%)'};
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: rgb(255, 255, 255);
    padding: 8px;
    opacity: 1;
    cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
`;
export const ProgressBarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
`
export const ProgressBarRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;
export const ProgressValue = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
`;
export const Progress = styled.div`
    width: ${({ width }) => `${width}%`};
    height: 4px;
    background: linear-gradient(99.78deg, rgb(159, 11, 238) 0.69%, rgb(20, 91, 253) 98.32%);
    border-radius: 4px;
`;
export const StatsWrapper = styled(ProgressBarRow)`
    gap: 8px;
`;
export const StatsContainer = styled.div`
    width: calc(33% - 8px);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 24px;
    border-radius: 10px;
`;
export const StatsTitle = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
`;
export const StatsSubTitle = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: rgb(126, 131, 144);
`;
export const StatsValue = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
`;