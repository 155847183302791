import { useContext, useEffect, useState, useRef } from 'react'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import Moralis from '../../../const/moralis'
import { BlueBall, EngineContainer } from './styles'
import LeftPanel from '../components/Panels/LeftPanel'
import RightPanel from '../components/Panels/RightPanel'
import { Context } from '../store/Context'
import { SaveBtn } from '../components/Buttons/SaveBtn'
import { SubmitTokenBtn } from '../components/Buttons/SubmitToken'
import { upgradeProject } from '../../editor/Core/Algs/Basic'
import ObjectItem from '../../editor/Core/Entities/ObjectItem'
import { OptionsBtn } from '../components/Buttons/OptionsBtn'
import { BuyNFTPanel } from '../components/Panels/BuyNFTPanel'

import { PointerLocker } from '../components/Modals/PointerLocker'
import { ProgressScreen } from '../components/Modals/ProgressScreen'
import { PreviewBtn } from '../components/Buttons/PreviewBtn'
import { Sight } from '../components/Sight'
import { SubmitTokenModal } from '../components/Modals'
import { SpaceOptionsModal } from '../components/Modals'
import { EditorProvider, useEditor } from '../store/editor-context'
import { useFetchEditorContext } from '../store/editor-context/fetch'
import { EditBtn } from '../components/Buttons/EditBtn'
import { saveRoom } from '../../api/dbAPI'
import { $host } from '../../api/base'
import { AccessDenied } from '../components/Placeholders'
import MultiplayerControlsPanel from '../components/Panels/MultiplayerControlsPanel'
import { MultiplayerSettingsPanel } from '../components/Panels/MultiplayerSettingsPanel'
import isMobileAndTablet from '../../../helpers/isMobile'
import { TypeEntity } from '../../editor/Core/Constants'
import { useLayoutEffect } from 'react'
import TouchControls from '../../editor/Renderer/Interfaces/Three/CameraControllers/ToutchControls/TouchControls'
import Header from '../components/Panels/Header'
import ToolsPanel from '../components/Panels/ToolsPanel'
import FlexibleModal from '../components/Modals/flexible'
import FramesPanel from '../components/Panels/FramesPanel'
import Light from '../../editor/Core/Entities/Light'
import { FlexibleModalContext } from '../contexts/flexible-modal/context'
import { Alert, Snackbar } from '@mui/material'
import { getUrlProfile } from '../../../helpers/getUrlProfile'
import { EditorSnackbarContext } from '../contexts/editor-snackbar'
import { getImageMeta } from '../../editor/Helpers/getImageMeta'
import { getFittedAspect } from '../../editor/Helpers/getFittedAspect'
import Picture from '../../editor/Core/Entities/Picture'
import { displayNFTScript } from '../helpers/displayNFTScript'
import { ClaimProduct } from '../components/Modals/ClaimProduct'
import { PCFSoftShadowMap } from 'three'

const VIEW_MODE = 2
const EDIT_MODE = 3

const EditPageChild = () => {
  useFetchEditorContext()
  const [showLeftPanel, setShowLeftPanel] = useState(true);
  const [showRightPanel, setShowRightPanel] = useState(false);
  const [showNFTBuyPanel, setShowNFTBuyPanel] = useState(false);
  const [showFramesPanel, setShowFramesPanel] = useState(false);
  const [showMPSettings, setShowMPSettings] = useState(false);
  const { address, uid, mode } = useParams();
  const [searchParams] = useSearchParams();
  const [editEnabled, setEditEnabled] = useState(mode === 'edit');
  const [previewMode, setPreviewMode] = useState(mode === 'preview');
  const [showProgressScreen, setShowProgressScreen] = useState(true);
  const [MPRoomData, setMPRoomData] = useState([]);
  const [NFTData, setNFTData] = useState(null);
  const [currentItemData, setCurrentItemData] = useState(null);
  const [leftTabIndex, setLeftTabIndex] = useState('1');
  const [spaceSettingModalOpened, setSpaceSettingModalOpened] = useState(false);
  const [flexibleModalChildren, setFlexibleModalChildren] = useState(null);
  const [spaceUri, setSpaceUri] = useState(null);
  const isMounted = useRef(true);
  const [isSnackbarOpen, setSnackBarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("Metaspace saved");
  const [isSavingSpace, setIsSavingSpace] = useState(false);

  const { editorStore, serviceStore, userStore } = useContext(Context)
  const navigate = useNavigate()
  const [loaded, setLoaded] = useState(false)

  const { space, isLoading, isError } = useEditor()

  const pushToEdit = () => {
    navigate(`/space/${address}/${uid}/edit`)
    navigate(0)
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarOpen(false);
  };

  const triggerSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackBarOpen(true);
  }

  const onKeyDown = async (event) => {
    if (event.key === "z" && event.ctrlKey && editEnabled) {
      editorStore.editor.actionManager.goBack();
    }
    if (event.key === "y" && event.ctrlKey && editEnabled) {
      editorStore.editor.actionManager.goForward();
    }
  }

  const addDisplayNFT = async () => {
    const nft = editorStore.editor.managerNFT?.roomArtworks[0]?.item;
    if (!nft) {
      console.warn("No NFTs submitted, skipping display addDisplayNFT");
      return;
    }
    const picture = await getImageMeta(nft.metadata.image);
    const fitted_aspect = getFittedAspect(picture.width / picture.height);
    const url = '../../models3d/picture-frames/Frame_' + fitted_aspect + '.glb';
    const frame = new Picture({ project: editorStore.editor.project });
    frame.data.position.value.x = 0;
    frame.data.position.value.y = 1.5;
    frame.data.position.value.z = 5;
    frame.data.rotation.value.x = 3.1415926 / 2;
    frame.data.aspect.value = `${fitted_aspect.split('x')[0]}:${fitted_aspect.split('x')[1]}`;
    frame.data.path.value = url;
    frame.data.name.value = nft.metadata.name;
    frame.data.script.value = displayNFTScript;
    // add NFT metadata
    frame.data.imageMeta.value = {
      token_id: nft.token_id,
      token_address: nft.token_address
    }
    frame.data.image.value = nft.metadata.image;
    frame.data.scale.value.xyz = 2;

    editorStore.editor.project.dispatchUpdateStates();
  }

  const firstSpaceLoad = async (data) => {
    // Triggered when the space loads for the very first time after creation
    // Add base space object
    const demoObj = new ObjectItem({ project: editorStore.editor.project });
    demoObj.data.path.value = data?.space?.animation_url;
    demoObj.data.name.value = data?.space?.name;
    demoObj.data.draggable.value = false;
    editorStore.editor.project.baseSpaceName = data?.space?.name;

    // Add lights
    const ambientLightObject = new Light({ project: editorStore.editor.project });
    ambientLightObject.data.intensity.value = 1;
    ambientLightObject.data.castShadow.value = false;
    ambientLightObject.data.name.value = "Ambient Light";
    ambientLightObject.data.type.value = "ambient";

    const directionalLightObject = new Light({ project: editorStore.editor.project });
    directionalLightObject.data.intensity.value = 1;
    directionalLightObject.data.castShadow.value = true;
    directionalLightObject.data.name.value = "Directional Light";
    directionalLightObject.data.type.value = "directional";
    directionalLightObject.data.position.value.y = 2;
  
    // Add NFT display
    await addDisplayNFT();
  }

  const loadMetaSpace = async () => {
    if (!loaded) {
      editorStore.editor.project.id = `${address}&${uid}`

      const { data } = await $host.get(`/space/${address}&${uid}`)

      await editorStore.editor.managerNFT.init(data?.all_tokens)
      const uri = await fetch(data?.space?.uri).then(res => res.json())
      data.space = uri
      setSpaceUri(uri);
      // console.log(data.space, data?.space?.animation_url)
      document.addEventListener("keydown", onKeyDown, false);

      editorStore.editor.project.destroy()
      const parseData = { log: { errors: [] } }
      if (data?.location?.objects) {
        editorStore.editor.project.baseSpaceName = data?.space?.name
        editorStore.editor.project.parse(data?.location, parseData)
        if (editorStore.editor.project.version !== data?.location?.version) {
          console.warn(
            'upgrading plan, from=' +
            data?.location?.version +
            ', to=' +
            editorStore.editor.project.version,
          )
          upgradeProject(editorStore.editor.project, data?.location)
        }
      } else if (data?.space?.animation_url) {
        await firstSpaceLoad(data);
      }

      const shadowsEnabled = !!editorStore?.editor?.project?.shadowsEnabled;
      editorStore.editor.engineComponent.setShadowMap(shadowsEnabled, PCFSoftShadowMap);

      try {
        await editorStore.editor.socket.loadAnimations()
        const uuid = await editorStore.editor.socket.connectSocket(editEnabled)
        if (uuid && !editEnabled) {
          editorStore.editor.engineComponent.sceneComponent.controls.setSocketHandler(editorStore.editor.socket);
          document.addEventListener("movePlayer", (event) => {
            editorStore.editor.socket.clientMove(event.detail)
          });
        }
      } catch (e) {
        console.warn('Socket connection failed, no multiplayer for you :(', e);
      }

      setLoaded(true)
    }
    editorStore.editor.project.dispatchUpdateStates()
  }

  useEffect(() => {
    userStore.getUser()
    serviceStore.init()

    const container = document.getElementById('engineContainer')
    if (container) editorStore.init(container).then(() => {
      if (editorStore.editor) {
        // console.log('Edit', editorStore.editor.hoverer._item)
        editorStore.editor.setEditMode(editEnabled)

        document.addEventListener('contextmenu', (e) => e.preventDefault(), false) // disable context menu

        // editorStore.editor.events.on('loadingItemsStarted', onLoadStart)
        editorStore.editor.events.on('loadingItemsFinished', onLoadFinish)
        editorStore.editor.events.on('showItem', onShowObject)
        editorStore.editor.events.on('showAttachedProduct', onShowAttachedProduct);
        editorStore.editor.events.on('deleteItem', () => setShowRightPanel(false))
        editorStore.editor.engineComponent.sceneComponent.setCameraControlMode(
          editEnabled ? EDIT_MODE : VIEW_MODE,
        )
        loadMetaSpace()
      } else console.warn('no editor')
    })

    if (isMounted.current) {
      return () => {
        componentDidMount()
        isMounted.current = false
      }
    }

  }, [])

  const componentDidMount = () => {
    serviceStore.destroy()
    if (editorStore.editor) {
      editorStore.editor.events.off('showItem', onShowObject)
      editorStore.editor.events.off('showAttachedProduct', onShowAttachedProduct);
      // editorStore.editor.events.off('loadingItemsStarted', onLoadStart)
      // editorStore.editor.events.off('loadingItemsFinished', onLoadFinish)
    } else console.warn('no editor')
    editorStore.destroy()
  }


  useEffect(() => {
    setEditEnabled(mode === 'edit')
    setPreviewMode(mode === 'preview')
  }, [mode])

  useLayoutEffect(() => {
    return () => {
      if (editorStore.editor.socket) {
        editorStore.editor.socket.closeConnection();
        editorStore.editor.socket = undefined;
      }

      if (editorStore.editor?.project?.objects) {
        console.log('Removing objects', editorStore.editor?.project?.objects)
        editorStore.editor.project.objects.forEach((object) => {
          if (object.type === TypeEntity.Picture) {
            if (object?.renderer?.fameMesh?.videoHtml) {
              object.renderer.fameMesh.videoHtml.muted = true;
              // @todo remove does not work for some reason
              object.renderer.fameMesh.videoHtml.remove();
            }
          }
        })
      }
    }
  }, [])

  useEffect(() => {
    const onUsersUpdate = () => {
      setMPRoomData([...editorStore.editor.socket.roomData]);
    }

    if (editorStore.editor.socket?.events) {
      editorStore.editor.socket.events.on("userConnected", onUsersUpdate);
      editorStore.editor.socket.events.on("userDisconnected", onUsersUpdate);
    }
  }, [editorStore.editor.socket]);

  const onLoadStart = (event) => {
    setShowProgressScreen(true)
  }

  const onLoadFinish = (event) => {
    if (editorStore.editor.engineComponent.sceneComponent.controls.isTouchControls) {
      editorStore.editor.engineComponent.sceneComponent.controls.init();
      editorStore.editor.engineComponent.sceneComponent.controls.events.on("showMPSettings", () => {
        console.log("Show mp settings")
        setShowMPSettings(true);
        setMPRoomData([...editorStore.editor.socket.roomData]);
      })
    }
    setShowProgressScreen(false)
  }

  const onBuyNFT = async (event) => {
    const nft_data = event?.item?.data?.imageMeta?._value
    console.log('onBuyNFT=', event, nft_data)
    if (nft_data) {
      console.log('space', space)
      const token_details = editorStore?.editor?.managerNFT?.roomArtworks?.find(
        ({ item }) =>
          item.token_address === nft_data.token_address &&
          item.token_id === nft_data.token_id,
      )
      console.log('token_details', token_details)
      setNFTData(token_details)
      setShowNFTBuyPanel(true)
      // editorStore.editor.engineComponent.sceneComponent.controls.controls.unlock()
    }
  }

  const onShowObject = (event) => {
    if (!event.item) return;
    if (editorStore.editor.editMode) {
      setCurrentItemData(
        event.item
          ? {
            properties: event.item.data,
            id: event.item.id,
            type: event.item.type,
          }
          : null,
      )
      setShowRightPanel(event.item !== null)
    } else {
      onBuyNFT(event)
    }
  }

  const onShowAttachedProduct = ({ item }) => {
    setFlexibleModalChildren(<ClaimProduct item={item} />);
  }

  const switchShowLeftPanel = () => {
    setShowLeftPanel(!showLeftPanel);
  }

  const setLeftTabIndexHandler = (event, newValue) => {
    setLeftTabIndex(newValue)
  }

  const saveRoomClick = () => {
    console.log('saveRoomClick=', editorStore.editor.project)
    const saveArtRoom = async () => {
      const prettyJson = true

      console.time('save project time')
      let serObj = {}
      const serData = { log: { errors: [] } }
      try {
        serObj = editorStore.editor.project.serialize()
      } catch (e) {
        console.warn(e)
      }
      if (serData.log.errors.length > 0)
        console.warn('serialized project has errors: ', serData.log.errors)
      console.log('saveArtRoom=', editorStore.editor.project, 'serObj=', serObj)
      console.time('save project stringify time')
      const serObjStr = prettyJson
        ? JSON.stringify(serObj, null, '\t')
        : JSON.stringify(serObj)
      console.timeEnd('save project stringify time')
      console.log('serObjStr=', serObjStr.length)
      const saveResult = await saveRoom(`${address}&${uid}`, serObj)
      console.log('saveResult=', saveResult)
      console.timeEnd('save project time')
      if (saveResult.data?.permissions && editorStore.editor.socket) {
        await editorStore.editor.socket.spaceSaved(serObj, editorStore.editor.managerNFT.roomArtworks)
      }
      triggerSnackbar('Metaspace saved', 'success');
      setIsSavingSpace(false);
    }
    try {
      setIsSavingSpace(true);
      saveArtRoom();
    } catch (e) {
      triggerSnackbar(`Metaspace save failed ${e.message}`, 'error');
      setIsSavingSpace(false);
    }
  }

  const goBack = () => {
    window.location.replace(getUrlProfile(searchParams.get("from")));
  }

  const isOwnerOrCurator = space && (space?.roles?.includes("owner_of") || space?.roles?.includes("curator"))

  const isAccessDenied =
    (!isLoading && space && !space?.permissions) || isError || (editEnabled && !isOwnerOrCurator)


  return (
    <FlexibleModalContext.Provider value={{ flexibleModalChildren, setFlexibleModalChildren }}>
      <EditorSnackbarContext.Provider value={{ triggerSnackbar }}>
        {showProgressScreen && <ProgressScreen background={space?.space?.preview || space?.image || spaceUri?.banner} />}
        {isAccessDenied && <AccessDenied />}
        {!editEnabled && <BlueBall title="Go back" onClick={goBack} />}
        {!editEnabled && !showProgressScreen &&
          <MultiplayerControlsPanel
            isCurator={isOwnerOrCurator}
            showMPSettings={showMPSettings}
            setShowMPSettings={setShowMPSettings}
            setModal={setFlexibleModalChildren}
            allowSubmit={space?.submit_allowed}
          />
        }
        {!editEnabled && showMPSettings &&
          <MultiplayerSettingsPanel
            roomData={MPRoomData}
            setShowMPSettings={setShowMPSettings}
          />
        }
        {showLeftPanel && editEnabled && (
          <LeftPanel
            tabIndex={leftTabIndex}
            setTabIndex={setLeftTabIndexHandler}
            event={() => {
              setShowLeftPanel(false)
            }}
            setModal={setFlexibleModalChildren}
          />
        )}
        {showRightPanel && (
          <RightPanel
            openSelector={() => {
              setShowLeftPanel(!showLeftPanel)
            }}
            currentItemData={currentItemData}
            onSetShowRightPanel={(state) => {
              setShowRightPanel(state)
            }}
            event={() => {
              setShowRightPanel(false)
            }}
            setLeftTabIndex={setLeftTabIndexHandler}
            setModalChildren={setFlexibleModalChildren}
          />
        )}
        {showNFTBuyPanel && (
          <BuyNFTPanel
            event={() => {
              setShowNFTBuyPanel(false)
            }}
            NFTData={NFTData}
          />
        )}

        {space?.roles?.includes('owner_of') && !editEnabled && !isMobileAndTablet() && (
          <EditBtn event={pushToEdit} />
        )}

        {editEnabled && (
          <>
            <Header
              setModalChildren={setFlexibleModalChildren}
              switchShowLeftPanel={switchShowLeftPanel}
              saveRoomClick={saveRoomClick}
              isSavingSpace={isSavingSpace}
              title={space?.space?.title}
            />
            <ToolsPanel
              setModalChildren={setFlexibleModalChildren}
              setShowFramesPanel={setShowFramesPanel}
              showFramesPanel={showFramesPanel}
            />
            {showFramesPanel && <FramesPanel />}
          </>
        )}

        <EngineContainer
          editEnabled={!window.location.pathname?.includes('/edit')}
          id="engineContainer"
        />

        {flexibleModalChildren &&
          <FlexibleModal
            children={flexibleModalChildren}
            setChildren={setFlexibleModalChildren}
          />
        }
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{
            width: '100%',
            background: "#262626",
            color: "#fff"
          }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </EditorSnackbarContext.Provider>
    </FlexibleModalContext.Provider>
  )
}

const EditPage = () => {
  return (
    <EditorProvider>
      <EditPageChild />
    </EditorProvider>
  )
}

export default EditPage
