export const displayNFTScript = `
let total = 0;
let rotationSpeed = 0.45;
const initRotation = 3.1415;

// This function is called once when object is loaded
function start() {

}

// This function is called every frame
function update(obj, dt) {
    total += dt;
    
    obj.data.rotation.value.z = rotationSpeed  *  total + initRotation;
    obj.update(8);
}

function click() {
    
}
`;