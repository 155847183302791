import {Box3, BoxGeometry, InstancedMesh, Matrix4, MeshBasicMaterial, Sphere, Vector3} from 'three'

export const getBounds = object3D => {
  object3D.updateMatrixWorld(true)

  let boxAll = null, sphereAll = null // new Box3()
  const box = new Box3(), sphere = new Sphere()
  const showBounds = false

  const objects3D = []
  object3D.traverse(obj => {
    if ((obj.isMesh || obj.type === 'Box3Helper') && obj.geometry) objects3D.push(obj)
  })

  let meshesCount, meshI, mesh, matrix
  if (showBounds) {
    console.log('objects3D=', objects3D)
    meshesCount = objects3D.length

    const material = new MeshBasicMaterial({color: 0x00ff00, opacity: 0.5, transparent: true})
    const geometry = new BoxGeometry(1, 1, 1)
    matrix = new Matrix4()
    mesh = new InstancedMesh(geometry, material, meshesCount)
    meshI = 0
  }

  objects3D.forEach(obj => {
    obj.geometry.computeBoundingBox()
    obj.geometry.computeBoundingSphere()

    if (obj.geometry.boundingBox && obj.geometry.boundingSphere) {
      box.copy(obj.geometry.boundingBox).applyMatrix4(obj.matrixWorld)
      if (!boxAll) boxAll = box.clone()
      else boxAll = boxAll.union(box)

      sphere.copy(obj.geometry.boundingSphere).applyMatrix4(obj.matrixWorld)
      if (!sphereAll) sphereAll = sphere.clone()
      else sphereAll = sphereAll.union(sphere)

      if (showBounds) {
        console.log('sphere=', obj, sphere.clone())
        const center = box.getCenter(new Vector3())
        const size = box.getSize(new Vector3())

        matrix.copy(new Matrix4().makeTranslation(center.x, center.y, center.z).multiply(new Matrix4().makeScale(size.x, size.y, size.z)))
        mesh.setMatrixAt(meshI, matrix)
        meshI++
      }
    }
  })

  if (showBounds) {
    console.log('meshesCount=' + meshesCount + ', meshI=' + meshI)
    // object3D.add(mesh)
    object3D.scene.add(mesh)
  }

  if (showBounds) console.log('getBounds=', object3D, 'boxAll=', boxAll, 'sphereAll=', sphereAll)
  return {box: boxAll, sphere: sphereAll}
}