import { MARKET_LIST_CONTEXT } from './const';
import { createContext, useContext } from 'react';

export const defaultState = {
  list: [],
  page: 0,
  isLoaded: false,
  isLoading: true,
  cursor: null,
};

export const MyNftsListStateContext = createContext(defaultState);
export const MyNftsListDispatchContext = createContext();

export const useMyNftsListContext = () => useContext(MyNftsListStateContext);
export const useMyNftsListDispatch = () =>
  useContext(MyNftsListDispatchContext);

export const dispatchProvider = (dispatch) => ({
  setPage: (payload) =>
    dispatch({ type: MARKET_LIST_CONTEXT.SET_PAGE, payload }),
  setIsLoading: (payload) =>
    dispatch({ type: MARKET_LIST_CONTEXT.SET_IS_LOADING, payload }),
  setMoralisData: (data, reinit = false) =>
    dispatch({
      type: MARKET_LIST_CONTEXT.SET_MORALIS_DATA,
      payload: { data, reinit },
    }),
  setItemType: (payload) =>
    dispatch({
      type: MARKET_LIST_CONTEXT.SET_ITEM_TYPE,
      payload,
    }),
});
