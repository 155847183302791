import singleTokenAbi from '../../const/abis/standards/ERC721.json'

const erc721approve = async (
  token_id,
  token_contract_address,
  sale_contract_address,
  user_eth_address,
  web3,
) => {
  console.log(web3)
  const contract = new web3.eth.Contract(singleTokenAbi, token_contract_address)
  const approvedAddress = await contract.methods
    .getApproved(token_id)
    .call({ from: user_eth_address })
  if (approvedAddress !== sale_contract_address) {
    await contract.methods
      .approve(sale_contract_address, token_id)
      .call({ from: user_eth_address })
    let response = await contract.methods
      .approve(sale_contract_address, token_id)
      .send({ from: user_eth_address })
    return response
  } else {
    return 'ok'
  }
}

export default erc721approve
