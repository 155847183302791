import Moralis from '../../../const/moralis'

export default class UserStore {
  user = null

  constructor() {
    this.user = Moralis.User.current()
  }

  getUser() {
    return Moralis.User.current()
  }
}
