import { TypeEntity, UpdateState } from '../Constants'
import { inheritConstructor, SerAttr } from '../SerializableObject'
import RenderableItem from '../RenderableItem';

export default class Light extends RenderableItem {
    onConstructor() {
        super.onConstructor(...arguments);

        this.type = TypeEntity.Light;

        const that = this

        this.addProperty('castShadow', {
            control: "edit",
            type: "bool",
            value: {
                default: false,
                get: property => property._value,
                set: (property, value) => {
                    if (property._value === value) return
                    property._value = value
                    this.setUpdateState(UpdateState.Shape)
                }
            }
        })

        this.addProperty('type', {
            control: "edit",
            type: "string",
            value: {
                default: "",
                get: property => {
                    return property._value
                },
                set: (property, value) => {
                    if (property._value === value) return
                    property._value = value
                    this.setUpdateState(UpdateState.Shape)
                }
            }
        })

        this.addProperty('intensity', {
            control: 'edit',
            type: 'float',
            value: {
                default: 1,
                get: property => {
                    return property._value
                },
                set: (property, value) => {
                    if (that.data.intensity._value === value) return
                    that.data.intensity._value = value
                    that.setUpdateState(UpdateState.Shape)
                }
            }
        })

        this.addProperty('position', {
            control: 'editFloat3',
            type: 'float',
            value: {
                get x() {
                    return that.data.position._value.x
                },
                set x(value) {
                    if (that.data.position._value.x === value) return
                    that.data.position._value.x = value
                    that.setUpdateState(UpdateState.Shape)
                },
                get y() {
                    return that.data.position._value.y
                },
                set y(value) {
                    if (that.data.position._value.y === value) return
                    that.data.position._value.y = value
                    that.setUpdateState(UpdateState.Shape)
                },
                get z() {
                    return that.data.position._value.z
                },
                set z(value) {
                    if (that.data.position._value.z === value) return
                    that.data.position._value.z = value
                    that.setUpdateState(UpdateState.Shape)
                }
            }
        })
        this.data.position._value = { x: 0, y: 0, z: 0 }

        this.addProperty('rotation', {
            control: 'editFloat3',
            type: 'float',
            value: {
                get x() {
                    return that.data.rotation._value.x
                },
                set x(value) {
                    if (that.data.rotation._value.x === value) return
                    that.data.rotation._value.x = value
                    that.setUpdateState(UpdateState.Shape)
                },
                get y() {
                    return that.data.rotation._value.y
                },
                set y(value) {
                    if (that.data.rotation._value.y === value) return
                    that.data.rotation._value.y = value
                    that.setUpdateState(UpdateState.Shape)
                },
                get z() {
                    return that.data.rotation._value.z
                },
                set z(value) {
                    if (that.data.rotation._value.z === value) return
                    that.data.rotation._value.z = value
                    that.setUpdateState(UpdateState.Shape)
                }
            }
        })
        this.data.rotation._value = { x: 0, y: 0, z: 0 }

        this.addProperty('size', {
            control: 'editFloat3',
            type: 'float',
            value: {
                get x() {
                    return that.data.size._value.x
                },
                set x(value) {
                    if (that.data.size._value.x === value) return
                    that.data.size._value.x = value
                    that.setUpdateState(UpdateState.Shape)
                },
                get y() {
                    return that.data.size._value.y
                },
                set y(value) {
                    if (that.data.size._value.y === value) return
                    that.data.size._value.y = value
                    that.setUpdateState(UpdateState.Shape)
                },
                get z() {
                    return that.data.size._value.z
                },
                set z(value) {
                    if (that.data.size._value.z === value) return
                    that.data.size._value.z = value
                    that.setUpdateState(UpdateState.Shape)
                }
            }
        })
        this.data.size._value = { x: 1, y: 1, z: 1 }

        this.addProperty('color', {
            control: 'editFloat3',
            type: 'float',
            value: {
                get r() {
                    return that.data.color._value.r
                },
                set r(value) {
                    if (that.data.color._value.r === value) return
                    that.data.color._value.r = value
                    that.setUpdateState(UpdateState.Shape)
                },
                get g() {
                    return that.data.color._value.g
                },
                set g(value) {
                    if (that.data.color._value.g === value) return
                    that.data.color._value.g = value
                    that.setUpdateState(UpdateState.Shape)
                },
                get b() {
                    return that.data.color._value.b
                },
                set b(value) {
                    if (that.data.color._value.b === value) return
                    that.data.color._value.b = value
                    that.setUpdateState(UpdateState.Shape)
                }
            }
        })
        this.data.color._value = { r: 1, g: 1, b: 1 }
    }

    setPosition(value) {
        this.data.position.value.x = value.x
        this.data.position.value.y = value.y
        this.data.position.value.z = value.z
    }

    setRotation(value) {
        this.data.rotation.value.x = value.x
        this.data.rotation.value.y = value.y
        this.data.rotation.value.z = value.z
    }

    setSize(value) {
        this.data.size.value.x = value.x
        this.data.size.value.y = value.y
        this.data.size.value.z = value.z
    }
}

inheritConstructor(Light, RenderableItem, 'Light', {
    type: TypeEntity.Light,
    serializable: [
        new SerAttr('position', { defaultValue: { x: 0, y: 0, z: 0 }, setter: (_this, value) => _this.setPosition(value) }),
        new SerAttr('intensity'),
        new SerAttr('rotation', { defaultValue: { x: 0, y: 0, z: 0 }, setter: (_this, value) => _this.setRotation(value) }),
        new SerAttr('size', { defaultValue: { x: 1, y: 1, z: 1 }, setter: (_this, value) => _this.setSize(value) }),
        new SerAttr('castShadow'),
        new SerAttr('type'),
        new SerAttr('color'),
    ]
})
