import {TypeEntity, UpdateState} from '../Constants'
import RenderableItem from '../RenderableItem'
import {inheritConstructor, SerAttr} from '../SerializableObject'

export default class Box extends RenderableItem {
  onConstructor ({project, owner, skipAdd, parsingFlag}) {
    // console.log('BOX onConstructor=', this.constructor.name, 'arguments=', arguments, 'params=', project, owner, skipAdd, parsingFlag)
    // this.onConstructor.apply(this, arguments)
    // super.onConstructor()
    //super.onConstructor.apply(this, arguments)
    super.onConstructor(...arguments)

    this.type = TypeEntity.Box

    const that = this
    this.addProperty('position', {control: 'editFloat3', type: 'float', value: {
      get x () {return that.data.position._value.x},
      set x (value) {
        if (that.data.position._value.x === value) return
        that.data.position._value.x = value
        that.setUpdateState(UpdateState.Shape)
      },
      get y () {return that.data.position._value.y},
      set y (value) {
        if (that.data.position._value.y === value) return
        that.data.position._value.y = value
        that.setUpdateState(UpdateState.Shape)
      },
      get z () {return that.data.position._value.z},
      set z (value) {
        if (that.data.position._value.z === value) return
        that.data.position._value.z = value
        that.setUpdateState(UpdateState.Shape)
      }
    }})
    this.data.position._value = {x: 0, y: 0, z: 0}

    this.addProperty('size', {control: 'editFloat3', type: 'float', value: {
      get x () {return that.data.size._value.x},
      set x (value) {
        if (that.data.size._value.x === value) return
        that.data.size._value.x = value
        that.setUpdateState(UpdateState.Shape)
      },
      get y () {return that.data.size._value.y},
      set y (value) {
        if (that.data.size._value.y === value) return
        that.data.size._value.y = value
        that.setUpdateState(UpdateState.Shape)
      },
      get z () {return that.data.size._value.z},
      set z (value) {
        if (that.data.size._value.z === value) return
        that.data.size._value.z = value
        that.setUpdateState(UpdateState.Shape)
      }
    }})
    this.data.size._value = {x: 1, y: 1, z: 1}
  }

  setPosition (value) {
    // console.log('setPosition=', value, this)
    this.data.position.value.x = value.x
    this.data.position.value.y = value.y
    this.data.position.value.z = value.z
  }

  setSize (value) {
    // console.log('setSize=', value, this)
    this.data.size.value.x = value.x
    this.data.size.value.y = value.y
    this.data.size.value.z = value.z
  }
}
inheritConstructor(Box, RenderableItem, 'Box', {type: TypeEntity.Box,
  serializable: [
    new SerAttr('position', {defaultValue: {x: 0, y: 0, z: 0}, setter: (_this, value) => _this.setPosition(value)}),
    new SerAttr('size', {defaultValue: {x: 1, y: 1, z: 1}, setter: (_this, value) => _this.setSize(value)})
  ]
})