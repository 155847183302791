import {TypeEntity} from '../Constants'
import RenderableItem from '../RenderableItem'
import {inheritConstructor, addLog, LogError} from '../SerializableObject'

export default class CameraView extends RenderableItem {
  onConstructor () {
    super.onConstructor(...arguments)

    this.hasRenderer = false

    this.type = TypeEntity.CameraView
  }

  onSerialize(obj, data) {
    super.onSerialize(...arguments)

    const sceneComponent = this.project.app.engineComponent.sceneComponent
    const controls = sceneComponent.controls
    const camera = sceneComponent.camera
    // console.log('onSerialize=', sceneComponent)

    if (sceneComponent.controlsMode === 0) {
      obj.position = controls.object.position.clone()
      obj.target = controls.target.clone()
      obj.zoom = controls.object.zoom

      obj.matrix = camera.matrix.toArray([])
      obj.zoom = camera.zoom
    } else if (sceneComponent.controlsMode === 3) {
      //obj.settings = JSON.stringify(controls.settings)
      //console.log('obj.settings=', obj.settings)
      obj.position = controls.position.clone()
      obj.target = controls.target.clone()
    }
  }

  parse(obj, data) {
    super.parse(...arguments)

    const sceneComponent = this.project.app.engineComponent.sceneComponent
    const controls = sceneComponent.controls
    const camera = sceneComponent.camera
    // console.log('parse=', sceneComponent, obj)

    if (sceneComponent.controlsMode === 0) {
      if (obj.position) controls.object.position.copy(obj.position)
      else addLog(data, new LogError({text: 'no position', from: this, extra: obj}))

      if (obj.target) controls.target.copy(obj.target)
      else addLog(data, new LogError({text: 'no target', from: this, extra: obj}))
      
      if (obj.zoom !== undefined) {
        controls.object.zoom = obj.zoom
        //camera.zoom = obj.zoom
      } else addLog(data, new LogError({text: 'no zoom', from: this, extra: obj}))

      if (obj.matrix) {
        const matrixArray = obj.matrix
        for (let i = 0; i < matrixArray.length; i++) {
            if (matrixArray[i] === null || matrixArray[i] === undefined) return
        }
        camera.matrix.fromArray(matrixArray)

        camera.matrix.decompose(camera.position, camera.quaternion, camera.scale)
        //camera.zoom = obj.zoom
      } else addLog(data, new LogError({text: 'no matrix', from: this, extra: obj}))

      //controls.object.updateProjectionMatrix()
      //controls.dispatchEvent(_changeEvent)
      //controls.update()
    } else if (sceneComponent.controlsMode === 3) {
      //controls.settings.fromJSON(obj.settings)
      if (obj.position) controls.setPosition(obj.position.x, obj.position.y, obj.position.z)
      else addLog(data, new LogError({text: 'no position', from: this, extra: obj}))

      if (obj.target) controls.setTarget(obj.target.x, obj.target.y, obj.target.z)
      else addLog(data, new LogError({text: 'no target', from: this, extra: obj}))
    }
  }
}
inheritConstructor(CameraView, RenderableItem, 'CameraView', {type: TypeEntity.CameraView, serializable: []})