import { Vector3, Quaternion, Matrix4, Euler } from 'three'
import CONFIG from '../../../../config'
import { TypeEntity } from '../Constants'
import CameraView from '../Entities/CameraView'

//import {TypeEntity} from '../Constants'

export const getResourceUrl = (url) => {
  // makes global url for short url from server
  // todo: test on host - if server & frontend on the same host - we can skip add REACT_APP_API_URL (may be)
  return url && (url?.includes('http') || url?.includes('./'))
    ? url
    : CONFIG.REACT_APP_API_URL + url // for frontend local and global - no change
}

export const deleteItem = (item) => {
  // console.warn('deleteItem=', item)
  item.destroy()
  if (item.project) item.project.remove(item)
}

export const attachPictureByPick = (item, pickInfo) => {
  if (!pickInfo.face) {
    console.warn('no pickInfo.face=', pickInfo)
    return
  }

  //const normalMatrix = new Matrix3().getNormalMatrix(pickInfo.object.matrixWorld)
  //pickInfo.face.normal.clone().applyNormalMatrix(normalMatrix)
  const normalWorld = pickInfo.face.normal
    .clone()
    .transformDirection(pickInfo.object.matrixWorld)

  //const angle = normalWorld.angleTo(new Vector3(0, 0, 1))
  //console.log('angle=', angle, 'normal=', normalWorld)

  //item.data.rotation.value.z = angle

  //const basisXc = new Vector3(1, 0, 0)
  //const basisYc = new Vector3(0, 1, 0)
  //const basisZc = new Vector3(0, 0, 1)
  /*const basisXc = new Vector3(-1, 0, 0)
  const basisYc = new Vector3(0, 0, 1)
  const basisZc = new Vector3().copy(basisXc).cross(basisYc)
  const basisCur = new Matrix4().makeBasis(basisXc, basisYc, basisZc)*/

  //const basisX = new Vector3().copy(normalWorld)
  //const basisY = new Vector3(0, 1, 0)
  //const basisZ = new Vector3().copy(basisX).cross(basisY)
  //const basisZ = new Vector3().copy(normalWorld.clone().negate())
  //const basisY = new Vector3(0, 1, 0)
  //const basisX = new Vector3().copy(basisZ).cross(basisY)
  /*const basisX = new Vector3(1, 0, 0)
  const basisY = new Vector3(0, 1, 0)
  const basisZ = new Vector3(0, 0, 1)
  const basisNew = new Matrix4().makeBasis(basisX, basisY, basisZ)*/

  /*const qRotNormal = new Quaternion().setFromUnitVectors(new Vector3(0, 0, 1), normalWorld)
  item.__forcedQuat = new Quaternion().setFromAxisAngle(new Vector3(1, 0, 0), Math.PI * 0.5)
  item.__forcedQuat.multiply(qRotNormal)*/

  //item.__forcedQuat = new Quaternion().setFromUnitVectors(new Vector3(0, 1, 0), normalWorld)
  //item.__forcedQuat = new Quaternion().setFromRotationMatrix(basisNew)
  //item.__forcedQuat = new Quaternion().setFromRotationMatrix(basisCur.clone().invert())
  //item.__forcedQuat = new Quaternion().setFromRotationMatrix(basisCur.clone().invert().multiply(basisNew))

  /*item.__forcedQuat = new Quaternion().setFromRotationMatrix(basisCur.clone().invert())
  const qRotNormal = new Quaternion().setFromUnitVectors(basisZc, normalWorld)
  item.__forcedQuat.multiply(qRotNormal)*/

  //item.__forcedQuat = new Quaternion().setFromRotationMatrix(basisCur.clone().invert().multiply(matrixRot))
  //item.__forcedQuat = new Quaternion().setFromRotationMatrix((basisCur.clone().invert().multiply(basisNew)).multiply(matrixRot))
  //item.__forcedQuat = new Quaternion().setFromRotationMatrix(basisCur.clone().invert().multiply(basisNew))

  const matrixRot = new Matrix4().lookAt(
    pickInfo.point,
    pickInfo.point.clone().add(normalWorld),
    new Vector3(0, 1, 0),
  )
  //item.__forcedQuat = new Quaternion().setFromRotationMatrix(matrixRot)
  //item.__forcedQuat.multiply(new Quaternion().setFromAxisAngle(new Vector3(1, 0, 0), -Math.PI * 0.5))
  const q = new Quaternion().setFromRotationMatrix(matrixRot)
  //q.multiply(new Quaternion().setFromAxisAngle(new Vector3(1, 0, 0), -Math.PI * 0.5)) // flipY=true

  q.multiply(
    new Quaternion().setFromAxisAngle(new Vector3(1, 0, 0), Math.PI * 0.5),
  ) // flipY=false
  q.multiply(new Quaternion().setFromAxisAngle(new Vector3(0, 0, 1), Math.PI))

  //q.multiply(new Quaternion().setFromAxisAngle(new Vector3(1, 0, 0), Math.PI * 0.5)) // flipY=false

  const rotation = new Euler()
  rotation.setFromQuaternion(q, undefined, false)

  item.data.rotation.value.x = rotation.x
  item.data.rotation.value.y = rotation.y
  item.data.rotation.value.z = rotation.z

  return normalWorld
}

export const upgradeProject = (project, serObj) => {
  if (project.version === serObj.version) return

  let view = project.objects.find((obj) => obj.type === TypeEntity.CameraView)
  if (!view) {
    view = new CameraView({ project })
    console.log('upgradeProject - add view=', view)
  }
}
