import React from 'react';
import styled from 'styled-components';
const BntBlack = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.small ? `10px 12px` : '10px 30px')};
  gap: 10px;
  background: #191919;
  border-radius: 7px;
  cursor: pointer;
  transition: all 0.2s ease;
  color: #fff;
  &:hover {
    color: #191919;
    background: #eeeeee;
  }
  &:active {
    color: #fff;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    :hover {
      color: #fff;
      background-color: #191919;
    }
  }
`;

export const CustomBlackBtn = ({
  children,
  onClick,
  small = false,
  disabled = false,
  style,
}) => {
  return (
    <BntBlack small={small} style={style} onClick={onClick} disabled={disabled}>
      {children}
    </BntBlack>
  );
};
