import React from 'react'
import { createRoot } from 'react-dom/client';
import App from './App'
import { MoralisProvider } from 'react-moralis'
import { ProvideAuth } from './helpers/auth/provider/ProvideAuth'
import CONFIG from './config'

const root = createRoot(document.getElementById("root"));
root.render(
  <MoralisProvider
    serverUrl={CONFIG.REACT_APP_MORALIS_SERVER_URL}
    appId={CONFIG.REACT_APP_MORALIS_APP_ID}
  >
    <ProvideAuth>
      <App />
    </ProvideAuth>
  </MoralisProvider>
)
