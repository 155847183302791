import { Button } from "antd";
import styled from "styled-components";

export const ButtonPublish = styled(Button)`
    border-radius: 50px;
    // background: ${({disabled}) => disabled ? '#969696' : 'linear-gradient(90deg, #4D44FE 0%, #8552F6 100%)'};
    // box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px -2px 0px 0px rgba(0, 0, 0, 0.20) inset;
    background: ${({disabled}) => disabled ? 'transparent' : 'linear-gradient(90deg, #4D44FE 0%, #8552F6 100%)'};
    box-shadow: inset 0px 0px 0px 2px ${({disabled}) => disabled ? '#969696' : '#4D44FE'};
    padding: 0;
    border: none;
    text-transform: uppercase;
    width: 48%;
    height: 40px;
    color: ${({disabled}) => disabled ? '#bbb' : '#fff'};
    cursor: ${({disabled}) => disabled ? 'auto' : 'pointer'};
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
`;
export const BorederedButton = styled(Button)`
    border-radius: 50px;
    width: 48%;
    height: 40px;
    background: transparent;
    box-shadow: inset 0px 0px 0px 2px #4D44FE;
    padding: 0;
    border: none;
    text-transform: uppercase;
    color: #fff;
    cursor: pointer;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
`;

export const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const Scrollbar = styled.div`
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: #161616;
    border-radius: 10px;
    -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
  }
  &::-webkit-scrollbar-thumb {
    background: #7E8390;
    border-radius: 10px;
  }
`;

export const ArtworkList = styled(Scrollbar)`
  height: 260px;
  max-height: 360px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ColunmList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;